import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';
import {map} from 'rxjs/operators';
import {  clientesModels} from '../models/clientes.models';

import {Router} from "@angular/router";

//import {Localidad} from '../interface/localidad';
import {Cliente} from '../interface/cliente.interface';
import {Movimiento} from '../interface/movimientos.interface';
interface myData{success: boolean, messagge: string, cliente?:Cliente[],clientesModels:clientesModels, movimientos?:Movimiento[]};
@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  private nombrephp:string='https://cerrapp.com/clientes.php';
  //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/clientes.php';
  public localidad:String[];
  constructor(private http:HttpClient,private router:Router) {
    //  this.listarLocalidades().subscribe(data=>{ this.localidad=data; console.log(this.localidad)})

  }

  listarLocalidades(){
    let localidad:String[]=["16 DE JULIO, AZUL, BUENOS AIRES",
  "17 DE AGOSTO, PUAN, BUENOS AIRES",
  "20 DE JUNIO, LA MATANZA, BUENOS AIRES",
  "20 DE JUNIO ,AP., TIGRE, BUENOS AIRES",
  "25 DE MAYO, 25 DE MAYO, BUENOS AIRES",
  "30 DE AGOSTO, TRENQUE LAUQUEN, BUENOS AIRES",
  "9 DE JULIO, 9 DE JULIO, BUENOS AIRES",
  "A.A.FERNANDEZ ,AP., LANUS, BUENOS AIRES",
  "A.F.ORMA ,PDA., LA PLATA, BUENOS AIRES",
  "ABASTO, LA PLATA, BUENOS AIRES",
  "ABBOT, MONTE, BUENOS AIRES",
  "ABEL, PEHUAJO, BUENOS AIRES",
  "ACASSUSO, SAN ISIDRO, BUENOS AIRES",
  "ACEVEDO, PERGAMINO, BUENOS AIRES",
  "ACHUPALLAS, ALBERTI, BUENOS AIRES",
  "ADELA, CHASCOMUS, BUENOS AIRES",
  "AEROPUERTO EZEIZA, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "AGOTE, MERCEDES, BUENOS AIRES",
  "AGUARA ,EST., BAHIA BLANCA, BUENOS AIRES",
  "AGUSTIN FERRARI ,PDA., MERLO, BUENOS AIRES",
  "AGUSTIN ROCA, JUNIN, BUENOS AIRES",
  "AGUSTINA, JUNIN, BUENOS AIRES",
  "ALAGON, PEHUAJO, BUENOS AIRES",
  "ALAMOS, GUAMINI, BUENOS AIRES",
  "ALASTUEY, LUJAN, BUENOS AIRES",
  "ALBARIÑO, PEHUAJO, BUENOS AIRES",
  "ALBERDI VIEJO, LEANDRO N.ALEM, BUENOS AIRES",
  "ALBERTI ,EST. ANDRES VACCAREZZA, ALBERTI, BUENOS AIRES",
  "ALDEA ROMANA, BAHIA BLANCA, BUENOS AIRES",
  "ALDO BONZI, LA MATANZA, BUENOS AIRES",
  "ALEGRE, GENERAL PAZ, BUENOS AIRES",
  "ALEJANDRO KORN, SAN VICENTE, BUENOS AIRES",
  "ALEJANDRO PETION ,AP., CAÑUELAS, BUENOS AIRES",
  "ALFEREZ SAN MARTIN, BAHIA BLANCA, BUENOS AIRES",
  "ALFREDO DEMARCHI, 9 DE JULIO, BUENOS AIRES",
  "ADROGUE, ALMIRANTE BROWN, BUENOS AIRES",
  "ALMIRANTE SOLIER, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "ALSINA, BARADERO, BUENOS AIRES",
  "ALTA VISTA, SAAVEDRA, BUENOS AIRES",
  "ALTAMIRA, MERCEDES, BUENOS AIRES",
  "ALTAMIRANO, BRANDSEN, BUENOS AIRES",
  "ALTIMPERGHER ,AP., GENERAL SARMIENTO, BUENOS AIRES",
  "ALTONA, TAPALQUE, BUENOS AIRES",
  "ALVAREZ DE TOLEDO, SALADILLO, BUENOS AIRES",
  "ALVAREZ JONTE, MAGDALENA, BUENOS AIRES",
  "ALZAGA, GONZALES CHAVES, BUENOS AIRES",
  "AMALIA, 9 DE JULIO, BUENOS AIRES",
  "AMEGHINO, GENERAL PINTO, BUENOS AIRES",
  "ANASAGASTI, NAVARRO, BUENOS AIRES",
  "ANCON ,EST., PEHUAJO, BUENOS AIRES",
  "ANDAT, DAIREAUX, BUENOS AIRES",
  "ANDERSON, 25 DE MAYO, BUENOS AIRES",
  "ANGEL ECHEVERRY, LA PLATA, BUENOS AIRES",
  "ANTONIO CARBONI, LOBOS, BUENOS AIRES",
  "APARICIO, CORONEL DORREGO, BUENOS AIRES",
  "ARANA, LA PLATA, BUENOS AIRES",
  "ARANO, ADOLFO ALSINA, BUENOS AIRES",
  "ARAUJO, 25 DE MAYO, BUENOS AIRES",
  "ARBOLEDAS, DAIREAUX, BUENOS AIRES",
  "ARBOLITO, COLON, BUENOS AIRES",
  "ARENALES ,EST., GENERAL ARENALES, BUENOS AIRES",
  "ARENAZA, LINCOLN, BUENOS AIRES",
  "ARGERICH, VILLARINO, BUENOS AIRES",
  "ARIEL, AZUL, BUENOS AIRES",
  "ARISTOBULO DEL VALLE ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "ARRECIFES, BARTOLOME MITRE, BUENOS AIRES",
  "ARRIBEÑOS, GENERAL ARENALES, BUENOS AIRES",
  "ARROYO CORTO, SAAVEDRA, BUENOS AIRES",
  "ARROYO DE LA CRUZ, EXALTACION DE LA CRUZ, BUENOS AIRES",
  "ARROYO DE LOS HUESOS, AZUL, BUENOS AIRES",
  "ARROYO DEL MEDIO, PERGAMINO, BUENOS AIRES",
  "ARROYO DULCE, SALTO, BUENOS AIRES",
  "ARROYO VENADO, GUAMINI, BUENOS AIRES",
  "ARTURO SEGUI, LA PLATA, BUENOS AIRES",
  "ARTURO VATTEONE ,EST, ADOLFO ALSINA, BUENOS AIRES",
  "ASAMBLEA, BRAGADO, BUENOS AIRES",
  "ASCENSION, GENERAL ARENALES, BUENOS AIRES",
  "ASTURIAS, PEHUAJO, BUENOS AIRES",
  "ATALAYA, MAGDALENA, BUENOS AIRES",
  "ATILIO PESSAGNO, CHASCOMUS, BUENOS AIRES",
  "ATUCHA, ZARATE, BUENOS AIRES",
  "AVELLANEDA, AVELLANEDA, BUENOS AIRES",
  "AVESTRUZ, ADOLFO ALSINA, BUENOS AIRES",
  "AYACUCHO, AYACUCHO, BUENOS AIRES",
  "AZCUENAGA, SAN ANDRES DE GILES, BUENOS AIRES",
  "AZOPARDO, PUAN, BUENOS AIRES",
  "AZUCENA, TANDIL, BUENOS AIRES",
  "AZUL, AZUL, BUENOS AIRES",
  "BACACAY, 9 DE JULIO, BUENOS AIRES",
  "BACCAR, SAN ISIDRO, BUENOS AIRES",
  "BADANO, RIVADAVIA, BUENOS AIRES",
  "BAHIA BLANCA, BAHIA BLANCA, BUENOS AIRES",
  "BAHIA SAN BLAS, PATAGONES, BUENOS AIRES",
  "BAIGORRITA, GENERAL VIAMONTE, BUENOS AIRES",
  "BAJO HONDO, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "BALCARCE, BALCARCE, BUENOS AIRES",
  "BALNEARIO ORENSE, TRES ARROYOS, BUENOS AIRES",
  "BALNEARIO ORIENTE, CORONEL DORREGO, BUENOS AIRES",
  "BALSA, LINCOLN, BUENOS AIRES",
  "BANCALARI ,AP., TIGRE, BUENOS AIRES",
  "BANDERALO, GENERAL VILLEGAS, BUENOS AIRES",
  "BANFIELD, LOMAS DE ZAMORA, BUENOS AIRES",
  "BARADERO, BARADERO, BUENOS AIRES",
  "BERISSO, BERISSO, BUENOS AIRES",
  "BARKER, JUAREZ, BUENOS AIRES",
  "BARRIO COLONIA ALEGRE, GENERAL PUEYRREDON, BUENOS AIRES",
  "BARRIO EL RETAZO, GENERAL PUEYRREDON, BUENOS AIRES",
  "BARRIO SAN PATRICIO, GENERAL PUEYRREDON, BUENOS AIRES",
  "BARROW, TRES ARROYOS, BUENOS AIRES",
  "BARTOLOME BAVIO, MAGDALENA, BUENOS AIRES",
  "BARTOLOME MITRE ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "BATAN, GENERAL PUEYRREDON, BUENOS AIRES",
  "BATHURST, CORONEL SUAREZ, BUENOS AIRES",
  "BAUDRIX, ALBERTI, BUENOS AIRES",
  "BAYAUCA, LINCOLN, BUENOS AIRES",
  "BELEN DE ESCOBAR, ESCOBAR, BUENOS AIRES",
  "BELLA VISTA, GENERAL SARMIENTO, BUENOS AIRES",
  "BELLOCO, CARLOS CASARES, BUENOS AIRES",
  "BENAVIDEZ, TIGRE, BUENOS AIRES",
  "BENITEZ, CHIVILCOY, BUENOS AIRES",
  "BERAZATEGUI, BERAZATEGUI, BUENOS AIRES",
  "BERDIER, SALTO, BUENOS AIRES",
  "BERMUDEZ, LINCOLN, BUENOS AIRES",
  "BERNAL, QUILMES, BUENOS AIRES",
  "BERRAONDO, TORQUINST, BUENOS AIRES",
  "BERUTTI, TRENQUE LAUQUEN, BUENOS AIRES",
  "BILLINGHURST, TRES DE FEBRERO, BUENOS AIRES",
  "BLANCA GRANDE, OLAVARRIA, BUENOS AIRES",
  "BLANDENGUES, JUNIN, BUENOS AIRES",
  "BLAQUIER, GENERAL PINTO, BUENOS AIRES",
  "BLAS DURAÑONA, 25 DE MAYO, BUENOS AIRES",
  "BOCA DEL TORO, TIGRE, BUENOS AIRES",
  "BOCAYUVA, PELLEGRINI, BUENOS AIRES",
  "BONNEMENT ,AP., GENERAL BELGRANO, BUENOS AIRES",
  "BORDENAVE, PUAN, BUENOS AIRES",
  "BORDEU, BAHIA BLANCA, BUENOS AIRES",
  "BORGES ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "BOSCH, BALCARCE, BUENOS AIRES",
  "BOSQUES, FLORENCIO VARELA, BUENOS AIRES",
  "BOULOGNE, SAN ISIDRO, BUENOS AIRES",
  "BRAGADO, BRAGADO, BUENOS AIRES",
  "BRANDSEN ,EST.CNL.BRANDSEN, BRANDSEN, BUENOS AIRES",
  "BUCHANAN, LA PLATA, BUENOS AIRES",
  "BURZACO, ALMIRANTE BROWN, BUENOS AIRES",
  "CABILDO, BAHIA BLANCA, BUENOS AIRES",
  "CACHARI, AZUL, BUENOS AIRES",
  "CADRET, CARLOS CASARES, BUENOS AIRES",
  "CAIOMUTA, SALLIQUELO, BUENOS AIRES",
  "CALDERON, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "CALFUCURA, MAR CHIQUITA, BUENOS AIRES",
  "CALVO, CORONEL DORREGO, BUENOS AIRES",
  "CAMBACERES, 9 DE JULIO, BUENOS AIRES",
  "CAMET, GENERAL PUEYRREDON, BUENOS AIRES",
  "CAMPANA, CAMPANA, BUENOS AIRES",
  "CAMPO DE MAYO, GENERAL SARMIENTO, BUENOS AIRES",
  "CAMPODONICO, AZUL, BUENOS AIRES",
  "CAN, ROBERTO, ROJAS, BUENOS AIRES",
  "CAÑADA MARIANO ,AP., ADOLFO ALSINA, BUENOS AIRES",
  "CAÑADA SECA, GENERAL VILLEGAS, BUENOS AIRES",
  "CANAL SAN FERNANDO ,EST., TIGRE, BUENOS AIRES",
  "CANGALLO, AYACUCHO, BUENOS AIRES",
  "CANNING, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "CANONIGO GORRITI, ADOLFO ALSINA, BUENOS AIRES",
  "CAÑUELAS, CAÑUELAS, BUENOS AIRES",
  "CAPILLA DEL SEÑOR ,EST.CAPILLA, EXALTACION DE LA CRUZ, BUENOS AIRES",
  "CAPITAN CASTRO, PEHUAJO, BUENOS AIRES",
  "CAPITAN SARMIENTO, CAPITAN SARMIENTO, BUENOS AIRES",
  "CARABELAS, ROJAS, BUENOS AIRES",
  "CARAPACHAY, VICENTE LOPEZ, BUENOS AIRES",
  "CARBONI, ANTONIO, LOBOS, BUENOS AIRES",
  "CARDENAL CAGLIERO, PATAGONES, BUENOS AIRES",
  "CARHUE, ADOLFO ALSINA, BUENOS AIRES",
  "CARMEN DE ARECO, CARMEN DE ARECO, BUENOS AIRES",
  "CASALINS, PILA, BUENOS AIRES",
  "CASANOVA, ISIDRO, LA MATANZA, BUENOS AIRES",
  "CASARES, CARLOS, CARLOS CASARES, BUENOS AIRES",
  "CASARES, VICENTE, CAÑUELAS, BUENOS AIRES",
  "CASAS, JOSE B., PATAGONES, BUENOS AIRES",
  "CASBAS, GUAMINI, BUENOS AIRES",
  "CASCADA, CORONEL SUAREZ, BUENOS AIRES",
  "CASCALLARES, MICAELA, TRES ARROYOS, BUENOS AIRES",
  "CASEROS, TRES DE FEBRERO, BUENOS AIRES",
  "CASEY, GUAMINI, BUENOS AIRES",
  "CASTELAR, MORON, BUENOS AIRES",
  "CASTELLI, CASTELLI, BUENOS AIRES",
  "CASTILLA, CHACABUCO, BUENOS AIRES",
  "CASTILLO, RAFAEL, LA MATANZA, BUENOS AIRES",
  "CAZON, SALADILLO, BUENOS AIRES",
  "CENTRO AGRICOLA EL PATO ,AP., BERAZATEGUI, BUENOS AIRES",
  "CERRITO, RIVADAVIA, BUENOS AIRES",
  "CHACABUCO, CHACABUCO, BUENOS AIRES",
  "CHANCAY, GENERAL VIAMONTE, BUENOS AIRES",
  "CHAPADMALAL, GENERAL PUEYRREDON, BUENOS AIRES",
  "CHAPALEUFU, RAUCH, BUENOS AIRES",
  "CHAS, GENERAL BELGRANO, BUENOS AIRES",
  "CHASCOMUS, CHASCOMUS, BUENOS AIRES",
  "CHASICO, TORNQUIST, BUENOS AIRES",
  "CHENAUT, EXALTACION DE LA CRUZ, BUENOS AIRES",
  "CHICLANA, PEHUAJO, BUENOS AIRES",
  "CHILLAR, AZUL, BUENOS AIRES",
  "CHIVILCOY, CHIVILCOY, BUENOS AIRES",
  "CHOIQUE, TORNQUIST, BUENOS AIRES",
  "CIRCUNVALACION ,EST., LA PLATA, BUENOS AIRES",
  "CITY BELL, LA PLATA, BUENOS AIRES",
  "CIUDAD GRL.MARTIN MIGUEL DE GUEMES, LA MATANZA, BUENOS AIRES",
  "CIUDADELA, TRES DE FEBRERO, BUENOS AIRES",
  "CLARAZ, NECOCHEA, BUENOS AIRES",
  "CLAROMECO, TRES ARROYOS, BUENOS AIRES",
  "CLAYPOLE, ALMIRANTE BROWN, BUENOS AIRES",
  "COBO, MAR CHIQUITA, BUENOS AIRES",
  "LEZAMA, LEZAMA, BUENOS AIRES",
  "COCHRANE, BAHIA BLANCA, BUENOS AIRES",
  "COLIQUEO, CHACABUCO, BUENOS AIRES",
  "COLMAN, MARTIN, RAUCH, BUENOS AIRES",
  "COLON, COLON, BUENOS AIRES",
  "COLONIA HOGAR RICARDO GUTIERREZ, MARCOS PAZ, BUENOS AIRES",
  "COLONIA SAN MIGUEL ARCANGEL, ADOLFO ALSINA, BUENOS AIRES",
  "COLONIA SERE, CARLOS TEJEDOR, BUENOS AIRES",
  "COLONIA VELAZ, SAN PEDRO, BUENOS AIRES",
  "COMANDANTE GIRIBONE, CHASCOMUS, BUENOS AIRES",
  "COMANDANTE NICANOR OTAMENDI, GENERAL ALVARADO, BUENOS AIRES",
  "COMODORO PY, BRAGADO, BUENOS AIRES",
  "CONESA, SAN NICOLAS, BUENOS AIRES",
  "COPETONAS, TRES ARROYOS, BUENOS AIRES",
  "CORACEROS ,EST., HIPOLITO YRIGOYEN, BUENOS AIRES",
  "CORAZZI, TRENQUE LAUQUEN, BUENOS AIRES",
  "CORBETT, 9 DE JULIO, BUENOS AIRES",
  "CORONADO, MARTIN, TRES DE FEBRERO, BUENOS AIRES",
  "CORONEL BOERR, LAS FLORES, BUENOS AIRES",
  "CORONEL BRANDSEN, BRANDSEN, BUENOS AIRES",
  "CORONEL CHARLONE, GENERAL VILLEGAS, BUENOS AIRES",
  "CORONEL DORREGO, CORONEL DORREGO, BUENOS AIRES",
  "CORONEL FALCON, CORONEL PRINGLES, BUENOS AIRES",
  "CORONEL GRANADA, GENERAL PINTO, BUENOS AIRES",
  "CORONEL MARCELINO FRYRE, DAIREAUX, BUENOS AIRES",
  "CORONEL MARTINEZ DE HOZ, LINCOLN, BUENOS AIRES",
  "CORONEL MARTINIANO CHILAVERT ,PDA., SAN MARTIN, BUENOS AIRES",
  "CORONEL MOM, ALBERTI, BUENOS AIRES",
  "CORONEL PRINGLES ,EST.PRINGLES, CORONEL PRINGLES, BUENOS AIRES",
  "CORONEL RODOLFO BUNGE, BENITO JUAREZ, BUENOS AIRES",
  "CORONEL SUAREZ, CORONEL SUAREZ, BUENOS AIRES",
  "CORONEL VIDAL, MAR CHIQUITA, BUENOS AIRES",
  "CORREAS, IGNACIO, LA PLATA, BUENOS AIRES",
  "CORTI, BAHIA BLANCA, BUENOS AIRES",
  "COSTA, EDUARDO, GENERAL PINTO, BUENOS AIRES",
  "COVELLO, TAPALQUE, BUENOS AIRES",
  "CRISTIANO MUERTO, SAN CAYETANO, BUENOS AIRES",
  "CROTO, TAPALQUE, BUENOS AIRES",
  "CURA MALAL, CORONEL SUAREZ, BUENOS AIRES",
  "CURARU, CARLOS TEJEDOR, BUENOS AIRES",
  "D ORBIGNY, CORONEL SUAREZ, BUENOS AIRES",
  "DAIREAUX, DAIREAUX, BUENOS AIRES",
  "DARREGUEIRA, PUAN, BUENOS AIRES",
  "DE BARY, PELLEGRINI, BUENOS AIRES",
  "DE ELIA, AGUSTIN ,AP., LA MATANZA, BUENOS AIRES",
  "DE LA CANAL, TANDIL, BUENOS AIRES",
  "DE LA GARMA, ADOLFO GONZALES CHAVES, BUENOS AIRES",
  "DE LA PLAZA, VICTORINO, GUAMINI, BUENOS AIRES",
  "DE LA RIESTRA, NORBERTO, 25 DE MAYO, BUENOS AIRES",
  "DE LUCA, ESTEBAN, CARLOS TEJEDOR, BUENOS AIRES",
  "DE VICTORIA, FRANCISCO, TRENQUE LAUQUEN, BUENOS AIRES",
  "DEFFERRARI, SAN CAYETANO, BUENOS AIRES",
  "DEL CARRIL, SALADILLO, BUENOS AIRES",
  "DEL VALLE, 25 DE MAYO, BUENOS AIRES",
  "DEL VALLE,ARISTOBULO ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "DEL VISO, GENERAL SARMIENTO, BUENOS AIRES",
  "DELTA ,EST., TIGRE, BUENOS AIRES",
  "DENNEHY, 9 DE JULIO, BUENOS AIRES",
  "DESCALZI, NICOLAS, CORONEL DORREGO, BUENOS AIRES",
  "DIQUE LUJAN, TIGRE, BUENOS AIRES",
  "DOCTOR DOMINGO HAROSTEGUY, LAS FLORES, BUENOS AIRES",
  "DOCTOR RICARDO LEVENE ,EST., BERAZATEGUI, BUENOS AIRES",
  "DOLORES, DOLORES, BUENOS AIRES",
  "DOMSELAAR, SAN VICENTE, BUENOS AIRES",
  "DON BOSCO, QUILMES, BUENOS AIRES",
  "DON CIPRIANO, CHASCOMUS, BUENOS AIRES",
  "DON TORCUATO, TIGRE, BUENOS AIRES",
  "DOS HERMANOS, GENERAL PINTO, BUENOS AIRES",
  "DOYLE, SAN PEDRO, BUENOS AIRES",
  "DRABBLE, GENERAL VILLEGAS, BUENOS AIRES",
  "DRYSDALE, CARLOS TEJEDOR, BUENOS AIRES",
  "DUCOS, SAAVEDRA, BUENOS AIRES",
  "DUDIGNAC, 9 DE JULIO, BUENOS AIRES",
  "DUFAUR, SAAVEDRA, BUENOS AIRES",
  "DUGGAN, SAN ANTONIO DE ARECO, BUENOS AIRES",
  "DUHAU, TRENQUE LAUQUEN, BUENOS AIRES",
  "DURAÑONA, OLAVARRIA, BUENOS AIRES",
  "DUSSAUD ,EST., GENERAL PINTO, BUENOS AIRES",
  "EGAÑA, RAUCH, BUENOS AIRES",
  "EL ARBOLITO, COLON, BUENOS AIRES",
  "EL DIA, GENERAL VILLEGAS, BUENOS AIRES",
  "EL DIVISORIO, CORONEL PRINGLES, BUENOS AIRES",
  "EL DORADO, LEANDRO N.ALEM, BUENOS AIRES",
  "EL LENGUARAZ, LOBERIA, BUENOS AIRES",
  "EL MORO, LOBERIA, BUENOS AIRES",
  "EL PALOMAR, MORON, BUENOS AIRES",
  "EL PARAISO, RAMALLO, BUENOS AIRES",
  "EL PENSAMIENTO, CORONEL PRINGLES, BUENOS AIRES",
  "EL PEREGRINO, GENERAL PINTO, BUENOS AIRES",
  "EL TALAR, TIGRE, BUENOS AIRES",
  "EL TEJAR, 9 DE JULIO, BUENOS AIRES",
  "EL TRIGO, LAS FLORES, BUENOS AIRES",
  "EL TRIUNFO, LINCOLN, BUENOS AIRES",
  "EL ZORRO, CORONEL DORREGO, BUENOS AIRES",
  "ELIZALDE, RUFINO DE, LA PLATA, BUENOS AIRES",
  "ELORDI, GENERAL VILLEGAS, BUENOS AIRES",
  "ELVIRA, LOBOS, BUENOS AIRES",
  "EMMA, GENERAL ALVEAR, BUENOS AIRES",
  "ENCINA, CARLOS TEJEDOR, BUENOS AIRES",
  "ENERGIA, NECOCHEA, BUENOS AIRES",
  "ENSENADA, ENSENADA, BUENOS AIRES",
  "EPUMER ,EST.YUTUYACO, ADOLFO ALSINA, BUENOS AIRES",
  "ESCALADA, ZARATE, BUENOS AIRES",
  "ESCALADA, REMEDIOS DE, LANUS, BUENOS AIRES",
  "ESCOBAR, BELEN DE ,EST.ESCOBAR, ESCOBAR, BUENOS AIRES",
  "ESCRIBANO, PEDRO N., CHASCOMUS, BUENOS AIRES",
  "ESPARTILLAR, SAAVEDRA, BUENOS AIRES",
  "ESPIGAS, OLAVARRIA, BUENOS AIRES",
  "ESPORA, SAN ANDRES DE GILES, BUENOS AIRES",
  "ESQUINA NEGRA, LA PLATA, BUENOS AIRES",
  "ESTELA, PUAN, BUENOS AIRES",
  "ESTHER, SALADILLO, BUENOS AIRES",
  "ESTOMBA, TORQUINST, BUENOS AIRES",
  "ESTRUGAMOU, LAS FLORES, BUENOS AIRES",
  "ETCHEVERRY, ANGEL, LA PLATA, BUENOS AIRES",
  "EZEIZA, JOSE MARIA, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "EZPELETA, QUILMES, BUENOS AIRES",
  "FAIR, AYACUCHO, BUENOS AIRES",
  "FARO, CORONEL DORREGO, BUENOS AIRES",
  "FAUZON, 9 DE JULIO, BUENOS AIRES",
  "FERNANDEZ, A.A.,PDA., LANUS, BUENOS AIRES",
  "FERNANDEZ, JUAN N., NECOCHEA, BUENOS AIRES",
  "FERRARI, AGUSTIN ,PDA., MERLO, BUENOS AIRES",
  "FERRARI, JOSE, MAGDALENA, BUENOS AIRES",
  "FERRE, GENERAL ARENALES, BUENOS AIRES",
  "FIORITO, LOMAS DE ZAMORA, BUENOS AIRES",
  "FLORENCIO VARELA, FLORENCIO VARELA, BUENOS AIRES",
  "FLORIDA, VICENTE LOPEZ, BUENOS AIRES",
  "FLORIDA, VICENTE LOPEZ, BUENOS AIRES",
  "FORTABAT, ALFREDO ,EST., BENITO JUAREZ, BUENOS AIRES",
  "FORTIN ACHA, LEANDRO N.ALEM, BUENOS AIRES",
  "FORTIN OLAVARRIA, RIVADAVIA, BUENOS AIRES",
  "FORTIN TIBURCIO, JUNIN, BUENOS AIRES",
  "FORTIN VIGILANCIA, LINCOLN, BUENOS AIRES",
  "FRENCH, 9 DE JULIO, BUENOS AIRES",
  "FULTON, TANDIL, BUENOS AIRES",
  "GAHAN, SALTO, BUENOS AIRES",
  "GAMBIER ,EST., LA PLATA, BUENOS AIRES",
  "GAMEN, PEDRO, PEHUAJO, BUENOS AIRES",
  "GANDARA, CHASCOMUS, BUENOS AIRES",
  "GARCIA DEL RIO, TORQUINST, BUENOS AIRES",
  "GARCIA, MANUEL J., MERCEDES, BUENOS AIRES",
  "GARDEY, TANDIL, BUENOS AIRES",
  "GARIN, ESCOBAR, BUENOS AIRES",
  "GARRE, GUAMINI, BUENOS AIRES",
  "GASCON, ESTEBAN AGUSTIN, ADOLFO ALSINA, BUENOS AIRES",
  "GENERAL ALVEAR, GENERAL ALVEAR, BUENOS AIRES",
  "GENERAL ARENALES ,EST.ARENALES, GENERAL ARENALES, BUENOS AIRES",
  "GENERAL BELGRANO, GENERAL BELGRANO, BUENOS AIRES",
  "GENERAL CONESA, TORDILLO, BUENOS AIRES",
  "GENERAL DANIEL CERRI ,EST.GRL.CERRI, BAHIA BLANCA, BUENOS AIRES",
  "GENERAL GUIDO, GENERAL GUIDO, BUENOS AIRES",
  "GENERAL HORNOS, GENERAL LAS HERAS, BUENOS AIRES",
  "GENERAL JUAN MADARIAGA ,EST.G.MADARIAGA, GENERAL JUAN MADARIAGA, BUENOS AIRES",
  "GENERAL LA MADRID, GENERAL LA MADRID, BUENOS AIRES",
  "GENERAL LAS HERAS ,EST.LAS HERAS, GENERAL LAS HERAS, BUENOS AIRES",
  "GENERAL LAVALLE, GENERAL LAVALLE, BUENOS AIRES",
  "GENERAL MANSILLA ,EST.BARTOLOME BAVIO, MAGDALENA, BUENOS AIRES",
  "GENERAL O'BRIEN, BRAGADO, BUENOS AIRES",
  "GENERAL PACHECO, TIGRE, BUENOS AIRES",
  "GENERAL PINTO, GENERAL PINTO, BUENOS AIRES",
  "GENERAL PIRAN, MAR CHIQUITA, BUENOS AIRES",
  "GENERAL RODRIGUEZ, GENERAL RODRIGUEZ, BUENOS AIRES",
  "GENERAL ROJO, SAN NICOLAS, BUENOS AIRES",
  "GENERAL RONDEAU, PUAN, BUENOS AIRES",
  "GENERAL SAN MARTIN ,EST.SAN MARTIN, SAN MARTIN, BUENOS AIRES",
  "GENERAL VIAMONTE ,EST.LOS TOLDOS, GENERAL VIAMONTE, BUENOS AIRES",
  "GENERAL VILLEGAS, GENERAL VILLEGAS, BUENOS AIRES",
  "GERLI, AVELLANEDA, BUENOS AIRES",
  "GERMANIA ,EST.MAYOR ORELLANO, GENERAL PINTO, BUENOS AIRES",
  "GIL, CORONEL DORREGO, BUENOS AIRES",
  "GIRODIAS, TRENQUE LAUQUEN, BUENOS AIRES",
  "GIRONDO, PEHUAJO, BUENOS AIRES",
  "GLEW, ALMIRANTE BROWN, BUENOS AIRES",
  "GNECCO, PEHUAJO, BUENOS AIRES",
  "GOBERNADOR ARIAS, CARLOS CASARES, BUENOS AIRES",
  "GOBERNADOR CASTRO, SAN PEDRO, BUENOS AIRES",
  "GOBERNADOR EDUARDO ARANA ,AP., LA PLATA, BUENOS AIRES",
  "GOBERNADOR LUIS GARCIA ,AP., LA PLATA, BUENOS AIRES",
  "GOBERNADOR MONTEVERDE, FLORENCIO VARELA, BUENOS AIRES",
  "GOBERNADOR UDAONDO, CAÑUELAS, BUENOS AIRES",
  "GOBERNADOR UGARTE, 25 DE MAYO, BUENOS AIRES",
  "GOMEZ, VALENTIN, RIVADAVIA, BUENOS AIRES",
  "GONDRA, GENERAL VILLEGAS, BUENOS AIRES",
  "GONNET, MANUEL B., LA PLATA, BUENOS AIRES",
  "GONZALEZ CATAN, LA MATANZA, BUENOS AIRES",
  "GONZALEZ CHAVES, ADOLFO ,EST.G.CHAVES, ADOLFO GONZALES CHAVES, BUENOS AIRES",
  "GONZALEZ MORENO, RIVADAVIA, BUENOS AIRES",
  "GORCHS, GENERAL BELGRANO, BUENOS AIRES",
  "GORINA, JOAQUIN, LA PLATA, BUENOS AIRES",
  "GOROSTIAGA, CHIVILCOY, BUENOS AIRES",
  "GOUIN, CARMEN DE ARECO, BUENOS AIRES",
  "GOYENA, SAAVEDRA, BUENOS AIRES",
  "GOYENECHE, MONTE, BUENOS AIRES",
  "GRACIARENA, SALLIQUELO, BUENOS AIRES",
  "GRAND BOURG, GENERAL SARMIENTO, BUENOS AIRES",
  "GRAVIÑA, RICARDO, BENITO JUAREZ, BUENOS AIRES",
  "GRUNBEIN, BAHIA BLANCA, BUENOS AIRES",
  "GRUNBEIN ,EST.EMP., CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "GUAMINI, GUAMINI, BUENOS AIRES",
  "GUANACO, PEHUAJO, BUENOS AIRES",
  "GUERNICA, SAN VICENTE, BUENOS AIRES",
  "GUERRERO, CASTELLI, BUENOS AIRES",
  "GUERRICO, PERGAMINO, BUENOS AIRES",
  "GUILLON, LUIS, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "GUISASOLA,JOSE A., CORONEL DORREGO, BUENOS AIRES",
  "GUNTHER, GENERAL PINTO, BUENOS AIRES",
  "GUTIERREZ, JUAN M., BERAZATEGUI, BUENOS AIRES",
  "HAEDO, MORON, BUENOS AIRES",
  "HALE, BOLIVAR, BUENOS AIRES",
  "HEAVY, SAN ANDRES DE GILES, BUENOS AIRES",
  "HENDERSON, HIPOLITO YRIGOYEN, BUENOS AIRES",
  "HEREFORD, CARLOS TEJEDOR, BUENOS AIRES",
  "HERNANDEZ, JOSE, LA PLATA, BUENOS AIRES",
  "HERRERA VEGAS, HIPOLITO YRIGOYEN, BUENOS AIRES",
  "HINOJO, OLAVARRIA, BUENOS AIRES",
  "HIRSCH, MAURICIO, CARLOS CASARES, BUENOS AIRES",
  "HORTENSIA, CARLOS CASARES, BUENOS AIRES",
  "HUANGUELEN, CORONEL SUAREZ, BUENOS AIRES",
  "HUDSON, GUILLERMO E., BERAZATEGUI, BUENOS AIRES",
  "HUERGO,DELFIN, ADOLFO ALSINA, BUENOS AIRES",
  "HUETEL, 25 DE MAYO, BUENOS AIRES",
  "HUNTER, ROJAS, BUENOS AIRES",
  "HURLINGHAM, MORON, BUENOS AIRES",
  "HUSARES, CARLOS TEJEDOR, BUENOS AIRES",
  "IBAÑEZ, GENERAL BELGRANO, BUENOS AIRES",
  "IBARRA,JUAN F., BOLIVAR, BUENOS AIRES",
  "IGARZABAL, PATAGONES, BUENOS AIRES",
  "INDART, INES, SALTO, BUENOS AIRES",
  "INDIO RICO, CORONEL PRINGLES, BUENOS AIRES",
  "INGENIERO ANDRES VILLANUEVA ,AP., LA PLATA, BUENOS AIRES",
  "INGENIERO BALBIN, GENERAL PINTO, BUENOS AIRES",
  "INGENIERO BEAUGEY, CARLOS TEJEDOR, BUENOS AIRES",
  "INGENIERO BUNGE, LOMAS DE ZAMORA, BUENOS AIRES",
  "INGENIERO JUAN ALLAN, FLORENCIO VARELA, BUENOS AIRES",
  "INGENIERO MASCHWITZ, ESCOBAR, BUENOS AIRES",
  "INGENIERO MONETA, SAN PEDRO, BUENOS AIRES",
  "INGENIERO PABLO NOGUES, GENERAL SARMIENTO, BUENOS AIRES",
  "INGENIERO SANTIAGO BRIAN ,AP., LA MATANZA, BUENOS AIRES",
  "INGENIERO THOMPSON, PELLEGRINI, BUENOS AIRES",
  "INGENIERO WIHTE, BAHIA BLANCA, BUENOS AIRES",
  "INGENIERO WILLIAMS, NAVARRO, BUENOS AIRES",
  "INVERNADAS, GENERAL JUAN MADARIAGA, BUENOS AIRES",
  "IRALA, BRAGADO, BUENOS AIRES",
  "IRAOLA, TANDIL, BUENOS AIRES",
  "IRENE, CORONEL DORREGO, BUENOS AIRES",
  "IRIARTE, GENERAL PINTO, BUENOS AIRES",
  "ISLAS, 25 DE MAYO, BUENOS AIRES",
  "ITURREGUI, OLAVARRIA, BUENOS AIRES",
  "ITUZAINGO, MORON, BUENOS AIRES",
  "JEPPENER, BRANDSEN, BUENOS AIRES",
  "JOFRE, TOMAS, MERCEDES, BUENOS AIRES",
  "JUANCHO, GENERAL JUAN MADARIAGA, BUENOS AIRES",
  "BENITO JUAREZ, BENITO JUAREZ, BUENOS AIRES",
  "JUNIN, JUNIN, BUENOS AIRES",
  "JUSTO VILLEGAS ,PDA., LA MATANZA, BUENOS AIRES",
  "JUSTO, JUAN B. ,PDA., VICENTE LOPEZ, BUENOS AIRES",
  "KILOMERO 8 ,AP., BAHIA BLANCA, BUENOS AIRES",
  "KILOMETRO 12 ,AP., LA MATANZA, BUENOS AIRES",
  "KILOMETRO 17 ,PDA., SAN FERNANDO, BUENOS AIRES",
  "KILOMETRO 19 ,AP., MORON, BUENOS AIRES",
  "KILOMETRO 27 ,AP., FLORENCIO VARELA, BUENOS AIRES",
  "KILOMETRO 34 ,AP., LOMAS DE ZAMORA, BUENOS AIRES",
  "KILOMETRO 36 ,AP., FLORENCIO VARELA, BUENOS AIRES",
  "KILOMETRO 40 ,AP., FLORENCIO VARELA, BUENOS AIRES",
  "KILOMETRO 40 ,AP., TIGRE, BUENOS AIRES",
  "KILOMETRO 45 ,AP., MARCOS PAZ, BUENOS AIRES",
  "KILOMETRO 48 ,AP., ESCOBAR, BUENOS AIRES",
  "KILOMETRO 53 ,AP., MARCOS PAZ, BUENOS AIRES",
  "KILOMETRO 54 ,PDA., ENSENADA, BUENOS AIRES",
  "KILOMETRO 55 ,AP., MARCOS PAZ, BUENOS AIRES",
  "KILOMETRO 641 ,PDA., CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "KILOMETRO 9 ,AP., BAHIA BLANCA, BUENOS AIRES",
  "KORN, ALEJANDRO, SAN VICENTE, BUENOS AIRES",
  "KRABBE, CORONEL PRINGLES, BUENOS AIRES",
  "LA ANGELITA, GENERAL ARENALES, BUENOS AIRES",
  "LA BARRANCOSA, SALADILLO, BUENOS AIRES",
  "LA BEBA, ROJAS, BUENOS AIRES",
  "LA CARRETA, TRENQUE LAUQUEN, BUENOS AIRES",
  "LA COLINA, GENERAL LA MADRID, BUENOS AIRES",
  "LA CONSTANCIA ,AP.KILOMETRO 360, AYACUCHO, BUENOS AIRES",
  "LA COPETA ,EST., DAIREAUX, BUENOS AIRES",
  "LA CUMBRE ,PDA., LA PLATA, BUENOS AIRES",
  "LA DELFINA, GENERAL VIAMONTE, BUENOS AIRES",
  "LA DORITA, CARLOS CASARES, BUENOS AIRES",
  "LA EMILIA, SAN NICOLAS, BUENOS AIRES",
  "LA INVENCIBLE, SALTO, BUENOS AIRES",
  "LA LARGA, DAIREAUX, BUENOS AIRES",
  "LA LIMPIA, BRAGADO, BUENOS AIRES",
  "LA LUCILA, VICENTE LOPEZ, BUENOS AIRES",
  "LA LUCILA DEL MAR, PARTIDO DE LA COSTA, BUENOS AIRES",
  "LA LUISA, CAPITAN SARMIENTO, BUENOS AIRES",
  "LA MANUELA, DAIREAUX, BUENOS AIRES",
  "LA MASCOTA ,EST.MASCOTA, VILLARINO, BUENOS AIRES",
  "LA NEGRA, NECOCHEA, BUENOS AIRES",
  "LA NEVADA, GUAMINI, BUENOS AIRES",
  "LA NIÑA, 9 DE JULIO, BUENOS AIRES",
  "LA NORIA, CAÑUELAS, BUENOS AIRES",
  "LA ORIENTAL, JUNIN, BUENOS AIRES",
  "LA PALA, ADOLFO ALSINA, BUENOS AIRES",
  "LA PASTORA, TANDIL, BUENOS AIRES",
  "LA PLATA, LA PLATA, BUENOS AIRES",
  "LA PORTEÑA, TRENQUE LAUQUEN, BUENOS AIRES",
  "LA PRIMAVERA, CORONEL SUAREZ, BUENOS AIRES",
  "LA PROVIDENCIA, OLAVARRIA, BUENOS AIRES",
  "LA REFORMA, ROQUE PEREZ, BUENOS AIRES",
  "LA REJA, MORENO, BUENOS AIRES",
  "LA RICA, CHIVILCOY, BUENOS AIRES",
  "LA SALADA, LA MATANZA, BUENOS AIRES",
  "LA SOFIA, CARLOS CASARES, BUENOS AIRES",
  "LA SORTIJA, TRES ARROYOS, BUENOS AIRES",
  "LA VIOLETA, PERGAMINO, BUENOS AIRES",
  "LA VITICOLA, BAHIA BLANCA, BUENOS AIRES",
  "LA ZANJA, TRENQUE LAUQUEN, BUENOS AIRES",
  "LABARDEN, GENERAL GUIDO, BUENOS AIRES",
  "LAFERRERE, LA MATANZA, BUENOS AIRES",
  "LAGO EPECUEN, ADOLFO ALSINA, BUENOS AIRES",
  "LAGUNA ALSINA ,EST.BONIFACIO, GUAMINI, BUENOS AIRES",
  "LAMARCA, EMILIO, PATAGONES, BUENOS AIRES",
  "LANGUEYU, AYACUCHO, BUENOS AIRES",
  "LANUS, LANUS, BUENOS AIRES",
  "LAPLACETE, JUNIN, BUENOS AIRES",
  "LAPRIDA, LAPRIDA, BUENOS AIRES",
  "LARRE, SANTIAGO, ROQUE PEREZ, BUENOS AIRES",
  "LARTIGAU, CORONEL PRINGLES, BUENOS AIRES",
  "LAS ARMAS, MAIPU, BUENOS AIRES",
  "LAS BARRANCAS, SAN ISIDRO, BUENOS AIRES",
  "LAS CHACRAS, LOBOS, BUENOS AIRES",
  "LAS FLORES, LAS FLORES, BUENOS AIRES",
  "LAS HERMANAS, LAPRIDA, BUENOS AIRES",
  "LAS MALVINAS, GENERAL RODRIGUEZ, BUENOS AIRES",
  "LAS MARIANAS, NAVARRO, BUENOS AIRES",
  "LAS NUTRIAS, LOBERIA, BUENOS AIRES",
  "LAS PALMAS, ZARATE, BUENOS AIRES",
  "LAS PARVAS, JUNIN, BUENOS AIRES",
  "LAS TOSCAS, LINCOLN, BUENOS AIRES",
  "LAS VAQUERIAS, TRES ARROYOS, BUENOS AIRES",
  "LASALLE, PEDRO P., ADOLFO GONZALES CHAVES, BUENOS AIRES",
  "LASTRA, GENERAL LA MADRID, BUENOS AIRES",
  "LAVALLE, ENRIQUE, DAIREAUX, BUENOS AIRES",
  "LAVALLOL, LOMAS DE ZAMORA, BUENOS AIRES",
  "LAZZARINO, AZUL, BUENOS AIRES",
  "LERTORA, TRENQUE LAUQUEN, BUENOS AIRES",
  "LEUBUCO, ADOLFO ALSINA, BUENOS AIRES",
  "LEVALLE, NICOLAS, VILLARINO, BUENOS AIRES",
  "LIBANO, GENERAL LA MADRID, BUENOS AIRES",
  "LIBERTAD, MERLO, BUENOS AIRES",
  "LIBRES DEL SUD, CHASCOMUS, BUENOS AIRES",
  "LICENCIADO MATIENZO, LOBERIA, BUENOS AIRES",
  "LIMA, ZARATE, BUENOS AIRES",
  "LIN CALEL, TRES ARROYOS, BUENOS AIRES",
  "LINCOLN, LINCOLN, BUENOS AIRES",
  "LLORENTE, GALO, 9 DE JULIO, BUENOS AIRES",
  "LOBERIA, LOBERIA, BUENOS AIRES",
  "LOBOS, LOBOS, BUENOS AIRES",
  "LOMA NEGRA, OLAVARRIA, BUENOS AIRES",
  "LOMA PARAGUAYA ,EST., BAHIA BLANCA, BUENOS AIRES",
  "LOMA VERDE, GENERAL PAZ, BUENOS AIRES",
  "LOMAS DE ZAMORA, LOMAS DE ZAMORA, BUENOS AIRES",
  "LONGCHAMPS, ALMIRANTE BROWN, BUENOS AIRES",
  "LOPEZ, BENITO JUAREZ, BUENOS AIRES",
  "LOPEZ CAMELO ,PDA., TIGRE, BUENOS AIRES",
  "LOPEZ LECUBE, PUAN, BUENOS AIRES",
  "LOPEZ, VICENTE, VICENTE LOPEZ, BUENOS AIRES",
  "LOS ANGELES, CHACABUCO, BUENOS AIRES",
  "LOS GAUCHOS ,PDA., ADOLFO ALSINA, BUENOS AIRES",
  "LOS INDIOS, ROJAS, BUENOS AIRES",
  "LOS PINOS, BALCARCE, BUENOS AIRES",
  "LOS POLVORINES, GENERAL SARMIENTO, BUENOS AIRES",
  "LOUGE, DAIREAUX, BUENOS AIRES",
  "LOURDES, TRES DE FEBRERO, BUENOS AIRES",
  "LUJAN, LUJAN, BUENOS AIRES",
  "LUMB, SAN CAYETANO, BUENOS AIRES",
  "LURO, DAIREAUX, BUENOS AIRES",
  "LURO, PEDRO, VILLARINO, BUENOS AIRES",
  "MACEDO, GENERAL JUAN MADARIAGA, BUENOS AIRES",
  "MACHITA, BRAGADO, BUENOS AIRES",
  "MADERO, FRANCISCO, PEHUAJO, BUENOS AIRES",
  "MAGDALA, PEHUAJO, BUENOS AIRES",
  "MAGDALENA, MAGDALENA, BUENOS AIRES",
  "MAGNANO, FRANCISCO, TRENQUE LAUQUEN, BUENOS AIRES",
  "MAGUIRRE, PERGAMINO, BUENOS AIRES",
  "MAIPU, MAIPU, BUENOS AIRES",
  "MALAVER ,PDA., SAN MARTIN, BUENOS AIRES",
  "MAMAGUITA, 25 DE MAYO, BUENOS AIRES",
  "MAPIS, OLAVARRIA, BUENOS AIRES",
  "MAQUINISTA SAVIO, GENERAL SARMIENTO, BUENOS AIRES",
  "MAR DE AJO, PARTIDO DE LA COSTA, BUENOS AIRES",
  "MAR DEL PLATA, GENERAL PUEYRREDON, BUENOS AIRES",
  "MAR DEL TUYU, PARTIDO DE LA COSTA, BUENOS AIRES",
  "MARI LAUQUEN, TRENQUE LAUQUEN, BUENOS AIRES",
  "MARIA IGNACIA ,EST.VEIA, TANDIL, BUENOS AIRES",
  "MARIA LUCILA, HIPOLITO YRIGOYEN, BUENOS AIRES",
  "MARMOL, JOSE, ALMIRANTE BROWN, BUENOS AIRES",
  "MARTIN FIERRO, AZUL, BUENOS AIRES",
  "MARTINEZ, SAN ISIDRO, BUENOS AIRES",
  "MARUCHA, CARLOS TEJEDOR, BUENOS AIRES",
  "MASUREL ,EST., DAIREAUX, BUENOS AIRES",
  "MATHEU, ESCOBAR, BUENOS AIRES",
  "MAYOR BURATOVICH, VILLARINO, BUENOS AIRES",
  "MAZA, ADOLFO ALSINA, BUENOS AIRES",
  "MECHONGUE, GENERAL ALVARADO, BUENOS AIRES",
  "MEDANOS, VILLARINO, BUENOS AIRES",
  "MEMBRILLAR, CHACABUCO, BUENOS AIRES",
  "MERCEDES, MERCEDES, BUENOS AIRES",
  "MERLO, MERLO, BUENOS AIRES",
  "MERLO GOMEZ ,AP., MORON, BUENOS AIRES",
  "MICHEO, JOSE M., GENERAL ALVEAR, BUENOS AIRES",
  "MIGUELETE ,PDA., SAN MARTIN, BUENOS AIRES",
  "MINISTRO RIVADAVIA, ALMIRANTE BROWN, BUENOS AIRES",
  "MIRA PAMPA, RIVADAVIA, BUENOS AIRES",
  "MIRAMAR, GENERAL ALVARADO, BUENOS AIRES",
  "MIRAMONTE, AZUL, BUENOS AIRES",
  "MIRANDA, RAUCH, BUENOS AIRES",
  "MITRE, BARTOLOME ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "MOCTEZUMA, CARLOS CASARES, BUENOS AIRES",
  "MOLINA, CLAUDIO, TRES ARROYOS, BUENOS AIRES",
  "MONASTERIO, CHASCOMUS, BUENOS AIRES",
  "MONES CAZON, PEHUAJO, BUENOS AIRES",
  "MONROE, SALTO, BUENOS AIRES",
  "MONSALVO, MAIPU, BUENOS AIRES",
  "MONTE CHINGOLO, LANUS, BUENOS AIRES",
  "MONTE GRANDE, ECHEVERRIA, BUENOS AIRES",
  "MONTE HERMOSO, CORONEL DORREGO, BUENOS AIRES",
  "MONTE VELOZ, MAGDALENA, BUENOS AIRES",
  "MONTES DE OCA, VILLARINO, BUENOS AIRES",
  "MOORES, GENERAL VILLEGAS, BUENOS AIRES",
  "MOQUEHUA, CHIVILCOY, BUENOS AIRES",
  "MORENO, MARIANO ,EST.MORENO, MORENO, BUENOS AIRES",
  "MORON, MORON, BUENOS AIRES",
  "MORRIS, WILIAM C.,PDA., MORON, BUENOS AIRES",
  "MORSE, JUNIN, BUENOS AIRES",
  "MOSCONI, 25 DE MAYO, BUENOS AIRES",
  "MOURAS ,EST., DAIREAUX, BUENOS AIRES",
  "MUÑIZ, GENERAL SARMIENTO, BUENOS AIRES",
  "MUÑOZ, OLAVARRIA, BUENOS AIRES",
  "MUNRO, VICENTE LOPEZ, BUENOS AIRES",
  "MURATURE, FRANCISCO, ADOLFO ALSINA, BUENOS AIRES",
  "NAHUEL RUCA, MAR CHIQUITA, BUENOS AIRES",
  "NAON, CARLOS M., 9 DE JULIO, BUENOS AIRES",
  "NAPALEOFU, BALCARCE, BUENOS AIRES",
  "NAPOSTA, BAHIA BLANCA, BUENOS AIRES",
  "NAVARRO, NAVARRO, BUENOS AIRES",
  "NECOCHEA, NECOCHEA, BUENOS AIRES",
  "NECOL, CARLOS TEJEDOR, BUENOS AIRES",
  "NEILD, RAMON J., 9 DE JULIO, BUENOS AIRES",
  "NEWTON, GENERAL BELGRANO, BUENOS AIRES",
  "NIEVES, AZUL, BUENOS AIRES",
  "NORUMBEGA, 9 DE JULIO, BUENOS AIRES",
  "NUEVA PLATA, PEHUAJO, BUENOS AIRES",
  "NUEVA ROMA, TORNQUIST, BUENOS AIRES",
  "NUEVA SUIZA ,EST., GENERAL PINTO, BUENOS AIRES",
  "OBLIGADO, SAN PEDRO, BUENOS AIRES",
  "OBLIGADO, RAFAEL, ROJAS, BUENOS AIRES",
  "OCAMPO, MANUEL, PERGAMINO, BUENOS AIRES",
  "OCHANDIO, SAN CAYETANO, BUENOS AIRES",
  "O'HIGGINS, JUNIN, BUENOS AIRES",
  "OLASCOAGA, BRAGADO, BUENOS AIRES",
  "OLAVARRIA, OLAVARRIA, BUENOS AIRES",
  "OLIDEN, BRANDSEN, BUENOS AIRES",
  "OLIVERA, NICANOR ,EST.LA DULCE, NECOCHEA, BUENOS AIRES",
  "OLIVOS, VICENTE LOPEZ, BUENOS AIRES",
  "OLMOS, LISANDRO ,AP., LA PLATA, BUENOS AIRES",
  "OMBU, CORONEL SUAREZ, BUENOS AIRES",
  "OMBUCTA, VILLARINO, BUENOS AIRES",
  "ORDOQUI, CARLOS CASARES, BUENOS AIRES",
  "ORENSE, TRES ARROYOS, BUENOS AIRES",
  "ORIENTE, CORONEL DORREGO, BUENOS AIRES",
  "ORMA, ADOLFO F. ,AP., LA PLATA, BUENOS AIRES",
  "ORTIZ BASUALDO, PERGAMINO, BUENOS AIRES",
  "ORTIZ DE ROSAS, 25 DE MAYO, BUENOS AIRES",
  "OSTENDE, PINAMAR, BUENOS AIRES",
  "OTOÑO ,EST., CORONEL SUAREZ, BUENOS AIRES",
  "PADILLA, MIGUEL MANUEL ,EST., VICENTE LOPEZ, BUENOS AIRES",
  "PARAGUIL, LAPRIDA, BUENOS AIRES",
  "PARDO, LAS FLORES, BUENOS AIRES",
  "PARISH, AZUL, BUENOS AIRES",
  "PARRAVICINI, DOLORES, BUENOS AIRES",
  "PASCO ,AP., LOMAS DE ZAMORA, BUENOS AIRES",
  "PASMAN, CORONEL SUAREZ, BUENOS AIRES",
  "PASO DEL REY, MORENO, BUENOS AIRES",
  "PASO MAYOR, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "PASO, JUAN JOSE, PEHUAJO, BUENOS AIRES",
  "PASTEUR, LINCOLN, BUENOS AIRES",
  "PATAGONES, CARMEN DE, PATAGONES, BUENOS AIRES",
  "PATRICIOS, 9 DE JULIO, BUENOS AIRES",
  "PAYRO, ROBERTO, MAGDALENA, BUENOS AIRES",
  "PAZ, JOSE C., GENERAL SARMIENTO, BUENOS AIRES",
  "PAZ, MARCOS, MARCOS PAZ, BUENOS AIRES",
  "PAZ, MAXIMO, CAÑUELAS, BUENOS AIRES",
  "PAZOS KANKI, GENERAL PINTO, BUENOS AIRES",
  "PEARSON, COLON, BUENOS AIRES",
  "PEDERNALES, 25 DE MAYO, BUENOS AIRES",
  "PEHUAJO, PEHUAJO, BUENOS AIRES",
  "PEHUELCHES, PELLEGRINI, BUENOS AIRES",
  "PEHUEN-CO, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "PELICURA, TORNQUIST, BUENOS AIRES",
  "PELLEGRINI, PELLEGRINI, BUENOS AIRES",
  "PERALTA, CORONEL SUAREZ, BUENOS AIRES",
  "PEREDA, VICENTE, AZUL, BUENOS AIRES",
  "PEREZ MILLAN, RAMALLO, BUENOS AIRES",
  "PEREZ, ROQUE, ROQUE PEREZ, BUENOS AIRES",
  "PERGAMINO, PERGAMINO, BUENOS AIRES",
  "PESSAGNO, ATILIO, CHASCOMUS, BUENOS AIRES",
  "PETION, ALEJANDRO ,PDA., CAÑUELAS, BUENOS AIRES",
  "PICHINCHA, GENERAL VILLEGAS, BUENOS AIRES",
  "PIEDRA ECHADA, PUAN, BUENOS AIRES",
  "PIEDRITAS, GENERAL VILLEGAS, BUENOS AIRES",
  "PIERES, LOBERIA, BUENOS AIRES",
  "PIGUE, SAAVEDRA, BUENOS AIRES",
  "PILA, PILA, BUENOS AIRES",
  "PILAR, PILAR, BUENOS AIRES",
  "PILLAHUINCO, CORONEL PRINGLES, BUENOS AIRES",
  "PINAMAR, PINAMAR, BUENOS AIRES",
  "PIÑERO, GENERAL SARMIENTO, BUENOS AIRES",
  "PIÑEYRO, CORONEL SUAREZ, BUENOS AIRES",
  "PIPINAS, MAGDALENA, BUENOS AIRES",
  "PIROVANO, BOLIVAR, BUENOS AIRES",
  "PLA, ALBERTI, BUENOS AIRES",
  "PLATANOS, BERAZATEGUI, BUENOS AIRES",
  "PLAZA MONTERO, LAS FLORES, BUENOS AIRES",
  "POBLET, LA PLATA, BUENOS AIRES",
  "POLVAREDAS, SALADILLO, BUENOS AIRES",
  "PONTAUT, GENERAL LA MADRID, BUENOS AIRES",
  "PONTEVEDRA, MERLO, BUENOS AIRES",
  "PORTELA, IRINEO, BARADERO, BUENOS AIRES",
  "PORVENIR, GENERAL PINTO, BUENOS AIRES",
  "POURTALE, OLAVARRIA, BUENOS AIRES",
  "PRADERE, JUAN A., PATAGONES, BUENOS AIRES",
  "PRESIDENTE QUINTANA, ALBERTI, BUENOS AIRES",
  "PRIMERA JUNTA, TRENQUE LAUQUEN, BUENOS AIRES",
  "PUAN, PUAN, BUENOS AIRES",
  "PUEBLITOS, 25 DE MAYO, BUENOS AIRES",
  "PUENTE CASCALLARES, MORENO, BUENOS AIRES",
  "PUERTO BELGRANO, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "PUERTO CUATREROS, BAHIA BLANCA, BUENOS AIRES",
  "PUERTO GALVAN, BAHIA BLANCA, BUENOS AIRES",
  "PUERTO ROSALES, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "PUNTA ALTA ,EST.ALMIRANTE SOLIER, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "PUNTA CHICA, SAN FERNANDO, BUENOS AIRES",
  "PUNTA INDIO, MAGDALENA, BUENOS AIRES",
  "PUNTA LARA, ENSENADA, BUENOS AIRES",
  "QUENUMA, SALLIQUELO, BUENOS AIRES",
  "QUEQUEN, LOBERIA, BUENOS AIRES",
  "QUERANDI ,EST., LA MATANZA, BUENOS AIRES",
  "QUERANDIES ,EMP., OLAVARRIA, BUENOS AIRES",
  "QUILCO, GENERAL LA MADRID, BUENOS AIRES",
  "QUILMES, QUILMES, BUENOS AIRES",
  "QUIÑIHUAL, CORONEL SUAREZ, BUENOS AIRES",
  "QUIRNO COSTA, GENERAL VIAMONTE, BUENOS AIRES",
  "QUIROGA, 9 DE JULIO, BUENOS AIRES",
  "RAMALLO, RAMALLO, BUENOS AIRES",
  "RAMOS MEJIA, LA MATANZA, BUENOS AIRES",
  "RAMOS OTERO, BALCARCE, BUENOS AIRES",
  "RANCAGUA, PERGAMINO, BUENOS AIRES",
  "RANCHOS, GENERAL PAZ, BUENOS AIRES",
  "RANELAGH, BERAZATEGUI, BUENOS AIRES",
  "RAUCH, RAUCH, BUENOS AIRES",
  "RAULET, GENERAL LA MADRID, BUENOS AIRES",
  "RAWSON, CHACABUCO, BUENOS AIRES",
  "REAL AUDIENCIA, PILA, BUENOS AIRES",
  "RECALDE, OLAVARRIA, BUENOS AIRES",
  "RESERVA, CORONEL PRINGLES, BUENOS AIRES",
  "RETA, TRES ARROYOS, BUENOS AIRES",
  "RINGUELET, LA PLATA, BUENOS AIRES",
  "RIO LUJAN ,EST., CAMPANA, BUENOS AIRES",
  "RIO SALADO ,AP., MONTE, BUENOS AIRES",
  "RIO TALA, SAN PEDRO, BUENOS AIRES",
  "RIVADAVIA ,EST.AMERICA, RIVADAVIA, BUENOS AIRES",
  "RIVADEO, PUAN, BUENOS AIRES",
  "RIVAS, SUIPACHA, BUENOS AIRES",
  "RIVERA, ADOLFO ALSINA, BUENOS AIRES",
  "ROBERTS, LINCOLN, BUENOS AIRES",
  "ROCA, AGUSTIN, JUNIN, BUENOS AIRES",
  "ROCHA, OLAVARRIA, BUENOS AIRES",
  "ROJAS, ROJAS, BUENOS AIRES",
  "ROLDAN, MARIANO, BENITO JUAREZ, BUENOS AIRES",
  "ROLITO, GUAMINI, BUENOS AIRES",
  "ROMERO, ELIAS ,EST., MARCOS PAZ, BUENOS AIRES",
  "ROMERO, MELCHOR, LA PLATA, BUENOS AIRES",
  "ROOSEVELT, RIVADAVIA, BUENOS AIRES",
  "ROSAS, LAS FLORES, BUENOS AIRES",
  "RUBEN DARIO ,EST., MORON, BUENOS AIRES",
  "RUIZ, SAN ANDRES DE GILES, BUENOS AIRES",
  "SAAVEDRA, SAAVEDRA, BUENOS AIRES",
  "SAENZ PEÑA, TRES DE FEBRERO, BUENOS AIRES",
  "SALADILLO, SALADILLO, BUENOS AIRES",
  "SALAS, CARLOS, LINCOLN, BUENOS AIRES",
  "SALAZAR, DAIREAUX, BUENOS AIRES",
  "SALDUNGARAY, TORNQUIST, BUENOS AIRES",
  "SALINERA ,DV., PATAGONES, BUENOS AIRES",
  "SALLIQUELO, SALLIQUELO, BUENOS AIRES",
  "SALTO, SALTO, BUENOS AIRES",
  "SALVADOR MARIA, LOBOS, BUENOS AIRES",
  "SAN AGUSTIN, BALCARCE, BUENOS AIRES",
  "SAN ANDRES, SAN MARTIN, BUENOS AIRES",
  "SAN ANDRES DE GILES ,EST.GILES, SAN ANDRES DE GILES, BUENOS AIRES",
  "SAN ANTONIO DE ARECO, SAN ANTONIO DE ARECO, BUENOS AIRES",
  "SAN ANTONIO DE PADUA, MERLO, BUENOS AIRES",
  "SAN BERNARDO DEL TUYU, PARTIDO DE LA COSTA, BUENOS AIRES",
  "SAN BERNARDO, TAPALQUE, BUENOS AIRES",
  "SAN CARLOS DE BOLIVAR ,EST.BOLIVAR, BOLIVAR, BUENOS AIRES",
  "SAN CAYETANO, SAN CAYETANO, BUENOS AIRES",
  "SAN CLEMENTE DEL TUYU, PARTIDO DE LA COSTA, BUENOS AIRES",
  "SAN ELADIO, LUJAN, BUENOS AIRES",
  "SAN EMILIO, GENERAL VIAMONTE, BUENOS AIRES",
  "SAN ENRIQUE, 25 DE MAYO, BUENOS AIRES",
  "SAN FERMIN, GUAMINI, BUENOS AIRES",
  "SAN FERNANDO, SAN FERNANDO, BUENOS AIRES",
  "SAN FRANCISCO DE BELLOCQ, TRES ARROYOS, BUENOS AIRES",
  "SAN FRANCISCO SOLANO, QUILMES, BUENOS AIRES",
  "SAN GERMAN, PUAN, BUENOS AIRES",
  "SAN IGNACIO, AYACUCHO, BUENOS AIRES",
  "SAN ISIDRO, SAN ISIDRO, BUENOS AIRES",
  "SAN JORGE, LAPRIDA, BUENOS AIRES",
  "SAN JOSE, NECOCHEA, BUENOS AIRES",
  "SAN JOSE, CORONEL SUAREZ, BUENOS AIRES",
  "SAN JUSTO, LA MATANZA, BUENOS AIRES",
  "SAN MANUEL, LOBERIA, BUENOS AIRES",
  "SAN MAURICIO, RIVADAVIA, BUENOS AIRES",
  "SAN MAYOL, TRES ARROYOS, BUENOS AIRES",
  "SAN MIGUEL, GENERAL SARMIENTO, BUENOS AIRES",
  "SAN MIGUEL ,EST.GENERAL SARMIENTO, GENERAL SARMIENTO, BUENOS AIRES",
  "SAN MIGUEL DEL MONTE ,EST.MONTE, MORON, BUENOS AIRES",
  "SAN NICOLAS DE LOS ARROYOS,EST.S.NICOLAS, SAN NICOLAS, BUENOS AIRES",
  "SAN PATRICIO, CHACABUCO, BUENOS AIRES",
  "SAN PEDRO, SAN PEDRO, BUENOS AIRES",
  "SAN ROMAN, CORONEL DORREGO, BUENOS AIRES",
  "SAN VICENTE, SAN VICENTE, BUENOS AIRES",
  "SANDRINI ,DV., CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "SANSINENA, RIVADAVIA, BUENOS AIRES",
  "SANTA CATALINA, LOMAS DE ZAMORA, BUENOS AIRES",
  "SANTA COLOMA, BARADERO, BUENOS AIRES",
  "SANTA ELENA, LAPRIDA, BUENOS AIRES",
  "SANTA ELEODORA, GENERAL VILLEGAS, BUENOS AIRES",
  "SANTA INES, CARLOS TEJEDOR, BUENOS AIRES",
  "SANTA LUCIA, OLAVARRIA, BUENOS AIRES",
  "SANTA LUCIA, SAN PEDRO, BUENOS AIRES",
  "SANTA MARIA, CORONEL SUAREZ, BUENOS AIRES",
  "SANTA REGINA, GENERAL VILLEGAS, BUENOS AIRES",
  "SANTA TERESITA, PARTIDO DE LA COSTA, BUENOS AIRES",
  "SANTAMARINA, RAMON, NECOCHEA, BUENOS AIRES",
  "SANTO DOMINGO, MAIPU, BUENOS AIRES",
  "SANTOS AREVALO, JOSE, LOBOS, BUENOS AIRES",
  "SANTOS LUGARES, TRES DE FEBRERO, BUENOS AIRES",
  "SANTOS UNZUE, 9 DE JULIO, BUENOS AIRES",
  "SARANDI, AVELLANEDA, BUENOS AIRES",
  "SARASA, COLON, BUENOS AIRES",
  "SATURNO, GUAMINI, BUENOS AIRES",
  "SEGUI, ARTURO, LA PLATA, BUENOS AIRES",
  "SEGUROLA, MAIPU, BUENOS AIRES",
  "SEVIGNE, DOLORES, BUENOS AIRES",
  "SHAW, AZUL, BUENOS AIRES",
  "SIERRA CHICA, OLAVARRIA, BUENOS AIRES",
  "SIERRA DE LA VENTANA, TORNQUIST, BUENOS AIRES",
  "SIERRAS BAYAS, OLAVARRIA, BUENOS AIRES",
  "SMITH, CARLOS CASARES, BUENOS AIRES",
  "SOLA, FELIPE, PUAN, BUENOS AIRES",
  "SOLANET, AYACUCHO, BUENOS AIRES",
  "SOSA, INOCENCIO, PEHUAJO, BUENOS AIRES",
  "SOURDEAUX, ADOLFO ,PDA., GENERAL SARMIENTO, BUENOS AIRES",
  "SOURIGUES, CARLOS ,AP., BARAZATEGUI, BUENOS AIRES",
  "SPANO, GUIDO, ROJAS, BUENOS AIRES",
  "SPEGAZZINI, CARLOS, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "SPURR ,EST., BAHIA BLANCA, BUENOS AIRES",
  "STEGMANN, CORONEL PRINGLES, BUENOS AIRES",
  "STROEDER, PATAGONES, BUENOS AIRES",
  "SUAREZ, JOSE LEON, SAN MARTIN, BUENOS AIRES",
  "SUAREZ, TRISTAN, ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "SUIPACHA, SUIPACHA, BUENOS AIRES",
  "SUNDBLAD, RIVADAVIA, BUENOS AIRES",
  "TABLADA, LA MATANZA, BUENOS AIRES",
  "TACUARI, SALTO, BUENOS AIRES",
  "TAMANGUEYU, LOBERIA, BUENOS AIRES",
  "TANDIL, TANDIL, BUENOS AIRES",
  "TAPALQUE, TAPALQUE, BUENOS AIRES",
  "TAPIALES, LA MATANZA, BUENOS AIRES",
  "TATAY, CARMEN DE ARECO, BUENOS AIRES",
  "TEJEDOR, CARLOS ,EST.TEJEDOR, CARLOS TEJEDOR, BUENOS AIRES",
  "TEMPERLEY, LOMAS DE ZAMORA, BUENOS AIRES",
  "TENIENTE CORONEL MIÑANA, OLAVARRIA, BUENOS AIRES",
  "TENIENTE ORIGONE, VILLARINO, BUENOS AIRES",
  "THAMES, ADOLFO ALSINA, BUENOS AIRES",
  "TIGRE, TIGRE, BUENOS AIRES",
  "TIMOTE, CARLOS TEJEDOR, BUENOS AIRES",
  "TIMOTE ,EST., CARLOS TEJEDOR, BUENOS AIRES",
  "TODD, BARTOLOME MITRE, BUENOS AIRES",
  "TOLOSA, LA PLATA, BUENOS AIRES",
  "TORNQUIST, TORNQUIST, BUENOS AIRES",
  "TORTUGUITAS, GENERAL SARMIENTO, BUENOS AIRES",
  "TRENQUE LAUQUEN, TRENQUE LAUQUEN, BUENOS AIRES",
  "TRES ALGARROBOS ,EST.CUENCA, CARLOS TEJEDOR, BUENOS AIRES",
  "TRES ARROYOS, TRES ARROYOS, BUENOS AIRES",
  "TRES LAGUNAS, ADOLFO ALSINA, BUENOS AIRES",
  "TRES LOMAS, PELLEGRINI, BUENOS AIRES",
  "TRES PICOS, TORNQUIST, BUENOS AIRES",
  "TRIUNVIRATO, LINCOLN, BUENOS AIRES",
  "TRONCONI, JUAN, ROQUE PEREZ, BUENOS AIRES",
  "TRONGE, TRENQUE LAUQUEN, BUENOS AIRES",
  "TURDERA, LOMAS DE ZAMORA, BUENOS AIRES",
  "TUYUTI, SAN ANDRES DE GILES, BUENOS AIRES",
  "UBALLES, EUFEMIO, TAPALQUE, BUENOS AIRES",
  "UDAQUIOLA, AYACUCHO, BUENOS AIRES",
  "UNION FERROVIARIA ,PDA., ESTEBAN ECHEVERRIA, BUENOS AIRES",
  "UNZUE, MARIANO, BOLIVAR, BUENOS AIRES",
  "URDAMPILLETA, BOLIVAR, BUENOS AIRES",
  "URIBURU, TEDIN, BENITO JUAREZ, BUENOS AIRES",
  "VALDES, 25 DE MAYO, BUENOS AIRES",
  "VALLIMANCA, BOLIVAR, BUENOS AIRES",
  "VARELA, FLORENCIO, FLORENCIO VARELA, BUENOS AIRES",
  "VASQUEZ, ADOLFO GONZALES CHAVES, BUENOS AIRES",
  "VATTEONE, ARTURO ,EST., ALSINA, BUENOS AIRES",
  "VEDIA, LEANDRO N.ALEM, BUENOS AIRES",
  "VELLOSO, TAPALQUE, BUENOS AIRES",
  "VERGARA, MAGDALENA, BUENOS AIRES",
  "VERONICA, MAGDALENA, BUENOS AIRES",
  "VICEALMIRANTE E.MONTES, TIGRE, BUENOS AIRES",
  "VICTORIA, SAN FERNANDO, BUENOS AIRES",
  "VIDELA DORNA, ZENON, MONTE, BUENOS AIRES",
  "VIEYTES, MAGDALENA, BUENOS AIRES",
  "VILELA, LAS FLORES, BUENOS AIRES",
  "VILLA ADELINA, SAN ISIDRO, BUENOS AIRES",
  "VILLA ANGELICA ,EST,EL SOCORRO, PERGAMINO, BUENOS AIRES",
  "VILLA BALLESTER, SAN MARTIN, BUENOS AIRES",
  "VILLA BARILARI, AVELLANEDA, BUENOS AIRES",
  "VILLA BOSCH ,PDA.JUAN MARIA BOSCH, TRES DE FEBRERO, BUENOS AIRES",
  "VILLA CARAZA, LANUS, BUENOS AIRES",
  "VILLA CASTELAR ,EST.ERIZE, PUAN, BUENOS AIRES",
  "VILLA CRISTOBAL COLON, AVELLANEDA, BUENOS AIRES",
  "VILLA DE MAYO, GENERAL SARMIENTO, BUENOS AIRES",
  "VILLA DIAMANTE, LANUS, BUENOS AIRES",
  "VILLA DOMINICO, AVELLANEDA, BUENOS AIRES",
  "VILLA EL CACIQUE, BENITO JUAREZ, BUENOS AIRES",
  "VILLA ELISA, LA PLATA, BUENOS AIRES",
  "VILLA ESPAÑA, BERAZATEGUI, BUENOS AIRES",
  "VILLA GARIBALDI, LA PLATA, BUENOS AIRES",
  "VILLA GENERAL ARIAS ,EST.KILOMETRO 638, CORONEL DE MARINA L. ROSALES, BUENOS AIRES",
  "VILLA GENERAL SAVIO EST.SANCHEZ, RAMALLO, BUENOS AIRES",
  "VILLA GESELL, VILLA GESELL, BUENOS AIRES",
  "VILLA GIAMBRUNO, BERAZATEGUI, BUENOS AIRES",
  "VILLA HARDING GREEN, BAHIA BLANCA, BUENOS AIRES",
  "VILLA IRIS, PUAN, BUENOS AIRES",
  "VILLA LUZURIAGA, LA MATANZA, BUENOS AIRES",
  "VILLA LYCH PUEYRREDON, BOLIVAR, BUENOS AIRES",
  "VILLA LYNCH ,EST.CNL.FRANCISCO LYNCH, SAN MARTIN, BUENOS AIRES",
  "VILLA MADERO, LA MATANZA, BUENOS AIRES",
  "VILLA MOLL, NAVARRO, BUENOS AIRES",
  "VILLA NUMANCIA, SAN VICENTE, BUENOS AIRES",
  "VILLA OLGA, BAHIA BLANCA, BUENOS AIRES",
  "VILLA PRONSATO ,EST.CORONEL MALDONADO, BAHIA BLANCA, BUENOS AIRES",
  "VILLA SABOYA, GENERAL VILLEGAS, BUENOS AIRES",
  "VILLA SAUZE, GENERAL VILLEGAS, BUENOS AIRES",
  "VILLA SENA, RIVADAVIA, BUENOS AIRES",
  "VILLA SERRA, BAHIA BLANCA, BUENOS AIRES",
  "VILLALONGA, PATAGONES, BUENOS AIRES",
  "VILLANUEVA, GENERAL PAZ, BUENOS AIRES",
  "VILLARS, MARCOS PAZ, BUENOS AIRES",
  "VIÑA, BARTOLOME MITRE, BUENOS AIRES",
  "VIRREYES ,EST.FACUNDO QUIROGA, SAN FERNANDO, BUENOS AIRES",
  "VIVORATA, MAR CHIQUITA, BUENOS AIRES",
  "VIVORATA, MAR CHIQUITA, BUENOS AIRES",
  "VOLUNTAD, LAPRIDA, BUENOS AIRES",
  "VUCETICH, JUAN ,AP., GENERAL SARMIENTO, BUENOS AIRES",
  "WARNES, BRAGADO, BUENOS AIRES",
  "WILDE, AVELLANEDA, BUENOS AIRES",
  "YERBAS, TAPALQUE, BUENOS AIRES",
  "YRAIZOZ, GENERAL ALVARADO, BUENOS AIRES",
  "ZAMUDIO ,AP., MARCOS PAZ, BUENOS AIRES",
  "ZARATE, ZARATE, BUENOS AIRES",
  "ZAVALIA, GENERAL VIAMONTE, BUENOS AIRES",
  "ZEBALLOS, ESTANISLAO SEVERO ,EST., FLORENCIO VARELA, BUENOS AIRES",
  "ZENTENA ,EST., CORONEL SUAREZ, BUENOS AIRES",
  "ZUBIAURRE, CORONEL DORREGO, BUENOS AIRES",
  "ADOLFO E. CARRANZA, CAPAYAN, CATAMARCA",
  "AGUA DE LAS PALOMAS, ANDALGALA, CATAMARCA",
  "ALBIGASTA, EL ALTO, CATAMARCA",
  "ALIJILAN, SANTA ROSA, CATAMARCA",
  "ALTA GRACIA, SANTA ROSA, CATAMARCA",
  "ALTO DE LA ESQUINA, ANCASTI, CATAMARCA",
  "ALUMBRERA, ANDALGALA, CATAMARCA",
  "AMADORES, PACLIN, CATAMARCA",
  "AMANA, ANCASTI, CATAMARCA",
  "AMANAO, ANDALGALA, CATAMARCA",
  "AMPOLLA, SANTA ROSA, CATAMARCA",
  "ANCASTI, ANCASTI, CATAMARCA",
  "ANDALGALA, ANDALGALA, CATAMARCA",
  "ANDALHUALA, SANTA MARIA, CATAMARCA",
  "ANDALUCA, TINOGASTA, CATAMARCA",
  "ANJULI, LA PAZ, CATAMARCA",
  "ANQUINCILA, ANCASTI, CATAMARCA",
  "ANTAPOCA, VALLE VIEJO, CATAMARCA",
  "ANTINACO, TINOGASTA, CATAMARCA",
  "ANTOFAGASTA DE LA SIERRA, ANTOFAGASTA DE LA SIERRA, CATAMARCA",
  "AZAMPAY, BELEN, CATAMARCA",
  "BALCOSNA, PACLIN, CATAMARCA",
  "BAÑADO DE OVANTA, SANTA ROSA, CATAMARCA",
  "BANDA DE VARELA, CAPITAL, CATAMARCA",
  "BELEN, BELEN, CATAMARCA",
  "BELLA VISTA, EL ALTO, CATAMARCA",
  "BREA, CAPAYAN, CATAMARCA",
  "CAPAYAN, CAPAYAN, CATAMARCA",
  "CAPILLITAS, ANDALGALA, CATAMARCA",
  "CARA CIENAGA, ANTOFAGASTA DE LA SIERRA, CATAMARCA",
  "CARRANZA, ADOLFO E., CAPAYAN, CATAMARCA",
  "CASA ARMADA, ANCASTI, CATAMARCA",
  "CERRO NEGRO, TINOGASTA, CATAMARCA",
  "CHAÑAR LAGUNA, EL ALTO, CATAMARCA",
  "CHAÑARITOS, LA PAZ, CATAMARCA",
  "CHAQUIAGO, ANDALGALA, CATAMARCA",
  "CHUMBICHA, CAPAYAN, CATAMARCA",
  "CIENAGUITA ,AP., TINOGASTA, CATAMARCA",
  "COLLAGASTA, FRAY MAMERTO ESQUIU, CATAMARCA",
  "COLPES, POMAN, CATAMARCA",
  "CONCEPCION, CAPAYAN, CATAMARCA",
  "CONDOR HUASI, BELEN, CATAMARCA",
  "NUEVA CONETA, CAPAYAN, CATAMARCA",
  "COPACABANA, TINOGASTA, CATAMARCA",
  "CORTADERAS, SANTA ROSA, CATAMARCA",
  "CULAMPAJA, BELEN, CATAMARCA",
  "EL ALTO, EL ALTO, CATAMARCA",
  "EL AYBAL, LA PAZ, CATAMARCA",
  "EL BAÑADO, CAPAYAN, CATAMARCA",
  "EL BAVIANO, LA PAZ, CATAMARCA",
  "EL BOLSON, AMBATO, CATAMARCA",
  "EL CAJON, SANTA MARIA, CATAMARCA",
  "EL DIVISADERO, LA PAZ, CATAMARCA",
  "EL JUMEAL, LA PAZ, CATAMARCA",
  "EL MEDANO ,AP.KILOMETRO 99, CAPAYAN, CATAMARCA",
  "EL PAJONAL ,EST.POMAN, POMAN, CATAMARCA",
  "EL PORTEZUELO, VALLE VIEJO, CATAMARCA",
  "EL PUESTO, TINOGASTA, CATAMARCA",
  "EL RODEO, AMBATO, CATAMARCA",
  "EL TACO, ANCASTI, CATAMARCA",
  "ESQUIU, LA PAZ, CATAMARCA",
  "FIAMBALA, TINOGASTA, CATAMARCA",
  "FUERTE QUEMADO, SANTA MARIA, CATAMARCA",
  "GUAYAMBA, EL ALTO, CATAMARCA",
  "HUALFIN, BELEN, CATAMARCA",
  "HUAYCAMA, VALLE VIEJO, CATAMARCA",
  "HUILLAPIMA, CAPAYAN, CATAMARCA",
  "ICAÑO, LA PAZ, CATAMARCA",
  "ILOGA, EL ALTO, CATAMARCA",
  "INCAHUASI, ANTOFAGASTA DE LA SIERRA, CATAMARCA",
  "INFANZON, EL ALTO, CATAMARCA",
  "IPIZCA, ANCASTI, CATAMARCA",
  "JOYANGO, POMAN, CATAMARCA",
  "KILOMETR0 62 ,EST., CAPAYAN, CATAMARCA",
  "KILOMETRO 1017, LA PAZ, CATAMARCA",
  "KILOMETRO 38 ,EMB., LA PAZ, CATAMARCA",
  "KILOMETRO 969 ,EMB., LA PAZ, CATAMARCA",
  "KILOMETRO 997, LA PAZ, CATAMARCA",
  "LA AGUADA, CAPITAL, CATAMARCA",
  "LA BAJADA, PACLIN, CATAMARCA",
  "LA CIENAGA, BELEN, CATAMARCA",
  "LA CIENAGA, TINOGASTA, CATAMARCA",
  "LA DORADA, LA PAZ, CATAMARCA",
  "LA GUARDIA, LA PAZ, CATAMARCA",
  "LA HOYADA, SANTA MARIA, CATAMARCA",
  "LA MAJADA, ANCASTI, CATAMARCA",
  "LA MERCED, PACLIN, CATAMARCA",
  "LA PARADA, LA PAZ, CATAMARCA",
  "LA PUERTA, AMBATO, CATAMARCA",
  "LA PUERTA DE SAN JOSE, BELEN, CATAMARCA",
  "LA PUNTILLA, TINOGASTA, CATAMARCA",
  "LA RAMADITA, TINOGASTA, CATAMARCA",
  "LA VIÑA, PACLIN, CATAMARCA",
  "LAS CAÑAS, SANTA ROSA, CATAMARCA",
  "LAS CUEVAS, BELEN, CATAMARCA",
  "LAS JUNTAS, AMBATO, CATAMARCA",
  "LAS JUNTAS, BELEN, CATAMARCA",
  "LAS MOJARRAS, SANTA MARIA, CATAMARCA",
  "LAS PIRQUITAS, FRAY MAMERTO ESQUIU, CATAMARCA",
  "LAS TEJAS, LA PAZ, CATAMARCA",
  "LONDRES, BELEN, CATAMARCA",
  "LORO HUASI, SANTA MARIA, CATAMARCA",
  "LOS MORTEROS, VALLE VIEJO, CATAMARCA",
  "LOS SUNCHOS, LA PAZ, CATAMARCA",
  "LOS VARELA, AMBATO, CATAMARCA",
  "MANANTIALES, SANTA ROSA, CATAMARCA",
  "MARENGO, VALLE VIEJO, CATAMARCA",
  "MEDANITOS, TINOGASTA, CATAMARCA",
  "MIRAFLORES, CAPAYAN, CATAMARCA",
  "MONTE POTRERO, PACLIN, CATAMARCA",
  "MUTQUIN, POMAN, CATAMARCA",
  "NACIMIENTO, TINOGASTA, CATAMARCA",
  "NACIMIENTOS DE ABAJO, BELEN, CATAMARCA",
  "NACIMIENTOS DE ARRIBA, BELEN, CATAMARCA",
  "OJO DE AGUA, EL ALTO, CATAMARCA",
  "OVEJERIA, SANTA MARIA, CATAMARCA",
  "PALO BLANCO, TINOGASTA, CATAMARCA",
  "PALO LABRADO, PACLIN, CATAMARCA",
  "PIEDRA LARGA, BELEN, CATAMARCA",
  "POMAN, POMAN, CATAMARCA",
  "POMANCILLO, FRAY MAMERTO ESQUIU, CATAMARCA",
  "PUCARA, ANDALGALA, CATAMARCA",
  "PUERTA DE CORRAL QUEMADO, BELEN, CATAMARCA",
  "PUNTA DE BALASTO, SANTA MARIA, CATAMARCA",
  "QUIROS, LA PAZ, CATAMARCA",
  "RAMBLONES, LA PAZ, CATAMARCA",
  "RECREO, LA PAZ, CATAMARCA",
  "RINCON, POMAN, CATAMARCA",
  "RIO CHICO, ANCASTI, CATAMARCA",
  "RIO COLORADO, TINOGASTA, CATAMARCA",
  "RIO DE LA DORADA, LA PAZ, CATAMARCA",
  "RODEO GRANDE, ANDALGALA, CATAMARCA",
  "ROSARIO DE COLANA, POMAN, CATAMARCA",
  "SALADO, TINOGASTA, CATAMARCA",
  "SAN ANTONIO, FRAY MAMERTO ESQUIU, CATAMARCA",
  "SAN ANTONIO, PACLIN, CATAMARCA",
  "SAN ANTONIO ,EST.SAN A.DE LA PAZ, LA PAZ, CATAMARCA",
  "SAN FDO.DEL VALLE DE CATAMARCA ,EST., CAPITAL, CATAMARCA",
  "SAN FERNANDO, BELEN, CATAMARCA",
  "SAN ISIDRO, VALLE VIEJO, CATAMARCA",
  "SAN JOSE, LA PAZ, CATAMARCA",
  "SAN JOSE, FRAY MAMERTO ESQUIU, CATAMARCA",
  "SAN JOSE, TINOGASTA, CATAMARCA",
  "SAN JOSE, SANTA MARIA, CATAMARCA",
  "SAN MARTIN, CAPAYAN, CATAMARCA",
  "SAN MIGUEL, LA PAZ, CATAMARCA",
  "SAN MIGUEL, POMAN, CATAMARCA",
  "SANTA CRUZ, VALLE VIEJO, CATAMARCA",
  "SANTA LUCIA, LA PAZ, CATAMARCA",
  "SANTA MARIA, SANTA MARIA, CATAMARCA",
  "SANTA ROSA, VALLE VIEJO, CATAMARCA",
  "SANTA ROSA, TINOGASTA, CATAMARCA",
  "SAUJIL, POMAN, CATAMARCA",
  "SAUJIL, TINOGASTA, CATAMARCA",
  "SIJAN, POMAN, CATAMARCA",
  "SISIHUASI, CAPAYAN, CATAMARCA",
  "SUMALAO, VALLE VIEJO, CATAMARCA",
  "SUMAMPA, SANTA ROSA, CATAMARCA",
  "TALAMAYO, BELEN, CATAMARCA",
  "TAPSO, EL ALTO, CATAMARCA",
  "TATON, TINOGASTA, CATAMARCA",
  "TELARITOS, CAPAYAN, CATAMARCA",
  "TINOGASTA, TINOGASTA, CATAMARCA",
  "TIPAN, CAPAYAN, CATAMARCA",
  "TORO YACO, SANTA MARIA, CATAMARCA",
  "TRES PUENTES, CAPITAL, CATAMARCA",
  "VILISMAN, EL ALTO, CATAMARCA",
  "VILLA CUBAS, CAPITAL, CATAMARCA",
  "VILLA DOLORES, VALLE VIEJO, CATAMARCA",
  "VILLA GIL, BELEN, CATAMARCA",
  "YERBA BUENA, ANCASTI, CATAMARCA",
  "YOCAN, PACLIN, CATAMARCA",
  "AVIA TERAI, INDEPENDENCIA, CHACO",
  "BARRANQUERAS, SAN FERNANDO, CHACO",
  "BASAIL, SAN FERNANDO, CHACO",
  "BENITEZ, PRIMERO DE MAYO, CHACO",
  "CABEZA DE TIGRE, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "CACUI ,EST, SAN FERNANDO, CHACO",
  "CAMPO LARGO, INDEPENDENCIA, CHACO",
  "CAPITAN SOLARI, SARGENTO CABRAL, CHACO",
  "CASTELLI ,EST.COLONIA J.J.CASTELLI, GENERAL GUEMES, CHACO",
  "CHARADAI, TAPENAGA, CHACO",
  "CHARATA, CHACABUCO, CHACO",
  "CHOROTIS, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "CIERVO PETISO, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "COLONIA AGRICOLA ABORIGEN CHACO, 25 DE MAYO, CHACO",
  "COLONIA BARANDA, SAN FERNANDO, CHACO",
  "COLONIA BRAVO, 12 DE OCTUBRE, CHACO",
  "COLONIA CABEZA DE TIGRE, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "COLONIA CERVEZA HELADA, COMANDANTE FERNANDEZ, CHACO",
  "COLONIA EL PARAISAL, QUITILIPI, CHACO",
  "COLONIA ELISA, SARGENTO CABRAL, CHACO",
  "COLONIA ELISA, SARGENTO CABRAL, CHACO",
  "COLONIA JOSE MARMOL, INDEPENDENCIA, CHACO",
  "COLONIA JUAN JOSE PASO, MAYOR LUIS J.FONTANA, CHACO",
  "COLONIA LA FLORIDA GRANDE, GENERAL GUEMES, CHACO",
  "COLONIA LA TAMBORA, 25 DE MAYO, CHACO",
  "COLONIA MARIANO SARRATEA, INDEPENDENCIA, CHACO",
  "COLONIA POPULAR, LIBERTAD, CHACO",
  "COLONIA VELAZ, BERMEJO, CHACO",
  "COLONIAS UNIDAS, SARGENTO CABRAL, CHACO",
  "CONCEPCION DEL BERMEJO, ALMIRANTE BROWN, CHACO",
  "CORONEL DU GRATY, MAYOR LUIS J.FONTANA, CHACO",
  "CORZUELA, GENERAL BELGRANO, CHACO",
  "COTE-LAI, TAPENAGA, CHACO",
  "EL ESPINILLO, GENERAL GUEMES, CHACO",
  "EL PERDIDO, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "EL PINTADO, GENERAL GUEMES, CHACO",
  "FONTANA, SAN FERNANDO, CHACO",
  "FORTIN CARDOSO, LIBERTAD, CHACO",
  "FUERTE ESPERANZA, GENERAL GUEMES, CHACO",
  "GANCEDO, 12 DE OCTUBRE, CHACO",
  "GENERAL CAPDEVILA, 12 DE OCTUBRE, CHACO",
  "GENERAL DONOVAN, LIBERTAD, CHACO",
  "GENERAL JOSE DE SAN MARTIN ,EST.ZAPALLAR, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "GENERAL OBLIGADO, LIBERTAD, CHACO",
  "GENERAL PINEDO, 12 DE OCTUBRE, CHACO",
  "GENERAL VEDIA, BERMEJO, CHACO",
  "GIRASOL ,EMB., COMANDANTE FERNANDEZ, CHACO",
  "HAUMONIA, TAPENAGA, CHACO",
  "HERMOSO CAMPO, 12 DE OCTUBRE, CHACO",
  "HORQUILLA, TAPENAGA, CHACO",
  "INGENIERO BARBET, SARGENTO CABRAL, CHACO",
  "ITIN, 12 DE OCTUBRE, CHACO",
  "KILOMETR0 501 ,AP., LIBERTAD, CHACO",
  "KILOMETRO 22 ,EMB., SARGENTO CABRAL, CHACO",
  "KILOMETRO 29 ,EMB., SARGENTO CABRAL, CHACO",
  "KILOMETRO 34 ,EMB., SARGENTO CABRAL, CHACO",
  "KILOMETRO 39 ,EMB., SARGENTO CABRAL, CHACO",
  "KILOMETRO 42 ,EMB., LIBERTADOR GRL. SAN MARTIN, CHACO",
  "KILOMETRO 443 ,EMB., TAPENAGA, CHACO",
  "KILOMETRO 474 ,EMB., TAPENAGA, CHACO",
  "KILOMETRO 48 ,EMB., LIBERTADOR GRL. SAN MARTIN, CHACO",
  "KILOMETRO 518 ,DV., GENERAL DONOVAN, CHACO",
  "KILOMETRO 520 ,EMB., SAN LORENZO, CHACO",
  "KILOMETRO 522 ,DV., GENERAL DONOVAN, CHACO",
  "KILOMETRO 53 ,AP., SAN LORENZO, CHACO",
  "KILOMETRO 59 ,EMB., LIBERTADOR GRL. SAN MARTIN, CHACO",
  "KILOMETRO 596 ,DV., FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "KILOMETRO 602 ,AP., SARGENTO CABRAL, CHACO",
  "KILOMETRO 75, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "KILOMETRO 841 ,EMB., MAIPU, CHACO",
  "KILOMETRO 855 ,EMB., MAIPU, CHACO",
  "KILOMETRO 884 ,EMB., MAIPU, CHACO",
  "LA CHIQUITA ,EMB., QUITILIPI, CHACO",
  "LA CLOTILDE, O'HIGGINS, CHACO",
  "LA EDUVIGIS, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "LA ESCONDIDA, GENERAL DONOVAN, CHACO",
  "LA ESCONDIDA ,EMB., GENERAL DONOVAN, CHACO",
  "LA FIDELIDAD, GENERAL GUEMES, CHACO",
  "LA LEONESA, BERMEJO, CHACO",
  "LA PASTORIL ,DV., SARGENTO CABRAL, CHACO",
  "LA SABANA, TAPENAGA, CHACO",
  "LA TIGRA, O'HIGGINS, CHACO",
  "LA VERDE, GENERAL DONOVAN, CHACO",
  "LA VICUÑA, TAPENAGA, CHACO",
  "LAGUNA BLANCA, LIBERTAD, CHACO",
  "LAGUNA LIMPIA, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "LAPACHITO, GENERAL DONOVAN, CHACO",
  "LAS BREÑAS, 9 DE JULIO, CHACO",
  "LAS GARCITAS, SARGENTO CABRAL, CHACO",
  "LAS HACHERAS, GENERAL GUEMES, CHACO",
  "LAS PALMAS, BERMEJO, CHACO",
  "LAS VIBORAS, 12 DE OCTUBRE, CHACO",
  "LOMA FLORIDA, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "LOS FRENTONES, ALMIRANTE BROWN, CHACO",
  "LOS PALMARES, SAN FERNANDO, CHACO",
  "LOS QUEBRACHITOS, 12 DE OCTUBRE, CHACO",
  "MACHAGAI, 25 DE MAYO, CHACO",
  "MAKALLE, GENERAL DONOVAN, CHACO",
  "MANANTIALES, GENERAL GUEMES, CHACO",
  "MIRAFLORES, GENERAL GUEMES, CHACO",
  "NAPALPI, 25 DE MAYO, CHACO",
  "NAPENAY, INDEPENDENCIA, CHACO",
  "NUEVA POBLACION, GENERAL GUEMES, CHACO",
  "NUEVA POMPEYA, GENERAL GUEMES, CHACO",
  "PALO MARCADO, GENERAL GUEMES, CHACO",
  "PAMPA ALMIRON, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "PAMPA CABA ÑARO, MAIPU, CHACO",
  "PAMPA DEL CIELO, 9 DE JULIO, CHACO",
  "PAMPA DEL INDIO, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "PAMPA DEL INFIERNO, ALMIRANTE BROWN, CHACO",
  "PAMPA FLORIDA, MAIPU, CHACO",
  "PAMPA GUANACO, ALMIRANTE BROWN, CHACO",
  "POZO DEL INDIO ,DV., 9 DE JULIO, CHACO",
  "POZO HONDO, ALMIRANTE BROWN, CHACO",
  "PRESIDENCIA ROCA, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "PRESIDENCIA ROQUE SAENZ PEÑA, COMANDANTE FERNANDEZ, CHACO",
  "PUERTO BERMEJO, BERMEJO, CHACO",
  "PUERTO LAS PALMAS, BERMEJO, CHACO",
  "PUERTO TIROL, LIBERTAD, CHACO",
  "PUERTO VILELAS, SAN FERNANDO, CHACO",
  "QUITILIPI, QUITILIPI, CHACO",
  "RESISTENCIA, SAN FERNANDO, CHACO",
  "RIO MUERTO, ALMIRANTE BROWN, CHACO",
  "RIO TAPENAGA, TAPENAGA, CHACO",
  "SAMUHU, SAN LORENZO, CHACO",
  "SAN BERNARDO, O'HIGGINS, CHACO",
  "SAN JUANCITO, GENERAL GUEMES, CHACO",
  "SANTA SYLVINA, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "SELVAS DEL RIO DE ORO, LIBERTADOR GRL. SAN MARTIN, CHACO",
  "SIMBOLAR, GENERAL GUEMES, CHACO",
  "TACO POZO, ALMIRANTE BROWN, CHACO",
  "TRES ESTACAS, CHACABUCO, CHACO",
  "TRES ISLETAS, MAIPU, CHACO",
  "TRES MOJONES, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "TUNALES, GENERAL GUEMES, CHACO",
  "URIEN, ENRIQUE, MAYOR LUIS J.FONTANA, CHACO",
  "VENADOS GRANDES,EMB., FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "VILLA ANGELA, MAYOR LUIS J.FONTANA, CHACO",
  "VILLA BERTHET, SAN LORENZO, CHACO",
  "YATAY, BERMEJO, CHACO",
  "ZAPARINQUI, GENERAL GUEMES, CHACO",
  "ZUBERBUHLER, FRAY JUSTO SANTA MARIA DE ORO, CHACO",
  "ALDEA, RIO SENGUERR, CHUBUT",
  "ALDEA APELEG, RIO SENGUERR, CHUBUT",
  "ALTO DE LAS PLUMAS, MARTIRES, CHUBUT",
  "ALTO RIO MAYO, RIO SENGUERR, CHUBUT",
  "ALTO RIO SENGUERR, RIO SENGUERR, CHUBUT",
  "APELEG, RIO SENGUERR, CHUBUT",
  "ASTRA, ESCALANTE, CHUBUT",
  "BAHIA BUSTAMANTE, ESCALANTE, CHUBUT",
  "BOCA DE LA ZANJA, GAIMAN, CHUBUT",
  "BUEN PASTO, SARMIENTO, CHUBUT",
  "BUENOS AIRES CHICO, CUSHAMEN, CHUBUT",
  "CABO RASO, FLORENTINO AMEGHINO, CHUBUT",
  "CALETA CORDOBA, ESCALANTE, CHUBUT",
  "CAMARONES, FLORENTINO AMEGHINO, CHUBUT",
  "CAMPAMENTO I, ESCALANTE, CHUBUT",
  "CAMPAMENTO VILLEGAS ,EST., GAIMAN, CHUBUT",
  "CAMPO ESCALANTE ,AP., ESCALANTE, CHUBUT",
  "CAÑADON IGLESIAS, GAIMAN, CHUBUT",
  "CAÑADON LAGARTO ,EMB., SARMIENTO, CHUBUT",
  "CANQUEL, PASO DE INDIOS, CHUBUT",
  "CERRO DRAGON, ESCALANTE, CHUBUT",
  "CHASICO, TELSEN, CHUBUT",
  "CHOLILA, CUSHAMEN, CHUBUT",
  "COLAN CONHUE, LANGUIÑEO, CHUBUT",
  "COLELACHE, GASTRE, CHUBUT",
  "COLHUE HUAPI, SARMIENTO, CHUBUT",
  "COMODORO RIVADAVIA, ESCALANTE, CHUBUT",
  "DIADEMA ARGENTINA, ESCALANTE, CHUBUT",
  "DIQUE FLORENTINO AMEGHINO, GAIMAN, CHUBUT",
  "DOCTOR RICARDO ROJAS, RIO SENGUERR, CHUBUT",
  "DOLAVON, GAIMAN, CHUBUT",
  "EL CORCOVADO, FUTALEUFU, CHUBUT",
  "EL MAITEN, CUSHAMEN, CHUBUT",
  "EL MAYOCO, CUSHAMEN, CHUBUT",
  "EL MIRASOL, MARTIRES, CHUBUT",
  "EL MOLLE, TEHUELCHES, CHUBUT",
  "EL PAJARITO, PASO DE INDIOS, CHUBUT",
  "EL SOMBRERO, PASO DE INDIOS, CHUBUT",
  "EL TORDILLO, ESCALANTE, CHUBUT",
  "EPUYEN, CUSHAMEN, CHUBUT",
  "ESCALANTE, ESCALANTE, CHUBUT",
  "ESQUEL, FUTALEUFU, CHUBUT",
  "FACUNDO, RIO SENGUERR, CHUBUT",
  "GAIMAN, GAIMAN, CHUBUT",
  "GAN GAN, TELSEN, CHUBUT",
  "GARAYALDE, FLORENTINO AMEGHINO, CHUBUT",
  "GASTRE, GASTRE, CHUBUT",
  "GENERAL MOSCONI, ESCALANTE, CHUBUT",
  "GOBERNADOR COSTA, TEHUELCHES, CHUBUT",
  "GUALJAINA, CUSHAMEN, CHUBUT",
  "HERMITTE, ENRIQUE ,AP., SARMIENTO, CHUBUT",
  "HOLDICH, ESCALANTE, CHUBUT",
  "HOYO DE EPUYEN, CUSHAMEN, CHUBUT",
  "INGENIERO BRUNO J.THOMAE ,AP., CUSHAMEN, CHUBUT",
  "KILOMETR0 81 ,AP., GAIMAN, CHUBUT",
  "KILOMETRO 11, ESCALANTE, CHUBUT",
  "KILOMETRO 141, GAIMAN, CHUBUT",
  "KILOMETRO 161, GAIMAN, CHUBUT",
  "KILOMETRO 191 ,AP., SARMIENTO, CHUBUT",
  "KILOMETRO 192, MARTIRES, CHUBUT",
  "KILOMETRO 35, RAWSON, CHUBUT",
  "KILOMETRO 78 ,AP., RAWSON, CHUBUT",
  "KILOMETRO 82 ,AP., RAWSON, CHUBUT",
  "KILOMETRO 87 ,AP., RAWSON, CHUBUT",
  "KILOMETRO 95 ,AP., GAIMAN, CHUBUT",
  "LAGO BLANCO, RIO SENGUERR, CHUBUT",
  "LAGO PUELO, CUSHAMEN, CHUBUT",
  "LAGO VINTTER, TEHUELCHES, CHUBUT",
  "LAGUNA GRANDE ,EMB., MARTIRES, CHUBUT",
  "LANGUIÑEO, LANGUIÑEO, CHUBUT",
  "LAS CHAPAS, MARTIRES, CHUBUT",
  "LAS PLUMAS, MARTIRES, CHUBUT",
  "LAS PULGAS, SARMIENTO, CHUBUT",
  "LELEQUE, CUSHAMEN, CHUBUT",
  "LEPA, CUSHAMEN, CHUBUT",
  "LOS CIPRESES, FUTALEUFU, CHUBUT",
  "MALASPINA, FLORENTINO AMEGHINO, CHUBUT",
  "MANANTIALES BERH, ESCALANTE, CHUBUT",
  "NUEVA LUBECKA, TEHUELCHES, CHUBUT",
  "PAMPA DEL CASTILLO, ESCALANTE, CHUBUT",
  "PASO DE INDIOS, PASO DE INDIOS, CHUBUT",
  "PASTOS BLANCOS, RIO SENGUERR, CHUBUT",
  "PETROQUIMICA ,EST.COMFERPET, ESCALANTE, CHUBUT",
  "PICO DE SALAMANCA, ESCALANTE, CHUBUT",
  "PLAYA UNION, RAWSON, CHUBUT",
  "PUERTO MADRYN, VIEDMA, CHUBUT",
  "PUERTO PIRAMIDE, VIEDMA, CHUBUT",
  "PUERTO RAWSON, RAWSON, CHUBUT",
  "PUERTO VISSER, ESCALANTE, CHUBUT",
  "PUNTA DELGADA, VIEDMA, CHUBUT",
  "PUTRACHOIQUE, TEHUELCHES, CHUBUT",
  "RADA TILLY, ESCALANTE, CHUBUT",
  "RAWSON, RAWSON, CHUBUT",
  "RIO MAYO, RIO SENGUERR, CHUBUT",
  "RIO PICO, TEHUELCHES, CHUBUT",
  "SACANANA, GASTRE, CHUBUT",
  "SAN MARTIN, JOSE DE, TEHUELCHES, CHUBUT",
  "SARMIENTO, SARMIENTO, CHUBUT",
  "SIERRA CHATA, TELSEN, CHUBUT",
  "SIERRA NEVADA, PASO DE INDIOS, CHUBUT",
  "TALAGAPA, TELSEN, CHUBUT",
  "TECKA, LANGUIÑEO, CHUBUT",
  "TELSEN, TELSEN, CHUBUT",
  "TRELEW, RAWSON, CHUBUT",
  "TREVELIN, FUTALEUFU, CHUBUT",
  "UZCUDUM, FLORENTINO AMEGHINO, CHUBUT",
  "ACHIRAS, RIO CUARTO, CORDOBA",
  "ADELIA MARIA, RIO CUARTO, CORDOBA",
  "AGUA DE LAS PIEDRAS, TOTORAL, CORDOBA",
  "AGUA DE ORO, COLON, CORDOBA",
  "AGUA DEL TALA, MINAS, CORDOBA",
  "AGUAS DE RAMON, MINAS, CORDOBA",
  "AGUILA BLANCA, PUNILLA, CORDOBA",
  "ALCIRA ,EST.GIGENA, RIO CUARTO, CORDOBA",
  "ALEJANDRO ROCA ,EST. ALEJANDRO., JUAREZ CELMAN, CORDOBA",
  "ALEJO LEDESMA, MARCOS JUAREZ, CORDOBA",
  "ALICIA, SAN JUSTO, CORDOBA",
  "ALMAFUERTE, TERCERO ARRIBA, CORDOBA",
  "ALPA CORRAL, RIO CUARTO, CORDOBA",
  "ALTA CORDOBA, RIO SECO, CORDOBA",
  "ALTA CORDOBA ,EST., CAPITAL, CORDOBA",
  "ALTA GRACIA, SANTA MARIA, CORDOBA",
  "ALTAUTINA, SAN ALBERTO, CORDOBA",
  "ALTO ALEGRE, UNION, CORDOBA",
  "ALTO DEL TALA, POCHO, CORDOBA",
  "ALTO FRESCO, TOTORAL, CORDOBA",
  "ALTO SAN PEDRO, PUNILLA, CORDOBA",
  "ALTOS DE CHIPION, SAN JUSTO, CORDOBA",
  "AMBOY, CALAMUCHITA, CORDOBA",
  "AMBUL, SAN ALBERTO, CORDOBA",
  "ANA ZUMARAN, UNION, CORDOBA",
  "ARBOL BLANCO, ISCHILIN, CORDOBA",
  "ARGUELLO, CAPITAL, CORDOBA",
  "ARIAS, MARCOS JUAREZ, CORDOBA",
  "ARROYITO, SAN JUSTO, CORDOBA",
  "ARROYO ALGODON, GENERAL SAN MARTIN, CORDOBA",
  "ARROYO CABRAL, GENERAL SAN MARTIN, CORDOBA",
  "ASCOCHINGA, COLON, CORDOBA",
  "ASSUNTA, JUAREZ CELMAN, CORDOBA",
  "ATAHONA, RIO PRIMERO, CORDOBA",
  "ATOS PAMPA, CALAMUCHITA, CORDOBA",
  "AUSONIA, GENERAL SAN MARTIN, CORDOBA",
  "AVELLANEDA, ISCHILIN, CORDOBA",
  "BAJO CHICO, SANTA MARIA, CORDOBA",
  "BALDE DE LA MORA, SAN ALBERTO, CORDOBA",
  "BALLESTEROS, UNION, CORDOBA",
  "BALLESTEROS SUR, UNION, CORDOBA",
  "BALNEARIA, SAN JUSTO, CORDOBA",
  "BAÑADO DE SOTO, CRUZ DEL EJE, CORDOBA",
  "BARRANCA YACO, TOTORAL, CORDOBA",
  "BARRETO, JUAREZ CELMAN, CORDOBA",
  "BARRIO FLORES ,EMB., CAPITAL, CORDOBA",
  "BELL VILLE, UNION, CORDOBA",
  "BENGOLEA, JUAREZ CELMAN, CORDOBA",
  "BENJAMIN GOULD, UNION, CORDOBA",
  "BERROTARAN, RIO CUARTO, CORDOBA",
  "BIALET MASSE, PUNILLA, CORDOBA",
  "BLAS DE ROSALES, COLON, CORDOBA",
  "BOCA DEL TIGRE, RIO CUARTO, CORDOBA",
  "BOUWER, SANTA MARIA, CORDOBA",
  "BRINKMANN, SAN JUSTO, CORDOBA",
  "BUCHARDO ,EST.HIPOLITO BOUCHARD, GENERAL ROCA, CORDOBA",
  "BUEN RETIRO ,AP.EL VADO, PUNILLA, CORDOBA",
  "BUENA VISTA, SANTA MARIA, CORDOBA",
  "BUEY MUERTO, RIO PRIMERO, CORDOBA",
  "BULNES, RIO CUARTO, CORDOBA",
  "BURMEISTER, GENERAL ROCA, CORDOBA",
  "CABANA, COLON, CORDOBA",
  "CACHI YACO, SOBREMONTE, CORDOBA",
  "CACHIYUYO, CRUZ DEL EJE, CORDOBA",
  "CALCHIN, RIO SEGUNDO, CORDOBA",
  "CALMAYO, CALAMUCHITA, CORDOBA",
  "CAMILO ALDAO, MARCOS JUAREZ, CORDOBA",
  "CAMINIAGA, SOBREMONTE, CORDOBA",
  "CAMPO GRANDE, RIO SECO, CORDOBA",
  "CAÑADA DE ALVAREZ, CALAMUCHITA, CORDOBA",
  "CAÑADA DE JUME, TOTORAL, CORDOBA",
  "CAÑADA DE LUQUE, TOTORAL, CORDOBA",
  "CAÑADA DE MACHADO, RIO PRIMERO, CORDOBA",
  "CAÑADA DE RIO PINTO, ISCHILIN, CORDOBA",
  "CAÑADA DE SALAS, POCHO, CORDOBA",
  "CAÑADA DEL CORO, PUNILLA, CORDOBA",
  "CAÑADA HONDA, RIO PRIMERO, CORDOBA",
  "CANALS, UNION, CORDOBA",
  "CANDELARIA, CRUZ DEL EJE, CORDOBA",
  "CANDELARIA NORTE, RIO SECO, CORDOBA",
  "CANDELARIA SUD, TOTORAL, CORDOBA",
  "CANDONGA, COLON, CORDOBA",
  "CANTERAS EL SAUCE ,EST.EMP., COLON, CORDOBA",
  "CANTERAS QUILPO, CRUZ DEL EJE, CORDOBA",
  "CAPILLA DE LA SAGRADA FAMILIA, RIO PRIMERO, CORDOBA",
  "CAPILLA DE LOS REMEDIOS, RIO PRIMERO, CORDOBA",
  "CAPILLA DE SITON, TOTORAL, CORDOBA",
  "CAPILLA DEL CARMEN, RIO SEGUNDO, CORDOBA",
  "CAPILLA DEL MONTE, PUNILLA, CORDOBA",
  "CAPITAN GENERAL BERNARDO O HIGGINS, MARCOS JUAREZ, CORDOBA",
  "CARCANO, RAMON J., UNION, CORDOBA",
  "CARNERILLO, JUAREZ CELMAN, CORDOBA",
  "CAROLINA, RIO CUARTO, CORDOBA",
  "CAROYA, COLON, CORDOBA",
  "CARRILOBO, RIO SEGUNDO, CORDOBA",
  "CASA BAMBA, COLON, CORDOBA",
  "CASA GRANDE, PUNILLA, CORDOBA",
  "CASERIO LA LONJA, UNION, CORDOBA",
  "CASSAFOUSTH, SANTA MARIA, CORDOBA",
  "CASTRO URDIALES, MARCOS JUAREZ, CORDOBA",
  "CAVALANGO, PUNILLA, CORDOBA",
  "CAVANAGH, MARCOS JUAREZ, CORDOBA",
  "CAYUQUEO, GENERAL SAN MARTIN, CORDOBA",
  "CERRO COLORADO, TULUMBA, CORDOBA",
  "CHAJAN, RIO CUARTO, CORDOBA",
  "CHALACEA, RIO PRIMERO, CORDOBA",
  "CHALACEA CENTRO, RIO PRIMERO, CORDOBA",
  "CHAMICO, POCHO, CORDOBA",
  "CHAÑAR VIEJO, RIO SECO, CORDOBA",
  "CHAÑARITOS, RIO CUARTO, CORDOBA",
  "CHANCANI, POCHO, CORDOBA",
  "CHARBONIER, PUNILLA, CORDOBA",
  "CHARRAS, JUAREZ CELMAN, CORDOBA",
  "CHAZON, GENERAL SAN MARTIN, CORDOBA",
  "CHILIBROSTE, UNION, CORDOBA",
  "CHUCHIRAS, SAN JAVIER, CORDOBA",
  "CHUCUL, RIO CUARTO, CORDOBA",
  "CHUÑA, ISCHILIN, CORDOBA",
  "CHUÑA HUASI, SOBREMONTE, CORDOBA",
  "CHURQUI CAÑADA, TULUMBA, CORDOBA",
  "CIENAGA DE ALLENDE, SAN ALBERTO, CORDOBA",
  "CIENAGA DE BRITOS, MINAS, CORDOBA",
  "CIENAGA DEL CORO, MINAS, CORDOBA",
  "CINTRA, UNION, CORDOBA",
  "COLAZO, RIO SEGUNDO, CORDOBA",
  "COLONIA 10 DE JULIO, SAN JUSTO, CORDOBA",
  "COLONIA ALMADA, TERCERO ARRIBA, CORDOBA",
  "COLONIA ALMADA, TERCERO ARRIBA, CORDOBA",
  "COLONIA ANITA, SAN JUSTO, CORDOBA",
  "COLONIA ANITA, SAN JUSTO, CORDOBA",
  "COLONIA BISMARCK, UNION, CORDOBA",
  "COLONIA BREMEN, UNION, CORDOBA",
  "COLONIA CAROYA, COLON, CORDOBA",
  "COLONIA COYUNDA, SAN JUSTO, CORDOBA",
  "COLONIA EL CARMEN, RIO CUARTO, CORDOBA",
  "COLONIA LA TORDILLA, SAN JUSTO, CORDOBA",
  "COLONIA LAS CUATRO ESQUINAS, RIO PRIMERO, CORDOBA",
  "COLONIA MACKINLAY, RIO SECO, CORDOBA",
  "COLONIA MALBERTINA ,EMB.KILOMETRO 531, SAN JUSTO, CORDOBA",
  "COLONIA MARINA, SAN JUSTO, CORDOBA",
  "COLONIA PROSPERIDAD, SAN JUSTO, CORDOBA",
  "COLONIA RIO CHICO, COLON, CORDOBA",
  "COLONIA SAN BARTOLOME, SAN JUSTO, CORDOBA",
  "COLONIA SAN PEDRO, SAN JUSTO, CORDOBA",
  "COLONIA SAN RAFAEL, SAN JUSTO, CORDOBA",
  "COLONIA SANTA MARIA, UNION, CORDOBA",
  "COLONIA SANTA MARIA, SAN JUSTO, CORDOBA",
  "COLONIA TIROLESA ,EST., COLON, CORDOBA",
  "COLONIA VEINTICINCO, MARCOS JUAREZ, CORDOBA",
  "COLONIA VIDELA, RIO SEGUNDO, CORDOBA",
  "COLONIA VIGNAUD, SAN JUSTO, CORDOBA",
  "COLONIA WALTELINA, SAN JUSTO, CORDOBA",
  "CONLARA, SAN JAVIER, CORDOBA",
  "COPACABANA, ISCHILIN, CORDOBA",
  "CORDOBA, CAPITAL, CORDOBA",
  "CORONEL BAIGORRIA, RIO CUARTO, CORDOBA",
  "CORONEL MOLDES, RIO CUARTO, CORDOBA",
  "CORONEL OLMEDO, CAPITAL, CORDOBA",
  "CORRAL DE BARRANCA, TULUMBA, CORDOBA",
  "CORRAL DE BUSTOS, MARCOS JUAREZ, CORDOBA",
  "CORRAL DEL BAJO, UNION, CORDOBA",
  "CORRALITO, TERCERO ARRIBA, CORDOBA",
  "CORTES, JERONIMO, SAN JUSTO, CORDOBA",
  "COSME, SANTA MARIA, CORDOBA",
  "COSQUIN, PUNILLA, CORDOBA",
  "COSTA SACATE, RIO SEGUNDO, CORDOBA",
  "COTAGAITA, SAN JUSTO, CORDOBA",
  "CRAIK, JAMES, TERCERO ARRIBA, CORDOBA",
  "CRUZ ALTA, MARCOS JUAREZ, CORDOBA",
  "CRUZ CHICA, PUNILLA, CORDOBA",
  "CRUZ DE CAÑA, CRUZ DEL EJE, CORDOBA",
  "CRUZ DEL EJE ,EST.NORTE, CRUZ DEL EJE, CORDOBA",
  "CRUZ DEL QUEMADO, TOTORAL, CORDOBA",
  "CRUZ GRANDE, PUNILLA, CORDOBA",
  "CRUZ MOJADA, ISCHILIN, CORDOBA",
  "CUATRO CAMINOS, UNION, CORDOBA",
  "CUATRO VIENTOS, RIO CUARTO, CORDOBA",
  "CUCHI CORRAL, PUNILLA, CORDOBA",
  "CUEVA DE INDIOS, PUNILLA, CORDOBA",
  "CUEVA DE LOS PAJARITOS, PUNILLA, CORDOBA",
  "CURAPALIGUE, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "DE LA QUINTANA, JOSE, SANTA MARIA, CORDOBA",
  "DE LA SERNA, GENERAL ROCA, CORDOBA",
  "DEAN FUNES, ISCHILIN, CORDOBA",
  "DEL CAMPILLO, GENERAL ROCA, CORDOBA",
  "DESPEÑADEROS ,EST.DR.LUCAS A.DE OLMOS, SANTA MARIA, CORDOBA",
  "DEVOTO, SAN JUSTO, CORDOBA",
  "DIQUE SAN ROQUE, COLON, CORDOBA",
  "DOCTOR NICASIO SALAS OROÑO, TOTORAL, CORDOBA",
  "DOLORES, PUNILLA, CORDOBA",
  "DUMESNIL, COLON, CORDOBA",
  "EL ARAÑADO, SAN JUSTO, CORDOBA",
  "EL BAÑADO, POCHO, CORDOBA",
  "EL BAÑADO, TULUMBA, CORDOBA",
  "EL BRETE, CRUZ DEL EJE, CORDOBA",
  "EL CADILLO, SAN ALBERTO, CORDOBA",
  "EL CANO, CALAMUCHITA, CORDOBA",
  "EL CHACHO, MINAS, CORDOBA",
  "EL CRISPIN, RIO PRIMERO, CORDOBA",
  "EL DIQUECITO ,AP., COLON, CORDOBA",
  "EL DURAZNO, PUNILLA, CORDOBA",
  "EL DURAZNO, TULUMBA, CORDOBA",
  "EL FARO, SAN ALBERTO, CORDOBA",
  "EL FLORENTINO, UNION, CORDOBA",
  "EL FORTIN, SAN JUSTO, CORDOBA",
  "EL FUERTECITO, SAN JUSTO, CORDOBA",
  "EL GATEADO, CAPITAL, CORDOBA",
  "EL MANZANO, COLON, CORDOBA",
  "EL MIRADOR, SAN ALBERTO, CORDOBA",
  "EL PAMPERO, GENERAL ROCA, CORDOBA",
  "EL PAYADOR ,AP., COLON, CORDOBA",
  "EL PERCHEL, CRUZ DEL EJE, CORDOBA",
  "EL PERCHEL, TULUMBA, CORDOBA",
  "EL PONIENTE, SAN ALBERTO, CORDOBA",
  "EL PORTEZUELO, SAN ALBERTO, CORDOBA",
  "EL QUEBRACHO, RIO PRIMERO, CORDOBA",
  "EL QUEBRACHO, ISCHILIN, CORDOBA",
  "EL RASTREADOR, JUAREZ CELMAN, CORDOBA",
  "EL RETIRO, PUNILLA, CORDOBA",
  "EL ROSARIO, PUNILLA, CORDOBA",
  "EL SAUCE, COLON, CORDOBA",
  "EL SUNCHO, RIO PRIMERO, CORDOBA",
  "EL TAMBO, RIO CUARTO, CORDOBA",
  "EL TIO, SAN JUSTO, CORDOBA",
  "EL TOSTADO, RIO PRIMERO, CORDOBA",
  "EL TUSCAL, TULUMBA, CORDOBA",
  "EL VENCE, TULUMBA, CORDOBA",
  "EL ZAINO ,AP., COLON, CORDOBA",
  "EL ZAPATO, PUNILLA, CORDOBA",
  "ELCANO, SEBASTIAN, RIO SECO, CORDOBA",
  "ELENA, RIO CUARTO, CORDOBA",
  "EMBALSE MINISTRO JUAN PISTARINI, CALAMUCHITA, CORDOBA",
  "ENCRUCIJADAS, RIO SECO, CORDOBA",
  "ESCALANTE, WENCESLAO, UNION, CORDOBA",
  "ESCUELA PARACAIDISTAS ,AP., CAPITAL, CORDOBA",
  "ESPINILLO, RIO CUARTO, CORDOBA",
  "ESQUINA, POCHO, CORDOBA",
  "ESTANCIA DE GUADALUPE, MINAS, CORDOBA",
  "ETRURIA, GENERAL SAN MARTIN, CORDOBA",
  "FALDA DEL CARMEN, SANTA MARIA, CORDOBA",
  "FERREYRA, CAPITAL, CORDOBA",
  "FLORA, MARCOS JUAREZ, CORDOBA",
  "FRAGUEIRO, RIO CUARTO, CORDOBA",
  "FRAY CAYETANO RODRIGUEZ, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "FREYRE, SAN JUSTO, CORDOBA",
  "FUNES, DOMINGO ,AP., PUNILLA, CORDOBA",
  "FUNES, PEDRO E., JUAREZ CELMAN, CORDOBA",
  "GARCIA, RAFAEL, SANTA MARIA, CORDOBA",
  "GAVILAN ,EST., PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "GENERAL BALDISSERA, MARCOS JUAREZ, CORDOBA",
  "GENERAL CABRERA, JUAREZ CELMAN, CORDOBA",
  "GENERAL DEHEZA, JUAREZ CELMAN, CORDOBA",
  "GENERAL FOTHERINGHAM, TERCERO ARRIBA, CORDOBA",
  "GENERAL LEVALLE, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "GENERAL ORTIZ DE OCAMPO ,AP., COLON, CORDOBA",
  "GENERAL PAZ, COLON, CORDOBA",
  "GENERAL ROCA, MARCOS JUAREZ, CORDOBA",
  "GENERAL SOLER, RIO CUARTO, CORDOBA",
  "GIGENA ESTACION, RIO CUARTO, CORDOBA",
  "GOULD, BENJAMIN, UNION, CORDOBA",
  "GUANACO MUERTO, CRUZ DEL EJE, CORDOBA",
  "GUARDIA VIEJA, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "GUASAPAMPA, MINAS, CORDOBA",
  "GUATIMOZIN, MARCOS JUAREZ, CORDOBA",
  "GUIÑAZU, CAPITAL, CORDOBA",
  "GUTEMBERG, RIO SECO, CORDOBA",
  "HERNANDO, TERCERO ARRIBA, CORDOBA",
  "HUANCHILLA, JUAREZ CELMAN, CORDOBA",
  "HUASCHA, ISCHILIN, CORDOBA",
  "HUERTA GRANDE, PUNILLA, CORDOBA",
  "HUINCA RENANCO, GENERAL ROCA, CORDOBA",
  "IDIAZABAL, UNION, CORDOBA",
  "IGLESIA VIEJA, CRUZ DEL EJE, CORDOBA",
  "IMPIRA, RIO SEGUNDO, CORDOBA",
  "INGENIERO MALMEN, GENERAL ROCA, CORDOBA",
  "INRIVILLE, MARCOS JUAREZ, CORDOBA",
  "ISCHILIN, ISCHILIN, CORDOBA",
  "ISCHILIN VIEJO, ISCHILIN, CORDOBA",
  "ISLA DE SAN ANTONIO, TULUMBA, CORDOBA",
  "ISLA VERDE, MARCOS JUAREZ, CORDOBA",
  "ITALO, GENERAL ROCA, CORDOBA",
  "JEANMAIRE, SAN JUSTO, CORDOBA",
  "JESUS MARIA, COLON, CORDOBA",
  "JUAREZ CELMAN, COLON, CORDOBA",
  "JUAREZ, MARCOS, MARCOS JUAREZ, CORDOBA",
  "KILOMETRO 1,5 AP., CAPITAL, CORDOBA",
  "KILOMETRO 136 ,EMP., RIO CUARTO, CORDOBA",
  "KILOMETRO 16, COLON, CORDOBA",
  "KILOMETRO 16, COLON, CORDOBA",
  "KILOMETRO 182 ,DV., RIO PRIMERO, CORDOBA",
  "KILOMETRO 23, SANTA MARIA, CORDOBA",
  "KILOMETRO 23, COLON, CORDOBA",
  "KILOMETRO 25, COLON, CORDOBA",
  "KILOMETRO 271 ,AP., RIO PRIMERO, CORDOBA",
  "KILOMETRO 316 ,AP., RIO PRIMERO, CORDOBA",
  "KILOMETRO 394 ,AP., TOTORAL, CORDOBA",
  "KILOMETRO 466 ,DV., MARCOS JUAREZ, CORDOBA",
  "KILOMETRO 480 ,AP., ISCHILIN, CORDOBA",
  "KILOMETRO 541 ,AP., CRUZ DEL EJE, CORDOBA",
  "KILOMETRO 563 ,DV., GENERAL SAN MARTIN, CORDOBA",
  "KILOMETRO 57 ,EST.EMB., MARCOS JUAREZ, CORDOBA",
  "KILOMETRO 579 ,AP., PUNILLA, CORDOBA",
  "KILOMETRO 581 ,EMB., SAN JUSTO, CORDOBA",
  "KILOMETRO 608 ,AP., PUNILLA, CORDOBA",
  "KILOMETRO 618 ,DV., SAN JUSTO, CORDOBA",
  "KILOMETRO 636 ,DV., MINAS, CORDOBA",
  "KILOMETRO 651 ,DV., MINAS, CORDOBA",
  "KILOMETRO 679, SANTA MARIA, CORDOBA",
  "KILOMETRO 691 ,DV., RIO PRIMERO, CORDOBA",
  "KILOMETRO 7, CAPITAL, CORDOBA",
  "KILOMETRO 730 ,AP., CAPITAL, CORDOBA",
  "KILOMETRO 745, COLON, CORDOBA",
  "KILOMETRO 859 ,AP., ISCHILIN, CORDOBA",
  "KILOMETRO 865 ,AP., ISCHILIN, CORDOBA",
  "KILOMETRO 881 ,EMB., ISCHILIN, CORDOBA",
  "KILOMETRO 9, SANTA MARIA, CORDOBA",
  "KILOMETRO 907 ,EST., TULUMBA, CORDOBA",
  "KILOMETRO 931, TULUMBA, CORDOBA",
  "LA AGUADA, RIO CUARTO, CORDOBA",
  "LA ARGENTINA, MINAS, CORDOBA",
  "LA BISMUTINA, MINAS, CORDOBA",
  "LA BUENA PARADA ,EST.COMECHINGONES, RIO PRIMERO, CORDOBA",
  "LA CALERA, COLON, CORDOBA",
  "LA CAÑADA, RIO SECO, CORDOBA",
  "LA CAÑADA, TULUMBA, CORDOBA",
  "LA CARLOTA, JUAREZ CELMAN, CORDOBA",
  "LA CAUTIVA, RIO CUARTO, CORDOBA",
  "LA CORTADERA, SAN JUSTO, CORDOBA",
  "LA CRUZ, CALAMUCHITA, CORDOBA",
  "LA CUESTA, PUNILLA, CORDOBA",
  "LA CUMBRE, PUNILLA, CORDOBA",
  "LA ESPERANZA, TULUMBA, CORDOBA",
  "LA ESQUINA, RIO CUARTO, CORDOBA",
  "LA ESTANCIA, SANTA MARIA, CORDOBA",
  "LA FALDA, PUNILLA, CORDOBA",
  "LA FRANCIA, SAN JUSTO, CORDOBA",
  "LA GILDA, RIO CUARTO, CORDOBA",
  "LA GRANJA, COLON, CORDOBA",
  "LA HERRADURA, GENERAL SAN MARTIN, CORDOBA",
  "LA HIGUERA, CRUZ DEL EJE, CORDOBA",
  "LA JUANITA, ISCHILIN, CORDOBA",
  "LA LAGUNA, GENERAL SAN MARTIN, CORDOBA",
  "LA MAJADILLA, TULUMBA, CORDOBA",
  "LA MUDANA, POCHO, CORDOBA",
  "LA NACIONAL, GENERAL ROCA, CORDOBA",
  "LA PALESTINA, GENERAL SAN MARTIN, CORDOBA",
  "LA PAMPA, TOTORAL, CORDOBA",
  "LA PAQUITA ,EST.PRESIDENTE F.ALCORTA, SAN JUSTO, CORDOBA",
  "LA PARA, RIO PRIMERO, CORDOBA",
  "LA PATRIA, POCHO, CORDOBA",
  "LA PAZ, SAN JAVIER, CORDOBA",
  "LA PENCA, GENERAL ROCA, CORDOBA",
  "LA PLAYA, MINAS, CORDOBA",
  "LA PLAYOSA, GENERAL SAN MARTIN, CORDOBA",
  "LA POBLACION, SAN JAVIER, CORDOBA",
  "LA POSTA, RIO PRIMERO, CORDOBA",
  "LA PUERTA, COLON, CORDOBA",
  "LA PUERTA, RIO PRIMERO, CORDOBA",
  "LA RAMADA, SAN JAVIER, CORDOBA",
  "LA REPRESA, SAN JUSTO, CORDOBA",
  "LA RINCONADA, RIO SECO, CORDOBA",
  "LA SERRANITA, SANTA MARIA, CORDOBA",
  "LA TABLADA, POCHO, CORDOBA",
  "LA TABLADA ,AP., CAPITAL, CORDOBA",
  "LA TIGRA, UNION, CORDOBA",
  "LA TOMA, PUNILLA, CORDOBA",
  "LA TOMA, TULUMBA, CORDOBA",
  "LABORDE, UNION, CORDOBA",
  "LABOULAYE, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "LAGUNA DEL MONTE, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "LAGUNA DEL SUNCHO, RIO SECO, CORDOBA",
  "LAGUNA LARGA, RIO SEGUNDO, CORDOBA",
  "LAGUNA OSCURA, RIO CUARTO, CORDOBA",
  "LAGUNILLA, RIO SEGUNDO, CORDOBA",
  "LARSEN, GENERAL ROCA, CORDOBA",
  "LAS ACEQUIAS, RIO CUARTO, CORDOBA",
  "LAS ARRIAS, TULUMBA, CORDOBA",
  "LAS AVERIAS, RIO PRIMERO, CORDOBA",
  "LAS BAJADAS, CALAMUCHITA, CORDOBA",
  "LAS CALLES, SAN ALBERTO, CORDOBA",
  "LAS CAÑITAS, RIO CUARTO, CORDOBA",
  "LAS CHACRAS, COLON, CORDOBA",
  "LAS CHACRAS, POCHO, CORDOBA",
  "LAS CHACRAS, PUNILLA, CORDOBA",
  "LAS CHACRAS, CRUZ DEL EJE, CORDOBA",
  "LAS ENSENADAS, SAN ALBERTO, CORDOBA",
  "LAS GAMAS, RIO CUARTO, CORDOBA",
  "LAS HIGUERAS, RIO CUARTO, CORDOBA",
  "LAS ISLETILLAS, TERCERO ARRIBA, CORDOBA",
  "LAS JUNTURAS, RIO SEGUNDO, CORDOBA",
  "LAS MOJARRAS, GENERAL SAN MARTIN, CORDOBA",
  "LAS MOSTAZAS, CORONEL PRINGLES, CORDOBA",
  "LAS MOSTAZAS, RIO SECO, CORDOBA",
  "LAS PALMAS, POCHO, CORDOBA",
  "LAS PEÑAS, RIO CUARTO, CORDOBA",
  "LAS PEÑAS, TOTORAL, CORDOBA",
  "LAS PERDICES, TERCERO ARRIBA, CORDOBA",
  "LAS RABONAS, SAN ALBERTO, CORDOBA",
  "LAS SALADAS, RIO PRIMERO, CORDOBA",
  "LAS TAPIAS, RIO CUARTO, CORDOBA",
  "LAS TAPIAS, SAN JAVIER, CORDOBA",
  "LAS TOSCAS, SAN ALBERTO, CORDOBA",
  "LAS TOSCAS, ISCHILIN, CORDOBA",
  "LAS VARAS, SAN JUSTO, CORDOBA",
  "LAS VARILLAS, SAN JUSTO, CORDOBA",
  "LAS VERTIENTES, RIO CUARTO, CORDOBA",
  "LASPIUR, SATURNINO M., SAN JUSTO, CORDOBA",
  "LECUEDER, GENERAL ROCA, CORDOBA",
  "LEDESMA, ALEJO, MARCOS JUAREZ, CORDOBA",
  "LEGUIZAMON, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "LEONES, MARCOS JUAREZ, CORDOBA",
  "LOA ALAMOS, TULUMBA, CORDOBA",
  "LOA ALGARROBOS, RIO SECO, CORDOBA",
  "LOMAS DE SAN JOSE, COLON, CORDOBA",
  "LOS ALFALFARES, GENERAL ROCA, CORDOBA",
  "LOS ALTOS, COLON, CORDOBA",
  "LOS CADILLOS, ISCHILIN, CORDOBA",
  "LOS CALLEJONES, SAN ALBERTO, CORDOBA",
  "LOS CERRILLOS, SAN JAVIER, CORDOBA",
  "LOS CERRILLOS, RIO SECO, CORDOBA",
  "LOS CHAÑARES, TOTORAL, CORDOBA",
  "LOS CHAÑARITOS, RIO SEGUNDO, CORDOBA",
  "LOS CISNES, JUAREZ CELMAN, CORDOBA",
  "LOS COCOS, PUNILLA, CORDOBA",
  "LOS COMETIERRA, TOTORAL, CORDOBA",
  "LOS CONDORES, CALAMUCHITA, CORDOBA",
  "LOS GIGANTES, PUNILLA, CORDOBA",
  "LOS HORNILLOS, SAN JAVIER, CORDOBA",
  "LOS HOYOS, RIO SECO, CORDOBA",
  "LOS JAGUELES, RIO CUARTO, CORDOBA",
  "LOS MANANTIALES, SAN JAVIER, CORDOBA",
  "LOS MEDANITOS, POCHO, CORDOBA",
  "LOS MISTOLES, TOTORAL, CORDOBA",
  "LOS MOLINOS, CALAMUCHITA, CORDOBA",
  "LOS MOLLES, SAN JAVIER, CORDOBA",
  "LOS MOLLES, COLON, CORDOBA",
  "LOS MORTERITOS, MINAS, CORDOBA",
  "LOS PORONGOS, RIO SECO, CORDOBA",
  "LOS POZOS ,AP.KILOMETRO 827, ISCHILIN, CORDOBA",
  "LOS QUEBRACHITOS, POCHO, CORDOBA",
  "LOS QUEBRACHITOS, COLON, CORDOBA",
  "LOS REARTES, CALAMUCHITA, CORDOBA",
  "LOS SAUCES, CRUZ DEL EJE, CORDOBA",
  "LOS SURGENTES, MARCOS JUAREZ, CORDOBA",
  "LOS TARTAROS, ISCHILIN, CORDOBA",
  "LOS ZORROS, TERCERO ARRIBA, CORDOBA",
  "LOZADA, SANTA MARIA, CORDOBA",
  "LUCA, GENERAL SAN MARTIN, CORDOBA",
  "LUGONES, LEOPOLDO ,AP., PUNILLA, CORDOBA",
  "LUQUE, RIO SEGUNDO, CORDOBA",
  "LUTTI, CALAMUCHITA, CORDOBA",
  "LUXARDO, SAN JUSTO, CORDOBA",
  "LUYABA, SAN JAVIER, CORDOBA",
  "MACHA, TOTORAL, CORDOBA",
  "MAGIGASTA, POCHO, CORDOBA",
  "MAJADA DE SANTIAGO, CRUZ DEL EJE, CORDOBA",
  "MALAGUENO, SANTA MARIA, CORDOBA",
  "MALENA, RIO CUARTO, CORDOBA",
  "MALLIN, PUNILLA, CORDOBA",
  "MALVINAS ARGENTINAS ,EST.KILOMETRO 711, COLON, CORDOBA",
  "MANANTIALES, JUAREZ CELMAN, CORDOBA",
  "MANFREDI, RIO SEGUNDO, CORDOBA",
  "MANSILLA, LUCIO V., TULUMBA, CORDOBA",
  "MAQUINISTA GALLINI, RIO PRIMERO, CORDOBA",
  "MARULL, SAN JUSTO, CORDOBA",
  "MATORRALES, RIO SEGUNDO, CORDOBA",
  "MATTALDI, GENERAL ROCA, CORDOBA",
  "MEDIA LUNA, COLON, CORDOBA",
  "MEDIA NARANJA, CRUZ DEL EJE, CORDOBA",
  "MELO, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "MENDIOLAZA, COLON, CORDOBA",
  "MINA CLAVERO, SAN ALBERTO, CORDOBA",
  "MIRAMAR, SAN JUSTO, CORDOBA",
  "MOLINARI, PUNILLA, CORDOBA",
  "MONTE BUEY, MARCOS JUAREZ, CORDOBA",
  "MONTE CRISTO, RIO PRIMERO, CORDOBA",
  "MONTE DE LOS GAUCHOS, RIO CUARTO, CORDOBA",
  "MONTE DEL ROSARIO, RIO PRIMERO, CORDOBA",
  "MONTE LEÑA, UNION, CORDOBA",
  "MONTE MAIZ, UNION, CORDOBA",
  "MONTE RALO, SANTA MARIA, CORDOBA",
  "MONTE REDONDO ,EST., SAN JUSTO, CORDOBA",
  "MORRISON, UNION, CORDOBA",
  "MORTEROS, SAN JUSTO, CORDOBA",
  "MULA MUERTA, TOTORAL, CORDOBA",
  "MUSSI, SAN ALBERTO, CORDOBA",
  "NARVAJA, TRISTAN ,AP., CAPITAL, CORDOBA",
  "NAZCA, GENERAL ROCA, CORDOBA",
  "NINALQUIN, MINAS, CORDOBA",
  "NOETINGER, UNION, CORDOBA",
  "NONO, SAN ALBERTO, CORDOBA",
  "NUEVA ALDALUCIA, RIO PRIMERO, CORDOBA",
  "NUÑEZ DEL ORADO, COLON, CORDOBA",
  "OBISPO TREJO, RIO PRIMERO, CORDOBA",
  "OJO DE AGUA, MINAS, CORDOBA",
  "OJO DE AGUA, MINAS, CORDOBA",
  "OLAEN, PUNILLA, CORDOBA",
  "OLAETA, JUAREZ CELMAN, CORDOBA",
  "OLIVA, RIO TERCERO, CORDOBA",
  "OLMOS, JUAREZ CELMAN, CORDOBA",
  "ONAGOITY, GENERAL ROCA, CORDOBA",
  "ONCATIVO, RIO SEGUNDO, CORDOBA",
  "ONGAMIRA, ISCHILIN, CORDOBA",
  "ORDOÑEZ, UNION, CORDOBA",
  "ORO GRUESO, CRUZ DEL EJE, CORDOBA",
  "PACHANGO, SAN ALBERTO, CORDOBA",
  "PACHECO DE MELO, JUAREZ CELMAN, CORDOBA",
  "PAJAS BLANCAS, COLON, CORDOBA",
  "PAMPAYASTA NORTE, TERCERO ARRIBA, CORDOBA",
  "PAMPAYASTA SUR, TERCERO ARRIBA, CORDOBA",
  "PAN DE AZUCAR ,AP.KILOMETRO 592, PUNILLA, CORDOBA",
  "PANAHOLMA, SAN ALBERTO, CORDOBA",
  "PASCANAS, UNION, CORDOBA",
  "PASCO, GENERAL SAN MARTIN, CORDOBA",
  "PASO DEL RIO SECO, RIO CUARTO, CORDOBA",
  "PASO VIEJO, CRUZ DEL EJE, CORDOBA",
  "PAUNERO, RIO CUARTO, CORDOBA",
  "PAVIN, JUAREZ CELMAN, CORDOBA",
  "PEGASANO, GENERAL ROCA, CORDOBA",
  "PELLICO, SILVIO, GENERAL SAN MARTIN, CORDOBA",
  "PETER, JAIME, ISCHILIN, CORDOBA",
  "PICHANAS, CRUZ DEL EJE, CORDOBA",
  "PIEDRA BLANCA, RIO CUARTO, CORDOBA",
  "PIEDRAS ANCHAS, MINAS, CORDOBA",
  "PIEDRAS BLANCAS, CRUZ DEL EJE, CORDOBA",
  "PIEDRITA BLANCA, MINAS, CORDOBA",
  "PIEDRITAS ROSADAS, POCHO, CORDOBA",
  "PILAR, RIO SEGUNDO, CORDOBA",
  "PINAS, MINAS, CORDOBA",
  "PINCEN, GENERAL ROCA, CORDOBA",
  "PINTOS, PUNILLA, CORDOBA",
  "PIQUILLIN, RIO PRIMERO, CORDOBA",
  "PIZARRO, MODESTINO, GENERAL ROCA, CORDOBA",
  "PLAYA GRANDE, SAN JUSTO, CORDOBA",
  "PLAZA BRUNO, SAN JUSTO, CORDOBA",
  "PLAZA COLAZO, RIO SEGUNDO, CORDOBA",
  "PLAZA DE MERCEDES, RIO PRIMERO, CORDOBA",
  "PLAZA DEHEZA, SAN JUSTO, CORDOBA",
  "PLAZA LUXARDO, SAN JUSTO, CORDOBA",
  "PLAZA SAN FRANCISCO, SAN JUSTO, CORDOBA",
  "PLAZA VIDELA, RIO SEGUNDO, CORDOBA",
  "POCHO, POCHO, CORDOBA",
  "PORTEÑA, SAN JUSTO, CORDOBA",
  "POSSE, JUSTINIANO, UNION, CORDOBA",
  "POZO DE LA LOMA, RIO PRIMERO, CORDOBA",
  "POZO DE LA PAMPA, SAN ALBERTO, CORDOBA",
  "POZO DE LAS OLLAS, RIO SECO, CORDOBA",
  "POZO DEL CHAÑAR, SAN JAVIER, CORDOBA",
  "POZO DEL MOLLE, RIO SEGUNDO, CORDOBA",
  "POZO DEL MORO, RIO PRIMERO, CORDOBA",
  "POZO DEL TIGRE, COLON, CORDOBA",
  "PRETOT FREYRE, RIO CUARTO, CORDOBA",
  "PUEBLO ITALIANO, UNION, CORDOBA",
  "PUEBLO VIEJO, UNION, CORDOBA",
  "PUERTA COLORADA, RIO CUARTO, CORDOBA",
  "PUESTO DE AFUERA ,AP., RIO PRIMERO, CORDOBA",
  "PUESTO DE CASTRO, RIO SECO, CORDOBA",
  "PUESTO DEL ROSARIO, TOTORAL, CORDOBA",
  "PUESTO PUCHETA, RIO PRIMERO, CORDOBA",
  "PUESTO SAN JOSE, TULUMBA, CORDOBA",
  "PUNILLA ,AP., PUNILLA, CORDOBA",
  "PUNTA DEL AGUA, RIO CUARTO, CORDOBA",
  "PUNTA DEL AGUA, TERCERO ARRIBA, CORDOBA",
  "PUNTA DEL AGUA, RIO PRIMERO, CORDOBA",
  "QUEBRACHO HERRADO, SAN JUSTO, CORDOBA",
  "QUEBRADA DE LOS POZOS, SAN JAVIER, CORDOBA",
  "QUILINO, ISCHILIN, CORDOBA",
  "QUISQUIZACATE ,AP., CAPITAL, CORDOBA",
  "RANGEL, RIO PRIMERO, CORDOBA",
  "RANQUELES, GENERAL ROCA, CORDOBA",
  "RARA FORTUNA, MINAS, CORDOBA",
  "RAYO CORTADO, RIO SECO, CORDOBA",
  "RINCON, RIO SEGUNDO, CORDOBA",
  "RINCON DE LUNA, CALAMUCHITA, CORDOBA",
  "RIO BAMBA, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "RIO CEBALLOS, COLON, CORDOBA",
  "RIO CUARTO, RIO CUARTO, CORDOBA",
  "RIO CUARTO NORTE ,AP., RIO CUARTO, CORDOBA",
  "RIO DE LOS MOLINOS ,PDA., CALAMUCHITA, CORDOBA",
  "RIO DE LOS SAUCES, CALAMUCHITA, CORDOBA",
  "RIO HONDO, POCHO, CORDOBA",
  "RIO PRIMERO, RIO PRIMERO, CORDOBA",
  "RIO SEGUNDO, RIO SEGUNDO, CORDOBA",
  "RIO TERCERO, TERCERO ARRIBA, CORDOBA",
  "RIVERA INDARTE, CAPITAL, CORDOBA",
  "ROCA, ALEJANDRO ,EST.ALEJANDRO, JUAREZ CELMAN, CORDOBA",
  "RODEO GRANDE, MINAS, CORDOBA",
  "RODEO VIEJO, RIO CUARTO, CORDOBA",
  "RODRIGUEZ DEL BUSTO, CAPITAL, CORDOBA",
  "ROJAS, DIEGO DE, RIO PRIMERO, CORDOBA",
  "ROSALES, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "ROSALES, BLAS DE, COLON, CORDOBA",
  "RUIZ DIAZ DE GUZMAN, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "RUMIYACO, MINAS, CORDOBA",
  "SACANTA, SAN JUSTO, CORDOBA",
  "SAGUION, ISCHILIN, CORDOBA",
  "SAIRA, MARCOS JUAREZ, CORDOBA",
  "SALADILLO, MARCOS JUAREZ, CORDOBA",
  "SALAS, MIGUEL, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "SALDAN, COLON, CORDOBA",
  "SALGUERO, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "SALSACATE, POCHO, CORDOBA",
  "SALSIPUEDES, COLON, CORDOBA",
  "SAMPACHO, RIO CUARTO, CORDOBA",
  "SAN AGUSTIN, SAN JUSTO, CORDOBA",
  "SAN AGUSTIN, CALAMUCHITA, CORDOBA",
  "SAN AMBROSIO, RIO CUARTO, CORDOBA",
  "SAN ANTINIO, SAN JUSTO, CORDOBA",
  "SAN ANTONIO, SANTA MARIA, CORDOBA",
  "SAN ANTONIO, CRUZ DEL EJE, CORDOBA",
  "SAN ANTONIO DE ARREDONDO, PUNILLA, CORDOBA",
  "SAN ANTONIO DE LITIN, UNION, CORDOBA",
  "SAN BARTOLOME, RIO CUARTO, CORDOBA",
  "SAN BASILIO, RIO CUARTO, CORDOBA",
  "SAN BUENAVENTURA, PUNILLA, CORDOBA",
  "SAN BUENAVENTURA ,AP., PUNILLA, CORDOBA",
  "SAN CARLOS, MINAS, CORDOBA",
  "SAN CARLOS, TULUMBA, CORDOBA",
  "SAN CLEMENTE, SANTA MARIA, CORDOBA",
  "SAN ESTEBAN, PUNILLA, CORDOBA",
  "SAN FERNANDO, COLON, CORDOBA",
  "SAN FRANCISCO, SAN JUSTO, CORDOBA",
  "SAN FRANCISCO DEL CHAÑAR, SOBREMONTE, CORDOBA",
  "SAN GERONIMO, POCHO, CORDOBA",
  "SAN IGNACIO, CALAMUCHITA, CORDOBA",
  "SAN IGNACIO, CRUZ DEL EJE, CORDOBA",
  "SAN JAVIER, SAN JAVIER, CORDOBA",
  "SAN JOAQUIN, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "SAN JOSE, SAN JAVIER, CORDOBA",
  "SAN JOSE, PUNILLA, CORDOBA",
  "SAN JOSE DE LA DORMIDA, TULUMBA, CORDOBA",
  "SAN JOSE DE LAS SALINAS ,EST.SAN JOSE, TULUMBA, CORDOBA",
  "SAN JOSE DEL SALTEÑO, MARCOS JUAREZ, CORDOBA",
  "SAN LORENZO, SAN ALBERTO, CORDOBA",
  "SAN LUIS, RIO SECO, CORDOBA",
  "SAN MARCOS, UNION, CORDOBA",
  "SAN MARCOS SIERRA, CRUZ DEL EJE, CORDOBA",
  "SAN MIGUEL, RIO SECO, CORDOBA",
  "SAN PEDRO, SAN ALBERTO, CORDOBA",
  "SAN PEDRO, SANTA MARIA, CORDOBA",
  "SAN PEDRO, RIO SECO, CORDOBA",
  "SAN PEDRO DE TOYOS, ISCHILIN, CORDOBA",
  "SAN PEDRO NORTE, TULUMBA, CORDOBA",
  "SAN RAFAEL, SAN ALBERTO, CORDOBA",
  "SAN ROQUE, PUNILLA, CORDOBA",
  "SAN SEVERO, UNION, CORDOBA",
  "SAN VICENTE, SAN ALBERTO, CORDOBA",
  "SANABRIA, GENERAL SAN MARTIN, CORDOBA",
  "SANTA ANA, CRUZ DEL EJE, CORDOBA",
  "SANTA CATALINA, SAN ALBERTO, CORDOBA",
  "SANTA CATALINA ,EST.HOLMBERG, RIO CUARTO, CORDOBA",
  "SANTA CRUZ, TULUMBA, CORDOBA",
  "SANTA EUFEMIA, JUAREZ CELMAN, CORDOBA",
  "SANTA ISABEL, CAPITAL, CORDOBA",
  "SANTA MAGDALENA ,EST.JOVITA, GENERAL ROCA, CORDOBA",
  "SANTA MARIA, TOTORAL, CORDOBA",
  "SANTA MARIA DE PUNILLA ,EST.SANTA MARIA, PUNILLA, CORDOBA",
  "SANTA ROSA, SAN ALBERTO, CORDOBA",
  "SANTA ROSA DE CALAMUCHITA, CALAMUCHITA, CORDOBA",
  "SANTA ROSA DE RIO PRIMERO ,EST.V.S.ROSA, RIO PRIMERO, CORDOBA",
  "SANTA SABINA, TOTORAL, CORDOBA",
  "SANTA TERESA, COLON, CORDOBA",
  "SANTA VICTORIA, GENERAL SAN MARTIN, CORDOBA",
  "SARMIENTO, TOTORAL, CORDOBA",
  "SAUCE DE LOS QUEVEDOS, MINAS, CORDOBA",
  "SEEBER, SAN JUSTO, CORDOBA",
  "SERRANO, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "SERREZUELA, CRUZ DEL EJE, CORDOBA",
  "SIMBOLAR, TOTORAL, CORDOBA",
  "SINSACATE, TOTORAL, CORDOBA",
  "SOCONCHO, CALAMUCHITA, CORDOBA",
  "SOTO, CRUZ DEL EJE, CORDOBA",
  "SUCO, RIO CUARTO, CORDOBA",
  "SUQUIA ,AP., CAPITAL, CORDOBA",
  "SVILLA NUEVA, GENERAL SAN MARTIN, CORDOBA",
  "TABAQUILLO, CRUZ DEL EJE, CORDOBA",
  "TALA CAÑADA, POCHO, CORDOBA",
  "TALA CRUZ, CALAMUCHITA, CORDOBA",
  "TALA NORTE ,EST.EL ALCALDE, RIO PRIMERO, CORDOBA",
  "TALAINI, MINAS, CORDOBA",
  "TANCACHA, TERCERO ARRIBA, CORDOBA",
  "TANTI, PUNILLA, CORDOBA",
  "TANTI NUEVO, PUNILLA, CORDOBA",
  "TASMA, SAN ALBERTO, CORDOBA",
  "TEGUA, RIO CUARTO, CORDOBA",
  "TEMPLE, SANTIAGO, RIO SEGUNDO, CORDOBA",
  "THEA, PUNILLA, CORDOBA",
  "TICINO, GENERAL SAN MARTIN, CORDOBA",
  "TILQUICHO, SAN JAVIER, CORDOBA",
  "TINOCO, COLON, CORDOBA",
  "TIO PUJIO, GENERAL SAN MARTIN, CORDOBA",
  "TOLEDO, SANTA MARIA, CORDOBA",
  "TOSNO, MINAS, CORDOBA",
  "TOSQUITA, RIO CUARTO, CORDOBA",
  "TOTORALEJOS, TULUMBA, CORDOBA",
  "TRANSITO, SAN JUSTO, CORDOBA",
  "TRAVESIA, SAN JAVIER, CORDOBA",
  "TRES ARBOLES, CRUZ DEL EJE, CORDOBA",
  "TRINCHERA, SAN JUSTO, CORDOBA",
  "TUCLAME, CRUZ DEL EJE, CORDOBA",
  "UCACHA, JUAREZ CELMAN, CORDOBA",
  "UNQUILLO, COLON, CORDOBA",
  "VALLE HERMOSO, PUNILLA, CORDOBA",
  "VELEZ SARSFIELD, DALMACIO, TERCERO ARRIBA, CORDOBA",
  "VELEZ SARSFIELD, DALMACIO ,AP., CAPITAL, CORDOBA",
  "VIAMONTE, UNION, CORDOBA",
  "VICUÑA MACKENNA, RIO CUARTO, CORDOBA",
  "VILLA AIZACATE, SANTA MARIA, CORDOBA",
  "VILLA ALICIA, ISCHILIN, CORDOBA",
  "VILLA ALLENDE, COLON, CORDOBA",
  "VILLA ASCASUBI, TERCERO ARRIBA, CORDOBA",
  "VILLA BUSTOS, PUNILLA, CORDOBA",
  "VILLA CANDELARIA, RIO SECO, CORDOBA",
  "VILLA CARLOS PAZ, PUNILLA, CORDOBA",
  "VILLA CERRO AZUL, COLON, CORDOBA",
  "VILLA COLANCHANGA, COLON, CORDOBA",
  "VILLA COLONIA ITALIANA, MARCOS JUAREZ, CORDOBA",
  "VILLA CONCEPCION DEL TIO, SAN JUSTO, CORDOBA",
  "VILLA CORAZON DE MARIA, COLON, CORDOBA",
  "VILLA CURA BROCHERO, SAN ALBERTO, CORDOBA",
  "VILLA DE LAS ROSAS, SAN JAVIER, CORDOBA",
  "VILLA DE MARIA, RIO SECO, CORDOBA",
  "VILLA DE SOTO, CRUZ DEL EJE, CORDOBA",
  "VILLA DEL DIQUE, CALAMUCHITA, CORDOBA",
  "VILLA DEL LAGO, PUNILLA, CORDOBA",
  "VILLA DEL ROSARIO, RIO SEGUNDO, CORDOBA",
  "VILLA DEL TOTORAL, TOTORAL, CORDOBA",
  "VILLA DEL TRANSITO, SAN JUSTO, CORDOBA",
  "VILLA DOLORES, SAN JAVIER, CORDOBA",
  "VILLA ELISA, MARCOS JUAREZ, CORDOBA",
  "VILLA ESQUIU, CAPITAL, CORDOBA",
  "VILLA FONTANA, RIO PRIMERO, CORDOBA",
  "VILLA GARCIA, PUNILLA, CORDOBA",
  "VILLA GENERAL BELGRANO, CALAMUCHITA, CORDOBA",
  "VILLA GIARDINO, PUNILLA, CORDOBA",
  "VILLA GUTIERREZ ,EMB. KM. 807, ISCHILIN, CORDOBA",
  "VILLA HUIDOBRO ,EST.CAÑADA VERDE, GENERAL ROCA, CORDOBA",
  "VILLA ICHO CRUZ, PUNILLA, CORDOBA",
  "VILLA INDEPENDENCIA, PUNILLA, CORDOBA",
  "VILLA LOS MOLINOS, CALAMUCHITA, CORDOBA",
  "VILLA MARIA, GENERAL SAN MARTIN, CORDOBA",
  "VILLA MODERNA, GENERAL ROCA, CORDOBA",
  "VILLA ÑU PORA, COLON, CORDOBA",
  "VILLA QUILINO, ISCHILIN, CORDOBA",
  "VILLA RAFAEL BENEGAS, SAN ALBERTO, CORDOBA",
  "VILLA REDUCCION, JUAREZ CELMAN, CORDOBA",
  "VILLA ROSARIO DEL SALADILLO, TULUMBA, CORDOBA",
  "VILLA ROSSI, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "VILLA RUMIPAL, CALAMUCHITA, CORDOBA",
  "VILLA SAN ANTONIO, RIO PRIMERO, CORDOBA",
  "VILLA SAN ESTEBAN, SAN JUSTO, CORDOBA",
  "VILLA SARMIENTO, GENERAL ROCA, CORDOBA",
  "VILLA TULUMBA, TULUMBA, CORDOBA",
  "VILLA VALERIA, GENERAL ROCA, CORDOBA",
  "VILLA VAUDAGNA, SAN JUSTO, CORDOBA",
  "VILLA VISO, POCHO, CORDOBA",
  "VIVAS, PEDRO E. ,EMB.KILOMETRO 658, RIO PRIMERO, CORDOBA",
  "VIVERO, PRESIDENTE ROQUE SAENZ PEÑA, CORDOBA",
  "WASHINGTON, RIO CUARTO, CORDOBA",
  "WATT ,EST.MELIDEO, GENERAL ROCA, CORDOBA",
  "YACANTO, SAN JAVIER, CORDOBA",
  "YACANTO DE CALAMUCHITA, CALAMUCHITA, CORDOBA",
  "YOCSINA, SANTA MARIA, CORDOBA",
  "ZUMARAN, ANA, UNION, CORDOBA",
  "9 DE JULIO ,EST.PUEBLO DE JULIO, SAN ROQUE, CORRIENTES",
  "ACUÑA, MONTE CASEROS, CORRIENTES",
  "AGUAPEY, GENERAL ALVEAR, CORRIENTES",
  "AGUAY, CURUZU CUATIA, CORRIENTES",
  "ALEM CUE, SAN MARTIN, CORRIENTES",
  "ALFONSO LOMA, MERCEDES, CORRIENTES",
  "ALVEAR, GENERAL ALVEAR, CORRIENTES",
  "ANGUA, SALADAS, CORRIENTES",
  "APIPE, ITUZAINGO, CORRIENTES",
  "ARERUNGUA, BERON DE ASTRADA, CORRIENTES",
  "ARROYITO, BELLA VISTA, CORRIENTES",
  "ARROYO AMBROSIO ,AP., SALADAS, CORRIENTES",
  "ARROYO GONZALEZ, SAN ROQUE, CORRIENTES",
  "ARROYO PONTON, SAN LUIS DEL PALMAR, CORRIENTES",
  "BAIBIENE, CURUZU CUATIA, CORRIENTES",
  "BAÑADO SUR, CAPITAL, CORRIENTES",
  "BATEL, LAVALLE, CORRIENTES",
  "BELLA VISTA, BELLA VISTA, CORRIENTES",
  "BERON DE ASTRADA, BERON DE ASTRADA, CORRIENTES",
  "BONPLAND, PASO DE LOS LIBRES, CORRIENTES",
  "BOQUERON, MERCEDES, CORRIENTES",
  "BUENA VISTA, GOYA, CORRIENTES",
  "CAA GUAZU ,AP., MERCEDES, CORRIENTES",
  "CABRED, PASO DE LOS LIBRES, CORRIENTES",
  "CAMPO GRANDE, EMPEDRADO, CORRIENTES",
  "CAMPO GRANDE, SAN LUIS DEL PALMAR, CORRIENTES",
  "CAÑADITAS, SAUCE, CORRIENTES",
  "CAPILLITAS, GENERAL PAZ, CORRIENTES",
  "CAPITAN JOAQUIN MADARIAGA, CURUZU CUATIA, CORRIENTES",
  "CAZA PAVA, SANTO TOME, CORRIENTES",
  "CAZADORES CORRENTINOS, CURUZU CUATIA, CORRIENTES",
  "CERRITO, GENERAL PAZ, CORRIENTES",
  "CERRUDO CUE, SAN LUIS DEL PALMAR, CORRIENTES",
  "CHAVARRIA, SAN ROQUE, CORRIENTES",
  "COLONIA 3 DE ABRIL, BELLA VISTA, CORRIENTES",
  "COLONIA BARRIENTOS, CURUZU CUATIA, CORRIENTES",
  "COLONIA BUEN RETIRO, MONTE CASEROS, CORRIENTES",
  "COLONIA CARLOS PELLEGRINI, SAN MARTIN, CORRIENTES",
  "COLONIA CAROLINA, GOYA, CORRIENTES",
  "COLONIA CAROLINA ,AP., GOYA, CORRIENTES",
  "COLONIA GARABI, SANTO TOME, CORRIENTES",
  "COLONIA ISABEL VICTORIA, GOYA, CORRIENTES",
  "COLONIA MADARIAGA, SAN MIGUEL, CORRIENTES",
  "COLONIA PANDO, SAN ROQUE, CORRIENTES",
  "COLONIA PORVENIR, GOYA, CORRIENTES",
  "COLONIA PROGRESO, BELLA VISTA, CORRIENTES",
  "COLONIA PUCHETA, GOYA, CORRIENTES",
  "COLONIA ROMERO, GENERAL PAZ, CORRIENTES",
  "COLONIA SAN MARTIN, GENERAL PAZ, CORRIENTES",
  "COLONIA SARGENTO JUAN B. CABRAL, SALADAS, CORRIENTES",
  "COLONIA TATACUA, CONCEPCION, CORRIENTES",
  "COLONIA YATAYTI CALLE, LAVALLE, CORRIENTES",
  "CONCEPCION, CONCEPCION, CORRIENTES",
  "CONI, EMILIO R., CURUZU CUATIA, CORRIENTES",
  "CORONEL ABRAHAM SCHWEIZER, ESQUINA, CORRIENTES",
  "CORONEL DESIDERIO SOSA, SANTO TOME, CORRIENTES",
  "CORRIENTES, CAPITAL, CORRIENTES",
  "COSTA GRANDE, SAN LUIS DEL PALMAR, CORRIENTES",
  "CRUCESITA, LAVALLE, CORRIENTES",
  "CRUZ DE LOS MILAGROS, LAVALLE, CORRIENTES",
  "CUAY GRANDE, SANTO TOME, CORRIENTES",
  "CURUZU CUATIA, CURUZU CUATIA, CORRIENTES",
  "DERQUI, MANUEL, EMPEDRADO, CORRIENTES",
  "DESMOCHADO, BELLA VISTA, CORRIENTES",
  "DIAZ COLODRERO,PEDRO, CURUZU CUATIA, CORRIENTES",
  "DOCTOR FELIX MARIA GOMEZ ,AP., CAPITAL, CORRIENTES",
  "EJERCITO ARGENTINO ,DV., MERCEDES, CORRIENTES",
  "EL SOMBRERO ,EMB., EMPEDRADO, CORRIENTES",
  "EMPEDRADO LIMPIO, SAN LUIS DEL PALMAR, CORRIENTES",
  "ENSENADA GRANDE, SAN COSME, CORRIENTES",
  "ESQUINA, ESQUINA, CORRIENTES",
  "FANEGAS, GOYA, CORRIENTES",
  "FERNANDEZ, PEDRO R.,EST.M.F.MANSILLA, SAN ROQUE, CORRIENTES",
  "GALARZA, SANTO TOME, CORRIENTES",
  "GARRUCHOS, SANTO TOME, CORRIENTES",
  "GDOR.IGR.VALENTIN VIRASORO.GDOR.VIRASORO, SANTO TOME, CORRIENTES",
  "GOBERNADOR JUAN E.MARTINEZ, LAVALLE, CORRIENTES",
  "GOYA, GOYA, CORRIENTES",
  "GUAVIRAVI ,EST.25 DE FEBRERO, SAN MARTIN, CORRIENTES",
  "GUAYQUIRARO, ESQUINA, CORRIENTES",
  "HERLITKZA, SAN LUIS DEL PALMAR, CORRIENTES",
  "HORMIGUERO, SANTO TOME, CORRIENTES",
  "IBAHAY, GENERAL PAZ, CORRIENTES",
  "IFRAN, GOYA, CORRIENTES",
  "INGENIO PRIMER CORRENTINO, SAN COSME, CORRIENTES",
  "ISOQUI, SAN MARTIN, CORRIENTES",
  "ITA IBATE, GENERAL PAZ, CORRIENTES",
  "ITATI, ITATI, CORRIENTES",
  "ITUZAINGO, ITUZAINGO, CORRIENTES",
  "KILOMETR 402 ,AP., BELLA VISTA, CORRIENTES",
  "KILOMETR0 167 ,AP., MONTE CASEROS, CORRIENTES",
  "KILOMETR0 301 ,AP., PASO DE LOS LIBRES, CORRIENTES",
  "KILOMETRO 104 ,AP., MONTE CASEROS, CORRIENTES",
  "KILOMETRO 120 ,AP., MONTE CASEROS, CORRIENTES",
  "KILOMETRO 161 ,PDA., MONTE CASEROS, CORRIENTES",
  "KILOMETRO 173 ,EMB, MONTE CASEROS, CORRIENTES",
  "KILOMETRO 182 ,AP., MONTE CASEROS, CORRIENTES",
  "KILOMETRO 204 ,AP., PASO DE LOS LIBRES, CORRIENTES",
  "KILOMETRO 374 ,AP., SAN ROQUE, CORRIENTES",
  "KILOMETRO 382 ,AP., SAN ROQUE, CORRIENTES",
  "KILOMETRO 387 ,AP., SAN ROQUE, CORRIENTES",
  "KILOMETRO 394 ,AP., GENERAL ALVEAR, CORRIENTES",
  "KILOMETRO 396 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 405 .AP., CURUZU CUATIA, CORRIENTES",
  "KILOMETRO 406 ,AP., BELLA VISTA, CORRIENTES",
  "KILOMETRO 410 ,AP., LAVALLE, CORRIENTES",
  "KILOMETRO 416 ,AP., LAVALLE, CORRIENTES",
  "KILOMETRO 431 ,AP., SALADAS, CORRIENTES",
  "KILOMETRO 442 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 451 ,AP., EMPEDRADO, CORRIENTES",
  "KILOMETRO 459 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 470 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 476 ,AP., EMPEDRADO, CORRIENTES",
  "KILOMETRO 479 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 485 ,AP., EMPEDRADO, CORRIENTES",
  "KILOMETRO 489 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 492 ,AP., EMPEDRADO, CORRIENTES",
  "KILOMETRO 501 ,AP., EMPEDRADO, CORRIENTES",
  "KILOMETRO 504 ,AP., CAPITAL, CORRIENTES",
  "KILOMETRO 506 ,AP., SANTO TOME, CORRIENTES",
  "KILOMETRO 512 ,AP., CAPITAL, CORRIENTES",
  "KILOMETRO 516 ,AP., CAPITAL, CORRIENTES",
  "KILOMETRO 517 ,AP., SANTO TOME, CORRIENTES",
  "LA CASUALIDAD, ESQUINA, CORRIENTES",
  "LA CRUZ, SAN MARTIN, CORRIENTES",
  "LABOUGLE, MONTE CASEROS, CORRIENTES",
  "LAGUNA BRAVA, CAPITAL, CORRIENTES",
  "LAS LOMAS, BELLA VISTA, CORRIENTES",
  "LAS PALMITAS, GENERAL ALVEAR, CORRIENTES",
  "LAVALLE, LAVALLE, CORRIENTES",
  "LIBERTAD, MONTE CASEROS, CORRIENTES",
  "LIBERTAD ,EST., MONTE CASEROS, CORRIENTES",
  "LOMAS DE GALARZA, SAN LUIS DEL PALMAR, CORRIENTES",
  "LOMAS DE VALLEJOS, GENERAL PAZ, CORRIENTES",
  "LORETO, SAN MIGUEL, CORRIENTES",
  "LOZA, MARIANO I. ,EST.JUSTINO SOLARI, MERCEDES, CORRIENTES",
  "MALOYAS, SAN LUIS DEL PALMAR, CORRIENTES",
  "MALVINAS, ESQUINA, CORRIENTES",
  "MANANTIALES, MBURUCUYA, CORRIENTES",
  "MBURUCUYA, MBURUCUYA, CORRIENTES",
  "MERCEDES, MERCEDES, CORRIENTES",
  "MINUANES ,AP., CURUZU CUATIA, CORRIENTES",
  "MONTE CASEROS, MONTE CASEROS, CORRIENTES",
  "MONTE GRANDE, SAN LUIS DEL PALMAR, CORRIENTES",
  "MORA, GOYA, CORRIENTES",
  "MUCHAS ISLAS, BELLA VISTA, CORRIENTES",
  "NARANJITO, MERCEDES, CORRIENTES",
  "NUESTRA SEÑORA DEL ROSARIO DE CAA CATI, GENERAL PAZ, CORRIENTES",
  "OMBUCITO, PASO DE LOS LIBRES, CORRIENTES",
  "PAGO LARGO, CURUZU CUATIA, CORRIENTES",
  "PAGO REDONDO, GOYA, CORRIENTES",
  "PALMAR GRANDE, GENERAL PAZ, CORRIENTES",
  "PANCHO CUE, GENERAL ALVEAR, CORRIENTES",
  "PARAJE SAN ISIDRO, GOYA, CORRIENTES",
  "PASO DE LA PATRIA, SAN COSME, CORRIENTES",
  "PASO DE LOS LIBRES, PASO DE LOS LIBRES, CORRIENTES",
  "PASO FLORENTIN, GENERAL PAZ, CORRIENTES",
  "PASO ITU, GENERAL ALVEAR, CORRIENTES",
  "PASO PESOA, CAPITAL, CORRIENTES",
  "PERUGORRIA, CURUZU CUATIA, CORRIENTES",
  "PLAYADITO, ITUZAINGO, CORRIENTES",
  "PUCHETA ,PDA., PASO DE LOS LIBRES, CORRIENTES",
  "PUEBLO LIBERTADOR, ESQUINA, CORRIENTES",
  "PUENTE BATEL, LAVALLE, CORRIENTES",
  "PUERTO EMPEDRADO, EMPEDRADO, CORRIENTES",
  "PUERTO GOYA, GOYA, CORRIENTES",
  "PUERTO LAVALLE, LAVALLE, CORRIENTES",
  "PUISOYE, GENERAL PAZ, CORRIENTES",
  "PUJOL, JUAN, MONTE CASEROS, CORRIENTES",
  "PUNTA GRANDE, MBURUCUYA, CORRIENTES",
  "RAMADA PASO, ITATI, CORRIENTES",
  "RAMONES, SAN LUIS DEL PALMAR, CORRIENTES",
  "RIACHUELO, CAPITAL, CORRIENTES",
  "SAENZ VALIENTE, MONTE CASEROS, CORRIENTES",
  "SALADAS, SALADAS, CORRIENTES",
  "SALDAÑA, GENERAL PAZ, CORRIENTES",
  "SAN ANTONIO, PASO DE LOS LIBRES, CORRIENTES",
  "SAN CARLOS, ITUZAINGO, CORRIENTES",
  "SAN COSME, SAN COSME, CORRIENTES",
  "SAN GABRIEL, SAN MARTIN, CORRIENTES",
  "SAN LORENZO, SALADAS, CORRIENTES",
  "SAN LUIS DEL PALMAR, SAN LUIS DEL PALMAR, CORRIENTES",
  "SAN MIGUEL, SAN MIGUEL, CORRIENTES",
  "SAN ROQUE, SAN ROQUE, CORRIENTES",
  "SAN SALVADOR, PASO DE LOS LIBRES, CORRIENTES",
  "SANTA ANA, SAN COSME, CORRIENTES",
  "SANTA LUCIA, LAVALLE, CORRIENTES",
  "SANTA ROSA, CONCEPCION, CORRIENTES",
  "SANTILLAN, GOYA, CORRIENTES",
  "SANTO TOME, SANTO TOME, CORRIENTES",
  "SAUCE, SAUCE, CORRIENTES",
  "TABAY, CONCEPCION, CORRIENTES",
  "TACUARAL, GENERAL PAZ, CORRIENTES",
  "TACUARITAS, SAN ROQUE, CORRIENTES",
  "TAPEBICUA, PASO DE LOS LIBRES, CORRIENTES",
  "TIMBOCITO, MERCEDES, CORRIENTES",
  "TORRENT, GENERAL ALVEAR, CORRIENTES",
  "UGUAY, MERCEDES, CORRIENTES",
  "VACA CUA, CURUZU CUATIA, CORRIENTES",
  "VILLA CORDOBA, LAVALLE, CORRIENTES",
  "YAHAPE, BERON DE ASTRADA, CORRIENTES",
  "YAPEYU, SAN MARTIN, CORRIENTES",
  "YAPEYU ,AP., PASO DE LOS LIBRES, CORRIENTES",
  "YOFRE, FELIPE, MERCEDES, CORRIENTES",
  "YUQUERI, MERCEDES, CORRIENTES",
  "1° DE MAYO, URUGUAY, ENTRE RIOS",
  "20 DE SEPTIEMBRE, NOGOYA, ENTRE RIOS",
  "ACHAGUE, TALA, ENTRE RIOS",
  "ALBERTO GERCHUNOF ,PDA., URUGUAY, ENTRE RIOS",
  "ALDEA ASUNCION, GUALEGUAY, ENTRE RIOS",
  "ALDEA BRASILERA, DIAMANTE, ENTRE RIOS",
  "ALDEA CUESTA, PARANA, ENTRE RIOS",
  "ALDEA MARIA LUISA, PARANA, ENTRE RIOS",
  "ALDEA PROTESTANTE, DIAMANTE, ENTRE RIOS",
  "ALDEA SAN ANTONIO, GUALEGUAYCHU, ENTRE RIOS",
  "ALDEA SAN GREGORIO, COLON, ENTRE RIOS",
  "ALDEA SAN JUAN, GUALEGUAYCHU, ENTRE RIOS",
  "ALDEA SANTA MARIA, PARANA, ENTRE RIOS",
  "ALDEA SPATZENKUTTER, DIAMANTE, ENTRE RIOS",
  "ALDEA VALLE MARIA, DIAMANTE, ENTRE RIOS",
  "ALGARROBITOS, NOGOYA, ENTRE RIOS",
  "ALTAMIRANO NORTE, TALA, ENTRE RIOS",
  "ALTAMIRANO SUR, TALA, ENTRE RIOS",
  "ANTELO, VICTORIA, ENTRE RIOS",
  "ARANGUREN, VICTORIA, ENTRE RIOS",
  "ARROYO BARU, COLON, ENTRE RIOS",
  "ARROYO CLE, TALA, ENTRE RIOS",
  "ARROYO DEL MEDIO, VILLAGUAY, ENTRE RIOS",
  "ARROYO PALMAR ,EST., COLON, ENTRE RIOS",
  "ARROYO URQUIZA, URUGUAY, ENTRE RIOS",
  "ATENCIO, FELICIANO, ENTRE RIOS",
  "BAJADA GRANDE, PARANA, ENTRE RIOS",
  "BASAVILBASO, URUGUAY, ENTRE RIOS",
  "BELGRANO, DIAMANTE, ENTRE RIOS",
  "BENITO LEGEREN ,AP., CONCORDIA, ENTRE RIOS",
  "BERDUC ,EST.MARTINIANO LEGUIZAMON, COLON, ENTRE RIOS",
  "BERISSO ,EMB., GUALEGUAYCHU, ENTRE RIOS",
  "BETBEDER, NOGOYA, ENTRE RIOS",
  "BOCA DEL TIGRE ,AP., PARANA, ENTRE RIOS",
  "BOVRIL, LA PAZ, ENTRE RIOS",
  "CALABACILLAS, CONCORDIA, ENTRE RIOS",
  "CAMPS, DIAMANTE, ENTRE RIOS",
  "CARBO, ENRIQUE, GUALEGUAYCHU, ENTRE RIOS",
  "CEIBAS, GUALEGUAYCHU, ENTRE RIOS",
  "CERRITO ,EST.COLONIA CERRITO, PARANA, ENTRE RIOS",
  "CHAJARI, FEDERACION, ENTRE RIOS",
  "CHAÑAR, FEDERAL, ENTRE RIOS",
  "CLARA, VILLAGUAY, ENTRE RIOS",
  "COLON, COLON, ENTRE RIOS",
  "COLONIA ARGENTINA, FEDERACION, ENTRE RIOS",
  "COLONIA ARGENTINA, PARANA, ENTRE RIOS",
  "COLONIA ARRUABARRENA, FEDERACION, ENTRE RIOS",
  "COLONIA AVIGDOR, LA PAZ, ENTRE RIOS",
  "COLONIA BELEZ, VILLAGUAY, ENTRE RIOS",
  "COLONIA BUENA VISTA, LA PAZ, ENTRE RIOS",
  "COLONIA CASEROS ,EST.CASEROS, URUGUAY, ENTRE RIOS",
  "COLONIA CENTENARIO ,AP., PARANA, ENTRE RIOS",
  "COLONIA DELTA, GUALEGUAYCHU, ENTRE RIOS",
  "COLONIA EL CARMEN, COLON, ENTRE RIOS",
  "COLONIA EL SAUCE, URUGUAY, ENTRE RIOS",
  "COLONIA ELIA, URUGUAY, ENTRE RIOS",
  "COLONIA ENSANCHE SAUCE, FEDERACION, ENTRE RIOS",
  "COLONIA HAMBIS, COLON, ENTRE RIOS",
  "COLONIA HOCKER, COLON, ENTRE RIOS",
  "COLONIA HUGHES, COLON, ENTRE RIOS",
  "COLONIA NUEVA ALEMANIA, VILLAGUAY, ENTRE RIOS",
  "COLONIA SAN ERNESTO, COLON, ENTRE RIOS",
  "COLONIA SAN MIGUEL, COLON, ENTRE RIOS",
  "COLONIA SAN VICENTE, VILLAGUAY, ENTRE RIOS",
  "CONCEPCION DEL URUGUAY, URUGUAY, ENTRE RIOS",
  "CONCORDIA, CONCORDIA, ENTRE RIOS",
  "CONSCRIPTO BERNARDI, FEDERAL, ENTRE RIOS",
  "COSTA GRANDE, DIAMANTE, ENTRE RIOS",
  "CRESPO, PARANA, ENTRE RIOS",
  "CUCHILLA REDONDA, GUALEGUAYCHU, ENTRE RIOS",
  "CURTIEMBRE, PARANA, ENTRE RIOS",
  "DIAMANTE, DIAMANTE, ENTRE RIOS",
  "DON CRISTOBAL, NOGOYA, ENTRE RIOS",
  "DON GONZALO ,AP., FEDERAL, ENTRE RIOS",
  "DURAZNO, TALA, ENTRE RIOS",
  "EL BRILLANTE, COLON, ENTRE RIOS",
  "EL CIMARRON, FEDERAL, ENTRE RIOS",
  "EL GUALEGUAY ,AP., CONCORDIA, ENTRE RIOS",
  "EL MOCHO ,AP., FEDERAL, ENTRE RIOS",
  "EL PAGO ,AP., FEDERAL, ENTRE RIOS",
  "EL PALENQUE ,AP., PARANA, ENTRE RIOS",
  "EL PINGO, PARANA, ENTRE RIOS",
  "EL REDOMON, CONCORDIA, ENTRE RIOS",
  "ESCRIÑA, GUALEGUAYCHU, ENTRE RIOS",
  "ESTACAS, LA PAZ, ENTRE RIOS",
  "FABRICA COLON, COLON, ENTRE RIOS",
  "FEBRE, NOGOYA, ENTRE RIOS",
  "FEDERACION, FEDERACION, ENTRE RIOS",
  "FEDERAL, FEDERAL, ENTRE RIOS",
  "FERNANDEZ ,EMB., GUALEGUAYCHU, ENTRE RIOS",
  "FORTUNA, FEDERACION, ENTRE RIOS",
  "GARAT, JUAN ,EST.GARAT, FELICIANO, ENTRE RIOS",
  "GENERAL ALMADA, GUALEGUAYCHU, ENTRE RIOS",
  "GENERAL ALVEAR, DIAMANTE, ENTRE RIOS",
  "GENERAL CAMPOS, CONCORDIA, ENTRE RIOS",
  "GENERAL GALARZA, GUALEGUAY, ENTRE RIOS",
  "GENERAL GUEMES, PARANA, ENTRE RIOS",
  "GENERAL RACEDO, DIAMANTE, ENTRE RIOS",
  "GENERAL RAMIREZ ,EST.RAMIREZ, DIAMANTE, ENTRE RIOS",
  "GILBERT, GUALEGUAYCHU, ENTRE RIOS",
  "GOBERNADOR MANSILLA, TALA, ENTRE RIOS",
  "GONZALEZ CALDERON, GUALEGUAY, ENTRE RIOS",
  "GONZALEZ, LUCAS, NOGOYA, ENTRE RIOS",
  "GUALEGUAY, GUALEGUAY, ENTRE RIOS",
  "GUALEGUAYCHU, GUALEGUAYCHU, ENTRE RIOS",
  "GUARDAMONTE, TALA, ENTRE RIOS",
  "HASENKAMP, PARANA, ENTRE RIOS",
  "HERNANDEZ, NOGOYA, ENTRE RIOS",
  "HERRERA, URUGUAY, ENTRE RIOS",
  "HOLT ,EST, GUALEGUAYCHU, ENTRE RIOS",
  "IBICUY, GUALEGUAYCHU, ENTRE RIOS",
  "IRAZUSTA, GUALEGUAYCHU, ENTRE RIOS",
  "JUAN JORGE, COLON, ENTRE RIOS",
  "JUBILEO, VILLAGUAY, ENTRE RIOS",
  "KILOMETRO 160 ,AP., LA PAZ, ENTRE RIOS",
  "KILOMETRO 180 ,AP., TALA, ENTRE RIOS",
  "KILOMETRO 183,AP., TALA, ENTRE RIOS",
  "KILOMETRO 200 ,AP., TALA, ENTRE RIOS",
  "KILOMETRO 208 ,AP., URUGUAY, ENTRE RIOS",
  "KILOMETRO 213 ,AP., TALA, ENTRE RIOS",
  "KILOMETRO 306 ,AP., VILLAGUAY, ENTRE RIOS",
  "KILOMETRO 325 ,AP., VILLAGUAY, ENTRE RIOS",
  "KILOMETRO 33 ,AP., CONCORDIA, ENTRE RIOS",
  "KILOMETRO 340 ,PDA., GUALEGUAYCHU, ENTRE RIOS",
  "KILOMETRO 361 ,EMB., GUALEGUAYCHU, ENTRE RIOS",
  "KILOMETRO 389 ,AP., GUALEGUAYCHU, ENTRE RIOS",
  "KILOMETRO 45 ,AP., COLON, ENTRE RIOS",
  "LA ARGENTINA, GUALEGUAYCHU, ENTRE RIOS",
  "LA CALANDRIA, FEDERAL, ENTRE RIOS",
  "LA CLARITA, COLON, ENTRE RIOS",
  "LA CRIOLLA, CONCORDIA, ENTRE RIOS",
  "LA ESMERALDA, FELICIANO, ENTRE RIOS",
  "LA GRANJA, CONCORDIA, ENTRE RIOS",
  "LA HIERRA, FELICIANO, ENTRE RIOS",
  "LA LLAVE, NOGOYA, ENTRE RIOS",
  "LA PAZ, LA PAZ, ENTRE RIOS",
  "LA PICADA, PARANA, ENTRE RIOS",
  "LA QUERENCIA, CONCORDIA, ENTRE RIOS",
  "LA VIRGEN ,AP., FEDERAL, ENTRE RIOS",
  "LAGUNA LARGA, VILLAGUAY, ENTRE RIOS",
  "LARROQUE, GUALEGUAYCHU, ENTRE RIOS",
  "LAS CUEVAS, DIAMANTE, ENTRE RIOS",
  "LAS DELICIAS, PARANA, ENTRE RIOS",
  "LAS GARZAS, PARANA, ENTRE RIOS",
  "LAS MERCEDES, GUALEGUAYCHU, ENTRE RIOS",
  "LAS MOSCAS, URUGUAY, ENTRE RIOS",
  "LAS MULITAS, FELICIANO, ENTRE RIOS",
  "LAZO, GUALEGUAY, ENTRE RIOS",
  "LEDESMA, CLODOMIRO, CONCORDIA, ENTRE RIOS",
  "LEGUIZAMON, LA PAZ, ENTRE RIOS",
  "LIBAROS, URUGUAY, ENTRE RIOS",
  "LIEBIG, COLON, ENTRE RIOS",
  "LOS CHARRUAS, CONCORDIA, ENTRE RIOS",
  "LOS CONQUISTADORES, FEDERACION, ENTRE RIOS",
  "MACIA, TALA, ENTRE RIOS",
  "MAGNASCO, OSVALDO, CONCORDIA, ENTRE RIOS",
  "MANDISOVI, FEDERACION, ENTRE RIOS",
  "MAZARUCA, GUALEGUAYCHU, ENTRE RIOS",
  "MEDANOS, GUALEGUAYCHU, ENTRE RIOS",
  "MIÑONES, FEDERAL, ENTRE RIOS",
  "MOJONES NORTE, VILLAGUAY, ENTRE RIOS",
  "MOJONES SUR, VILLAGUAY, ENTRE RIOS",
  "MOLINO DOLL, VICTORIA, ENTRE RIOS",
  "MONTIEL, LA PAZ, ENTRE RIOS",
  "MONTOYA, VICTORIA, ENTRE RIOS",
  "MOREIRA, FEDERAL, ENTRE RIOS",
  "NOGOYA, NOGOYA, ENTRE RIOS",
  "NUEVA ESCOCIA, CONCORDIA, ENTRE RIOS",
  "NUEVA VIZCAYA, FEDERAL, ENTRE RIOS",
  "ORO VERDE, PARANA, ENTRE RIOS",
  "PALAVECINO, GUALEGUAYCHU, ENTRE RIOS",
  "PALMAR, COLON, ENTRE RIOS",
  "PALO A PIQUE, FELICIANO, ENTRE RIOS",
  "PARANA, PARANA, ENTRE RIOS",
  "PARANACITO ,EST.LIB.GRL.SAN MARTIN, GUALEGUAYCHU, ENTRE RIOS",
  "PARERA, FAUSTINO M., GUALEGUAYCHU, ENTRE RIOS",
  "PARERA, RAMONA A. ,EMB., PARANA, ENTRE RIOS",
  "PASO DE LA LAGUNA, VILLAGUAY, ENTRE RIOS",
  "PASTOR BRITOS, GUALEGUAYCHU, ENTRE RIOS",
  "PEDERNAL, CONCORDIA, ENTRE RIOS",
  "PERDICES, GUALEGUAYCHU, ENTRE RIOS",
  "PIEDRAS BLANCAS, LA PAZ, ENTRE RIOS",
  "PILOTO AVILA, LA PAZ, ENTRE RIOS",
  "PRIMER CONGRESO ,AP., LA PAZ, ENTRE RIOS",
  "PRONUNCIAMIENTO, URUGUAY, ENTRE RIOS",
  "PUEBLO ARRUA ,EST.ALCARAZ, LA PAZ, ENTRE RIOS",
  "PUEBLO BRUGO, PARANA, ENTRE RIOS",
  "PUEBLO FERRE, CONCORDIA, ENTRE RIOS",
  "PUEBLO GENERAL PAZ, PARANA, ENTRE RIOS",
  "PUEBLO MORENO, PARANA, ENTRE RIOS",
  "PUERTO CUPALEN, URUGUAY, ENTRE RIOS",
  "PUERTO IBICUY, GUALEGUAYCHU, ENTRE RIOS",
  "PUERTO UNZUE, GUALEGUAYCHU, ENTRE RIOS",
  "PUIGGARI, DIAMANTE, ENTRE RIOS",
  "RAICES, VILLAGUAY, ENTRE RIOS",
  "RINCON DE NOGOYA, VICTORIA, ENTRE RIOS",
  "ROCAMORA, URUGUAY, ENTRE RIOS",
  "ROSARIO DE TALA ,EST.TALA, TALA, ENTRE RIOS",
  "SAN BENITO, PARANA, ENTRE RIOS",
  "SAN GUSTAVO, LA PAZ, ENTRE RIOS",
  "SAN JAIME, FELICIANO, ENTRE RIOS",
  "SAN JORGE, VILLAGUAY, ENTRE RIOS",
  "SAN JOSE DE FELICIANO, FELICIANO, ENTRE RIOS",
  "SAN JUSTO, URUGUAY, ENTRE RIOS",
  "SAN RAMON, FEDERACION, ENTRE RIOS",
  "SAN SALVADOR, COLON, ENTRE RIOS",
  "SAN VICTOR, FELICIANO, ENTRE RIOS",
  "SANTA ANA, FEDERACION, ENTRE RIOS",
  "SANTA ANITA, URUGUAY, ENTRE RIOS",
  "SANTA ELENA, LA PAZ, ENTRE RIOS",
  "SAUCE DE LUNA, FEDERAL, ENTRE RIOS",
  "SAUCE PINTO, PARANA, ENTRE RIOS",
  "SEGUI, PARANA, ENTRE RIOS",
  "SOLA, TALA, ENTRE RIOS",
  "SOSA, PARANA, ENTRE RIOS",
  "STROBEL, DIAMANTE, ENTRE RIOS",
  "TABOSSI, PARANA, ENTRE RIOS",
  "TALITA, URUGUAY, ENTRE RIOS",
  "TALITAS ,AP., LA PAZ, ENTRE RIOS",
  "TENIENTE 1° BRIGIDO CAINZO ,AP., FEDERAL, ENTRE RIOS",
  "TEZANOS PINTO, PARANA, ENTRE RIOS",
  "TRES BOCAS, GUALEGUAY, ENTRE RIOS",
  "UBAJAY, COLON, ENTRE RIOS",
  "URDINARRAIN, GUALEGUAYCHU, ENTRE RIOS",
  "VIALE, PARANA, ENTRE RIOS",
  "VICTORIA, VICTORIA, ENTRE RIOS",
  "VILLA DEL ROSARIO, FEDERACION, ENTRE RIOS",
  "VILLA DOMINGUEZ ,EST.DOMINGUEZ, VILLAGUAY, ENTRE RIOS",
  "VILLA ELISA, COLON, ENTRE RIOS",
  "VILLA HERNANDARIAS, PARANA, ENTRE RIOS",
  "VILLA MANTERO, URUGUAY, ENTRE RIOS",
  "VILLA MARIA GRANDE, PARANA, ENTRE RIOS",
  "VILLA NUEVA MONTEVIDEO, GUALEGUAYCHU, ENTRE RIOS",
  "VILLA PARANACITO, GUALEGUAYCHU, ENTRE RIOS",
  "VILLA SAN JOSE, COLON, ENTRE RIOS",
  "VILLA SAN JOSE, CASTELLANOS, ENTRE RIOS",
  "VILLA SAN MARCIAL ,EST.GDR.URQUIZA, URUGUAY, ENTRE RIOS",
  "VILLA URQUIZA, PARANA, ENTRE RIOS",
  "VILLA ZORRAQUIN, CONCORDIA, ENTRE RIOS",
  "VILLAGUAY, VILLAGUAY, ENTRE RIOS",
  "YAROS, CONCORDIA, ENTRE RIOS",
  "YERUA, CONCORDIA, ENTRE RIOS",
  "YESO, LA PAZ, ENTRE RIOS",
  "YUQUERI, CONCORDIA, ENTRE RIOS",
  "APAYEREY, PILAGAS, FORMOSA",
  "BARTOLOME DE LAS CASAS, PATIÑO, FORMOSA",
  "BOUVIER, PILCOMAYO, FORMOSA",
  "BRUCHARD, PATIÑO, FORMOSA",
  "BUENA VISTA, BERMEJO, FORMOSA",
  "CABO 1RO. LUGONES, PATIÑO, FORMOSA",
  "CAMPO ALEGRE, PATIÑO, FORMOSA",
  "CLORINDA, PILCOMAYO, FORMOSA",
  "COLONIA ALTO ALEGRE, PATIÑO, FORMOSA",
  "COLONIA BUENA VISTA, PILAGAS, FORMOSA",
  "COLONIA CAMPO VILLAFAÑE, PIRANE, FORMOSA",
  "COLONIA PASTORIL, FORMOSA, FORMOSA",
  "COLONIA PERIN, PATIÑO, FORMOSA",
  "COMANDANTE FONTANA, PATIÑO, FORMOSA",
  "CORONEL ARGENTINO LARRABURE, PATIÑO, FORMOSA",
  "DE LAS CASAS, BARTOLOME, PATIÑO, FORMOSA",
  "DEL CAMPO, ESTANISLAO, PATIÑO, FORMOSA",
  "DOCTOR CARLOS MONTAG, PATIÑO, FORMOSA",
  "DOCTOR E.RAMOS MEJIAS ,EST.CHIRIGUANOS, BERMEJO, FORMOSA",
  "DOCTOR GUMERSINDO SAYAGO, BERMEJO, FORMOSA",
  "DOCTOR JOSE DE GASPRI, BERMEJO, FORMOSA",
  "DOCTOR LUIS AGOTE, MATACOS, FORMOSA",
  "EL CHAÑARAL, RAMON LISTA, FORMOSA",
  "EL COATI, PIRANE, FORMOSA",
  "EL COLORADO, PIRANE, FORMOSA",
  "EL PERDIDO, PATIÑO, FORMOSA",
  "EL TOTORAL, RAMON LISTA, FORMOSA",
  "ESPINILLO, PILAGAS, FORMOSA",
  "ESTERO PATIÑO ,AP., PIRANE, FORMOSA",
  "FORMOSA, FORMOSA, FORMOSA",
  "FORTIN PILCOMAYO, BERMEJO, FORMOSA",
  "FORTIN SARGENTO 1º LEYES, PATIÑO, FORMOSA",
  "FORTIN SOLEDAD, BERMEJO, FORMOSA",
  "GENERAL ENRIQUE MOSCONI, RAMON LISTA, FORMOSA",
  "GENERAL FRANCISCO BASILIANO BOSCH, BERMEJO, FORMOSA",
  "GRAN GUARDIA, FORMOSA, FORMOSA",
  "GUADALCAZAR, BERMEJO, FORMOSA",
  "HERRADURA, LAISHI, FORMOSA",
  "IBARRETA, PATIÑO, FORMOSA",
  "INGENIERO ENRIQUE H.FAURE, BERMEJO, FORMOSA",
  "INGENIERO GUILLERMO N.JUAREZ, MATACOS, FORMOSA",
  "KILOMETRO 1769, PATIÑO, FORMOSA",
  "KILOMETRO 1895 ,AP., PIRANE, FORMOSA",
  "KILOMETRO 213, PIRANE, FORMOSA",
  "LA ESPERANZA, MATACOS, FORMOSA",
  "LA FLORENCIA, MATACOS, FORMOSA",
  "LA FRONTERA, PILCOMAYO, FORMOSA",
  "LA ISLETA, PATIÑO, FORMOSA",
  "LA PRIMAVERA, BERMEJO, FORMOSA",
  "LA SIRENA, BERMEJO, FORMOSA",
  "LAGUNA BLANCA, PILCOMAYO, FORMOSA",
  "LAGUNA NAICK-NECK, PILCOMAYO, FORMOSA",
  "LAGUNA YEMA, BERMEJO, FORMOSA",
  "LAPRIDA, FRANCISCO NARCISO DE, RAMON LISTA, FORMOSA",
  "LAS LOMITAS, PATIÑO, FORMOSA",
  "LOA MATACOS, PIRANE, FORMOSA",
  "LOS PILAGAS ,EMB., FORMOSA, FORMOSA",
  "MEDIA LUNA, RAMON LISTA, FORMOSA",
  "MISION TACAAGLE, PILAGAS, FORMOSA",
  "MISION YACARE, MATACOS, FORMOSA",
  "MONTE LINDO CHICO, PILCOMAYO, FORMOSA",
  "PALMA SOLA, PILCOMAYO, FORMOSA",
  "PALMA SOLA, BERMEJO, FORMOSA",
  "PALMARCITO, RAMON LISTA, FORMOSA",
  "PALO SANTO, PIRANE, FORMOSA",
  "PASO, JUAN JOSE, PATIÑO, FORMOSA",
  "PIRANE, PIRANE, FORMOSA",
  "PORTEÑO VIEJO, PATIÑO, FORMOSA",
  "POSTA CAMBIO A ZALAZAR, PATIÑO, FORMOSA",
  "POSTA KILOMETRO 45, PATIÑO, FORMOSA",
  "POZO DEL TIGRE, PATIÑO, FORMOSA",
  "PRESIDENTE YRIGOYEN, FORMOSA, FORMOSA",
  "PUERTO CABO IRIGOYEN, BERMEJO, FORMOSA",
  "PUERTO DALMACIA, FORMOSA, FORMOSA",
  "PUERTO PILCOMAYO, PILCOMAYO, FORMOSA",
  "PUERTO VELAZ, LAISHI, FORMOSA",
  "SAN CAMILO, BERMEJO, FORMOSA",
  "SAN FRANCISCO DE LAISHI, LAISHI, FORMOSA",
  "SAN HILARIO, FORMOSA, FORMOSA",
  "SAN MARTIN 1, PATIÑO, FORMOSA",
  "SAN MARTIN 2, PATIÑO, FORMOSA",
  "SAN RAMON, PATIÑO, FORMOSA",
  "SARGENTO AYUDANTE VICTOR SANABRIA, PATIÑO, FORMOSA",
  "SELVA MARIA, RAMON LISTA, FORMOSA",
  "SIETE PALMAS, PILCOMAYO, FORMOSA",
  "TENIENTE BROWN, PATIÑO, FORMOSA",
  "TENIENTE GENERAL JUAN CARLOS SANCHEZ, PILCOMAYO, FORMOSA",
  "TENIENTE GENERAL ROSENDO M FRAGA, MATACOS, FORMOSA",
  "TRES LAGUNAS, PILAGAS, FORMOSA",
  "VILLA ESCOLAR, LAISHI, FORMOSA",
  "23 DE AGOSTO, LEDESMA, JUJUY",
  "ABDON CASTRO TOLAY, COCHINOCA, JUJUY",
  "ABRA LAITE, COCHINOCA, JUJUY",
  "ABRA PAMPA, COCHINOCA, JUJUY",
  "AGUA CALIENTE, EL CARMEN, JUJUY",
  "AGUA CALIENTE, COCHINOCA, JUJUY",
  "AGUA CHICA, COCHINOCA, JUJUY",
  "AGUADITA, TUMBAYA, JUJUY",
  "AGUAS CALIENTES, EL CARMEN, JUJUY",
  "ALTO LA TORRE, CAPITAL, JUJUY",
  "ALTO LA VIÑA, CAPITAL, JUJUY",
  "BORDO LA ISLA, EL CARMEN, JUJUY",
  "CAIMANCITO, LEDESMA, JUJUY",
  "CALILEGUA, LEDESMA, JUJUY",
  "CANGREJILLOS, YAVI, JUJUY",
  "CAPLA, HUMAHUACA, JUJUY",
  "CARACARA, YAVI, JUJUY",
  "CARAHUASI, RINCONADA, JUJUY",
  "CASABINDO, COCHINOCA, JUJUY",
  "CASPALA, VALLE GRANDE, JUJUY",
  "CASTRO TOLAY, ABDON, COCHINOCA, JUJUY",
  "CATUA, SUSQUES, JUJUY",
  "CERRILLOS, YAVI, JUJUY",
  "CHALICAN, LEDESMA, JUJUY",
  "CHAÑI ,AP., CAPITAL, JUJUY",
  "CHAUPI RODERO, HUMAHUACA, JUJUY",
  "CHURQUI, SANTA CATALINA, JUJUY",
  "CIENAGUILLAS, SANTA CATALINA, JUJUY",
  "COCHINOCA, COCHINOCA, JUJUY",
  "CONDOR, HUMAHUACA, JUJUY",
  "CORANZULI, SUSQUES, JUJUY",
  "CORAYA, HUMAHUACA, JUJUY",
  "CORRAL BLANCO, YAVI, JUJUY",
  "EL AGUILAR, HUMAHUACA, JUJUY",
  "EL BRETE, CAPITAL, JUJUY",
  "EL CARMEN, EL CARMEN, JUJUY",
  "EL CARRIL, SAN ANTONIO, JUJUY",
  "EL CEIBAL, SAN ANTONIO, JUJUY",
  "EL MORENO, TUMBAYA, JUJUY",
  "EL PARQUE, CAPITAL, JUJUY",
  "EL PIQUETE, SANTA BARBARA, JUJUY",
  "EL PORVENIR, SUSQUES, JUJUY",
  "EL QUEMADO, SAN PEDRO, JUJUY",
  "EL REMATE, CAPITAL, JUJUY",
  "EL SUNCHAL, EL CARMEN, JUJUY",
  "ESCAYA, YAVI, JUJUY",
  "FRAILE PINTADO, LEDESMA, JUJUY",
  "GALAN, JUAN, CAPITAL, JUJUY",
  "GUERRERO, CAPITAL, JUJUY",
  "HORNILLOS, SANTA CATALINA, JUJUY",
  "HUACALERA, TILCARA, JUJUY",
  "HUMAHUACA, HUMAHUACA, JUJUY",
  "INGENIO LA ESPERANZA, SAN PEDRO, JUJUY",
  "INTI CANCHA, YAVI, JUJUY",
  "ITURBE, HUMAHUACA, JUJUY",
  "KILOMETRO 1129 ,DV., CAPITAL, JUJUY",
  "KILOMETRO 1137 ,EST., SAN PEDRO, JUJUY",
  "KILOMETRO 1149 ,AP., CAPITAL, JUJUY",
  "KILOMETRO 1183 ,AP., CAPITAL, JUJUY",
  "KILOMETRO 1210 ,DV., LEDESMA, JUJUY",
  "KILOMETRO 1243 ,AP., TILCARA, JUJUY",
  "KILOMETRO 1247, LEDESMA, JUJUY",
  "KILOMETRO 1278, HUMAHUACA, JUJUY",
  "KILOMETRO 1289 ,AP., HUMAHUACA, JUJUY",
  "KILOMETRO 1321 ,DV., HUMAHUACA, JUJUY",
  "KILOMETRO 1373 ,AP., SUSQUES, JUJUY",
  "LA ALMONA, CAPITAL, JUJUY",
  "LA CAPILLA, COCHINOCA, JUJUY",
  "LA CUEVA, HUMAHUACA, JUJUY",
  "LA MENDIETA, SAN PEDRO, JUJUY",
  "LA QUIACA, YAVI, JUJUY",
  "LA TOMA, SAN ANTONIO, JUJUY",
  "LAGUNA, YAVI, JUJUY",
  "LAS CUEVAS, CAPITAL, JUJUY",
  "LEON, CAPITAL, JUJUY",
  "LIBERTADOR GRL.SAN MARTIN, EST.LEDESMA, LEDESMA, JUJUY",
  "LOS ALISOS, SAN ANTONIO, JUJUY",
  "LOS BLANCOS, CAPITAL, JUJUY",
  "LOS CHANCHILLOS, CAPITAL, JUJUY",
  "LOS LAPACHOS ,EST.MAQUINISTA VERON, EL CARMEN, JUJUY",
  "LOS PERALES, CAPITAL, JUJUY",
  "MADREJON, CAPITAL, JUJUY",
  "MAIMARA, TILCARA, JUJUY",
  "MEDANITOS, SUSQUES, JUJUY",
  "MINA AJEDREZ, RINCONADA, JUJUY",
  "MINA PIRQUITAS, RINCONADA, JUJUY",
  "MONTE RICO, EL CARMEN, JUJUY",
  "MONTE RICO ,AP., EL CARMEN, JUJUY",
  "OLACAPATO ,EST., SUSQUES, JUJUY",
  "OLAROZ CHICO, SUSQUES, JUJUY",
  "OLAROZ GRANDE, SUSQUES, JUJUY",
  "ORATORIO, SANTA CATALINA, JUJUY",
  "OROSMAYO, RINCONADA, JUJUY",
  "PAIRIQUE CHICO, SUSQUES, JUJUY",
  "PAIRIQUE GRANDE, SUSQUES, JUJUY",
  "PALCA DE APARZO, HUMAHUACA, JUJUY",
  "PALMA SOLA, SANTA BARBARA, JUJUY",
  "PALPALA ,EST.GRL.MANUEL N.SAVIO, CAPITAL, JUJUY",
  "PAMPA BLANCA, EL CARMEN, JUJUY",
  "PAMPICHUELA, VALLE GRANDE, JUJUY",
  "PERICO, EL CARMEN, JUJUY",
  "PUEBLO LEDESMA, LEDESMA, JUJUY",
  "PUEBLO VIEJO, HUMAHUACA, JUJUY",
  "PUESTO DEL MARQUES, COCHINOCA, JUJUY",
  "PUESTO GRANDE, SANTA CATALINA, JUJUY",
  "PUMAHUASI, YAVI, JUJUY",
  "PURMAMARCA, TUMBAYA, JUJUY",
  "QUICHAGUA, COCHINOCA, JUJUY",
  "REYES, CAPITAL, JUJUY",
  "RINCONADA, RINCONADA, JUJUY",
  "RINCONADILLAS, COCHINOCA, JUJUY",
  "RIO BLANCO ,AP., CAPITAL, JUJUY",
  "RIO GRANDE, COCHINOCA, JUJUY",
  "RODERO, HUMAHUACA, JUJUY",
  "ROSARIO, RINCONADA, JUJUY",
  "ROSARIO, RINCONADA, JUJUY",
  "SAN ANTONIO, SAN ANTONIO, JUJUY",
  "SAN BERNARDO, TUMBAYA, JUJUY",
  "SAN JAVIER, TUMBAYA, JUJUY",
  "SAN JUAN DE DIOS, SAN PEDRO, JUJUY",
  "SAN JUAN DE ORO, SANTA CATALINA, JUJUY",
  "SAN JUAN DE PALCA, SANTA CATALINA, JUJUY",
  "SAN JUANCITO, EL CARMEN, JUJUY",
  "SAN JUANCITO, YAVI, JUJUY",
  "SAN PEDRITO, CAPITAL, JUJUY",
  "SAN PEDRO ,EST.SAN PEDRO DE JUJUY, SAN PEDRO, JUJUY",
  "SAN SALVADOR DE JUJUY ,EST.JUJUY, CAPITAL, JUJUY",
  "SANTA ANA, VALLE GRANDE, JUJUY",
  "SANTA BARBARA, VALLE GRANDE, JUJUY",
  "SANTA CATALINA, SANTA CATALINA, JUJUY",
  "SANTA CLARA, SANTA BARBARA, JUJUY",
  "SANTO DOMINGO, RINCONADA, JUJUY",
  "SAYATE, COCHINOCA, JUJUY",
  "SENADOR PEREZ, HUMAHUACA, JUJUY",
  "SEY, SUSQUES, JUJUY",
  "SIBERIA, SUSQUES, JUJUY",
  "SOL DE MAYO, YAVI, JUJUY",
  "SURIPUGIO, YAVI, JUJUY",
  "SUSQUES, SUSQUES, JUJUY",
  "TABLADITAS, COCHINOCA, JUJUY",
  "TAFNA, YAVI, JUJUY",
  "TERMAS DE REYES, CAPITAL, JUJUY",
  "TILCARA, TILCARA, JUJUY",
  "TIMON CRUZ, SANTA CATALINA, JUJUY",
  "TIOMAYO, RINCONADA, JUJUY",
  "TIONSO, HUMAHUACA, JUJUY",
  "TRES CRUCES, HUMAHUACA, JUJUY",
  "TRES MORROS, TUMBAYA, JUJUY",
  "TUMBAYA, TUMBAYA, JUJUY",
  "TURU TARI, SUSQUES, JUJUY",
  "TUSAQUILLA, COCHINOCA, JUJUY",
  "UQUIA, HUMAHUACA, JUJUY",
  "VALLE COLORADO, VALLE GRANDE, JUJUY",
  "VALLE GRANDE, VALLE GRANDE, JUJUY",
  "VILLA ACHAVAL, CAPITAL, JUJUY",
  "VOLCAN, TUMBAYA, JUJUY",
  "YALA, CAPITAL, JUJUY",
  "YAVI, YAVI, JUJUY",
  "YOSCABA, SANTA CATALINA, JUJUY",
  "YUTO, LEDESMA, JUJUY",
  "ZAPLA, CAPITAL, JUJUY",
  "ABRAMO, HUCAL, LA PAMPA",
  "ADOLFO VAN PRAET, REALICO, LA PAMPA",
  "AGUSTONI, MARACO, LA PAMPA",
  "ALFREDO PEÑA, QUEMU QUEMU, LA PAMPA",
  "ALGARROBO DEL AGUILA, CHICAL CO, LA PAMPA",
  "ALPACHIRI, GUATRACHE, LA PAMPA",
  "ALTA ITALIA, REALICO, LA PAMPA",
  "ANGUIL, CAPITAL, LA PAMPA",
  "ANZOATEGUI, CALEU CALEU, LA PAMPA",
  "APUYACO, GUATRACHE, LA PAMPA",
  "ARATA, TRENEL, LA PAMPA",
  "ATALIVA ROCA, UTRACAN, LA PAMPA",
  "ATREUCO, ATREUCO, LA PAMPA",
  "BAJO DE LAS PALOMAS, TOAY, LA PAMPA",
  "BERNARDO LARROUDE, CHAPALEUFU, LA PAMPA",
  "BERNASCONI, HUCAL, LA PAMPA",
  "BOEUF, CONELO, LA PAMPA",
  "BUTA RANQUIL, PUELEN, LA PAMPA",
  "CACHIRULO, TOAY, LA PAMPA",
  "CALEUFU, RANCUL, LA PAMPA",
  "CANE, MIGUEL, QUEMU QUEMU, LA PAMPA",
  "CARRO QUEMADO, LOVENTUE, LA PAMPA",
  "CASTEX, EDUARDO, CONHELO, LA PAMPA",
  "CATRILO, CATRILO, LA PAMPA",
  "CEBALLOS, CHAPALEUFU, LA PAMPA",
  "CEREALES, ATREUCO, LA PAMPA",
  "CHACHARRAMENDI, ULTRACAN, LA PAMPA",
  "CHAMAICO, RANCUL, LA PAMPA",
  "CHICAL CO, CHICAL CO, LA PAMPA",
  "COLONIA 25 DE MAYO, PUELEN, LA PAMPA",
  "COLONIA BARON, QUEMU QUEMU, LA PAMPA",
  "COLONIA EMILIO MITRE, CHALILEO, LA PAMPA",
  "COLONIA INES Y CARLOTA, CAPITAL, LA PAMPA",
  "COLONIA LA PASTORIL, CHALILEO, LA PAMPA",
  "COLONIA SAN JOSE, QUEMU QUEMU, LA PAMPA",
  "COLONIA SANTA TERESA, GUATRACHE, LA PAMPA",
  "CONHELO, CONHELO, LA PAMPA",
  "CORONEL HILARIO LAGOS, CHAPALEUFU, LA PAMPA",
  "COTITA, HUCAL, LA PAMPA",
  "CUCHILLO-CO, LIHUEL CALEL, LA PAMPA",
  "DOBLAS, ATREUCO, LA PAMPA",
  "DORILA, MARACO, LA PAMPA",
  "EL DURAZNO, LOVENTUE, LA PAMPA",
  "EMBAJADOR MARTINI, REALICO, LA PAMPA",
  "EPU-PEL, UTRACAN, LA PAMPA",
  "FALUCHO, REALICO, LA PAMPA",
  "GAMAY, ULTRACAN, LA PAMPA",
  "GAVIOTAS, CALEU CALEU, LA PAMPA",
  "GENERAL ACHA, ULTRACAN, LA PAMPA",
  "GENERAL MANUEL CAMPOS, GUATRACHE, LA PAMPA",
  "GENERAL PICO, MARACO, LA PAMPA",
  "GENERAL SAN MARTIN, HUCAL, LA PAMPA",
  "GOBERNADOR AYALA, PUELEN, LA PAMPA",
  "GOBERNADOR DUVAL, CURACO, LA PAMPA",
  "GUATRACHE, GUATRACHE, LA PAMPA",
  "HIDALGO, ATREUCO, LA PAMPA",
  "HUCAL, HUCAL, LA PAMPA",
  "HUELEN, QUEMU QUEMU, LA PAMPA",
  "INGENIERO FOSTER, RANCUL, LA PAMPA",
  "INGENIERO LUIGGI, REALICO, LA PAMPA",
  "INTENDENTE ALVEAR, CHAPALEUFU, LA PAMPA",
  "IVANOWSKY, CATRILO, LA PAMPA",
  "KILOMETRO 755 ,DV., CALEU CALEU, LA PAMPA",
  "LA ADELA, CALEU CALEU, LA PAMPA",
  "LA ASTURIANA, LIHUEL CALEL, LA PAMPA",
  "LA COPELINA, PUELEN, LA PAMPA",
  "LA ESTRELLA, LIMAY MAHUIDA, LA PAMPA",
  "LA GLORIA, CATRILO, LA PAMPA",
  "LA MARUJA, RANCUL, LA PAMPA",
  "LA PRIMAVERA, LIMAY MAHUIDA, LA PAMPA",
  "LA REFORMA, LIMAY MAHUIDA, LA PAMPA",
  "LARROUDE, BERNARDO, CHAPALEUFU, LA PAMPA",
  "LAVALLE, RICARDO, REALICO, LA PAMPA",
  "LIHUEL CALEL, LIHUEL CALEL, LA PAMPA",
  "LIMAY MAHUIDA, LIMAY MAHUIDA, LA PAMPA",
  "LONQUIMAY, CATRILO, LA PAMPA",
  "LOVENTUE, LOVENTUE, LA PAMPA",
  "LUAN TORO, LOVENTUE, LA PAMPA",
  "MACACHIN, ATREUCO, LA PAMPA",
  "MAISONAVE, DAMIAN ,EST.SIMSON, REALICO, LA PAMPA",
  "MAYER, MAURICIO, CONHELO, LA PAMPA",
  "METILEO, TRENEL, LA PAMPA",
  "MONTE NIEVAS, CONHELO, LA PAMPA",
  "NAICO, TOAY, LA PAMPA",
  "OJEDA, REALICO, LA PAMPA",
  "OLONIA SANTA MARIA, ULTRACAN, LA PAMPA",
  "PARERA, RANCUL, LA PAMPA",
  "PASO DE LOS ALGARROBOS, CHALILEO, LA PAMPA",
  "PEÑA, ALFREDO, QUEMU QUEMU, LA PAMPA",
  "PERU, GUATRACHE, LA PAMPA",
  "PICHI HUINCA, RANCUL, LA PAMPA",
  "PUELCHES, CURACO, LA PAMPA",
  "PUELEN, PUELEN, LA PAMPA",
  "QUEHUE, ULTRACAN, LA PAMPA",
  "QUEMU QUEMU, QUEMU QUEMU, LA PAMPA",
  "QUETREQUEN, RANCUL, LA PAMPA",
  "RANCUL, RANCUL, LA PAMPA",
  "REALICO, REALICO, LA PAMPA",
  "RELMO, QUEMU QUEMU, LA PAMPA",
  "REMECO, GUATRACHE, LA PAMPA",
  "RIGLOS, MIGUEL, ATREUCO, LA PAMPA",
  "ROCA, ATALIVA, ULTRACAN, LA PAMPA",
  "ROLON, ATREUCO, LA PAMPA",
  "RUCANELO, CONHELO, LA PAMPA",
  "SANTA ISABEL, CHALILEO, LA PAMPA",
  "SANTA ROSA, CAPITAL, LA PAMPA",
  "SARAH, CHAPALEUFU, LA PAMPA",
  "SPELUZZI, MARACO, LA PAMPA",
  "TELEN, LOVENTUE, LA PAMPA",
  "TENIENTE GENERAL EMILIO MITRE, CONHELO, LA PAMPA",
  "TOAY, TOAY, LA PAMPA",
  "TREBOLARES, MARACO, LA PAMPA",
  "TRENEL, TRENEL, LA PAMPA",
  "TRILI, MARACO, LA PAMPA",
  "UNANJE, ULTRACAN, LA PAMPA",
  "URIBURU, CATRILO, LA PAMPA",
  "UTRACAN, ULTRACAN, LA PAMPA",
  "VAN PRAET, ADOLFO, REALICO, LA PAMPA",
  "VERTIZ, CHAPALEUFU, LA PAMPA",
  "VICTORICA, LOVENTUE, LA PAMPA",
  "VILLA MIRASOL, QUEMU QUEMU, LA PAMPA",
  "WINIFREDA, CONHELO, LA PAMPA",
  "AGUA BLANCA, CASTRO BARROS, LA RIOJA",
  "AGUAYO, GENERAL SAN MARTIN, LA RIOJA",
  "AICUÑA, GENERAL LAVALLE, LA RIOJA",
  "AIMOGASTA, ARAUCO, LA RIOJA",
  "ALCAZAR, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "ALPASINCHE, SAN BLAS DE LOS SAUCES, LA RIOJA",
  "AMANA, INDEPENDENCIA, LA RIOJA",
  "AMBIL, GENERAL OCAMPO, LA RIOJA",
  "AMINGA, CASTRO BARROS, LA RIOJA",
  "ANDALUCAS, SAN BLAS DE LOS SAUCES, LA RIOJA",
  "ANGUINAN, CHILECITO, LA RIOJA",
  "ANGULOS, FAMATINA, LA RIOJA",
  "ANILLACO, CASTRO BARROS, LA RIOJA",
  "ANIMAN, CASTRO BARROS, LA RIOJA",
  "ANJULLON, CASTRO BARROS, LA RIOJA",
  "ANTINACO, FAMATINA, LA RIOJA",
  "ARAUCO, ARAUCO, LA RIOJA",
  "ATILES, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "BAJO DEL GALLO, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "BAJO GRANDE, GENERAL BELGRANO, LA RIOJA",
  "BAJO HONDO, GENERAL SARMIENTO, LA RIOJA",
  "BANDA FLORIDA, GENERAL LAVALLE, LA RIOJA",
  "BAZAN, CAPITAL, LA RIOJA",
  "BELLA VISTA, GOBERNADOR GORDILLO, LA RIOJA",
  "CABOLLAR, CAPITAL, LA RIOJA",
  "CAMPANAS, FAMATINA, LA RIOJA",
  "CARRIZAL, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "CARRIZAL, CAPITAL, LA RIOJA",
  "CARRIZAL, FAMATINA, LA RIOJA",
  "CASTRO BARROS, GENERAL BELGRANO, LA RIOJA",
  "CATINZACO, CHILECITO, LA RIOJA",
  "CHAMICAL ,EST.GOBERNADOR GORDILLO, GOBERNADOR GORDILLO, LA RIOJA",
  "CHAÑAR, GENERAL BELGRANO, LA RIOJA",
  "CHAÑARMUYO, FAMATINA, LA RIOJA",
  "CHELCOS, GENERAL SAN MARTIN, LA RIOJA",
  "CHEPES, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "CHEPES VIEJO, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "CHILECITO, CHILECITO, LA RIOJA",
  "CHUQUIS, CASTRO BARROS, LA RIOJA",
  "COCHANGASTA, CAPITAL, LA RIOJA",
  "COMANDANTE LEAL, GENERAL OCAMPO, LA RIOJA",
  "CORRAL DE ISAAC, GENERAL SAN MARTIN, LA RIOJA",
  "CORTADERAS, GENERAL BELGRANO, LA RIOJA",
  "CUATRO ESQUINAS, GENERAL SAN MARTIN, LA RIOJA",
  "EL ALTO, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "EL BARREAL, CAPITAL, LA RIOJA",
  "EL CALDEN, GENERAL SAN MARTIN, LA RIOJA",
  "EL CHIFLON, INDEPENDENCIA, LA RIOJA",
  "EL CONDADO, GENERAL LA MADRID, LA RIOJA",
  "EL MOLLAR, GOBERNADOR GORDILLO, LA RIOJA",
  "EL PORTEZUELO, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "EL POTRERILLO, FAMATINA, LA RIOJA",
  "EL QUEBRACHO, CAPITAL, LA RIOJA",
  "EL RODEO, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "EL SUNCHAL, CAPITAL, LA RIOJA",
  "EL TALA, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "EL VILGO, INDEPENDENCIA, LA RIOJA",
  "FAMATINA, FAMATINA, LA RIOJA",
  "GUANDACOL, GENERAL LAVALLE, LA RIOJA",
  "ILISCA, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "JAGUE, GENERAL SARMIENTO, LA RIOJA",
  "KILOMETRO 875 ,DV., CAPITAL, LA RIOJA",
  "KILOMETRO 891 ,AP., CAPITAL, LA RIOJA",
  "LA AGUADITA, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "LA AGUADITA DE LOS BRIZUELA, CAPITAL, LA RIOJA",
  "LA BANDA, GENERAL SARMIENTO, LA RIOJA",
  "LA CALERA, CAPITAL, LA RIOJA",
  "LA CIENAGA, GENERAL SARMIENTO, LA RIOJA",
  "LA CIENAGA, GENERAL BELGRANO, LA RIOJA",
  "LA FALDA DE CITAN, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "LA ISLA ,EST., GENERAL SAN MARTIN, LA RIOJA",
  "LA JARILLA, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "LA MERCED, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "LA PERSEGUIDA, CAPITAL, LA RIOJA",
  "LA PUERTA, CHILECITO, LA RIOJA",
  "LA REPRESA, GENERAL SAN MARTIN, LA RIOJA",
  "LA RIOJA, CAPITAL, LA RIOJA",
  "LAS CLOACAS, CAPITAL, LA RIOJA",
  "LAS COLORADAS, INDEPENDENCIA, LA RIOJA",
  "LAS PADERCITAS, CAPITAL, LA RIOJA",
  "LAS TOSCAS, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "LOMA BLANCA, GENERAL BELGRANO, LA RIOJA",
  "LOMA LARGA, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "LOS AGUIRRES, GENERAL OCAMPO, LA RIOJA",
  "LOS ALANICES, GENERAL OCAMPO, LA RIOJA",
  "LOS BALDES, GOBERNADOR GORDILLO, LA RIOJA",
  "LOS MOGOTES, INDEPENDENCIA, LA RIOJA",
  "LOS MOLINOS, CASTRO BARROS, LA RIOJA",
  "LOS PALACIOS, GENERAL LAVALLE, LA RIOJA",
  "LOS SARMIENTOS, CHILECITO, LA RIOJA",
  "LOS TAMBILLOS, GENERAL LAVALLE, LA RIOJA",
  "MACHIGASTA, ARAUCO, LA RIOJA",
  "MAJADITA, GENERAL LA MADRID, LA RIOJA",
  "MALANZAN, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "MALLIGASTA, CHILECITO, LA RIOJA",
  "MASCASIN, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "MAZAN ,EST., ARAUCO, LA RIOJA",
  "MILAGRO, GENERAL OCAMPO, LA RIOJA",
  "MINA LA MEJICANA, CHILECITO, LA RIOJA",
  "MIRANDA, CHILECITO, LA RIOJA",
  "MOLLACO, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "NACATE, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "NONOGASTA, CHILECITO, LA RIOJA",
  "ÑOQUEBE, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "OBRAJE DE TOSCANO, CAPITAL, LA RIOJA",
  "OLPAS, GENERAL OCAMPO, LA RIOJA",
  "OLTA, GENERAL BELGRANO, LA RIOJA",
  "PAGANCILLO, GENERAL LAVALLE, LA RIOJA",
  "PAGANZO, INDEPENDENCIA, LA RIOJA",
  "PATQUIA, INDEPENDENCIA, LA RIOJA",
  "PINCHAS, CASTRO BARROS, LA RIOJA",
  "PITUIL, FAMATINA, LA RIOJA",
  "PORTEZUELO DE ARCE, GENERAL SAN MARTIN, LA RIOJA",
  "POZO DE AVILA, CAPITAL, LA RIOJA",
  "POZO DE PIEDRA, GENERAL SAN MARTIN, LA RIOJA",
  "PUERTO ALEGRE, CAPITAL, LA RIOJA",
  "PUERTO ALEGRE, GENERAL LAVALLE, LA RIOJA",
  "PUNTA DE LOS LLANOS, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "REAL DEL CADILLO ,DV., ROSARIO VERA PEÑALOZA, LA RIOJA",
  "RETAMAL, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "RIVADAVIA, GENERAL LA MADRID, LA RIOJA",
  "SALADILLO, CAPITAL, LA RIOJA",
  "SAN ANTONIO, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "SAN ANTONIO, CAPITAL, LA RIOJA",
  "SAN BLAS, SAN BLAS DE LOS SAUCES, LA RIOJA",
  "SAN ISIDRO, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "SAN JUAN, CAPITAL, LA RIOJA",
  "SAN PEDRO, CASTRO BARROS, LA RIOJA",
  "SAN RAMON, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "SAN ROQUE, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "SAN SOLANO, GENERAL SAN MARTIN, LA RIOJA",
  "SANOGASTA, CHILECITO, LA RIOJA",
  "SANTA CLARA, MAR CHIQUITA, BUENOS AIRES",
  "SANTA CRUZ, FAMATINA, LA RIOJA",
  "SANTA CRUZ ,DV., ROSARIO VERA PEÑALOZA, LA RIOJA",
  "SANTO DOMINGO, CAPITAL, LA RIOJA",
  "SCHAQUI, SAN BLAS DE LOS SAUCES, LA RIOJA",
  "SIERRA BRAVA, CAPITAL, LA RIOJA",
  "SOLCA, GENERAL JUAN FACUNDO QUIROGA, LA RIOJA",
  "TALAMUYUNA, CAPITAL, LA RIOJA",
  "TALVA, GENERAL BELGRANO, LA RIOJA",
  "TAMA, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "TASQUIN, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "TELLO, DESIDERIO, GENERAL SAN MARTIN, LA RIOJA",
  "TERMAS SANTA TERESITA, ARAUCO, LA RIOJA",
  "TOTORAL, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "TUIZON, GENERAL ANGEL VICENTE PEÑALOZA, LA RIOJA",
  "ULAPES, GENERAL SAN MARTIN, LA RIOJA",
  "VICHIGASTA, CHILECITO, LA RIOJA",
  "VILLA CASANA, ROSARIO VERA PEÑALOZA, LA RIOJA",
  "VILLA CASTELLI, GENERAL LA MADRID, LA RIOJA",
  "VILLA MAZAN, ARAUCO, LA RIOJA",
  "VILLA SANAGASTA, SANAGASTA, LA RIOJA",
  "VILLA SANTA RITA, GENERAL OCAMPO, LA RIOJA",
  "VILLA UNION, GENERAL LAVALLE, LA RIOJA",
  "VINCHINA, GENERAL SARMIENTO, LA RIOJA",
  "25 DE MAYO, SAN RAFAEL, MENDOZA",
  "AGRELO, LUJAN, MENDOZA",
  "AGUA ESCONDIDA, MALARGUE, MENDOZA",
  "AGUA NUEVA ,EMB.CAÑADA AMARILLA, SAN RAFAEL, MENDOZA",
  "ALDEA LA PRIMAVERA, GUAYMALLEN, MENDOZA",
  "ALGARROBITO, LAVALLE, MENDOZA",
  "ALTO VERDE, JUNIN, MENDOZA",
  "ALTOS DEL OLVIDO, LAVALLE, MENDOZA",
  "ANCHORIS, TUPUNGATO, MENDOZA",
  "ANCON, TUPUNGATO, MENDOZA",
  "ANDRADE, RIVADAVIA, MENDOZA",
  "ARISTIDES VILLANUEVA, SAN RAFAEL, MENDOZA",
  "ARROYITO, LAVALLE, MENDOZA",
  "BAJO LUNLUNTA, MAIPU, MENDOZA",
  "BAÑOS LUNLUNTA, LUJAN, MENDOZA",
  "BARCALA, MAIPU, MENDOZA",
  "BARDAS BLANCAS, MALARGUE, MENDOZA",
  "BARRANCAS, MAIPU, MENDOZA",
  "BARRAQUERO, GODOY CRUZ, MENDOZA",
  "BERMEJO, GUAYMALLEN, MENDOZA",
  "BOWEN, GENERAL ALVEAR, MENDOZA",
  "BUENA NUEVA, GUAYMALLEN, MENDOZA",
  "CACHEUTA, LUJAN, MENDOZA",
  "CADETES DE CHILE, LA PAZ, MENDOZA",
  "CALMUCO, MALARGUE, MENDOZA",
  "CAMPO LOS ANDES, TUNUYAN, MENDOZA",
  "CAMPOS, GASPAR, GENERAL ALVEAR, MENDOZA",
  "CAÑADA SECA, SAN RAFAEL, MENDOZA",
  "CANALEJAS, GENERAL ALVEAR, MENDOZA",
  "CAPACHO, SAN CARLOS, MENDOZA",
  "CAPDEVILLE, LAS HERAS, MENDOZA",
  "CAPILLA DEL ROSARIO, GUAYMALLEN, MENDOZA",
  "CAPILLA DEL ROSARIO, LAVALLE, MENDOZA",
  "CARMENSA, GENERAL ALVEAR, MENDOZA",
  "CHACRAS DE CORIA ,EST.PASO DE LOS ANDES, LUJAN DE CUYO, MENDOZA",
  "CHAPANAY, SAN MARTIN, MENDOZA",
  "CHILECITO, SAN CARLOS, MENDOZA",
  "COEHUE-CO, MALARGUE, MENDOZA",
  "COLONIA 3 DE MAYO, LAVALLE, MENDOZA",
  "COLONIA ALVEAR, GENERAL ALVEAR, MENDOZA",
  "COLONIA LAS ROSAS, TUNUYAN, MENDOZA",
  "COLONIA SUR, SANTA ROSA, MENDOZA",
  "COMANDANTE SALAS, SANTA ROSA, MENDOZA",
  "COQUIMBITO, MAIPU, MENDOZA",
  "CORRAL DE LORCA, GENERAL ALVEAR, MENDOZA",
  "CORRALITOS, GUAYMALLEN, MENDOZA",
  "COSTA DE ARAUJO, LAVALLE, MENDOZA",
  "CRUZ DE PIEDRA, MAIPU, MENDOZA",
  "CUADRO NACIONAL, SAN RAFAEL, MENDOZA",
  "DESAGUADERO, LA PAZ, MENDOZA",
  "EL ALGARROBO, SANTA ROSA, MENDOZA",
  "EL ALPERO ,EMB., LAVALLE, MENDOZA",
  "EL BORBOLLON, LAS HERAS, MENDOZA",
  "EL CARRIZAL ABAJO, LUJAN DE CUYO, MENDOZA",
  "EL CARRIZAL DE ARRIBA, LUJAN DE CUYO, MENDOZA",
  "EL CENTRAL, SAN MARTIN, MENDOZA",
  "EL CHALLAO, LAS HERAS, MENDOZA",
  "EL FORZADO, LAVALLE, MENDOZA",
  "EL MANZANO, MALARGUE, MENDOZA",
  "EL MIRADOR, RIVADAVIA, MENDOZA",
  "EL NIHUIL, SAN RAFAEL, MENDOZA",
  "EL NIHUIL ,EST., SAN RAFAEL, MENDOZA",
  "EL PARAISO, MAIPU, MENDOZA",
  "EL PASTAL, LAS HERAS, MENDOZA",
  "EL PLUMERILLO, LAS HERAS, MENDOZA",
  "EL RESGUARDO, LAS HERAS, MENDOZA",
  "EL RETAMO, LAVALLE, MENDOZA",
  "EL SAUCE, GUAYMALLEN, MENDOZA",
  "EL SOSNEADO, SAN RAFAEL, MENDOZA",
  "EL VERGEL, LAVALLE, MENDOZA",
  "ESPEJO, LAS HERAS, MENDOZA",
  "ESQUINA PESCADERO, GUAYMALLEN, MENDOZA",
  "ESTANCIA LA PAMPA, TUNUYAN, MENDOZA",
  "FRAY LUIS BELTRAN, MAIPU, MENDOZA",
  "GENERAL ALVEAR ,EST.COLONIA ALVEAR NORTE, GENERAL ALVEAR, MENDOZA",
  "GENERAL AMIGORENA ,EMB., GUAYMALLEN, MENDOZA",
  "GENERAL GUTIERREZ, MAIPU, MENDOZA",
  "GENERAL LAVALLE, LAVALLE, MENDOZA",
  "GENERAL ORTEGA, MAIPU, MENDOZA",
  "GERMANIA, LAS HERAS, MENDOZA",
  "GOBERNADOR BENEGAS, GODOY CRUZ, MENDOZA",
  "GOBERNADOR CIVIT, SANTA ROSA, MENDOZA",
  "GODOY CRUZ, GODOY CRUZ, MENDOZA",
  "GOICO, GENERAL ALVEAR, MENDOZA",
  "GOUDGE, SAN RAFAEL, MENDOZA",
  "GUADALES, SAN RAFAEL, MENDOZA",
  "GUIDO, LAS HERAS, MENDOZA",
  "HORNITO DEL GRINGO, LAS HERAS, MENDOZA",
  "INGENIERO GIAGNONI, SAN MARTIN, MENDOZA",
  "INGENIERO GUSTAVO ANDRE, LAVALLE, MENDOZA",
  "ISELIN, RODOLFO, SAN RAFAEL, MENDOZA",
  "JOCOLI, LAS HERAS, MENDOZA",
  "JOCOLI VIEJO, LAVALLE, MENDOZA",
  "JUNIN, JUNIN, MENDOZA",
  "JUSUS NAZARENO, GUAYMALLEN, MENDOZA",
  "KILOMETRO 976 ,EST., LAVALLE, MENDOZA",
  "LA ARBOLEDA, TUPUNGATO, MENDOZA",
  "LA CARRODILLA, LUJAN DE CUYO, MENDOZA",
  "LA CAUTIVA, LA PAZ, MENDOZA",
  "LA CIENAGUITA, LAS HERAS, MENDOZA",
  "LA CONSULTA, SAN CARLOS, MENDOZA",
  "LA COSTA, SANTA ROSA, MENDOZA",
  "LA DORMIDA, SANTA ROSA, MENDOZA",
  "LA ESCANDINAVA, GENERAL ALVEAR, MENDOZA",
  "LA HOLANDA, LAVALLE, MENDOZA",
  "LA HULLERA, LAS HERAS, MENDOZA",
  "LA MARZOLINA, GENERAL ALVEAR, MENDOZA",
  "LA MORA, GENERAL ALVEAR, MENDOZA",
  "LA PAZ, LA PAZ, MENDOZA",
  "LA PEGA, LAVALLE, MENDOZA",
  "LA UNION, LAS HERAS, MENDOZA",
  "LA VALENCIANA, MALARGUE, MENDOZA",
  "LAGUNITA, GUAYMALLEN, MENDOZA",
  "LAS CATITAS ,EST.JOSE NESTOR LENCINAS, SANTA ROSA, MENDOZA",
  "LAS CHACRAS, MALARGUE, MENDOZA",
  "LAS CUEVAS, LAS HERAS, MENDOZA",
  "LAS HERAS ,EST.CABINA TAMARINDOS, LAS HERAS, MENDOZA",
  "LAS MALVINAS, SAN RAFAEL, MENDOZA",
  "LAS PAREDES ,EST.CAPITAN MONTOYA, SAN RAFAEL, MENDOZA",
  "LOA ALAMITOS, SAN CARLOS, MENDOZA",
  "LOMAS BLANCAS, SANTA ROSA, MENDOZA",
  "LOS ARBOLES, TUNUYAN, MENDOZA",
  "LOS ARBOLES, RIVADAVIA, MENDOZA",
  "LOS HUARPES ,AP., GENERAL ALVEAR, MENDOZA",
  "LOS MOLLES, MALARGUE, MENDOZA",
  "LOS SAUCES, TUNUYAN, MENDOZA",
  "LOS TERNEROS ,DV., SAN RAFAEL, MENDOZA",
  "LUJAN DE CUYO, LUJAN DE CUYO, MENDOZA",
  "LUNLUNTA, MAIPU, MENDOZA",
  "LUZURIAGA, MAIPU, MENDOZA",
  "MAIPU, MAIPU, MENDOZA",
  "MALARGUE, MALARGUE, MENDOZA",
  "MAQUINISTA LEVET, LA PAZ, MENDOZA",
  "MAYOR DRUMMOND, LUJAN DE CUYO, MENDOZA",
  "MEDIA LUNA, GENERAL ALVEAR, MENDOZA",
  "MEDRANO, JUNIN, MENDOZA",
  "MENDOZA, CAPITAL, MENDOZA",
  "MINACAR, MALARGUE, MENDOZA",
  "MINAS SALAGASTA, LAS HERAS, MENDOZA",
  "MONTE COMAN, SAN RAFAEL, MENDOZA",
  "MOYANO, FEDERICO J., LAS HERAS, MENDOZA",
  "ÑACUNAN, SANTA ROSA, MENDOZA",
  "NEGRO QUEMADO, SAN RAFAEL, MENDOZA",
  "NEWBERY, JORGE, JUNIN, MENDOZA",
  "ÑIRE-CO, MALARGUE, MENDOZA",
  "NUEVA CALIFORNIA ,EST.MOLUCHES, SAN MARTIN, MENDOZA",
  "OVEJERIA, GENERAL ALVEAR, MENDOZA",
  "PALMIRA, SAN MARTIN, MENDOZA",
  "PAMPA DEL TIGRE, GENERAL ALVEAR, MENDOZA",
  "PAMPITA, LA PAZ, MENDOZA",
  "PANQUEUA, LAS HERAS, MENDOZA",
  "PARAMILLO ,AP., LAVALLE, MENDOZA",
  "PAREDITAS, SAN CARLOS, MENDOZA",
  "PEDREGAL, MAIPU, MENDOZA",
  "PERDRIEL, LUJAN DE CUYO, MENDOZA",
  "PHILIPPS, JUNIN, MENDOZA",
  "PICHI CIEGO, SANTA ROSA, MENDOZA",
  "PIRQUITA, LA PAZ, MENDOZA",
  "POLVAREDAS, LAS HERAS, MENDOZA",
  "POTRERILLOS, LUJAN DE CUYO, MENDOZA",
  "PRATS, JAIME, SAN RAFAEL, MENDOZA",
  "PUEBLO NUEVO (KM.ONCE), GUAYMALLEN, MENDOZA",
  "PUENTE DEL INCA, LAS HERAS, MENDOZA",
  "PUNTA DE VACA, LAS HERAS, MENDOZA",
  "PUNTA DEL AGUA, SAN RAFAEL, MENDOZA",
  "PUNTILLA, LUJAN DE CUYO, MENDOZA",
  "RAMA CAIDA, SAN RAFAEL, MENDOZA",
  "RANQUIL DEL NORTE, MALARGUE, MENDOZA",
  "REAL DEL PADRE, SAN RAFAEL, MENDOZA",
  "RESOLANA, SAN RAFAEL, MENDOZA",
  "RINCON DEL ATUEL ,EST.ING.BALLOFFET, SAN RAFAEL, MENDOZA",
  "RIVADAVIA, RIVADAVIA, MENDOZA",
  "RODEO DE LA CRUZ, GUAYMALLEN, MENDOZA",
  "RODEO DEL MEDIO, MAIPU, MENDOZA",
  "RODRIGUEZ PEÑA, JUNIN, MENDOZA",
  "RUSSELL, MAIPU, MENDOZA",
  "SALINAS DEL DIAMANTE ,EST., SAN RAFAEL, MENDOZA",
  "SALTO DE LAS ROSAS, SAN RAFAEL, MENDOZA",
  "SAN CARLOS, SAN CARLOS, MENDOZA",
  "SAN JOSE, TUPUNGATO, MENDOZA",
  "SAN JOSE, LAVALLE, MENDOZA",
  "SAN MARTIN ,EST.LIB.GRL.SAN MARTIN, SAN MARTIN, MENDOZA",
  "SAN MIGUEL, LAVALLE, MENDOZA",
  "SAN RAFAEL, SAN RAFAEL, MENDOZA",
  "SAN ROQUE, MAIPU, MENDOZA",
  "SANTA MARIA DE ORO, RIVADAVIA, MENDOZA",
  "SANTA ROSA, SANTA ROSA, MENDOZA",
  "SOITUE, SAN RAFAEL, MENDOZA",
  "TERMAS VILLAVICENCIO, LAS HERAS, MENDOZA",
  "TRES ESQUINAS, SAN CARLOS, MENDOZA",
  "TRES PORTEÑAS, SAN MARTIN, MENDOZA",
  "TROPERO SOSA ,EMP., LAS HERAS, MENDOZA",
  "TUNUYAN, TUNUYAN, MENDOZA",
  "TUPUNGATO, TUPUNGATO, MENDOZA",
  "UGARTECHE, LUJAN DE CUYO, MENDOZA",
  "USPALLATA, LAS HERAS, MENDOZA",
  "USPALLATA ,EST., LUJAN DE CUYO, MENDOZA",
  "VARGAS, PEDRO, SAN RAFAEL, MENDOZA",
  "VILLA ATUEL, SAN RAFAEL, MENDOZA",
  "VILLA BASTIA, TUPUNGATO, MENDOZA",
  "VILLA EDELMIRA, MAIPU, MENDOZA",
  "VILLA HIPODROMO, GODOY CRUZ, MENDOZA",
  "VILLA LA PAZ, LA PAZ, MENDOZA",
  "VILLA NUEVA, GUAYMALLEN, MENDOZA",
  "VILLA SECA, TUNUYAN, MENDOZA",
  "VILLANUEVA, ARISTIDES, SAN RAFAEL, MENDOZA",
  "VISTA FLORES, TUNUYAN, MENDOZA",
  "VISTALBA, LUJAN DE CUYO, MENDOZA",
  "ZAPATA, TUPUNGATO, MENDOZA",
  "25 DE MAYO, 25 DE MAYO, MISIONES",
  "25 DE MAYO, IGUAZU, MISIONES",
  "9 DE JULIO, EL DORADO, MISIONES",
  "ALBA POSE, 25 DE MAYO, MISIONES",
  "ALMAFUERTE, LEANDRO N.ALEM, MISIONES",
  "APOSTOLES, APOSTOLES, MISIONES",
  "ARISTOBULO DEL VALLE, CAINGUAS, MISIONES",
  "ARROYO DEL MEDIO, LEANDRO N.ALEM, MISIONES",
  "AZARA, APOSTOLES, MISIONES",
  "BARRA BONITA, 25 DE MAYO, MISIONES",
  "BARRA CONCEPCION, CONCEPCION, MISIONES",
  "BARRANCON SAN PEDRO, SAN PEDRO, MISIONES",
  "BELLA VISTA, CANDELARIA, MISIONES",
  "BERNARDO DE IRIGOYEN, GENERAL MANUEL BELGRANO, MISIONES",
  "BONPLAND, CANDELARIA, MISIONES",
  "CAA YARI, LEANDRO N.ALEM, MISIONES",
  "CAMPANA, OBERA, MISIONES",
  "CAMPIÑAS DE AMERICA, GENERAL MANUEL BELGRANO, MISIONES",
  "CAMPO GRANDE, CAINGUAS, MISIONES",
  "CAMPO RAMON, OBERA, MISIONES",
  "CAMPO VIERA, OBERA, MISIONES",
  "CANDELARIA, CANDELARIA, MISIONES",
  "CAPIOVI, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "CAPIOVICINO, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "CATARATAS DEL IGUAZU, IGUAZU, MISIONES",
  "CERRO AZUL, LEANDRO N.ALEM, MISIONES",
  "CERRO CORA, CANDELARIA, MISIONES",
  "COLONIA APOSTOLES, APOSTOLES, MISIONES",
  "COLONIA ARISTOBULO DEL VALLE, CAINGUAS, MISIONES",
  "COLONIA AURORA, 25 DE MAYO, MISIONES",
  "COLONIA MBOPICUA, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "COLONIA NARANJITO, SAN IGNACIO, MISIONES",
  "COLONIA SANTA TERESA, MONTECARLO, MISIONES",
  "COLONIA YABEBIRY, OBERA, MISIONES",
  "CONCEPCION DE LA SIERRA, CONCEPCION, MISIONES",
  "CORPUS, SAN IGNACIO, MISIONES",
  "CUÑAPIRU, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "DEL VALLE, ARISTOBULO, CAINGUAS, MISIONES",
  "DESEADO, GENERAL MANUEL BELGRANO, MISIONES",
  "DOS ARROYOS, LEANDRO N.ALEM, MISIONES",
  "DOS DE MAYO, CAINGUAS, MISIONES",
  "EL ALCAZAR, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "EL PARAISO, APOSTOLES, MISIONES",
  "EL SOBERBIO, EL GUARANI, MISIONES",
  "EL TIGRE, CAINGUAS, MISIONES",
  "ELDORADO, EL DORADO, MISIONES",
  "FACHINAL, CAPITAL, MISIONES",
  "FRACRAN, GUARANI, MISIONES",
  "GARUPA, CAPITAL, MISIONES",
  "GENERAL ALVEAR, OBERA, MISIONES",
  "GENERAL GUEMES, LEANDRO N.ALEM, MISIONES",
  "GOBERNADOR JUAN J.LANUSSE, IGUAZU, MISIONES",
  "GOBERNADOR LOPEZ, LEANDRO N.ALEM, MISIONES",
  "GOBERNADOR ROCA, SAN IGNACIO, MISIONES",
  "GUARANI, OBERA, MISIONES",
  "INVERNADA DE ITACARUARE, SAN JAVIER, MISIONES",
  "IRIGOYEN, BERNARDO DE, GENERAL MANUEL BELGRANO, MISIONES",
  "ITACARUARE, SAN JAVIER, MISIONES",
  "JARDIN AMERICA, SAN IGNACIO, MISIONES",
  "KILOMETRO 18, MONTECARLO, MISIONES",
  "KILOMETRO 538 ,AP., APOSTOLES, MISIONES",
  "KILOMETRO 546 ,AP., APOSTOLES, MISIONES",
  "KILOMETRO 577, CAPITAL, MISIONES",
  "LA GRUTA, SAN PEDRO, MISIONES",
  "LAHARRAGE, MONTECARLO, MISIONES",
  "LANUS, MIGUEL, CAPITAL, MISIONES",
  "LAS TUNAS, APOSTOLES, MISIONES",
  "LEIS ,PDA., CAPITAL, MISIONES",
  "LORETO, CANDELARIA, MISIONES",
  "LOS HELECHOS, OBERA, MISIONES",
  "MACHADINO, CONCEPCION, MISIONES",
  "MARTIRES, CANDELARIA, MISIONES",
  "MOJON GRANDE, SAN JAVIER, MISIONES",
  "MONTEAGUDO, GUARANI, MISIONES",
  "MONTECARLO, MONTECARLO, MISIONES",
  "ÑACANGUAZU, SAN IGNACIO, MISIONES",
  "OBERA, OBERA, MISIONES",
  "PANAMBI, OBERA, MISIONES",
  "PIÑALITO, SAN PEDRO, MISIONES",
  "PINDAPOY ,EST., APOSTOLES, MISIONES",
  "POSADAS, CAPITAL, MISIONES",
  "PROFUNDIDAD, CANDELARIA, MISIONES",
  "PUEBLO TARUMA, MONTECARLO, MISIONES",
  "PUERTO 3 DE MAYO, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO AVELLANEDA, MONTECARLO, MISIONES",
  "PUERTO BOSSETTI, IGUAZU, MISIONES",
  "PUERTO CARAGUATAY, MONTECARLO, MISIONES",
  "PUERTO CONCEPCION, CONCEPCION, MISIONES",
  "PUERTO DELICIA, EL DORADO, MISIONES",
  "PUERTO ELDORADO, EL DORADO, MISIONES",
  "PUERTO ELVECIA, IGUAZU, MISIONES",
  "PUERTO ERRECABORDE, IGUAZU, MISIONES",
  "PUERTO ESPAÑA, SAN IGNACIO, MISIONES",
  "PUERTO ESPERANZA, IGUAZU, MISIONES",
  "PUERTO GARUHAPE, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO GISELA, SAN IGNACIO, MISIONES",
  "PUERTO IGUAZU, IGUAZU, MISIONES",
  "PUERTO INGENIERO MORANDI, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO LEONI, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO LIBERTAD, IGUAZU, MISIONES",
  "PUERTO LONDERO, 25 DE MAYO, MISIONES",
  "PUERTO MADO, EL DORADO, MISIONES",
  "PUERTO MENOCCHIO, SAN IGNACIO, MISIONES",
  "PUERTO NARANJITO, SAN IGNACIO, MISIONES",
  "PUERTO OASIS, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO ORO VERDE, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO PARAISO, GUARANI, MISIONES",
  "PUERTO PARANAY, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO PAULITO, IGUAZU, MISIONES",
  "PUERTO PENINSULA, IGUAZU, MISIONES",
  "PUERTO PINARES, EL DORADO, MISIONES",
  "PUERTO PIRAY, MONTECARLO, MISIONES",
  "PUERTO RICO, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "PUERTO ROSARIO, SAN JAVIER, MISIONES",
  "PUERTO SAN ANTONIO, 25 DE MAYO, MISIONES",
  "PUERTO SAN ISIDRO, CONCEPCION, MISIONES",
  "PUERTO SAN LUCAS, CONCEPCION, MISIONES",
  "PUERTO SAN MARTIN, 25 DE MAYO, MISIONES",
  "PUERTO SEGUNDO, IGUAZU, MISIONES",
  "PUERTO VICTORIA, EL DORADO, MISIONES",
  "PUERTO YRIGOYEN, IGUAZU, MISIONES",
  "PUERTO MINERAL, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "RUIZ DE MONTOYA, LIBERTADOR GRL. SAN MARTIN, MISIONES",
  "SALTO, OBERA, MISIONES",
  "SAN ANTONIO, GENERAL MANUEL BELGRANO, MISIONES",
  "SAN FRANCISCO DE ASIS, 25 DE MAYO, MISIONES",
  "SAN IGNACIO, SAN IGNACIO, MISIONES",
  "SAN JAVIER, SAN JAVIER, MISIONES",
  "SAN JOSE, APOSTOLES, MISIONES",
  "SAN PEDRO, SAN PEDRO, MISIONES",
  "SAN VICENTE, GUARANI, MISIONES",
  "SANTA ANA, CANDELARIA, MISIONES",
  "SANTA MARIA, CONCEPCION, MISIONES",
  "SANTA RITA, 25 DE MAYO, MISIONES",
  "SANTA RITA, 25 DE MAYO, MISIONES",
  "SANTO PIPO, SAN IGNACIO, MISIONES",
  "TACUARUZU, CANDELARIA, MISIONES",
  "TOBUNA, SAN PEDRO, MISIONES",
  "TRES CAPONES, APOSTOLES, MISIONES",
  "VILLA LONCA, CAPITAL, MISIONES",
  "WANDA, IGUAZU, MISIONES",
  "YRIGOYEN, HIPOLITO, SAN IGNACIO, MISIONES",
  "ACHICO, COLLON CURA, NEUQUEN",
  "ALUMINE, ALUMINE, NEUQUEN",
  "ANDACOLLO, MINAS, NEUQUEN",
  "AÑELO, AÑELO, NEUQUEN",
  "ARROYITO CHALLACO, CONFLUENCIA, NEUQUEN",
  "ARROYO BLANCO, CHOS MALAL, NEUQUEN",
  "ASTRA, CONFLUENCIA, NEUQUEN",
  "AUCA MAHUIDA, AÑELO, NEUQUEN",
  "BAJADA COLORADA, COLLON CURA, NEUQUEN",
  "BAJADA DEL AGRIO, PICUNCHES, NEUQUEN",
  "BAÑOS LAS MAQUINITAS, ÑORQUIN, NEUQUEN",
  "BARRANCAS, PEHUENCHES, NEUQUEN",
  "BELLA VISTA, MINAS, NEUQUEN",
  "BOTE, MINAS, NEUQUEN",
  "BUTA MALLIN, ÑORQUIN, NEUQUEN",
  "BUTA RANQUIL, PEHUENCHES, NEUQUEN",
  "BUTACO, PEHUENCHES, NEUQUEN",
  "CAJON DE ALMANZA, LONCOPUE, NEUQUEN",
  "CAJON DEL MANZANO, LONCOPUE, NEUQUEN",
  "CALIFORNIA, CONFLUENCIA, NEUQUEN",
  "CAMPANA MAHUIDA, LONCOPUE, NEUQUEN",
  "CAMPO GRANDE, HUILICHES, NEUQUEN",
  "CASTRO, RAMON M., ZAPALA, NEUQUEN",
  "CATAN LIL, CATAN LIL, NEUQUEN",
  "CENTENARIO, CONFLUENCIA, NEUQUEN",
  "CHACAICO SUR, CATAN LIL, NEUQUEN",
  "CHALLACO, CONFLUENCIA, NEUQUEN",
  "CHOS MALAL, CHOS MALAL, NEUQUEN",
  "CODIHUE, PICUNCHES, NEUQUEN",
  "COIHUECO, LONCOPUE, NEUQUEN",
  "COLIPILLI, ÑORQUIN, NEUQUEN",
  "COLLON CURA, COLLON CURA, NEUQUEN",
  "COLLON-CO, HUILICHES, NEUQUEN",
  "COPAHUE, ÑORQUIN, NEUQUEN",
  "CORRENTOSO, LOS LAGOS, NEUQUEN",
  "CORTADERA, PEHUENCHES, NEUQUEN",
  "COVUNCO, PICUNCHES, NEUQUEN",
  "CUTRAL-CO, CONFLUENCIA, NEUQUEN",
  "EL CHOLAR, ÑORQUIN, NEUQUEN",
  "EL HUECU, ÑORQUIN, NEUQUEN",
  "EL MARUCHO, CATAN LIL, NEUQUEN",
  "ESPINAZO DEL ZORRO, CATAN LIL, NEUQUEN",
  "FORTIN 1º DE MAYO, CATAN LIL, NEUQUEN",
  "HUINGANCO, MINAS, NEUQUEN",
  "JUNIN DE LOS ANDES, HUILICHES, NEUQUEN",
  "LA BUITRERA, PICUNCHES, NEUQUEN",
  "LA LIPELA, LOS LAGOS, NEUQUEN",
  "LA NEGRA, CATAN LIL, NEUQUEN",
  "LA PICAZA, CATAN LIL, NEUQUEN",
  "LAS COLORADAS, CATAN LIL, NEUQUEN",
  "LAS LAJAS, PICUNCHES, NEUQUEN",
  "LAS OVEJAS, MINAS, NEUQUEN",
  "LILEO, MINAS, NEUQUEN",
  "LONCOPUE, LONCOPUE, NEUQUEN",
  "LOS HELECHOS, HUILICHES, NEUQUEN",
  "LOS MOLLES, CATAN LIL, NEUQUEN",
  "MALLEO, HUILICHES, NEUQUEN",
  "MORENO, MARIANO, ZAPALA, NEUQUEN",
  "MULICHINCO, LONCOPUE, NEUQUEN",
  "NAHUEL HUAPI, LOS LAGOS, NEUQUEN",
  "NAUNAUCO, ÑORQUIN, NEUQUEN",
  "NEUQUEN, CONFLUENCIA, NEUQUEN",
  "ÑORQUIN, ÑORQUIN, NEUQUEN",
  "PASO DE LOS INDIOS, ZAPALA, NEUQUEN",
  "PICUN LEUFU, PICUN LEUFU, NEUQUEN",
  "PIEDRA DEL AGUILA, COLLON CURA, NEUQUEN",
  "PILOLIL, CATAN LIL, NEUQUEN",
  "PINO HACHADO, PICUNCHES, NEUQUEN",
  "PLAZA HUINCUL, CONFLUENCIA, NEUQUEN",
  "PLOTTIER, CONFLUENCIA, NEUQUEN",
  "PUERTO HUA-HUM, LACAR, NEUQUEN",
  "PUERTO HUEMUL, LOS LAGOS, NEUQUEN",
  "PUERTO MANZANO, LOS LAGOS, NEUQUEN",
  "QUILACHANQUIL, ALUMINE, NEUQUEN",
  "QUILI MALAL, PICUNCHES, NEUQUEN",
  "RAHUE, ALUMINE, NEUQUEN",
  "RANQUILCO, ÑORQUIN, NEUQUEN",
  "SAN IGNACIO, HUILICHES, NEUQUEN",
  "SAN MARTIN DE LOS ANDES, LACAR, NEUQUEN",
  "SANICO, COLLON CURA, NEUQUEN",
  "SENILLOSA, CONFLUENCIA, NEUQUEN",
  "TRAFUL, LOS LAGOS, NEUQUEN",
  "TRES CHORROS, ÑORQUIN, NEUQUEN",
  "TRICAO MALAL, CHOS MALAL, NEUQUEN",
  "VARVARCI, MINAS, NEUQUEN",
  "VILLA EL CHOCON, CONFLUENCIA, NEUQUEN",
  "VILLA LA ANGOSTURA, LOS LAGOS, NEUQUEN",
  "VILLA TRAFUL, LOS LAGOS, NEUQUEN",
  "VISTA ALEGRE, CONFLUENCIA, NEUQUEN",
  "ZAPALA, ZAPALA, NEUQUEN",
  "AGUADA CECILIO, VALCHETA, RIO NEGRO",
  "AGUADA DE GUERRA, 25 DE MAYO, RIO NEGRO",
  "AGUADA DE GUZMAN, EL CUY, RIO NEGRO",
  "AGUADA TRONCOSO, ÑORQUINCO, RIO NEGRO",
  "ALLEN, GENERAL ROCA, RIO NEGRO",
  "ARROYO DE LA VENTANA, VALCHETA, RIO NEGRO",
  "ARROYO VERDE, VALCHETA, RIO NEGRO",
  "BARDA DEL MEDIO ,EST.KM 1218, GENERAL ROCA, RIO NEGRO",
  "BENJAMIN ZORRILLA, AVELLANEDA, RIO NEGRO",
  "BOCA DE LA TRAVESIA, CONESA, RIO NEGRO",
  "BUENA PARADA, PICHI MAHUIDA, RIO NEGRO",
  "CATRIEL, GENERAL ROCA, RIO NEGRO",
  "CERRO ABANICO ,AP., 25 DE MAYO, RIO NEGRO",
  "CERRO POLICIA, EL CUY, RIO NEGRO",
  "CERVANTES, GENERAL ROCA, RIO NEGRO",
  "CHACAY HUARRUCA, ÑORQUINCO, RIO NEGRO",
  "CHASICO, EL CUY, RIO NEGRO",
  "CHELFORO, AVELLANEDA, RIO NEGRO",
  "CHICHINALES, GENERAL ROCA, RIO NEGRO",
  "CHIMPAY, AVELLANEDA, RIO NEGRO",
  "CHOELE CHOEL, AVELLANEDA, RIO NEGRO",
  "CINCO CHAÑARES, SAN ANTONIO, RIO NEGRO",
  "CINCO SALTOS, GENERAL ROCA, RIO NEGRO",
  "CIPOLLETTI, GENERAL ROCA, RIO NEGRO",
  "COLONIA JOSEFA, AVELLANEDA, RIO NEGRO",
  "COLONIA JULIA Y ECHARREN, PICHI MAHUIDA, RIO NEGRO",
  "COMALLO, PILCANIYEU, RIO NEGRO",
  "CONA NIYEU, 9 DE JULIO, RIO NEGRO",
  "CONTRALMIRANTE CORDERO, GENERAL ROCA, RIO NEGRO",
  "CONTRALMIRANTE MARTIN GUERRICO, GENERAL ROCA, RIO NEGRO",
  "CORONEL BELISLE, AVELLANEDA, RIO NEGRO",
  "CORONEL EUGENIO DEL BUSTO, PICHI MAHUIDA, RIO NEGRO",
  "CORONEL FRANCISCO SOSA, CONESA, RIO NEGRO",
  "CORONEL JUAN JOSE GOMEZ, GENERAL ROCA, RIO NEGRO",
  "CUBANEA, ADOLFO ALSINA, RIO NEGRO",
  "DARWIN, AVELLANEDA, RIO NEGRO",
  "DOCTOR ROGELIO CORTIZO ,EMP., SAN ANTONIO, RIO NEGRO",
  "EL BOLSON, BARILOCHE, RIO NEGRO",
  "EL CAIN, 25 DE MAYO, RIO NEGRO",
  "EL CUY, EL CUY, RIO NEGRO",
  "EL PORVENIR ,EMB., CONESA, RIO NEGRO",
  "FALKNER, 9 DE JULIO, RIO NEGRO",
  "FITALANCAO, ÑORQUINCO, RIO NEGRO",
  "FORTIN UNO, AVELLANEDA, RIO NEGRO",
  "FRAY LUIS BELTRAN, AVELLANEDA, RIO NEGRO",
  "FUTA RUIN, ÑORQUINCO, RIO NEGRO",
  "GANZU LAUQUEN ,AP., 9 DE JULIO, RIO NEGRO",
  "GARAY, JUAN DE, PICHI MAHUIDA, RIO NEGRO",
  "GENERAL CONESA, CONESA, RIO NEGRO",
  "GENERAL ENRIQUE GODOY, GENERAL ROCA, RIO NEGRO",
  "GENERAL FERNANDEZ ORO, GENERAL ROCA, RIO NEGRO",
  "GENERAL LIBORIO BERNAL, ADOLFO ALSINA, RIO NEGRO",
  "GENERAL LORENZO VINTTER, ADOLFO ALSINA, RIO NEGRO",
  "GENERAL NICOLAS H.PALACIOS, ADOLFO ALSINA, RIO NEGRO",
  "GENERAL ROCA, GENERAL ROCA, RIO NEGRO",
  "GUARDIA MITRE, ADOLFO ALSINA, RIO NEGRO",
  "INGENIERO JACOBACCI, 25 DE MAYO, RIO NEGRO",
  "INGENIERO JULIAN ROMERO ,EST, GENERAL ROCA, RIO NEGRO",
  "INGENIERO LUIS A.HUERGO, GENERAL ROCA, RIO NEGRO",
  "INGENIERO OTTO KRAUSE, GENERAL ROCA, RIO NEGRO",
  "INGENIERO ZIMMERMANN RESTA ,AP., PILCANIYEU, RIO NEGRO",
  "KILOMETRO 1013, AVELLANEDA, RIO NEGRO",
  "KILOMETRO 1040, AVELLANEDA, RIO NEGRO",
  "KILOMETRO 1071, GENERAL ROCA, RIO NEGRO",
  "KILOMETRO 21, ADOLFO ALSINA, RIO NEGRO",
  "KILOMETRO 38, CONESA, RIO NEGRO",
  "KILOMETRO 39, ÑORQUINCO, RIO NEGRO",
  "KILOMETRO 57, CONESA, RIO NEGRO",
  "KILOMETRO 648, 25 DE MAYO, RIO NEGRO",
  "KILOMETRO 829, PICHI MAHUIDA, RIO NEGRO",
  "KILOMETRO 839, PICHI MAHUIDA, RIO NEGRO",
  "KILOMETRO 867, PICHI MAHUIDA, RIO NEGRO",
  "KILOMETRO 875, PICHI MAHUIDA, RIO NEGRO",
  "KILOMETRO 899, PICHI MAHUIDA, RIO NEGRO",
  "KILOMETRO 966, AVELLANEDA, RIO NEGRO",
  "KILOMETRO 995, AVELLANEDA, RIO NEGRO",
  "LA ENSENADA, ADOLFO ALSINA, RIO NEGRO",
  "LA ESPERANZA, 25 DE MAYO, RIO NEGRO",
  "LA LOBERIA, ADOLFO ALSINA, RIO NEGRO",
  "LAGUNA BLANCA, PILCANIYEU, RIO NEGRO",
  "LAMARQUE, AVELLANEDA, RIO NEGRO",
  "LAS BAYAS, ÑORQUINCO, RIO NEGRO",
  "LLAO LLAO, BARILOCHE, RIO NEGRO",
  "LONCO VACA, EL CUY, RIO NEGRO",
  "LOS JUNCOS, 25 DE MAYO, RIO NEGRO",
  "LOS MENUCOS, 25 DE MAYO, RIO NEGRO",
  "LOS REPOLLOS, BARILOCHE, RIO NEGRO",
  "MAINQUE, GENERAL ROCA, RIO NEGRO",
  "MAMUEL CHOIQUE, ÑORQUINCO, RIO NEGRO",
  "MANCHA BLANCA ,EST., SAN ANTONIO, RIO NEGRO",
  "MAQUINCHAO, 25 DE MAYO, RIO NEGRO",
  "MENCUE, EL CUY, RIO NEGRO",
  "MENUCOS, AVELLANEDA, RIO NEGRO",
  "MINISTRO RAMOS MEXIA, 9 DE JULIO, RIO NEGRO",
  "MUSTERS ,EST., VALCHETA, RIO NEGRO",
  "NAHUEL NIYEU, VALCHETA, RIO NEGRO",
  "NEGRO MUERTO, AVELLANEDA, RIO NEGRO",
  "NENEO RUCA, PILCANIYEU, RIO NEGRO",
  "ÑIRIHUAU, PILCANIYEU, RIO NEGRO",
  "ÑORQUINCO, ÑORQUINCO, RIO NEGRO",
  "NUEVO LEON, ADOLFO ALSINA, RIO NEGRO",
  "OJOS DE AGUA, ÑORQUINCO, RIO NEGRO",
  "ONELLI, CLEMENTE, 25 DE MAYO, RIO NEGRO",
  "PADRE ALEJANDRO STEFENELLI, GENERAL ROCA, RIO NEGRO",
  "PAJALTA, VALCHETA, RIO NEGRO",
  "PASO CHACABUCO, PILCANIYEU, RIO NEGRO",
  "PASO FLORES, PILCANIYEU, RIO NEGRO",
  "PASO LIMAY, PILCANIYEU, RIO NEGRO",
  "PERITO MORENO, BARILOCHE, RIO NEGRO",
  "PERITO MORENO ,EST., PILCANIYEU, RIO NEGRO",
  "PICHI LEUFU, PILCANIYEU, RIO NEGRO",
  "PICHI MAHUIDA, PICHI MAHUIDA, RIO NEGRO",
  "PILCANIYEU, PILCANIYEU, RIO NEGRO",
  "POMONA, AVELLANEDA, RIO NEGRO",
  "PUERTO BLEST, BARILOCHE, RIO NEGRO",
  "RIO CHICO ,EST.CERRO MESA, ÑORQUINCO, RIO NEGRO",
  "RIO COLORADO, PICHI MAHUIDA, RIO NEGRO",
  "RIO COLORADO, PICHI MAHUIDA, RIO NEGRO",
  "SAN ANTONIO OESTE, SAN ANTONIO, RIO NEGRO",
  "SAN CARLOS DE BARILOCHE, BARILOCHE, RIO NEGRO",
  "SAN LORENZO, CONESA, RIO NEGRO",
  "SANTA ROSA, VALCHETA, RIO NEGRO",
  "SCOTT, PERCY H. ,AP., SAN ANTONIO, RIO NEGRO",
  "SIERRA COLORADA, 9 DE JULIO, RIO NEGRO",
  "SIERRA GRANDE, SAN ANTONIO, RIO NEGRO",
  "SIERRA PAILEMAN, VALCHETA, RIO NEGRO",
  "TALCAHUALA ,AP., 9 DE JULIO, RIO NEGRO",
  "TENIENTE MAZA ,EST., VALCHETA, RIO NEGRO",
  "TRAPALCO, AVELLANEDA, RIO NEGRO",
  "TRICA CO, EL CUY, RIO NEGRO",
  "VALCHETA, VALCHETA, RIO NEGRO",
  "VICEALMIRANTE EDUARDO O'CONNOR, ADOLFO ALSINA, RIO NEGRO",
  "VIEDMA, ADOLFO ALSINA, RIO NEGRO",
  "VIEDMA ,EST., ADOLFO ALSINA, RIO NEGRO",
  "VILLA MASCARDI, BARILOCHE, RIO NEGRO",
  "VILLA REGINA, GENERAL ROCA, RIO NEGRO",
  "ZANJON DE OYUELA, ADOLFO ALSINA, RIO NEGRO",
  "ZORRILLA, BENJAMIN, AVELLANEDA, RIO NEGRO",
  "20 DE FEBRERO, LA VIÑA, SALTA",
  "ACAMBUCO, SAN MARTIN, SALTA",
  "AGUA BLANCA, ORAN, SALTA",
  "AGUA SUCIA, ANTA, SALTA",
  "AGUARAY ,EST., GENERAL SAN MARTIN, SALTA",
  "ALEMANIA, GUACHIPAS, SALTA",
  "ALGARROBAL, ANTA, SALTA",
  "ALMIRANTE BROWN, ROSARIO DE LA FRONTERA, SALTA",
  "ALTO DE FLORES, RIVADAVIA, SALTA",
  "ALTO DEL MISTOL, METAN, SALTA",
  "AMBLAYO, SAN CARLOS, SALTA",
  "AMPASCACHI, LA VIÑA, SALTA",
  "ANGASTACO, SAN CARLOS, SALTA",
  "ANGOSTURA, SAN CARLOS, SALTA",
  "ANIMANA, SAN CARLOS, SALTA",
  "ANTA, ANTA, SALTA",
  "ANTILLA, ROSARIO DE LA FRONTERA, SALTA",
  "ANTONIO QUIJARRO, SAN MARTIN, SALTA",
  "APOLINARIO SARAVIA, ANTA, SALTA",
  "ARENAL, ROSARIO DE LA FRONTERA, SALTA",
  "BAJO GRANDE, METAN, SALTA",
  "BALBOA, ROSARIO DE LA FRONTERA, SALTA",
  "BELLA VISTA, ROSARIO DE LA FRONTERA, SALTA",
  "BETANIA, GENERAL GUEMES, SALTA",
  "CABEZA DE BUEY, GENERAL GUEMES, SALTA",
  "CACHI, CACHI, SALTA",
  "CACHIÑAL, ROSARIO DE LERMA, SALTA",
  "CACHIPAMPA, CACHI, SALTA",
  "CAFAYATE, CAFAYATE, SALTA",
  "CAIPE ,EST., LOS ANDES, SALTA",
  "CAMPICHUELO, GENERAL SAN MARTIN, SALTA",
  "CAMPO ALEGRE, ANTA, SALTA",
  "CAMPO CASEROS, CAPITAL, SALTA",
  "CAMPO DURAN, GENERAL SAN MARTIN, SALTA",
  "CAMPO GRANDE, GENERAL SAN MARTIN, SALTA",
  "CAMPO QUIJANO, ROSARIO DE LERMA, SALTA",
  "CAMPO SANTO, GENERAL GUEMES, SALTA",
  "CAPITAN JUAN PAGE, RIVADAVIA, SALTA",
  "CASTAÑARES ,EST., LA VIÑA, SALTA",
  "CEIBALITO, ANTA, SALTA",
  "CERRILLOS, CERRILLOS, SALTA",
  "CHAGUARAL, ORAN, SALTA",
  "CHAÑAR MUYO ,EST.CNL.OLLEROS, ANTA, SALTA",
  "CHICOANA, CHICOANA, SALTA",
  "CHICOANA ,EST., CHICOANA, SALTA",
  "CHORRILLOS, ROSARIO DE LERMA, SALTA",
  "CHORROARIN, ANTA, SALTA",
  "CHUCULAQUI ,EST., LOS ANDES, SALTA",
  "COBRES, LA POMA, SALTA",
  "COCHABAMBA ,AP., ROSARIO DE LA FRONTERA, SALTA",
  "COLONIA SANTA ROSA, ORAN, SALTA",
  "COPO QUILE, ROSARIO DE LA FRONTERA, SALTA",
  "CORONEL CORNEJO, GENERAL SAN MARTIN, SALTA",
  "CORONEL JUAN SOLA ,EST.MORILLO, RIVADAVIA, SALTA",
  "CORONEL MOLDES, LA VIÑA, SALTA",
  "CORONEL MOLLINEDO, ANTA, SALTA",
  "CORONEL VIDT, ANTA, SALTA",
  "CORRALITO, SAN CARLOS, SALTA",
  "CORRALITO, GENERAL SAN MARTIN, SALTA",
  "CRUZ QUEMADA, GENERAL GUEMES, SALTA",
  "DE ALMAGRO, DIEGO, ROSARIO DE LERMA, SALTA",
  "DOCTOR FACUNDO ZUVIRIA, CHICOANA, SALTA",
  "DRAGONES, GENERAL SAN MARTIN, SALTA",
  "EL AIBAL, LA CAPITAL, SALTA",
  "EL ALISAL, ROSARIO DE LERMA, SALTA",
  "EL BORDO, ROSARIO DE LA FRONTERA, SALTA",
  "EL BORDO, GENERAL GUEMES, SALTA",
  "EL BOTIN, RIVADAVIA, SALTA",
  "EL CARRIL, CHICOANA, SALTA",
  "EL CEBILLAR, GUACHIPAS, SALTA",
  "EL CEIBALITO, CERRILLOS, SALTA",
  "EL DURAZNO, LA CAPITAL, SALTA",
  "EL ENCON, ROSARIO DE LERMA, SALTA",
  "EL GALPON ,EST.FOGUISTA JORGE.F.SUAREZ, METAN, SALTA",
  "EL NARANJO, ROSARIO DE LA FRONTERA, SALTA",
  "EL PAJEAL, GENERAL SAN MARTIN, SALTA",
  "EL PIQUETE, ANTA, SALTA",
  "EL PUCARA, ROSARIO DE LERMA, SALTA",
  "EL QUEBRACHAL, ANTA, SALTA",
  "EL SALADILLO, LA POMA, SALTA",
  "EL TALA ,EST.RUIZ DE LOS LLANOS, CANDELARIA, SALTA",
  "EL TUNAL, METAN, SALTA",
  "EL TUNAL, ROSARIO DE LERMA, SALTA",
  "EMBARCACION, GENERAL SAN MARTIN, SALTA",
  "ESCOIPE, CHICOANA, SALTA",
  "ESQUINA DE QUISTO, GENERAL GUEMES, SALTA",
  "ESTECO, METAN, SALTA",
  "GAONA, ANTA, SALTA",
  "GENERAL ALVARADO, LA CAPITAL, SALTA",
  "GENERAL BALLIVIAN, GENERAL SAN MARTIN, SALTA",
  "GENERAL GUEMES ,EST.GUEMES, GENERAL GUEMES, SALTA",
  "GENERAL MOSCONI ,EST.VESPUCIO, GENERAL SAN MARTIN, SALTA",
  "GENERAL PIZARRO, ANTA, SALTA",
  "GOBERNADOR MANUEL SOLA, ROSARIO DE LERMA, SALTA",
  "GONZALEZ, °JOAQUIN V., ANTA, SALTA",
  "GUACHIPAS, GUACHIPAS, SALTA",
  "GUAYACAN, GENERAL SAN MARTIN, SALTA",
  "HICKMANN, GENERAL SAN MARTIN, SALTA",
  "HORCONES, ROSARIO DE LA FRONTERA, SALTA",
  "INCAHUASI, ROSARIO DE LERMA, SALTA",
  "INGENIERO MAURY, ROSARIO, SALTA",
  "IRUYA, IRUYA, SALTA",
  "JASIMANA, SAN CARLOS, SALTA",
  "JURAMENTO, METAN, SALTA",
  "KILOMETRO 1088 ,DV., ANTA, SALTA",
  "KILOMETRO 1104 ,DV., ANTA, SALTA",
  "KILOMETRO 1125 ,DV., LA CALDERA, SALTA",
  "KILOMETRO 1129 ,DV., LA CAPITAL, SALTA",
  "KILOMETRO 1152 ,DV., ANTA, SALTA",
  "KILOMETRO 1156 ,AP., ROSARIO DE LERMA, SALTA",
  "KILOMETRO 1291, ORAN, SALTA",
  "KILOMETRO 1291 ,DV., LA CAPITAL, SALTA",
  "KILOMETRO 1299, ROSARIO DE LERMA, SALTA",
  "KILOMETRO 1305 ,DV., ORAN, SALTA",
  "KILOMETRO 1357, LOS ANDES, SALTA",
  "KILOMETRO 1424, LOS ANDES, SALTA",
  "KILOMETRO 1448, GENERAL SAN MARTIN, SALTA",
  "KILOMETRO 1506, LOS ANDES, SALTA",
  "LA CALDERA, LA CALDERA, SALTA",
  "LA CANDELARIA, CANDELARIA, SALTA",
  "LA CANDELARIA, CERRILLOS, SALTA",
  "LA CORZUELA, RIVADAVIA, SALTA",
  "LA ESTRELLA, ORAN, SALTA",
  "LA FLORIDA, CERRILLOS, SALTA",
  "LA ISLA, CERRILLOS, SALTA",
  "LA MERCED, CERRILLOS, SALTA",
  "LA POMA, LA POMA, SALTA",
  "LA QUESERA, LA CAPITAL, SALTA",
  "LA ROSITA, CERRILLOS, SALTA",
  "LA SILLETA, ROSARIO DE LERMA, SALTA",
  "LA TRAMPA, GENERAL GUEMES, SALTA",
  "LA UNION, RIVADAVIA, SALTA",
  "LA VIÑA, LA VIÑA, SALTA",
  "LAS CAPILLAS, ROSARIO DE LERMA, SALTA",
  "LAS LAJITAS, ANTA, SALTA",
  "LAS LLAVES, RIVADAVIA, SALTA",
  "LAS MERCEDES, ROSARIO DE LA FRONTERA, SALTA",
  "LAS MESITAS ,DV., GENERAL GUEMES, SALTA",
  "LAS PALMAS, CERRILLOS, SALTA",
  "LAS SALADAS, ROSARIO DE LA FRONTERA, SALTA",
  "LAS TORTUGAS, ANTA, SALTA",
  "LAS TRES MARIAS, RIVADAVIA, SALTA",
  "LAS VIBORAS, ANTA, SALTA",
  "LORO HUASI, CAFAYATE, SALTA",
  "LOS BAÑOS, ROSARIO DE LA FRONTERA, SALTA",
  "LOS BLANCOS, RIVADAVIA, SALTA",
  "LOS MOGOTES, CANDELARIA, SALTA",
  "LOS NOGALES, ANTA, SALTA",
  "LOS PATOS ,AP., LOS ANDES, SALTA",
  "LOS SAUCES, LA VIÑA, SALTA",
  "LUMBRERA, METAN, SALTA",
  "LURACATAO, MOLINOS, SALTA",
  "MACAPILLO, ANTA, SALTA",
  "MARTINEZ DEL TINEO, ORAN, SALTA",
  "MATORRAS, JERONIMO, ORAN, SALTA",
  "MESETA, ROSARIO DE LERMA, SALTA",
  "METAN, METAN, SALTA",
  "METAN VIEJO, METAN, SALTA",
  "MINA CONCORDIA ,EMB., LOS ANDES, SALTA",
  "MINA LA CASUALIDAD, LOS ANDES, SALTA",
  "MOJOTORO, LA CALDERA, SALTA",
  "MOLINOS, MOLINOS, SALTA",
  "MONTEVERDE, SAN CARLOS, SALTA",
  "MORALES, GUACHIPAS, SALTA",
  "MORENILLO, ROSARIO DE LA FRONTERA, SALTA",
  "MUÑANO, LA POMA, SALTA",
  "NAZARENO, SANTA VICTORIA, SALTA",
  "NUESTRA SEÑORA DE TALAVERA, ANTA, SALTA",
  "OSMA, LA VIÑA, SALTA",
  "OSMA ,EST., CHICOANA, SALTA",
  "OVANDO, ROSARIO DE LA FRONTERA, SALTA",
  "PADRE LOZANO, GENERAL SAN MARTIN, SALTA",
  "PALERMO, ANTA, SALTA",
  "PALOMITAS, GENERAL GUEMES, SALTA",
  "PAMPA GRANDE, GUACHIPAS, SALTA",
  "PATRON COSTAS, LUIS, CERRILLOS, SALTA",
  "PAYOGASTA, CACHI, SALTA",
  "PICHANAL, ORAN, SALTA",
  "PIQUETE CABADO, ANTA, SALTA",
  "PIQUIRENDA, GENERAL SAN MARTIN, SALTA",
  "PLUMA DEL PATO, RIVADAVIA, SALTA",
  "POTRERILLOS, CANDELARIA, SALTA",
  "POTRERO, METAN, SALTA",
  "POTRERO DE URIBURU, ROSARIO DE LERMA, SALTA",
  "POZO BRAVO, GENERAL SAN MARTIN, SALTA",
  "POZO CERRADO, RIVADAVIA, SALTA",
  "POZO DE LA ESQUINA, ORAN, SALTA",
  "PROFESOR SALVADOR MAZZA ,EST.POCITOS, GENERAL SAN MARTIN, SALTA",
  "PUCARA, SAN CARLOS, SALTA",
  "PUEBLO VIEJO, LA POMA, SALTA",
  "PUENTE DE PLATA, ROSARIO DE LA FRONTERA, SALTA",
  "PUERTA DE DIAZ, RIVADAVIA, SALTA",
  "PUERTA TASTIL, ROSARIO DE LERMA, SALTA",
  "PUERTO LA PAZ, RIVADAVIA, SALTA",
  "PUNTA DEL AGUA, CACHI, SALTA",
  "QUEBRADA DEL AGUA ,EST., LOS ANDES, SALTA",
  "QUIJARRO, ANTONIO, GENERAL SAN MARTIN, SALTA",
  "RIO ANCHO ,AP., LA CAPITAL, SALTA",
  "RIO DEL VALLE, ANTA, SALTA",
  "RIO PESCADO, ORAN, SALTA",
  "RIO PIEDRAS, METAN, SALTA",
  "RIO SECO, GENERAL SAN MARTIN, SALTA",
  "RIO URUEÑA, ROSARIO DE LA FRONTERA, SALTA",
  "RIVADAVIA, RIVADAVIA, SALTA",
  "ROSARIO DE LA FRONTERA, ROSARIO DE LA FRONTERA, SALTA",
  "ROSARIO DE LERMA, ROSARIO DE LERMA, SALTA",
  "ROSARIO DEL DORADO, ANTA, SALTA",
  "SALAR DE POCITOS ,EST., LOS ANDES, SALTA",
  "SALTA, LA CAPITAL, SALTA",
  "SAN AGUSTIN, RIVADAVIA, SALTA",
  "SAN ANTONIO DE LOS COBRES, LOS ANDES, SALTA",
  "SAN CARLOS, SAN CARLOS, SALTA",
  "SAN JOSE DE ESCALCHI, CACHI, SALTA",
  "SAN LORENZO, LA CAPITAL, SALTA",
  "SAN NICOLAS, LA VIÑA, SALTA",
  "SAN PEDRO, IRUYA, SALTA",
  "SAN RAMON DE LA NUEVA ORAN, ORAN, SALTA",
  "SANTA MARIA, RIVADAVIA, SALTA",
  "SANTA ROSA DE LOS PASTOS GRANDES, LOS ANDES, SALTA",
  "SANTA ROSA DE TASTIL, ROSARIO DE LERMA, SALTA",
  "SANTA VICTORIA, RIVADAVIA, SALTA",
  "SANTA VICTORIA, SANTA VICTORIA, SALTA",
  "SANTO DOMINGO, ANTA, SALTA",
  "SARAVIA, APOLINARIO, ANTA, SALTA",
  "SAUCELITO, ORAN, SALTA",
  "SCHNEIDEWIND, METAN, SALTA",
  "SECLANTAS, MOLINOS, SALTA",
  "SENDA HACHADA, GENERAL SAN MARTIN, SALTA",
  "SENILLOSA, GENERAL SAN MARTIN, SALTA",
  "SOCOMPA ,EST., LOS ANDES, SALTA",
  "SUNCHAL, RIVADAVIA, SALTA",
  "TABACAL, ORAN, SALTA",
  "TACA TACA ,EST., LOS ANDES, SALTA",
  "TACUARA, ROSARIO DE LERMA, SALTA",
  "TACUY, GENERAL SAN MARTIN, SALTA",
  "TALAPAMPA, LA VIÑA, SALTA",
  "TARTAGAL, GENERAL SAN MARTIN, SALTA",
  "TEDIN, VIRGILIO, GENERAL GUEMES, SALTA",
  "TOBANTIRENDA, GENERAL SAN MARTIN, SALTA",
  "TOLAR GRANDE, LOS ANDES, SALTA",
  "TOLLOCHE, ANTA, SALTA",
  "TOLOMBON, CAFAYATE, SALTA",
  "TONCO, CACHI, SALTA",
  "TONONO, GENERAL SAN MARTIN, SALTA",
  "TRES YUCHANES, RIVADAVIA, SALTA",
  "UNQUILLAL ,EMB., LOS ANDES, SALTA",
  "URIZAR, ESTEBAN DE, ORAN, SALTA",
  "URUNDEL, ORAN, SALTA",
  "VAQUEROS, LA CALDERA, SALTA",
  "VEGA DE ARZARO ,EST., LOS ANDES, SALTA",
  "VILLA SARMIENTO, CERRILLOS, SALTA",
  "VIRREY TOLEDO, ROSARIO DE LERMA, SALTA",
  "VUELTA DE LOS TOBAS, RIVADAVIA, SALTA",
  "YARIGUARENDA, GENERAL SAN MARTIN, SALTA",
  "YATASTO, METAN, SALTA",
  "YUCHAN, ORAN, SALTA",
  "9 DE JULIO, 9 DE JULIO, SAN JUAN",
  "ADAN QUIROGA, JACHAL, SAN JUAN",
  "ALBARDON, ALBARDON, SAN JUAN",
  "ALGARROBO VERDE, 25 DE MAYO, SAN JUAN",
  "ALTO DE SIERRA, SANTA LUCIA, SAN JUAN",
  "ANGACO NORTE ,EST, ANGACO, SAN JUAN",
  "ANGUALASTO, IGLESIA, SAN JUAN",
  "BALDE DEL ROSARIO, VALLE FERTIL, SAN JUAN",
  "BAÑO CENTENARIO, IGLESIA, SAN JUAN",
  "BARREAL, CALINGASTA, SAN JUAN",
  "BARRIALITO, CALINGASTA, SAN JUAN",
  "BARRIO RIVADAVIA, RIVADAVIA, SAN JUAN",
  "BELLA VISTA, CALINGASTA, SAN JUAN",
  "BELLA VISTA, IGLESIA, SAN JUAN",
  "BERMEJO, CAUCETE, SAN JUAN",
  "BOCA DE LA QUEBRADA, JACHAL, SAN JUAN",
  "BUENA ESPERANZA, IGLESIA, SAN JUAN",
  "CALINGASTA, CALINGASTA, SAN JUAN",
  "CAÑADA HONDA, SARMIENTO, SAN JUAN",
  "CARPINTERIA, POCITO, SAN JUAN",
  "CASTAÑO NUEVO, CALINGASTA, SAN JUAN",
  "CASTAÑO VIEJO, CALINGASTA, SAN JUAN",
  "CAUCETE, CAUCETE, SAN JUAN",
  "CHIGUA, IGLESIA, SAN JUAN",
  "CHUCUMA, VALLE FERTIL, SAN JUAN",
  "CIENAGUITA, SARMIENTO, SAN JUAN",
  "COCHAGUAL, SARMIENTO, SAN JUAN",
  "COLANGUIL, IGLESIA, SAN JUAN",
  "COLL, SAN MARTIN, SAN JUAN",
  "COLOLA, IGLESIA, SAN JUAN",
  "CUYO ,EST., 25 DE MAYO, SAN JUAN",
  "DIVISORIA, 25 DE MAYO, SAN JUAN",
  "ECHAGUE, PEDRO ,EST., SANTA LUCIA, SAN JUAN",
  "EL BOSQUE, ANGACO, SAN JUAN",
  "EL FICAL, JACHAL, SAN JUAN",
  "EL SALADO, ALBARDON, SAN JUAN",
  "ENCON, 25 DE MAYO, SAN JUAN",
  "GUANACACHE, SARMIENTO, SAN JUAN",
  "HUACO, JACHAL, SAN JUAN",
  "HUERTA DE HUACHI, JACHAL, SAN JUAN",
  "ICHIGUALASTO, VALLE FERTIL, SAN JUAN",
  "IGLESIA, IGLESIA, SAN JUAN",
  "INGENIERO MATIAS G.SANCHEZ ,EST., JACHAL, SAN JUAN",
  "KILOMETRO 810, CAUCETE, SAN JUAN",
  "KILOMETRO 936 ,EMB., 25 DE MAYO, SAN JUAN",
  "LA BEBIDA, RIVADAVIA, SAN JUAN",
  "LA CAÑADA, IGLESIA, SAN JUAN",
  "LA CIENAGA DE CUMILLANGO, JACHAL, SAN JUAN",
  "LA HUERTA, VALLE FERTIL, SAN JUAN",
  "LA ISLA, CALINGASTA, SAN JUAN",
  "LA LAJA, ALBARDON, SAN JUAN",
  "LA RINCONADA, POCITO, SAN JUAN",
  "LAS CASUARINAS, 25 DE MAYO, SAN JUAN",
  "LAS CHIMBAS ,AP., CHIMBAS, SAN JUAN",
  "LAS DELICIAS, VALLE FERTIL, SAN JUAN",
  "LAS FLORES, IGLESIA, SAN JUAN",
  "LAS JUNTAS, VALLE FERTIL, SAN JUAN",
  "LAS LOMITAS ,EST.ALBARDON, ALBARDON, SAN JUAN",
  "LAS SALINAS ,DV., CAUCETE, SAN JUAN",
  "LAS TAPIAS, ALBARDON, SAN JUAN",
  "LOS BERROS, SARMIENTO, SAN JUAN",
  "LOS PAPAGAYOS, CAUCETE, SAN JUAN",
  "LOS RINCONES, VALLE FERTIL, SAN JUAN",
  "LOS VIÑEDOS ,AP., SANTA LUCIA, SAN JUAN",
  "MACATACLASTO, IGLESIA, SAN JUAN",
  "MALIMAN DE ABAJO, IGLESIA, SAN JUAN",
  "MARAYES, CAUCETE, SAN JUAN",
  "MARQUESADO, RIVADAVIA, SAN JUAN",
  "MATAGUSANOS, ULLUN, SAN JUAN",
  "MEDANO DE ORO, RAWSON, SAN JUAN",
  "MOGNA, JACHAL, SAN JUAN",
  "NIQUIVIL, JACHAL, SAN JUAN",
  "NIQUIVIL NUEVO, JACHAL, SAN JUAN",
  "NIQUIVIL VIEJO, JACHAL, SAN JUAN",
  "NUEVA CASTILLA ,DV., CAUCETE, SAN JUAN",
  "ORO, DOMINGO DE ,EST., ANGACO, SAN JUAN",
  "PACHIMOCO, JACHAL, SAN JUAN",
  "PAMPA DEL CHAÑAR, JACHAL, SAN JUAN",
  "PAMPA VIEJA, JACHAL, SAN JUAN",
  "PAMPITA, JACHAL, SAN JUAN",
  "PANACAN, JACHAL, SAN JUAN",
  "PASLEAM, JACHAL, SAN JUAN",
  "PEDERNAL, SARMIENTO, SAN JUAN",
  "PIE DE PALO, CAUCETE, SAN JUAN",
  "PISMANTA, IGLESIA, SAN JUAN",
  "POCITO, POCITO, SAN JUAN",
  "PUCHUZUN, CALINGASTA, SAN JUAN",
  "PUENTE NACIONAL ,AP., SAN MARTIN, SAN JUAN",
  "PUNTA DEL AGUA, JACHAL, SAN JUAN",
  "PUNTILLA BLANCA, SAN MARTIN, SAN JUAN",
  "QUILINQUIL, IGLESIA, SAN JUAN",
  "QUIROGA, ADAN, JACHAL, SAN JUAN",
  "RAMBLON, SARMIENTO, SAN JUAN",
  "RETAMITO, SARMIENTO, SAN JUAN",
  "RIVADAVIA, RIVADAVIA, SAN JUAN",
  "RODEO, IGLESIA, SAN JUAN",
  "SAN AGUSTIN DEL VALLE FERTIL, VALLE FERTIL, SAN JUAN",
  "SAN JOSE DE JACHAL, JACHAL, SAN JUAN",
  "SAN JUAN, CAPITAL, SAN JUAN",
  "SAN MARTIN ,EST.ANGACO SUD, SAN MARTIN, SAN JUAN",
  "SAN ROQUE ,EST.LOS DIAGUITAS, JACHAL, SAN JUAN",
  "SANCHEZ DE LORIA, POCITO, SAN JUAN",
  "SANTA LUCIA, SANTA LUCIA, SAN JUAN",
  "SANTO DOMINGO, VALLE FERTIL, SAN JUAN",
  "SLA CIENAGA, JACHAL, SAN JUAN",
  "SOROCAYENSE, CALINGASTA, SAN JUAN",
  "TALACASTO, ULLUN, SAN JUAN",
  "TAMBERIAS, CALINGASTA, SAN JUAN",
  "TOCOTA, IGLESIA, SAN JUAN",
  "TRINIDAD, RAWSON, SAN JUAN",
  "TUCUNUCO, JACHAL, SAN JUAN",
  "TUCUNUCO ,EMB., JACHAL, SAN JUAN",
  "TUDCUM, IGLESIA, SAN JUAN",
  "ULLUN, ULLUN, SAN JUAN",
  "USNO, VALLE FERTIL, SAN JUAN",
  "VALLECITO, CAUCETE, SAN JUAN",
  "VILLA ABERASTAIN, POCITO, SAN JUAN",
  "VILLA CORRAL, CALINGASTA, SAN JUAN",
  "VILLA DEL SALVADOR, ANGACO, SAN JUAN",
  "VILLA INDEPENDENCIA, CAUCETE, SAN JUAN",
  "VILLA KRAUSE, RAWSON, SAN JUAN",
  "VILLA MEDIA AGUA, SARMIENTO, SAN JUAN",
  "VILLA MERCEDES, JACHAL, SAN JUAN",
  "VILLA NUEVA, CALINGASTA, SAN JUAN",
  "VILLA PAULA ALBARRACIN DE SARMIENTO, CHIMBAS, SAN JUAN",
  "VILLA PITUIL, CALINGASTA, SAN JUAN",
  "VILLA SAN ISIDRO, SAN MARTIN, SAN JUAN",
  "VILLA SANTA ROSA, 25 DE MAYO, SAN JUAN",
  "YOCA, VALLE FERTIL, SAN JUAN",
  "ZONDA, ZONDA, SAN JUAN",
  "ALGARROBOS GRANDES, BELGRANO, SAN LUIS",
  "ALTO LINDO, CHACABUCO, SAN LUIS",
  "ALTO PELADO, LA CAPITAL, SAN LUIS",
  "ALTO PENCOSO, LA CAPITAL, SAN LUIS",
  "ANCHORENA, GOBERNADOR DUPUY, SAN LUIS",
  "ARBOL SOLO, BELGRANO, SAN LUIS",
  "ARIZONA, GOBERNADOR DUPUY, SAN LUIS",
  "AVANZADA ,EST., GENERAL PEDERNERA, SAN LUIS",
  "BAGUAL, GOBERNADOR DUPUY, SAN LUIS",
  "BAJADA NUEVA, GOBERNADOR DUPUY, SAN LUIS",
  "BAJOS HONDOS, LA CAPITAL, SAN LUIS",
  "BALCARCE, CHACABUCO, SAN LUIS",
  "BALDE, LA CAPITAL, SAN LUIS",
  "BALDE DE AZCURRA, AYACUCHO, SAN LUIS",
  "BALDE DE ESCUDERO, JUNIN, SAN LUIS",
  "BALDE DE LOS TORRES, AYACUCHO, SAN LUIS",
  "BALDE DE PUERTAS, AYACUCHO, SAN LUIS",
  "BALDE DE QUINES, AYACUCHO, SAN LUIS",
  "BALDE VIEJO, AYACUCHO, SAN LUIS",
  "BARRANCA COLORADA, LA CAPITAL, SAN LUIS",
  "BATAVIA, GOBERNADOR DUPUY, SAN LUIS",
  "BEAZLEY, LA CAPITAL, SAN LUIS",
  "BELLA ESTANCIA, BELGRANO, SAN LUIS",
  "BELLA VISTA, AYACUCHO, SAN LUIS",
  "BUENA ESPERANZA, GOBERNADOR DUPUY, SAN LUIS",
  "CALDENADAS, GENERAL PEDERNERA, SAN LUIS",
  "CAÑADA ANGOSTA, BELGRANO, SAN LUIS",
  "CAÑADA HONDA, CORONEL PRINGLES, SAN LUIS",
  "CANDELARIA ,EST.LA CANDELARIA, AYACUCHO, SAN LUIS",
  "CANTERAS, CORONEL PRINGLES, SAN LUIS",
  "CAROLINA, CORONEL PRINGLES, SAN LUIS",
  "CARPINTERIA, JUNIN, SAN LUIS",
  "CARPINTERIA, AYACUCHO, SAN LUIS",
  "CAZADOR, LA CAPITAL, SAN LUIS",
  "CHALANTA, GENERAL PEDERNERA, SAN LUIS",
  "CHIMBORAZO, BELGRANO, SAN LUIS",
  "CHIPISCU, AYACUCHO, SAN LUIS",
  "CHISCHACA ,DV., LA CAPITAL, SAN LUIS",
  "CHISCHAQUITA, LA CAPITAL, SAN LUIS",
  "CHOSMES, LA CAPITAL, SAN LUIS",
  "COCHEQUINGAN, GOBERNADOR DUPUY, SAN LUIS",
  "COLONIA CALZADA, GOBERNADOR DUPUY, SAN LUIS",
  "COLONIA SANTA VIRGINIA, LA CAPITAL, SAN LUIS",
  "COLONIA URDANIZ, GOBERNADOR DUPUY, SAN LUIS",
  "COMANDANTE GRANVILLE, CORONEL PRINGLES, SAN LUIS",
  "CONCARAN, CHACABUCO, SAN LUIS",
  "CORONEL ALZOGARAY, GENERAL PEDERNERA, SAN LUIS",
  "CORONEL SEGOVIA, GOBERNADOR DUPUY, SAN LUIS",
  "CORTADERAS, CHACABUCO, SAN LUIS",
  "CRAMER, GENERAL PEDERNERA, SAN LUIS",
  "CRUZ DE CAÑA, BELGRANO, SAN LUIS",
  "CUATRO ESQUINAS, CORONEL PRINGLES, SAN LUIS",
  "DARACT, JUSTO, GENERAL PEDERNERA, SAN LUIS",
  "DIXONVILLE ,EST.FORTIN EL PATRIA, GOBERNADOR DUPUY, SAN LUIS",
  "DONADO, LA CAPITAL, SAN LUIS",
  "DONOVAN, DANIEL, LA CAPITAL, SAN LUIS",
  "EL AGUILA, GOBERNADOR DUPUY, SAN LUIS",
  "EL AMPARO, CORONEL PRINGLES, SAN LUIS",
  "EL CARMEN, AYACUCHO, SAN LUIS",
  "EL CHORRILLO, LA CAPITAL, SAN LUIS",
  "EL DURAZNO, CORONEL PRINGLES, SAN LUIS",
  "EL HORMIGUERO, AYACUCHO, SAN LUIS",
  "EL MARTILLO, GOBERNADOR DUPUY, SAN LUIS",
  "EL MILAGRO, BELGRANO, SAN LUIS",
  "EL MOLLE, BELGRANO, SAN LUIS",
  "EL PORTEZUELO, LA CAPITAL, SAN LUIS",
  "EL PORTEZUELO, LA CAPITAL, SAN LUIS",
  "EL PORVENIR, GOBERNADOR DUPUY, SAN LUIS",
  "EL RECUERDO, LA CAPITAL, SAN LUIS",
  "EL RETAMO, AYACUCHO, SAN LUIS",
  "EL RODEO, GOBERNADOR DUPUY, SAN LUIS",
  "EL SAUCE, CHACABUCO, SAN LUIS",
  "EL VOLCAN, LA CAPITAL, SAN LUIS",
  "FORTUNA, GOBERNADOR DUPUY, SAN LUIS",
  "FRAGA, CORONEL PRINGLES, SAN LUIS",
  "FRISIA, GOBERNADOR DUPUY, SAN LUIS",
  "GEZ,JUAN W., LA CAPITAL, SAN LUIS",
  "GOMEZ, CASIMIRO, GOBERNADOR DUVAL, SAN LUIS",
  "GORGONTA, LA CAPITAL, SAN LUIS",
  "GUANIZUL, IGLESIA, SAN LUIS",
  "HUEJEDA, LA CAPITAL, SAN LUIS",
  "JARILLA, LA CAPITAL, SAN LUIS",
  "JORBA, JUAN, GENERAL PEDERNERA, SAN LUIS",
  "KILOMETRO 14 ,DIV., GENERAL PEDERNERA, SAN LUIS",
  "KILOMETRO 731, GENERAL PEDERNERA, SAN LUIS",
  "KILOMETRO 757, LA CAPITAL, SAN LUIS",
  "LA ANGELINA, GENERAL PEDERNERA, SAN LUIS",
  "LA BAJADA, CORONEL PRINGLES, SAN LUIS",
  "LA COCHA, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LA COSTA, LA CAPITAL, SAN LUIS",
  "LA ESQUINA, GENERAL PEDERNERA, SAN LUIS",
  "LA ESTRELLA, GOBERNADOR DUPUY, SAN LUIS",
  "LA FLORIDA, CORONEL PRINGLES, SAN LUIS",
  "LA HUERTA, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LA IRENE, LA CAPITAL, SAN LUIS",
  "LA MAROMA, GOBERNADOR DUPUY, SAN LUIS",
  "LA MELINA, GOBERNADOR DUPUY, SAN LUIS",
  "LA NELIDA, LA CAPITAL, SAN LUIS",
  "LA PAMPA GRANDE, AYACUCHO, SAN LUIS",
  "LA PUNILLA, GENERAL PEDERNERA, SAN LUIS",
  "LA SEÑA, LA CAPITAL, SAN LUIS",
  "LA TOMA, CORONEL PRINGLES, SAN LUIS",
  "LA TRANCA, AYACUCHO, SAN LUIS",
  "LA VERDE, GOBERNADOR DUPUY, SAN LUIS",
  "LAFINUR, JUNIN, SAN LUIS",
  "LAGUNAS LARGAS, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LAS AGUADAS, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LAS BARRANCAS, LA CAPITAL, SAN LUIS",
  "LAS BARRANQUITAS, CORONEL PRINGLES, SAN LUIS",
  "LAS CHACRAS, LA CAPITAL, SAN LUIS",
  "LAS CHACRAS, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LAS CHIMBAS, AYACUCHO, SAN LUIS",
  "LAS GAMAS, LA CAPITAL, SAN LUIS",
  "LAS ISLETAS, GENERAL PEDERNERA, SAN LUIS",
  "LAS LOMITAS, JUNIN, SAN LUIS",
  "LAS PALOMAS, JUNIN, SAN LUIS",
  "LAS VERTIENTES, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LAVAISSE, GENERAL PEDERNERA, SAN LUIS",
  "LIBERTADOR GENERAL SAN MARTIN, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "LLERENA, JUAN, GENERAL PEDERNERA, SAN LUIS",
  "LOBOS, ELEODORO, CORONEL PRINGLES, SAN LUIS",
  "LOMAS BLANCAS, BELGRANO, SAN LUIS",
  "LOMAS BLANCAS, AYACUCHO, SAN LUIS",
  "LOS ARGUELLOS, JUNIN, SAN LUIS",
  "LOS CERRILLOS, LA CAPITAL, SAN LUIS",
  "LOS CERRILLOS, LA CAPITAL, SAN LUIS",
  "LOS COROS, LA CAPITAL, SAN LUIS",
  "LOS MOLLES, JUNIN, SAN LUIS",
  "LOS PUQUIOS, LA CAPITAL, SAN LUIS",
  "LOYOLA, MARTIN DE, GOBERNADOR DUPUY, SAN LUIS",
  "LUJAN, AYACUCHO, SAN LUIS",
  "LUNA, LIBORIO, GENERAL PEDERNERA, SAN LUIS",
  "MANANTIALES, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "MERCEDES ,EST.VILLA MERCEDES, GENERAL PEDERNERA, SAN LUIS",
  "MERLO, JUNIN, SAN LUIS",
  "MOSMOTA, LA CAPITAL, SAN LUIS",
  "NAHUEL MAPA, GOBERNADOR DUPUY, SAN LUIS",
  "NASCHEL, CHACABUCO, SAN LUIS",
  "NAVIA, GOBERNADOR DUPUY, SAN LUIS",
  "NIKIZANGA ,DV., CAUCETE, SAN LUIS",
  "NUEVA CONSTITUCION, GOBERNADOR DUPUY, SAN LUIS",
  "NUEVA ESCOCIA, GENERAL PEDERNERA, SAN LUIS",
  "NUEVA GALIA, GOBERNADOR DUPUY, SAN LUIS",
  "OJO DEL RIO, JUNIN, SAN LUIS",
  "PAPAGAYO, CHACABUCO, SAN LUIS",
  "PASO DEL REY, CORONEL PRINGLES, SAN LUIS",
  "PASO GRANDE, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "PEDERNERA, GENERAL PEDERNERA, SAN LUIS",
  "PESCADORES, LA CAPITAL, SAN LUIS",
  "POLLEDO, GOBERNADOR DUPUY, SAN LUIS",
  "POTRERO DE LOS FUNES, LA CAPITAL, SAN LUIS",
  "POZO DEL CARRIL, LA CAPITAL, SAN LUIS",
  "POZO DEL TALA, BELGRANO, SAN LUIS",
  "QUEBRADA DE LOS CONDORES, LA CAPITAL, SAN LUIS",
  "QUINES, AYACUCHO, SAN LUIS",
  "RANQUELCO, GOBERNADOR DUPUY, SAN LUIS",
  "RIO QUINTO, GENERAL PEDERNERA, SAN LUIS",
  "ROSARIO, JUNIN, SAN LUIS",
  "SALADILLO, CORONEL PRINGLES, SAN LUIS",
  "SALINAS DEL BEBEDERO, LA CAPITAL, SAN LUIS",
  "SAN AMBROSIO, CORONEL PRINGLES, SAN LUIS",
  "SAN ANTONIO, BELGRANO, SAN LUIS",
  "SAN EDUARDO, CORONEL PRINGLES, SAN LUIS",
  "SAN FELIPE, CHACABUCO, SAN LUIS",
  "SAN FRANCISCO DEL MONTE DE ORO, AYACUCHO, SAN LUIS",
  "SAN GERONIMO, LA CAPITAL, SAN LUIS",
  "SAN ISIDRO, BELGRANO, SAN LUIS",
  "SAN ISIDRO, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "SAN JOSE DEL MORRO, GENERAL PEDERNERA, SAN LUIS",
  "SAN LUIS, LA CAPITAL, SAN LUIS",
  "SAN MARTIN DEL ALTO NEGRO, LA CAPITAL, SAN LUIS",
  "SAN MIGUEL, CORONEL PRINGLES, SAN LUIS",
  "SAN PABLO, CHACABUCO, SAN LUIS",
  "SAN PEDRO, BELGRANO, SAN LUIS",
  "SAN ROQUE, LA CAPITAL, SAN LUIS",
  "SANTA ROSA, AYACUCHO, SAN LUIS",
  "SANTA ROSA ,EST.ADOLFO RODRIGUEZ ZAA, JUNIN, SAN LUIS",
  "SANTA TERESA, LA CAPITAL, SAN LUIS",
  "SOCOSCORA, BELGRANO, SAN LUIS",
  "SOVEN, GENERAL PEDERNERA, SAN LUIS",
  "SUYUQUE, LA CAPITAL, SAN LUIS",
  "SUYUQUE NUEVO, LA CAPITAL, SAN LUIS",
  "TALITA, JUNIN, SAN LUIS",
  "TALITA, AYACUCHO, SAN LUIS",
  "TILISARAO, CHACABUCO, SAN LUIS",
  "TORO NEGRO, BELGRANO, SAN LUIS",
  "TOSCAL, GOBERNADOR DUPUY, SAN LUIS",
  "TRAPICHE, CORONEL PRINGLES, SAN LUIS",
  "TRAVESIA, LA CAPITAL, SAN LUIS",
  "TRES AMIGOS, GOBERNADOR DUPUY, SAN LUIS",
  "UNION, GOBERNADOR DUPUY, SAN LUIS",
  "USIYAL, GOBERNADOR DUPUY, SAN LUIS",
  "VARELA, LA CAPITAL, SAN LUIS",
  "VILLA DE PRAGA, LIBERTADOR GRL.SAN MARTIN, SAN LUIS",
  "VILLA DEL CARMEN, CHACABUCO, SAN LUIS",
  "VILLA GENERAL ROCA, BELGRANO, SAN LUIS",
  "VILLA LA QUEBRADA, BELGRANO, SAN LUIS",
  "VILLA LARCA, CHACABUCO, SAN LUIS",
  "VILLA REYNOLDS, GENERAL PEDERNERA, SAN LUIS",
  "YRIGOYEN, HIPOLITO, BELGRANO, SAN LUIS",
  "ZANJITAS, LA CAPITAL, SAN LUIS",
  "28 DE NOVIEMBRE, GUER AIKE, SANTA CRUZ",
  "AGUADA ALEGRE, DESEADO, SANTA CRUZ",
  "AGUADA ESCONDIDA, DESEADO, SANTA CRUZ",
  "AGUADA GRANDE, DESEADO, SANTA CRUZ",
  "ALMA GAUCHA, DESEADO, SANTA CRUZ",
  "ALQUINTA, GUER AIKE, SANTA CRUZ",
  "ANTONIO DE BIEDMA, DESEADO, SANTA CRUZ",
  "ARISTIZABAL, GUER AIKE, SANTA CRUZ",
  "BAHIA LAURA, DESEADO, SANTA CRUZ",
  "BAHIA OSO MARINO, DESEADO, SANTA CRUZ",
  "BAJO DE LOS CARACOLES, RIO CHICO, SANTA CRUZ",
  "BAJO FUEGO, MAGALLANES, SANTA CRUZ",
  "BAJO PIEDRA, DESEADO, SANTA CRUZ",
  "BELLA VISTA, RIO CHICO, SANTA CRUZ",
  "BELLA VISTA, DESEADO, SANTA CRUZ",
  "BELLA VISTA ,EST., GUER AIKE, SANTA CRUZ",
  "CABO BLANCO, DESEADO, SANTA CRUZ",
  "CABO BUEN TIEMPO, GUER AIKE, SANTA CRUZ",
  "CABO VIRGENES, GUER AIKE, SANTA CRUZ",
  "CALAFATE, LAGO ARGENTINO, SANTA CRUZ",
  "CALETA OLIVIA, DESEADO, SANTA CRUZ",
  "CAÑADON 11 DE SEPTIEMBRE, MAGALLANES, SANTA CRUZ",
  "CAÑADON SECO, DESEADO, SANTA CRUZ",
  "CANCHA CARRERAS, GUER AIKE, SANTA CRUZ",
  "CAPITAN EYROA ,EST., GUER AIKE, SANTA CRUZ",
  "CERRO BLANCO ,EMB., DESEADO, SANTA CRUZ",
  "COLONIA PERITO MORENO, LAGO ARGENTINO, SANTA CRUZ",
  "COMANDANTE LUIS PIEDRA BUENA, GUER AIKE, SANTA CRUZ",
  "COMANDANTE LUIS PIEDRA BUENA, CORPEN AIKE, SANTA CRUZ",
  "COY AIKE, GUER AIKE, SANTA CRUZ",
  "DE BIEDMA, ANTONIO, DESEADO, SANTA CRUZ",
  "DUFOUR, JULIA ,EST., GUER AIKE, SANTA CRUZ",
  "EL CALAFATE, LAGO ARGENTINO, SANTA CRUZ",
  "EL CERRITO, LAGO ARGENTINO, SANTA CRUZ",
  "EL CONDOR, GUER AIKE, SANTA CRUZ",
  "EL PLUMA, LAGO BUENOS AIRES, SANTA CRUZ",
  "EL PORTEZUELO, LAGO BUENOS AIRES, SANTA CRUZ",
  "EL SALADO, MAGALLANES, SANTA CRUZ",
  "EL TURBIO ,EST.GOBERNADOR MEYER, GUER AIKE, SANTA CRUZ",
  "EL VOLCAN, RIO CHICO, SANTA CRUZ",
  "EL ZURDO ,EST., GUER AIKE, SANTA CRUZ",
  "ESPERANZA, GUER AIKE, SANTA CRUZ",
  "FITZ ROY, DESEADO, SANTA CRUZ",
  "FLECHA NEGRA, LAGO BUENOS AIRES, SANTA CRUZ",
  "FLORIDA NEGRA, MAGALLANES, SANTA CRUZ",
  "FUENTES DEL COYLE, GUER AIKE, SANTA CRUZ",
  "FUHR, CHARLES, LAGO ARGENTINO, SANTA CRUZ",
  "GENDARME BARRETO, LAGO ARGENTINO, SANTA CRUZ",
  "GOBERNADOR GREGORES, RIO CHICO, SANTA CRUZ",
  "GOBERNADOR LISTA ,EST., GUER AIKE, SANTA CRUZ",
  "GOBERNADOR MAYER, GUER AIKE, SANTA CRUZ",
  "GOBERNADOR MOYANO, DESEADO, SANTA CRUZ",
  "GOBERNADOR MOYANO ,EST., GUER AIKE, SANTA CRUZ",
  "GUER AIKE, GUER AIKE, SANTA CRUZ",
  "HILL STATION, GUER AIKE, SANTA CRUZ",
  "JARAMILLO, DESEADO, SANTA CRUZ",
  "KILOMETRO 199 ,DV., GUER AIKE, SANTA CRUZ",
  "KOLUEL KAYKE, DESEADO, SANTA CRUZ",
  "LA ARAGONESA, LAGO BUENOS AIRES, SANTA CRUZ",
  "LA MARIA, LAGO BUENOS AIRES, SANTA CRUZ",
  "LAGO CARDIEL, RIO CHICO, SANTA CRUZ",
  "LAGO POSADAS, RIO CHICO, SANTA CRUZ",
  "LAGO SAN MARTIN, LAGO ARGENTINO, SANTA CRUZ",
  "LAGO VIEDMA, LAGO ARGENTINO, SANTA CRUZ",
  "LAGUNA GRANDE, CORPEN AIKE, SANTA CRUZ",
  "LAI AIKE, MAGALLANES, SANTA CRUZ",
  "LAS HERAS ,EST.COLONIA LAS HERAS, DESEADO, SANTA CRUZ",
  "LAS TRES HERMANAS, DESEADO, SANTA CRUZ",
  "LISTA, RAMON ,EST., DESEADO, SANTA CRUZ",
  "LOS ANTIGUOS, LAGO BUENOS AIRES, SANTA CRUZ",
  "LOS MONOS, DESEADO, SANTA CRUZ",
  "MATA AMARILLA, LAGO ARGENTINO, SANTA CRUZ",
  "MATA GRANDE, MAGALLANES, SANTA CRUZ",
  "MAZARREDO, DESEADO, SANTA CRUZ",
  "MINERALES ,EST., DESEADO, SANTA CRUZ",
  "MONTE AYMOND, GUER AIKE, SANTA CRUZ",
  "MONTE DINERO, GUER AIKE, SANTA CRUZ",
  "MONTE LEON, CORPEN AIKE, SANTA CRUZ",
  "PAMPA ALTA ,EMB., DESEADO, SANTA CRUZ",
  "PASO GREGORES, DESEADO, SANTA CRUZ",
  "PASO RODOLFO ROBALLOS, LAGO BUENOS AIRES, SANTA CRUZ",
  "PERITO MORENO, LAGO BUENOS AIRES, SANTA CRUZ",
  "PICO TRUNCADO, DESEADO, SANTA CRUZ",
  "PIEDRA CLAVADA ,EST., DESEADO, SANTA CRUZ",
  "PUERTO BANDERA, LAGO ARGENTINO, SANTA CRUZ",
  "PUERTO COIG, GUER AIKE, SANTA CRUZ",
  "PUERTO DESEADO, DESEADO, SANTA CRUZ",
  "PUERTO SAN JULIAN, MAGALLANES, SANTA CRUZ",
  "PUERTO SANTA CRUZ, CORPEN AIKE, SANTA CRUZ",
  "RINCON, CORPEN AIKE, SANTA CRUZ",
  "RIO CHICO, CORPEN AIKE, SANTA CRUZ",
  "RIO GALLEGOS, GUER AIKE, SANTA CRUZ",
  "RIO TURBIO, GUER AIKE, SANTA CRUZ",
  "TAMEL AIKE, RIO CHICO, SANTA CRUZ",
  "TAPI AIKE, GUER AIKE, SANTA CRUZ",
  "TEHUELCHES ,AP., DESEADO, SANTA CRUZ",
  "TELLIER, DESEADO, SANTA CRUZ",
  "TRES CERROS, DESEADO, SANTA CRUZ",
  "TRES LAGOS, LAGO ARGENTINO, SANTA CRUZ",
  "TUCU TUCU, RIO CHICO, SANTA CRUZ",
  "AARON CASTELLANOS, GENERAL LOPEZ, SANTA FE",
  "ACEBAL, ROSARIO, SANTA FE",
  "ADELA, GENERAL OBLIGADO, SANTA FE",
  "AERO CLUB ARGENTINO, ROSARIO, SANTA FE",
  "ALBARELLOS, ROSARIO, SANTA FE",
  "ALCORTA, CONSTITUCION, SANTA FE",
  "ALDAO, SAN LORENZO, SANTA FE",
  "ALEJANDRA, SAN JAVIER, SANTA FE",
  "ALTO VERDE, LA CAPITAL, SANTA FE",
  "ALVAREZ, ROSARIO, SANTA FE",
  "ALVEAR, ROSARIO, SANTA FE",
  "AMBROSETTI, SAN CRISTOBAL, SANTA FE",
  "AMENABAR, GENERAL LOPEZ, SANTA FE",
  "ANDINO, IRIONDO, SANTA FE",
  "ANGEL GALLARDO, LA CAPITAL, SANTA FE",
  "ANGELICA, CASTELLANOS, SANTA FE",
  "ANTARTIDA ARGENTINA ,EST., ROSARIO, SANTA FE",
  "ANTONIO PINI, 9 DE JULIO, SANTA FE",
  "AREQUITO, CASEROS, SANTA FE",
  "ARIJON ,DV., SAN JERONIMO, SANTA FE",
  "ARMINDA, ROSARIO, SANTA FE",
  "ARMSTRONG, BELGRANO, SANTA FE",
  "AROCENA, SAN JERONIMO, SANTA FE",
  "AROMOS ,EST., CAPITAL, SANTA FE",
  "ARRASCAETA ,AP., SAN JUSTO, SANTA FE",
  "ARROYO AGUIAR ,EST., LA CAPITAL, SANTA FE",
  "ARROYO CEIBAL, GENERAL OBLIGADO, SANTA FE",
  "ARROYO DEL REY, GENERAL OBLIGADO, SANTA FE",
  "ARROYO LEYES, LA CAPITAL, SANTA FE",
  "ARRUFO, SAN CRISTOBAL, SANTA FE",
  "ARTEAGA, CASEROS, SANTA FE",
  "ASCOCHINGAS ,DV., CAPITAL, SANTA FE",
  "ATALIVA, CASTELLANOS, SANTA FE",
  "AURELIA, CASTELLANOS, SANTA FE",
  "AURELIA NORTE, CASTELLANOS, SANTA FE",
  "AURELIA SUR, CASTELLANOS, SANTA FE",
  "AVELLANEDA ,EST.EWALD, GENERAL OBLIGADO, SANTA FE",
  "BARRANCAS, SAN JERONIMO, SANTA FE",
  "BAUER Y SIGEL, CASTELLANOS, SANTA FE",
  "BERABEVU, CASEROS, SANTA FE",
  "BERNA, GENERAL OBLIGADO, SANTA FE",
  "BERNARDO DE IRIGOYEN, SAN JERONIMO, SANTA FE",
  "BIGAND, CASEROS, SANTA FE",
  "BOMBAL, CONSTITUCION, SANTA FE",
  "BOUQUET, BELGRANO, SANTA FE",
  "BUSTINZA, IRIONDO, SANTA FE",
  "CABAL, LA CAPITAL, SANTA FE",
  "CACIQUE ARIACAIQUIN, SAN JAVIER, SANTA FE",
  "CAFFERATA, GENERAL LOPEZ, SANTA FE",
  "CALCHAQUI, VERA, SANTA FE",
  "CAMPO GARAY, 9 DE JULIO, SANTA FE",
  "CAMPO REDONDO, GENERAL OBLIGADO, SANTA FE",
  "CAÑADA DE GOMEZ, IRIONDO, SANTA FE",
  "CAÑADA DEL UCLE, GENERAL LOPEZ, SANTA FE",
  "CAÑADA OMBU, VERA, SANTA FE",
  "CAÑADA RICA ,EST., CONSTITUCION, SANTA FE",
  "CAÑADA ROSQUIN, SAN MARTIN, SANTA FE",
  "CAPITAN BERMUDEZ, SAN LORENZO, SANTA FE",
  "CAPIVARA, SAN CRISTOBAL, SANTA FE",
  "CARAGUATAY, VERA, SANTA FE",
  "CARCARAÑA, SAN LORENZO, SANTA FE",
  "CARMEN, GENERAL LOPEZ, SANTA FE",
  "CARRERAS, GENERAL LOPEZ, SANTA FE",
  "CARRIZALES ,EST.CLARKE, IRIONDO, SANTA FE",
  "CASABLANCA ,EST., CASTELLANOS, SANTA FE",
  "CASALEGNO, SAN JERONIMO, SANTA FE",
  "CASAS, SAN MARTIN, SANTA FE",
  "CASILDA, CASEROS, SANTA FE",
  "CASTELAR, SAN MARTIN, SANTA FE",
  "CASTELLANOS, CASTELLANOS, SANTA FE",
  "CASTELLANOS, AARON ,EST.CASTELLANOS, GENERAL LOPEZ, SANTA FE",
  "CAVOUR, LAS COLONIAS, SANTA FE",
  "CAYASTA, GARAY, SANTA FE",
  "CAYASTACITO, SAN JUSTO, SANTA FE",
  "CENTENO, SAN JERONIMO, SANTA FE",
  "CERES, SAN CRISTOBAL, SANTA FE",
  "CERRITOS, VERA, SANTA FE",
  "CHABAS, CASEROS, SANTA FE",
  "CHAÑAR LADEADO, CASEROS, SANTA FE",
  "CHAPUY, GENERAL LOPEZ, SANTA FE",
  "CHATEAUBRIAND ,EST.MURPHY, GENERAL LOPEZ, SANTA FE",
  "CHOVET, GENERAL LOPEZ, SANTA FE",
  "CHRISTOPHERSEN, GENERAL LOPEZ, SANTA FE",
  "CLASON, IRIONDO, SANTA FE",
  "CLUCELLAS, CASTELLANOS, SANTA FE",
  "CLUCELLAS ,EST., CASTELLANOS, SANTA FE",
  "COLASTINE, LA CAPITAL, SANTA FE",
  "COLMENA, GENERAL OBLIGADO, SANTA FE",
  "COLONIA ALDAO, CASTELLANOS, SANTA FE",
  "COLONIA ANA, SAN CRISTOBAL, SANTA FE",
  "COLONIA ANGELONI, SAN JUSTO, SANTA FE",
  "COLONIA BELGRANO, SAN MARTIN, SANTA FE",
  "COLONIA BOSSI, SAN CRISTOBAL, SANTA FE",
  "COLONIA CELLO, CASTELLANOS, SANTA FE",
  "COLONIA DOLORES, SAN JUSTO, SANTA FE",
  "COLONIA DOLORES, SAN JUSTO, SANTA FE",
  "COLONIA EL TOBA, SAN JAVIER, SANTA FE",
  "COLONIA ELLA, GENERAL OBLIGADO, SANTA FE",
  "COLONIA LA CAMILA, SAN JUSTO, SANTA FE",
  "COLONIA MASCIAS, GARAY, SANTA FE",
  "COLONIA MEDICI, IRIONDO, SANTA FE",
  "COLONIA RAQUEL, CASTELLANOS, SANTA FE",
  "COLONIA ROSA, SAN CRISTOBAL, SANTA FE",
  "COLONIA SILVA ,EST.ABIPONES, SAN JUSTO, SANTA FE",
  "COLONIA TACURALES, CASTELLANOS, SANTA FE",
  "CONSTANZA, SAN CRISTOBAL, SANTA FE",
  "CONSTITUYENTES, LA CAPITAL, SANTA FE",
  "CORONDA, SAN JERONIMO, SANTA FE",
  "CORONEL ARNOLD, SAN LORENZO, SANTA FE",
  "CORONEL BOGADO, ROSARIO, SANTA FE",
  "CORONEL FRAGA, CASTELLANOS, SANTA FE",
  "CORONEL RODRIGUEZ, LAS COLONIAS, SANTA FE",
  "CORONEL ROSETTI, GENERAL LOPEZ, SANTA FE",
  "CORREA, IRIONDO, SANTA FE",
  "CRISPI, SAN MARTIN, SANTA FE",
  "CULLEN ,EST., SAN LORENZO, SANTA FE",
  "CULULU, LAS COLONIAS, SANTA FE",
  "CURUPAITY, SAN CRISTOBAL, SANTA FE",
  "DENIS, GREGORIA PEREZ DE ,EST.EL NOCHERO, 9 DE JULIO, SANTA FE",
  "DIAZ, SAN JERONIMO, SANTA FE",
  "DIEGO DE ALVEAR, GENERAL LOPEZ, SANTA FE",
  "DOCTOR BARROS PAZOS, GENERAL OBLIGADO, SANTA FE",
  "DURHAM ,AP., GENERAL LOPEZ, SANTA FE",
  "ECHEVERRIA, VICENTE A., SAN LORENZO, SANTA FE",
  "EDISON, TOMAS A., LAS COLONIAS, SANTA FE",
  "EGUSQUIZA, CASTELLANOS, SANTA FE",
  "EL BAYO, CASTELLANOS, SANTA FE",
  "EL CANTOR ,EST., GENERAL LOPEZ, SANTA FE",
  "EL JARDIN, GENERAL LOPEZ, SANTA FE",
  "EL LAUREL, GARAY, SANTA FE",
  "EL RABON, GENERAL OBLIGADO, SANTA FE",
  "EL SOMBRERITO ,EST.PAUL GROUSSAC, GENERAL OBLIGADO, SANTA FE",
  "EL TREBOL, SAN MARTIN, SANTA FE",
  "ELISA, LAS COLONIAS, SANTA FE",
  "ELORTONDO, GENERAL LOPEZ, SANTA FE",
  "EMILIA, LA CAPITAL, SANTA FE",
  "EMPALME VILLA CONSTITUCION, CONSTITUCION, SANTA FE",
  "ESCALADA, MARCELINO, SAN JUSTO, SANTA FE",
  "ESCALADA, MIGUEL, SAN JUSTO, SANTA FE",
  "ESMERALDA, CASTELLANOS, SANTA FE",
  "ESPERANZA, LAS COLONIAS, SANTA FE",
  "ESPIN, VERA, SANTA FE",
  "ESTHER, SAN JUSTO, SANTA FE",
  "ESTRADA, JOSE M., CASTELLANOS, SANTA FE",
  "EUSEBIA, CASTELLANOS, SANTA FE",
  "FELICIA, LAS COLONIAS, SANTA FE",
  "FIGHIERA, ROSARIO, SANTA FE",
  "FIRMAT, GENERAL LOPEZ, SANTA FE",
  "FLOR DE ORO, GENERAL OBLIGADO, SANTA FE",
  "FLORENCIA, GENERAL OBLIGADO, SANTA FE",
  "FLORIDA, VERA, SANTA FE",
  "FORTIN ATAHUALPA, 9 DE JULIO, SANTA FE",
  "FRANCK, LAS COLONIAS, SANTA FE",
  "FRAY LUIS BELTRAN ,EST.TTE.CNL.L.BELTRAN, SAN LORENZO, SANTA FE",
  "FRONTERA, CASTELLANOS, SANTA FE",
  "FUENTES, SAN LORENZO, SANTA FE",
  "FUNES, ROSARIO, SANTA FE",
  "GALISTEO, CASTELLANOS, SANTA FE",
  "GALLARDO, ANGEL ,EMB., LA CAPITAL, SANTA FE",
  "GALVEZ, SAN JERONIMO, SANTA FE",
  "GARABATO, VERA, SANTA FE",
  "GARIBALDI, CASTELLANOS, SANTA FE",
  "GENERAL GELLY, CONSTITUCION, SANTA FE",
  "GENERAL LAGOS, ROSARIO, SANTA FE",
  "GESSLER, SAN JERONIMO, SANTA FE",
  "GOBERNADOR CANDIOTI, LA CAPITAL, SANTA FE",
  "GOBERNADOR CRESPO, SAN JUSTO, SANTA FE",
  "GODEKEN, CASEROS, SANTA FE",
  "GODOY, CONSTITUCION, SANTA FE",
  "GOLONDRINA, VERA, SANTA FE",
  "GOMEZ CELLO, PEDRO, SAN JUSTO, SANTA FE",
  "GRANADERO BAIGORRIA, ROSARIO, SANTA FE",
  "GRANADERO BLAS BARGAS ,AP., ROSARIO, SANTA FE",
  "GRANADEROS ,PDA., SAN LORENZO, SANTA FE",
  "GRANEROS ,EST., ROSARIO, SANTA FE",
  "GRUTLY, LAS COLONIAS, SANTA FE",
  "GRUTLY NORTE, LAS COLONIAS, SANTA FE",
  "GUADALUPE ,EST., LA CAPITAL, SANTA FE",
  "GUADALUPE NORTE, GENERAL OBLIGADO, SANTA FE",
  "GUASUNCHO, GENERAL OBLIGADO, SANTA FE",
  "GUAYCURU, VERA, SANTA FE",
  "HANSEN, CASEROS, SANTA FE",
  "HELVECIA, GARAY, SANTA FE",
  "HERSILIA, SAN CRISTOBAL, SANTA FE",
  "HUANQUEROS, SAN CRISTOBAL, SANTA FE",
  "HUGENTOBLER, CASTELLANOS, SANTA FE",
  "HUGES, GENERAL LOPEZ, SANTA FE",
  "HUMBOLDT, LAS COLONIAS, SANTA FE",
  "HUME ,EST.EL GAUCHO, ROSARIO, SANTA FE",
  "IBARLUCEA, ROSARIO, SANTA FE",
  "INDEPENDENCIA, 9 DE JULIO, SANTA FE",
  "INGENIERO BOASI, LAS COLONIAS, SANTA FE",
  "INGENIERO CHANOURDIE, GENERAL OBLIGADO, SANTA FE",
  "INTIYACO, VERA, SANTA FE",
  "IRIGOYEN, SAN JERONIMO, SANTA FE",
  "IRIGOYEN, BERNARDO DE, SAN JERONIMO, SANTA FE",
  "IRIONDO, LA CAPITAL, SANTA FE",
  "ITURRASPE, BELGRANO, SANTA FE",
  "JOSEFINA, CASTELLANOS, SANTA FE",
  "JUNCAL, CONSTITUCION, SANTA FE",
  "KILOMETR 465 ,EST., SAN MARTIN, SANTA FE",
  "KILOMETRO 17 ,EMB., VERA, SANTA FE",
  "KILOMETRO 187 ,EMB., SAN JUSTO, SANTA FE",
  "KILOMETRO 213 ,EMB., VERA, SANTA FE",
  "KILOMETRO 320, VERA, SANTA FE",
  "KILOMETRO 392 ,EMB., VERA, SANTA FE",
  "KILOMETRO 403 ,EMB., GENERAL OBLIGADO, SANTA FE",
  "KILOMETRO 405 ,EST., BELGRANO, SANTA FE",
  "KILOMETRO 408 ,AP., GENERAL OBLIGADO, SANTA FE",
  "KILOMETRO 41 ,AP., LA CAPITAL, SANTA FE",
  "KILOMETRO 41 ,EMB., GENERAL OBLIGADO, SANTA FE",
  "KILOMETRO 443 ,EMB., SAN MARTIN, SANTA FE",
  "KILOMETRO 468 ,EMB., 9 DE JULIO, SANTA FE",
  "KILOMETRO 483 ,EMB., CASTELLANOS, SANTA FE",
  "KILOMETRO 501 ,EMB., CASTELLANOS, SANTA FE",
  "KILOMETRO 85 ,AP., CASTELLANOS, SANTA FE",
  "KILOMETRO 9 ,AP., LA CAPITAL, SANTA FE",
  "LA BLANCA CENTRAL, SAN JUSTO, SANTA FE",
  "LA BRAVA, SAN JAVIER, SANTA FE",
  "LA CABRAL, SAN CRISTOBAL, SANTA FE",
  "LA CALIFORNIA, BELGRANO, SANTA FE",
  "LA CHISPA, GENERAL LOPEZ, SANTA FE",
  "LA CRIOLLA ,EST.CAÑADITA, SAN JUSTO, SANTA FE",
  "LA GALLARETA, VERA, SANTA FE",
  "LA GUARDIA, LA CAPITAL, SANTA FE",
  "LA LUCILA ,EST.LUCILA, SAN CRISTOBAL, SANTA FE",
  "LA PELADA, LAS COLONIAS, SANTA FE",
  "LA PENCA Y CARAGUATA, SAN JUSTO, SANTA FE",
  "LA RESERVA, GENERAL OBLIGADO, SANTA FE",
  "LA RUBIA, SAN CRISTOBAL, SANTA FE",
  "LA VANGUARDIA, CONSTITUCION, SANTA FE",
  "LA ZULEMA, VERA, SANTA FE",
  "LABORDEBOY, GENERAL LOPEZ, SANTA FE",
  "LAGUNA PAIVA, LA CAPITAL, SANTA FE",
  "LANDETA, SAN MARTIN, SANTA FE",
  "LANTERI, GENERAL OBLIGADO, SANTA FE",
  "LARGUIA, IRIONDO, SANTA FE",
  "LARRECHEA, SAN JERONIMO, SANTA FE",
  "LAS AVISPAS, SAN CRISTOBAL, SANTA FE",
  "LAS BANDURRIAS, SAN MARTIN, SANTA FE",
  "LAS CAÑAS, GARAY, SANTA FE",
  "LAS FLORES ,EMB., LA CAPITAL, SANTA FE",
  "LAS GAMAS, VERA, SANTA FE",
  "LAS GRAZAS, GENERAL OBLIGADO, SANTA FE",
  "LAS PALMERAS, SAN CRISTOBAL, SANTA FE",
  "LAS PAREJAS, BELGRANO, SANTA FE",
  "LAS PETACAS, SAN MARTIN, SANTA FE",
  "LAS ROSAS, BELGRANO, SANTA FE",
  "LAS TOSCAS, GENERAL OBLIGADO, SANTA FE",
  "LAS TROJAS ,EST., IRIONDO, SANTA FE",
  "LAS TUNAS, LAS COLONIAS, SANTA FE",
  "LAZZARINO, GENERAL LOPEZ, SANTA FE",
  "LEHMANN, CASTELLANOS, SANTA FE",
  "LEIVA, LUCIANO, SAN JUSTO, SANTA FE",
  "LLAMBI CAMPBELL, LA CAPITAL, SANTA FE",
  "LOGROÑO, 9 DE JULIO, SANTA FE",
  "LOMA ALTA, SAN JERONIMO, SANTA FE",
  "LOPEZ ,EST.SAN MARTIN DE TOURS, SAN JERONIMO, SANTA FE",
  "LOPEZ, LUCIO V., IRIONDO, SANTA FE",
  "LOS AMORES, VERA, SANTA FE",
  "LOS CARDOS, SAN MARTIN, SANTA FE",
  "LOS CERRILLOS, GARAY, SANTA FE",
  "LOS CHARABONES ,EMB., 9 DE JULIO, SANTA FE",
  "LOS MOLINOS, CASEROS, SANTA FE",
  "LOS NOGALES, CASEROS, SANTA FE",
  "LOS QUIRQUINCHOS, CASEROS, SANTA FE",
  "LOS SEMBRADOS, CASTELLANOS, SANTA FE",
  "LOS TABANOS ,EMB., VERA, SANTA FE",
  "LUDUEÑA ,EST., ROSARIO, SANTA FE",
  "MACIEL, SAN JERONIMO, SANTA FE",
  "MAGGIOLO, GENERAL LOPEZ, SANTA FE",
  "MALABRIGO, GENERAL OBLIGADO, SANTA FE",
  "MANUCHO, LA CAPITAL, SANTA FE",
  "MARGARITA, VERA, SANTA FE",
  "MARIA EUGENIA, SAN CRISTOBAL, SANTA FE",
  "MARIA JUANA, CASTELLANOS, SANTA FE",
  "MARIA SUSANA, SAN MARTIN, SANTA FE",
  "MARIA TERESA, GENERAL LOPEZ, SANTA FE",
  "MATILDE, LAS COLONIAS, SANTA FE",
  "MAUA, CASTELLANOS, SANTA FE",
  "MELINCUE, GENERAL LOPEZ, SANTA FE",
  "MERCEDITAS, GENERAL LOPEZ, SANTA FE",
  "MOCOVI, GENERAL OBLIGADO, SANTA FE",
  "MOLINA, JUAN B., CONSTITUCION, SANTA FE",
  "MOLINAS, NICANOR E., GENERAL OBLIGADO, SANTA FE",
  "MONIGOTES, SAN CRISTOBAL, SANTA FE",
  "MONJE, SAN JERONIMO, SANTA FE",
  "MONTE FLORES, ROSARIO, SANTA FE",
  "MONTE VERA, LA CAPITAL, SANTA FE",
  "MONTEFIORE, 9 DE JULIO, SANTA FE",
  "MONTES DE OCA, BELGRANO, SANTA FE",
  "MOUSSY, GENERAL OBLIGADO, SANTA FE",
  "ÑANDUCITA, SAN CRISTOBAL, SANTA FE",
  "NARE, SAN JUSTO, SANTA FE",
  "NELSON, LA CAPITAL, SANTA FE",
  "NUEVA ITALIA, 9 DE JULIO, SANTA FE",
  "NUEVA LEHMANN, CASTELLANOS, SANTA FE",
  "NUEVO ALBERDI ,EST., ROSARIO, SANTA FE",
  "NUEVO TORINO, LAS COLINAS, SANTA FE",
  "OGILVIE, VERA, SANTA FE",
  "OLIVEROS, IRIONDO, SANTA FE",
  "OMBU, LAS COLONIAS, SANTA FE",
  "OROÑO, SAN JERONIMO, SANTA FE",
  "PALACIOS, SAN CRISTOBAL, SANTA FE",
  "PALACIOS, LUIS ,EST.LA SALADA, SAN LORENZO, SANTA FE",
  "PAVON, CONSTITUCION, SANTA FE",
  "PAVON ARRIBA, CONSTITUCION, SANTA FE",
  "PAZ, FRANCISCO, CONSTITUCION, SANTA FE",
  "PAZ, MAXIMO ,EST.PAZ, CONSTITUCION, SANTA FE",
  "PELLEGRINI, CARLOS, SAN MARTIN, SANTA FE",
  "PEREYRA, ZENON, CASTELLANOS, SANTA FE",
  "PEREZ, ROSARIO, SANTA FE",
  "PEYRANO, CONSTITUCION, SANTA FE",
  "PIAMONTE, SAN MARTIN, SANTA FE",
  "PILAR, LAS COLONIAS, SANTA FE",
  "PINI, ANTONIO, 9 DE JULIO, SANTA FE",
  "PINO DE SAN LORENZO ,EST., SAN LORENZO, SANTA FE",
  "PIQUETE, LA CAPITAL, SANTA FE",
  "POMPEYA ,AP., LA CAPITAL, SANTA FE",
  "PORTUGALETTE, SAN CRISTOBAL, SANTA FE",
  "POZO BORRADO, 9 DE JULIO, SANTA FE",
  "PRESIDENTE ROCA, CASTELLANOS, SANTA FE",
  "PROGRESO, LAS COLONIAS, SANTA FE",
  "PROVIDENCIA, LAS COLONIAS, SANTA FE",
  "PUEBLO ANDINO, IRIONDO, SANTA FE",
  "PUEBLO MARINI, CASTELLANOS, SANTA FE",
  "PUEBLO MUÑOZ ,EST.BERNARD, ROSARIO, SANTA FE",
  "PUEBLO NUEVO, ROSARIO, SANTA FE",
  "PUERTO GABOTO, SAN JERONIMO, SANTA FE",
  "PUERTO GENERAL SAN MARTIN, SAN LORENZO, SANTA FE",
  "PUERTO OCAMPO, GENERAL OBLIGADO, SANTA FE",
  "PUERTO PIRACUACITO, GENERAL OBLIGADO, SANTA FE",
  "PUERTO RECONQUISTA, GENERAL OBLIGADO, SANTA FE",
  "PUERTO SAN LORENZO, SAN LORENZO, SANTA FE",
  "PUJATO, SAN LORENZO, SANTA FE",
  "RAFAELA, CASTELLANOS, SANTA FE",
  "RAMAYON, SAN JUSTO, SANTA FE",
  "RAMONA, CASTELLANOS, SANTA FE",
  "RAMS, ESTEBAN, 9 DE JULIO, SANTA FE",
  "RASTREADOR FOURNIER ,EST., GENERAL LOPEZ, SANTA FE",
  "RECONQUISTA, GENERAL OBLIGADO, SANTA FE",
  "RECREO, LA CAPITAL, SANTA FE",
  "RICARDONE, SAN LORENZO, SANTA FE",
  "RINCON DEL POTRERO, LA CAPITAL, SANTA FE",
  "RINCON DEL QUEBRACHO ,EMB., SAN CRISTOBAL, SANTA FE",
  "ROLDAN, SAN LORENZO, SANTA FE",
  "ROMANG, SAN JAVIER, SANTA FE",
  "ROSARIO, ROSARIO, SANTA FE",
  "RUEDA, CONSTITUCION, SANTA FE",
  "RUFINO, GENERAL LOPEZ, SANTA FE",
  "RUNCIMAN, GENERAL LOPEZ, SANTA FE",
  "SA PEREYRA, LAS COLONIAS, SANTA FE",
  "SAAVEDRA, MARIANO, LAS COLONIAS, SANTA FE",
  "SAGUIER, CASTELLANOS, SANTA FE",
  "SALADERO M.CABAL, GARAY, SANTA FE",
  "SALTO GRANDE, IRIONDO, SANTA FE",
  "SAN ANTONIO, CASTELLANOS, SANTA FE",
  "SAN ANTONIO DE OBLIGADO, GENERAL OBLIGADO, SANTA FE",
  "SAN BERNARDO, SAN JUSTO, SANTA FE",
  "SAN BERNARDO, 9 DE JULIO, SANTA FE",
  "SAN CARLOS ,EMP., LAS COLONIAS, SANTA FE",
  "SAN CARLOS CENTRO, LAS COLONIAS, SANTA FE",
  "SAN CARLOS NORTE, LAS COLONIAS, SANTA FE",
  "SAN CARLOS SUR, LAS COLONIAS, SANTA FE",
  "SAN CRISTOBAL, SAN CRISTOBAL, SANTA FE",
  "SAN EDUARDO, GENERAL LOPEZ, SANTA FE",
  "SAN ESTANISLAO, IRIONDO, SANTA FE",
  "SAN EUGENIO, SAN JERONIMO, SANTA FE",
  "SAN FABIAN, SAN JERONIMO, SANTA FE",
  "SAN FRANCISCO DE SANTA FE, GENERAL LOPEZ, SANTA FE",
  "SAN GENARO, SAN JERONIMO, SANTA FE",
  "SAN GENARO NORTE, SAN JERONIMO, SANTA FE",
  "SAN GREGORIO, GENERAL LOPEZ, SANTA FE",
  "SAN GUILLERMO, SAN CRISTOBAL, SANTA FE",
  "SAN JAVIER, SAN JAVIER, SANTA FE",
  "SAN JERONIMO DEL SAUCE, LAS COLONIAS, SANTA FE",
  "SAN JERONIMO NORTE, LAS COLONIAS, SANTA FE",
  "SAN JERONIMO SUR, SAN LORENZO, SANTA FE",
  "SAN JORGE, SAN MARTIN, SANTA FE",
  "SAN JOSE DE LA ESQUINA, CASEROS, SANTA FE",
  "SAN JOSE DEL RINCON, LA CAPITAL, SANTA FE",
  "SAN JUSTO, SAN JUSTO, SANTA FE",
  "SAN LORENZO, SAN LORENZO, SANTA FE",
  "SAN MARTIN DE LAS ESCOBAS, SAN MARTIN, SANTA FE",
  "SAN MARTIN NORTE, SAN JUSTO, SANTA FE",
  "SAN PEDRO, LA CAPITAL, SANTA FE",
  "SAN RICARDO, IRIONDO, SANTA FE",
  "SAN VICENTE, CASTELLANOS, SANTA FE",
  "SANCTI SPIRITU, GENERAL LOPEZ, SANTA FE",
  "SANFORD, CASEROS, SANTA FE",
  "SANTA CLARA DE BUENA VISTA, LAS COLONIAS, SANTA FE",
  "SANTA CLARA DE SAGUIER, CASTELLANOS, SANTA FE",
  "SANTA EMILIA, GENERAL LOPEZ, SANTA FE",
  "SANTA FE, LA CAPITAL, SANTA FE",
  "SANTA ISABEL, GENERAL LOPEZ, SANTA FE",
  "SANTA LUCIA, VERA, SANTA FE",
  "SANTA MARGARITA, 9 DE JULIO, SANTA FE",
  "SANTA ROSA, GARAY, SANTA FE",
  "SANTA TERESA, CONSTITUCION, SANTA FE",
  "SANTO DOMINGO, LAS COLONIAS, SANTA FE",
  "SANTO TOME, LA CAPITAL, SANTA FE",
  "SANTURCE, SAN CRISTOBAL, SANTA FE",
  "SARGENTO CABRAL, CONSTITUCION, SANTA FE",
  "SARMIENTO, LAS COLONIAS, SANTA FE",
  "SASTRE, SAN MARTIN, SANTA FE",
  "SAUCE VIEJO, LA CAPITAL, SANTA FE",
  "SCHIFFNER, SAN MARTIN, SANTA FE",
  "SERODINO, IRIONDO, SANTA FE",
  "SOLDINI, ROSARIO, SANTA FE",
  "SOLEDAD, SAN CRISTOBAL, SANTA FE",
  "SORRENTO ,EST., ROSARIO, SANTA FE",
  "SOUTOMAYOR, LAS COLONIAS, SANTA FE",
  "STEPHENSON ,EST., CONSTITUCION, SANTA FE",
  "SUARDI, SAN CRISTOBAL, SANTA FE",
  "SUNCHALES, CASTELLANOS, SANTA FE",
  "SUSANA, CASTELLANOS, SANTA FE",
  "TACUARENDI ,EMB.KILOMETRO 421, GENERAL OBLIGADO, SANTA FE",
  "TACURAL, CASTELLANOS, SANTA FE",
  "TARRAGONA, GENERAL LOPEZ, SANTA FE",
  "TARTAGAL ,EST.EL TAJAMAR, VERA, SANTA FE",
  "TEODELINA, GENERAL LOPEZ, SANTA FE",
  "TEODELINA ,EST., GENERAL LOPEZ, SANTA FE",
  "THEOBALD, CONSTITUCION, SANTA FE",
  "TIMBUES, SAN LORENZO, SANTA FE",
  "TOBA, VERA, SANTA FE",
  "TORRES, MIGUEL, GENERAL LOPEZ, SANTA FE",
  "TORTUGAS, BELGRANO, SANTA FE",
  "TOSTADO, 9 DE JULIO, SANTA FE",
  "TOTORAS, IRIONDO, SANTA FE",
  "TRAILL, SAN MARTIN, SANTA FE",
  "UMBERTO, CASTELLANOS, SANTA FE",
  "URANGA, ROSARIO, SANTA FE",
  "VENADO TUERTO, GENERAL LOPEZ, SANTA FE",
  "VERA ,EST.GOBERNADOR VERA, VERA, SANTA FE",
  "VERA MUJICA, SAN JUSTO, SANTA FE",
  "VERA Y PINTADO ,EST.GUARANIES, SAN JUSTO, SANTA FE",
  "VIDELA, SAN JUSTO, SANTA FE",
  "VILA, CASTELLANOS, SANTA FE",
  "VILLA AMELIA, ROSARIO, SANTA FE",
  "VILLA AMERICA, ROSARIO, SANTA FE",
  "VILLA ANA, GENERAL OBLIGADO, SANTA FE",
  "VILLA CAÑAS, GENERAL LOPEZ, SANTA FE",
  "VILLA CONSTITUCION, CONSTITUCION, SANTA FE",
  "VILLA DIEGO, ROSARIO, SANTA FE",
  "VILLA ELOISA, IRIONDO, SANTA FE",
  "VILLA ESTELA, GENERAL LOPEZ, SANTA FE",
  "VILLA GOBERNADOR GALVEZ, ROSARIO, SANTA FE",
  "VILLA GUILLERMINA, GENERAL OBLIGADO, SANTA FE",
  "VILLA MARGARITA, SAN LORENZO, SANTA FE",
  "VILLA MINETTI, 9 DE JULIO, SANTA FE",
  "VILLA MUGUETA, SAN LORENZO, SANTA FE",
  "VILLA OCAMPO, GENERAL OBLIGADO, SANTA FE",
  "VILLA SARALEGUI, SAN CRISTOBAL, SANTA FE",
  "VILLA TRINIDAD, SAN CRISTOBAL, SANTA FE",
  "VILLADA, CASEROS, SANTA FE",
  "VILLE, MOISES, SAN CRISTOBAL, SANTA FE",
  "VIRGINIA, CASTELLANOS, SANTA FE",
  "VUELTA DEL PARAGUAYO, LA CAPITAL, SANTA FE",
  "WHEELWRIGHT, GENERAL LOPEZ, SANTA FE",
  "WILDERMUTH ,EST.GRANADERO B.BUSTOS, SAN MARTIN, SANTA FE",
  "ZAVALLA, ROSARIO, SANTA FE",
  "ABRA GRANDE, BANDA, SANTIAGO DEL ESTERO",
  "AEROLITO, MORENO, SANTIAGO DEL ESTERO",
  "AGUSTINA LIBARONA, ALBERDI, SANTIAGO DEL ESTERO",
  "AHI VEREMOS, PELLEGRINI, SANTIAGO DEL ESTERO",
  "ALGARROBAL VIEJO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "ALHUAMPA, MORENO, SANTIAGO DEL ESTERO",
  "AMAMA, MORENO, SANTIAGO DEL ESTERO",
  "AMBARGASTA, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "AMICHA, RIO HONDO, SANTIAGO DEL ESTERO",
  "AÑATUYA, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "ANCAJAN, CHOYA, SANTIAGO DEL ESTERO",
  "ANIMAN, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "ANTAJE, BANDA, SANTIAGO DEL ESTERO",
  "ANTONIO E. TALBOT ,AP., CAPITAL, SANTIAGO DEL ESTERO",
  "ARAGONES, RIO HONDO, SANTIAGO DEL ESTERO",
  "ARBOL BLANCO, MORENO, SANTIAGO DEL ESTERO",
  "ARDILES, BANDA, SANTIAGO DEL ESTERO",
  "ARGENTINA, AGUIRRE, SANTIAGO DEL ESTERO",
  "ARMONIA, MORENO, SANTIAGO DEL ESTERO",
  "ARRAGA, SILIPICA, SANTIAGO DEL ESTERO",
  "AVERIAS, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "BAEZ, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "BAJO GRANDE, ALBERDI, SANTIAGO DEL ESTERO",
  "BANDERA, BELGRANO, SANTIAGO DEL ESTERO",
  "BANDERA BAJADA, FIGUEROA, SANTIAGO DEL ESTERO",
  "BARRANCAS, SALAVINA, SANTIAGO DEL ESTERO",
  "BARRIALITO, RIO HONDO, SANTIAGO DEL ESTERO",
  "BELGRANO, QUEBRACHOS, SANTIAGO DEL ESTERO",
  "BELGRANO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "BELTRAN, ROBLES, SANTIAGO DEL ESTERO",
  "BLANCA POZO, AVELLANEDA, SANTIAGO DEL ESTERO",
  "BOBADAL, JIMENEZ, SANTIAGO DEL ESTERO",
  "BOQUERON, ATAMISQUI, SANTIAGO DEL ESTERO",
  "BREA POZO, SAN MARTIN, SANTIAGO DEL ESTERO",
  "CAJON, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "CAMPO DEL CIELO, MATARA, SANTIAGO DEL ESTERO",
  "CAMPO GALLO, ALBERDI, SANTIAGO DEL ESTERO",
  "CAMPO GRANDE, PELLEGRINI, SANTIAGO DEL ESTERO",
  "CAÑADA ESCOBAR, BANDA, SANTIAGO DEL ESTERO",
  "CARTAVIO, FIGUEROA, SANTIAGO DEL ESTERO",
  "CASARES, AGUIRRE, SANTIAGO DEL ESTERO",
  "CEJOLAO, MORENO, SANTIAGO DEL ESTERO",
  "CHAUPI POZO, BANDA, SANTIAGO DEL ESTERO",
  "CHILCA JULIANA, SALAVINA, SANTIAGO DEL ESTERO",
  "CHILCA LA LOMA, ATAMISQUI, SANTIAGO DEL ESTERO",
  "CHOYA, CHOYA, SANTIAGO DEL ESTERO",
  "CLODOMIRA, BANDA, SANTIAGO DEL ESTERO",
  "COLONIA ARBOL BLANCO, MORENO, SANTIAGO DEL ESTERO",
  "COLONIA DORA, AVELLANEDA, SANTIAGO DEL ESTERO",
  "COLONIA SAN JUAN, FIGUEROA, SANTIAGO DEL ESTERO",
  "COLONIA TOTORILLAS, FIGUEROA, SANTIAGO DEL ESTERO",
  "CORONEL MANUEL L.RICO, ALBERDI, SANTIAGO DEL ESTERO",
  "CUATRO BOCAS, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "CUCHI CORRAL, QUEBRACHOS, SANTIAGO DEL ESTERO",
  "DOÑA LUISA, GUASAYAN, SANTIAGO DEL ESTERO",
  "DONADEU, ALBERDI, SANTIAGO DEL ESTERO",
  "EL CABURE, COPO, SANTIAGO DEL ESTERO",
  "EL CAMBIADO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "EL CEIBAL, COPO, SANTIAGO DEL ESTERO",
  "EL CERRO, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "EL CHARCO, RIO HONDO, SANTIAGO DEL ESTERO",
  "EL COLORADO, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "EL HOYO, MORENO, SANTIAGO DEL ESTERO",
  "EL MALACARA, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "EL MOJON, PELLEGRINI, SANTIAGO DEL ESTERO",
  "EL PERTIGO, MORENO, SANTIAGO DEL ESTERO",
  "EL POLEAR, BANDA, SANTIAGO DEL ESTERO",
  "EL PORVENIR, ALBERDI, SANTIAGO DEL ESTERO",
  "EL PUESTITO, CAPITAL, SANTIAGO DEL ESTERO",
  "EL SETENTA, ALBERDI, SANTIAGO DEL ESTERO",
  "EL TOBIANO ,EMB., GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "EL ZANJON, CAPITAL, SANTIAGO DEL ESTERO",
  "FERNANDEZ, DOBLES, SANTIAGO DEL ESTERO",
  "FORRES, ROBLES, SANTIAGO DEL ESTERO",
  "FORTIN INCA, BELGRANO, SANTIAGO DEL ESTERO",
  "FRIAS, CHOYA, SANTIAGO DEL ESTERO",
  "GARZA, SARMIENTO, SANTIAGO DEL ESTERO",
  "GIRARDET, MORENO, SANTIAGO DEL ESTERO",
  "GRAMILLA, RIO HONDO, SANTIAGO DEL ESTERO",
  "GRANADERO GATICA, MORENO, SANTIAGO DEL ESTERO",
  "GUAMPACHO, GUASAYAN, SANTIAGO DEL ESTERO",
  "GUARDIA ESCOLTA, BELGRANO, SANTIAGO DEL ESTERO",
  "HAASE, MORENO, SANTIAGO DEL ESTERO",
  "HERRERA, AVELLANEDA, SANTIAGO DEL ESTERO",
  "HOYON, ATAMISQUI, SANTIAGO DEL ESTERO",
  "HUACHANA, ALBERDI, SANTIAGO DEL ESTERO",
  "HUAICO HONDO, CAPITAL, SANTIAGO DEL ESTERO",
  "HURITU HUASI, FIGUEROA, SANTIAGO DEL ESTERO",
  "HUYAMAMPA, BANDA, SANTIAGO DEL ESTERO",
  "ICAÑO, AVELLANEDA, SANTIAGO DEL ESTERO",
  "INGENIERO CARLOS CHRISTIERNSON ,EMB., QUEBRACHOS, SANTIAGO DEL ESTERO",
  "ISCA YACU, JIMENEZ, SANTIAGO DEL ESTERO",
  "KILOMETR0 80 ,DV., OJO DE AGUA, SANTIAGO DEL ESTERO",
  "KILOMETR0699 ,EMP., MORENO, SANTIAGO DEL ESTERO",
  "KILOMETRO 101 ,PDA., OJO DE AGUA, SANTIAGO DEL ESTERO",
  "KILOMETRO 1362 ,EMB., ALBERDI, SANTIAGO DEL ESTERO",
  "KILOMETRO 1380 ,EMB., ALBERDI, SANTIAGO DEL ESTERO",
  "KILOMETRO 1391 ,EMB., ALBERDI, SANTIAGO DEL ESTERO",
  "KILOMETRO 153 ,AP., CAPITAL, SANTIAGO DEL ESTERO",
  "KILOMETRO 18 ,EMB., CHOYA, SANTIAGO DEL ESTERO",
  "KILOMETRO 390 ,DV., SALAVINA, SANTIAGO DEL ESTERO",
  "KILOMETRO 433 ,EMB., GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "KILOMETRO 436 ,DV., ATAMISQUI, SANTIAGO DEL ESTERO",
  "KILOMETRO 454 ,AP., GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "KILOMETRO 473 ,AP., LORETO, SANTIAGO DEL ESTERO",
  "KILOMETRO 494, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "KILOMETRO 499 ,AP., BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "KILOMETRO 546 ,AP., BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "KILOMETRO 546 ,AP., BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "KILOMETRO 55 ,AP., CHOYA, SANTIAGO DEL ESTERO",
  "KILOMETRO 606 ,AP., MORENO, SANTIAGO DEL ESTERO",
  "KILOMETRO 613 ,AP., FIGUEROA, SANTIAGO DEL ESTERO",
  "KILOMETRO 645 ,AP., BANDA, SANTIAGO DEL ESTERO",
  "KILOMETRO 659 ,AP., BANDA, SANTIAGO DEL ESTERO",
  "KILOMETRO 969 ,EMB., CHOYA, SANTIAGO DEL ESTERO",
  "LA ALOJA ,EMB., PELLEGRINI, SANTIAGO DEL ESTERO",
  "LA AURORA, BANDA, SANTIAGO DEL ESTERO",
  "LA BANDA, BANDA, SANTIAGO DEL ESTERO",
  "LA CAÑADA, FIGUEROA, SANTIAGO DEL ESTERO",
  "LA DONOSA, RIO HONDO, SANTIAGO DEL ESTERO",
  "LA FIRMEZA, COPO, SANTIAGO DEL ESTERO",
  "LA FRAGUA, PELLEGRINI, SANTIAGO DEL ESTERO",
  "LA INVERNADA, FIGUEROA, SANTIAGO DEL ESTERO",
  "LA ISLA, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "LA NENA, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "LA PALOMA ,EMB., MORENO, SANTIAGO DEL ESTERO",
  "LA PUNTA, CHOYA, SANTIAGO DEL ESTERO",
  "LA TAPA, FIGUEROA, SANTIAGO DEL ESTERO",
  "LAPRIDA, CHOYA, SANTIAGO DEL ESTERO",
  "LAS ABRAS, MITRE, SANTIAGO DEL ESTERO",
  "LAS CARPAS, ALBERDI, SANTIAGO DEL ESTERO",
  "LAS FLORES, CAPITAL, SANTIAGO DEL ESTERO",
  "LAS LOMITAS BLANCAS, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "LAS TINAJAS, MORENO, SANTIAGO DEL ESTERO",
  "LAVALLE, GUASAYAN, SANTIAGO DEL ESTERO",
  "LEZCANOS, RIO HONDO, SANTIAGO DEL ESTERO",
  "LIBARONA, AGUSTINA, ALBERDI, SANTIAGO DEL ESTERO",
  "LIBERTAD, MORENO, SANTIAGO DEL ESTERO",
  "LILO VIEJO, MORENO, SANTIAGO DEL ESTERO",
  "LLAJTA MAUCA, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "LOS JURIES, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "LOS LINARES, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "LOS MILAGROS, MORENO, SANTIAGO DEL ESTERO",
  "LOS NARANJOS, BANDA, SANTIAGO DEL ESTERO",
  "LOS NUÑEZ, RIO HONDO, SANTIAGO DEL ESTERO",
  "LOS PIRPINTOS, COPO, SANTIAGO DEL ESTERO",
  "LOS QUIROGA, BANDA, SANTIAGO DEL ESTERO",
  "LOS TELARES, SALAVINA, SANTIAGO DEL ESTERO",
  "LOS TIGRES, COPO, SANTIAGO DEL ESTERO",
  "LUGONES, AVELLANEDA, SANTIAGO DEL ESTERO",
  "MACO, CAPITAL, SANTIAGO DEL ESTERO",
  "MAILIN, AVELLANEDA, SANTIAGO DEL ESTERO",
  "MAL PASO, CAPITAL, SANTIAGO DEL ESTERO",
  "MALBRAN, AGUIRRE, SANTIAGO DEL ESTERO",
  "MATARA, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "MEDELLIN, ATAMISQUI, SANTIAGO DEL ESTERO",
  "MEDIA LUNA, MITRE, SANTIAGO DEL ESTERO",
  "MELERO, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "MIRAVAL, HERNAN M., MORENO, SANTIAGO DEL ESTERO",
  "MISTOL PAMPA ,AP., MORENO, SANTIAGO DEL ESTERO",
  "MONTE ALTO ,AP., MORENO, SANTIAGO DEL ESTERO",
  "MONTE QUEMADO, COPO, SANTIAGO DEL ESTERO",
  "MORALES, CAPITAL, SANTIAGO DEL ESTERO",
  "NASALO, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "NUEVA ESPERANZA, PELLEGRINI, SANTIAGO DEL ESTERO",
  "NUEVA FRANCIA, SILIPICA, SANTIAGO DEL ESTERO",
  "NUEVO SIMBOLAR, PELLEGRINI, SANTIAGO DEL ESTERO",
  "OTUMPA, MORENO, SANTIAGO DEL ESTERO",
  "PALO NEGRO, RIVADAVIA, SANTIAGO DEL ESTERO",
  "PAMPA DE LOS GUANACOS, COPO, SANTIAGO DEL ESTERO",
  "PAMPA MAYO, BANDA, SANTIAGO DEL ESTERO",
  "PAMPA MUYOJ ,EMB., MORENO, SANTIAGO DEL ESTERO",
  "PAMPA POZO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "PASO DE LOS OSCARES, MITRE, SANTIAGO DEL ESTERO",
  "PATAY, MORENO, SANTIAGO DEL ESTERO",
  "PIEDRAS BLANCAS, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "POZO BETBEDER, PELLEGRINI, SANTIAGO DEL ESTERO",
  "POZO DEL CASTAÑO, FIGUEROA, SANTIAGO DEL ESTERO",
  "POZO DEL TOBA, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "POZO DULCE, BELGRANO, SANTIAGO DEL ESTERO",
  "POZO HONDO, JIMENEZ, SANTIAGO DEL ESTERO",
  "POZO HUASCHO, GUASAYAN, SANTIAGO DEL ESTERO",
  "POZUELOS, RIO HONDO, SANTIAGO DEL ESTERO",
  "PROVIDENCIA, LORETO, SANTIAGO DEL ESTERO",
  "PUNA, MORENO, SANTIAGO DEL ESTERO",
  "PUNI TAJO ,DV., GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "QUEBRACHITOS, AGUIRRE, SANTIAGO DEL ESTERO",
  "QUEBRACHO COTO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "QUILUMPA, MORENO, SANTIAGO DEL ESTERO",
  "QUIMILI, MORENO, SANTIAGO DEL ESTERO",
  "RAMIREZ DE VELAZCO, QUEBRACHOS, SANTIAGO DEL ESTERO",
  "RAPELLI, PELLEGRINI, SANTIAGO DEL ESTERO",
  "REAL SAYANA, AVELLANEDA, SANTIAGO DEL ESTERO",
  "ROBLES, SAN MARTIN, SANTIAGO DEL ESTERO",
  "ROVERSI, MORENO, SANTIAGO DEL ESTERO",
  "SABAGASTA, SALAVINA, SANTIAGO DEL ESTERO",
  "SACHAYOJ, ALBERDI, SANTIAGO DEL ESTERO",
  "SALAVINA, SALAVINA, SANTIAGO DEL ESTERO",
  "SAN GREGORIO, JIMENEZ, SANTIAGO DEL ESTERO",
  "SAN GREGORIO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "SAN PABLO, FIGUEROA, SANTIAGO DEL ESTERO",
  "SAN PEDRO, GUASAYAN, SANTIAGO DEL ESTERO",
  "SAN RAMON, BANDA, SANTIAGO DEL ESTERO",
  "SAN RAMON, PELLEGRINI, SANTIAGO DEL ESTERO",
  "SANTA BARBARA, LORETO, SANTIAGO DEL ESTERO",
  "SANTA CATALINA, GUASAYAN, SANTIAGO DEL ESTERO",
  "SANTA CRUZ, CHOYA, SANTIAGO DEL ESTERO",
  "SANTA JUSTINA, MORENO, SANTIAGO DEL ESTERO",
  "SANTA MARIA, COPO, SANTIAGO DEL ESTERO",
  "SANTA MARIA ,EST.INGENIERO EZCURRA, CAPITAL, SANTIAGO DEL ESTERO",
  "SANTA MARIA SALOME, ALBERDI, SANTIAGO DEL ESTERO",
  "SANTIAGO DEL ESTERO, CAPITAL, SANTIAGO DEL ESTERO",
  "SANTO DOMINGO, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "SANTO DOMINGO, PELLEGRINI, SANTIAGO DEL ESTERO",
  "SANTOS LUGARES, ALBERDI, SANTIAGO DEL ESTERO",
  "SELVA, RIVADAVIA, SANTIAGO DEL ESTERO",
  "SEÑORA PUJIO, BANDA, SANTIAGO DEL ESTERO",
  "SIETE ARBOLES, PELLEGRINI, SANTIAGO DEL ESTERO",
  "SIMBOL, SILIPICA, SANTIAGO DEL ESTERO",
  "SIMBOL BAJO ,EMB., GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "SIMBOLAR, BANDA, SANTIAGO DEL ESTERO",
  "SOL DE JULIO, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "SOL DE MAYO, CHOYA, SANTIAGO DEL ESTERO",
  "SUMAMAO, SILIPICA, SANTIAGO DEL ESTERO",
  "SUMAMPA, QUEBRACHOS, SANTIAGO DEL ESTERO",
  "SUNCHO CORRAL, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "SURIHUAYA ,AP., MORENO, SANTIAGO DEL ESTERO",
  "TABOADA, SARMIENTO, SANTIAGO DEL ESTERO",
  "TACANITAS, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "TACO POZO, ALBERDI, SANTIAGO DEL ESTERO",
  "TERMAS DE RIO HONDO, RIO HONDO, SANTIAGO DEL ESTERO",
  "TINTINA, MORENO, SANTIAGO DEL ESTERO",
  "TIUN PUNCO, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "TOBAS, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "TRES MOJONES, MORENO, SANTIAGO DEL ESTERO",
  "TRES POZOS, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "URUTAU, COPO, SANTIAGO DEL ESTERO",
  "VACA HUANUNA, FIGUEROA, SANTIAGO DEL ESTERO",
  "VILELAS, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "VILLA ADELA, CHOYA, SANTIAGO DEL ESTERO",
  "VILLA ATAMISQUI, ATAMISQUI, SANTIAGO DEL ESTERO",
  "VILLA BRAÑA, MORENO, SANTIAGO DEL ESTERO",
  "VILLA ELVIRA, CHOYA, SANTIAGO DEL ESTERO",
  "VILLA FIGUEROA, FIGUEROA, SANTIAGO DEL ESTERO",
  "VILLA GENERAL MITRE ,EST.PINTO, AGUIRRE, SANTIAGO DEL ESTERO",
  "VILLA GUASAYAN, GUASAYAN, SANTIAGO DEL ESTERO",
  "VILLA JIMENEZ, RIO HONDO, SANTIAGO DEL ESTERO",
  "VILLA LA PUNTA, CHOYA, SANTIAGO DEL ESTERO",
  "VILLA MATARA, SARMIENTO, SANTIAGO DEL ESTERO",
  "VILLA MATILDE, MORENO, SANTIAGO DEL ESTERO",
  "VILLA MATOQUE, COPO, SANTIAGO DEL ESTERO",
  "VILLA MERCEDES, PELLEGRINI, SANTIAGO DEL ESTERO",
  "VILLA OJO DE AGUA, OJO DE AGUA, SANTIAGO DEL ESTERO",
  "VILLA PALMAR, ALBERDI, SANTIAGO DEL ESTERO",
  "VILLA QUEBRACHOS, QUEBRACHOS, SANTIAGO DEL ESTERO",
  "VILLA RIO HONDO, RIO HONDO, SANTIAGO DEL ESTERO",
  "VILLA SAN MARTIN ,EST.LORETO, LORETO, SANTIAGO DEL ESTERO",
  "VILLA SILIPICA, SILIPICA, SANTIAGO DEL ESTERO",
  "VILLA UNION, MITRE, SANTIAGO DEL ESTERO",
  "VILMER, ROBLES, SANTIAGO DEL ESTERO",
  "VINARA, RIO HONDO, SANTIAGO DEL ESTERO",
  "YOUNG, TOMAS, GENERAL TABOADA, SANTIAGO DEL ESTERO",
  "YUCHAN, BRIGADIER JUAN FELIPE IBARRA, SANTIAGO DEL ESTERO",
  "ZANJON ,EST., CAPITAL, SANTIAGO DEL ESTERO",
  "ALMIRANTE BROWN, USHUAIA, TIERRA DEL FUEGO",
  "BAHIA FOX, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "BAHIA THETIS, USHUAIA, TIERRA DEL FUEGO",
  "BARRIO ALMIRANTE BROWN, USHUAIA, TIERRA DEL FUEGO",
  "BASE A.C.E. ALFEREZ DE NAVIO SOBRAL, SECTOR ANTARTICO ARGENTINO, TIERRA DEL FUEGO",
  "BASE AEREA TTE. BENJAMIN MATIENZO, SECTOR ANTARTICO ARGENTINO, TIERRA DEL FUEGO",
  "BASE DE EJERCITO ESPERANZA, SECTOR ANTARTICO ARGENTINO, TIERRA DEL FUEGO",
  "BASE DE EJERCITO GRL.BELGRANO, SECTOR ANTARTICO ARGENTINO, TIERRA DEL FUEGO",
  "BASE DE EJERCITO GRL.SAN MARTIN, SECTOR ANTARTICO ARGENTINO, TIERRA DEL FUEGO",
  "CARMEN, RIO GRANDE, TIERRA DEL FUEGO",
  "CULLEN, RIO GRANDE, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL CORBETA URUGUAY, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL DECEPCION, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL ESPERANZA, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL MELCHIOR, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL ORCADAS, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "DESTACAMENTO NAVAL TENIENTE CAMARA, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "ESTACION AERONAVAL PETRIEL, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "ESTACION CIENTIFICA ALMIRANTE BROWN, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "ESTACION CIENTIFICA ELLSWORTH, ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "ESTACION DE APOYO Nº 1 FUERZA AEREA ARG., ANTARTIDA ARGENTINA, TIERRA DEL FUEGO",
  "GRYTVIKEN, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "KAIKEN, RIO GRANDE, TIERRA DEL FUEGO",
  "LA MARINA, RIO GRANDE, TIERRA DEL FUEGO",
  "LAPATAIA, USHUAIA, TIERRA DEL FUEGO",
  "MARIA LUISA, RIO GRANDE, TIERRA DEL FUEGO",
  "MISION SALESIANA, RIO GRANDE, TIERRA DEL FUEGO",
  "PUERTO ARGENTINO, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "PUERTO HARBERTON, USHUAIA, TIERRA DEL FUEGO",
  "PUERTO LEITH, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "PUERTO LUIS, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "PUERTO RANCHO, USHUAIA, TIERRA DEL FUEGO",
  "PUERTO REMOLINO, USHUAIA, TIERRA DEL FUEGO",
  "PUERTO SAN CARLOS, ISLAS DEL ATLANTICO SUR E ISLAS MALVINAS, TIERRA DEL FUEGO",
  "RANCHO HAMBRE, USHUAIA, TIERRA DEL FUEGO",
  "RIO GRANDE, RIO GRANDE, TIERRA DEL FUEGO",
  "SAN JULIO, RIO GRANDE, TIERRA DEL FUEGO",
  "SAN JUSTO, RIO GRANDE, TIERRA DEL FUEGO",
  "SAN PABLO, RIO GRANDE, TIERRA DEL FUEGO",
  "SAN SEBASTIAN, RIO GRANDE, TIERRA DEL FUEGO",
  "SARA, RIO GRANDE, TIERRA DEL FUEGO",
  "SARMIENTO, RIO GRANDE, TIERRA DEL FUEGO",
  "USHUAIA, USHUAIA, TIERRA DEL FUEGO",
  "VIAMONTE, RIO GRANDE, TIERRA DEL FUEGO",
  "VIEJA CARMEN, RIO GRANDE, TIERRA DEL FUEGO",
  "7 DE ABRIL, BURRUYACU, TUCUMAN",
  "ACEQUIONES, TRANCAS, TUCUMAN",
  "ACHERAL, MONTEROS, TUCUMAN",
  "AGUA DULCE, LEALES, TUCUMAN",
  "AGUA DULCE ,EMP., CRUZ ALTA, TUCUMAN",
  "AGUILARES, RIO CHICO, TUCUMAN",
  "ALDERETES, CRUZ ALTA, TUCUMAN",
  "ALPACHIRI, CHICLIGASTA, TUCUMAN",
  "ALTA GRACIA, BURRUYACU, TUCUMAN",
  "ALTO DE MEDINA, BURRUYACU, TUCUMAN",
  "ALTO VERDE, CHICLIGASTA, TUCUMAN",
  "AMAICHA DEL VALLE, TAFI, TUCUMAN",
  "AMBERES, MONTEROS, TUCUMAN",
  "AMPATA, CHICLIGASTA, TUCUMAN",
  "ANCAJULI, TAFI, TUCUMAN",
  "ANFAMA, TAFI, TUCUMAN",
  "ARAOZ, LEALES, TUCUMAN",
  "ARBOLES GRANDES, GRANEROS, TUCUMAN",
  "ARCADIA, CHICLIGASTA, TUCUMAN",
  "ARROYO, CHICLIGASTA, TUCUMAN",
  "ATAHONA, CHICLIGASTA, TUCUMAN",
  "BAJASTINE, GRANEROS, TUCUMAN",
  "BALDERRAMA, MONTEROS, TUCUMAN",
  "BANDA DEL RIO SALI, CRUZ ALTA, TUCUMAN",
  "BELICHA HUAICO, CHICLIGASTA, TUCUMAN",
  "BELLA VISTA, FAMAILLA, TUCUMAN",
  "BENJAMIN ARAOZ, BURRUYACU, TUCUMAN",
  "BENJAMIN PAZ, TRANCAS, TUCUMAN",
  "BURRUYACU, BURRUYACU, TUCUMAN",
  "BUSTAMANTE ,AP., CRUZ ALTA, TUCUMAN",
  "CACHI HUASI ,AP., LEALES, TUCUMAN",
  "CACHI YACO ,AP., LEALES, TUCUMAN",
  "CAMPO BELLO, GRANEROS, TUCUMAN",
  "CAÑETE, CRUZ ALTA, TUCUMAN",
  "CEVIL POZO, CRUZ ALTA, TUCUMAN",
  "CEVIL REDONDO, TAFI, TUCUMAN",
  "CHABELA, BURRUYACU, TUCUMAN",
  "CHAÑARITO, CAPITAL, TUCUMAN",
  "CHICLIGASTA, CHICLIGASTA, TUCUMAN",
  "CHILCAS, BURRUYACU, TUCUMAN",
  "CHOROMORO, TRANCAS, TUCUMAN",
  "CHORRILLOS, BURRUYACU, TUCUMAN",
  "CHUANTA, FAMAILLA, TUCUMAN",
  "CIUDACITA, CHICLIGASTA, TUCUMAN",
  "CIUDAD HOSPITAL, TAFI, TUCUMAN",
  "CIUDAD UNIVERSITARIA, TAFI, TUCUMAN",
  "COCHUNA, CHICLIGASTA, TUCUMAN",
  "COLALAO DEL VALLE, TAFI, TUCUMAN",
  "COLOMBRES, CRUZ ALTA, TUCUMAN",
  "COLONIA NUEVA TRINIDAD, RIO CHICO, TUCUMAN",
  "COLONIA SAN JORGE, CRUZ ALTA, TUCUMAN",
  "COLONIA SAN JOSE, CRUZ ALTA, TUCUMAN",
  "CONCEPCION, CHICLIGASTA, TUCUMAN",
  "CRUZ DEL NORTE, CRUZ ALTA, TUCUMAN",
  "DIQUE EL CADILLAL, CAPITAL, TUCUMAN",
  "EL ASERRADERO, BURRUYACU, TUCUMAN",
  "EL BAÑADO, TAFI, TUCUMAN",
  "EL BARCO, BURRUYACU, TUCUMAN",
  "EL BRACHO, CRUZ ALTA, TUCUMAN",
  "EL CADILLAL, TAFI, TUCUMAN",
  "EL CHAÑAR, BURRUYACU, TUCUMAN",
  "EL COLMENAL, CAPITAL, TUCUMAN",
  "EL DURAZNITO, CAPITAL, TUCUMAN",
  "EL GUARDAMONTE, LEALES, TUCUMAN",
  "EL JARDIN, MONTEROS, TUCUMAN",
  "EL MOLINO, CHICLIGASTA, TUCUMAN",
  "EL MOLLAR, TAFI, TUCUMAN",
  "EL NARANJO, BURRUYACU, TUCUMAN",
  "EL OJO, BURRUYACU, TUCUMAN",
  "EL PORVENIR, GRANEROS, TUCUMAN",
  "EL SUNCHAL, BURRUYACU, TUCUMAN",
  "EL ZAPALLAR, GRANEROS, TUCUMAN",
  "ENCRUCIJADA, LEALES, TUCUMAN",
  "ESCABA ABAJO, RIO CHICO, TUCUMAN",
  "ESCABA ARRIBA, RIO CHICO, TUCUMAN",
  "ESQUINA, LEALES, TUCUMAN",
  "FAMAILLA, FAMAILLA, TUCUMAN",
  "FINCA ELISA, CRUZ ALTA, TUCUMAN",
  "GALLO, DELFIN, CRUZ ALTA, TUCUMAN",
  "GARCIA FERNANDEZ, MANUEL, FAMAILLA, TUCUMAN",
  "GASTONA, CHICLIGASTA, TUCUMAN",
  "GOBERNADOR GARMENDIA, BURRUYACU, TUCUMAN",
  "GOBERNADOR NOUGUES, CAPITAL, TUCUMAN",
  "GOBERNADOR PIEDRABUENA, BURRUYACU, TUCUMAN",
  "GRANEROS, GRANEROS, TUCUMAN",
  "GUZMAN, CRUZ ALTA, TUCUMAN",
  "HUASA PAMPA NORTE ,EST.HUASA PAMPA, GRANEROS, TUCUMAN",
  "HUASA PAMPA SUR, GRANEROS, TUCUMAN",
  "ICHIPUCA, GRANEROS, TUCUMAN",
  "INGENIO AMALIA, CAPITAL, TUCUMAN",
  "INGENIO CONCEPCION, CRUZ ALTA, TUCUMAN",
  "INGENIO CRUZ ALTA, CRUZ ALTA, TUCUMAN",
  "INGENIO LA CORONA, CHICLIGASTA, TUCUMAN",
  "INGENIO LA ESPERANZA, CRUZ ALTA, TUCUMAN",
  "INGENIO LA FLORIDA, CRUZ ALTA, TUCUMAN",
  "INGENIO LA FRONTERITA, FAMAILLA, TUCUMAN",
  "INGENIO LASTENIA, CRUZ ALTA, TUCUMAN",
  "INGENIO LULES, FAMAILLA, TUCUMAN",
  "INGENIO LULES ,EST., FAMAILLA, TUCUMAN",
  "INGENIO SAN JOSE, TAFI, TUCUMAN",
  "INGENIO SAN MIGUEL, CRUZ ALTA, TUCUMAN",
  "INGENIO SAN PABLO, FAMAILLA, TUCUMAN",
  "INGENIO SANTA ANA, RIO CHICO, TUCUMAN",
  "INGENIO SANTA LUCIA, MONTEROS, TUCUMAN",
  "KILOMETRO 10 ,AP., GRANEROS, TUCUMAN",
  "KILOMETRO 102 ,AP., FAMAILLA, TUCUMAN",
  "KILOMETRO 1185 ,AP., RIO CHICO, TUCUMAN",
  "KILOMETRO 1194 ,AP., CHICLIGASTA, TUCUMAN",
  "KILOMETRO 1207 ,AP., CHICLIGASTA, TUCUMAN",
  "KILOMETRO 1213 ,AP., MONTEROS, TUCUMAN",
  "KILOMETRO 1235 ,DV., FAMAILLA, TUCUMAN",
  "KILOMETRO 1248 ,AP., FAMAILLA, TUCUMAN",
  "KILOMETRO 1254 ,AP., CAPITAL, TUCUMAN",
  "KILOMETRO 1256 ,AP., CAPITAL, TUCUMAN",
  "KILOMETRO 29 ,AP., GRANEROS, TUCUMAN",
  "KILOMETRO 36 ,AP., RIO CHICO, TUCUMAN",
  "KILOMETRO 46 ,AP., RIO CHICO, TUCUMAN",
  "KILOMETRO 5 ,AP., FAMAILLA, TUCUMAN",
  "KILOMETRO 55 ,AP., RIO CHICO, TUCUMAN",
  "KILOMETRO 770 ,DV., BURRUYACU, TUCUMAN",
  "KILOMETRO 781 ,AP., CRUZ ALTA, TUCUMAN",
  "KILOMETRO 784 ,AP., CRUZ ALTA, TUCUMAN",
  "KILOMETRO 794 ,AP., CRUZ ALTA, TUCUMAN",
  "KILOMETRO 808 ,AP., CAPITAL, TUCUMAN",
  "KILOMETRO 810 ,DV., CAPITAL, TUCUMAN",
  "KILOMETRO 825, CAPITAL, TUCUMAN",
  "KILOMETRO 847 ,DV., TRANCAS, TUCUMAN",
  "KILOMETRO 99,DV., FAMAILLA, TUCUMAN",
  "LA ANGOSTURA, TAFI, TUCUMAN",
  "LA CAÑADA, GRANEROS, TUCUMAN",
  "LA COCHA, GRANEROS, TUCUMAN",
  "LA CRUZ, BURRUYACU, TUCUMAN",
  "LA ENCANTADA, LEALES, TUCUMAN",
  "LA FALDA, TAFI, TUCUMAN",
  "LA HIGUERA, TRANCAS, TUCUMAN",
  "LA INVERNADA, GRANEROS, TUCUMAN",
  "LA MADRID, GRANEROS, TUCUMAN",
  "LA POSTA, GRANEROS, TUCUMAN",
  "LA RAMADA, BURRUYACU, TUCUMAN",
  "LA REDUCCION, FAMAILLA, TUCUMAN",
  "LA RINCONADA, TAFI, TUCUMAN",
  "LA TIPA, RIO CHICO, TUCUMAN",
  "LA TRINIDAD, CHICLIGASTA, TUCUMAN",
  "LAGUNA DE ROBLES, BURRUYACU, TUCUMAN",
  "LAS CEJAS, CRUZ ALTA, TUCUMAN",
  "LAS GUCHAS, CHICLIGASTA, TUCUMAN",
  "LAS PIEDRITAS, CRUZ ALTA, TUCUMAN",
  "LEALES, LEALES, TUCUMAN",
  "LILLO, MIGUEL, LEALES, TUCUMAN",
  "LOMA VERDE, LEALES, TUCUMAN",
  "LOPEZ DOMINGUEZ ,AP., CRUZ ALTA, TUCUMAN",
  "LOS AGUIRRE, CAPITAL, TUCUMAN",
  "LOS CHAÑARES, TAFI, TUCUMAN",
  "LOS GOMEZ, LEALES, TUCUMAN",
  "LOS GUTIERREZ, CRUZ ALTA, TUCUMAN",
  "LOS PEREYRA, CRUZ ALTA, TUCUMAN",
  "LOS PUESTOS, LEALES, TUCUMAN",
  "LOS RALOS, CRUZ ALTA, TUCUMAN",
  "LOS SARMIENTOS, RIO CHICO, TUCUMAN",
  "LOS SOSA, MONTEROS, TUCUMAN",
  "LUISIANA, CRUZ ALTA, TUCUMAN",
  "LULES, FAMAILLA, TUCUMAN",
  "LUNAREJOS, LEALES, TUCUMAN",
  "MACIO, MONTEROS, TUCUMAN",
  "MACOMITA, BURRUYACU, TUCUMAN",
  "MALVINAS, FAMAILLA, TUCUMAN",
  "MANANTIAL, TAFI, TUCUMAN",
  "MARIÑO, BURRUYACU, TUCUMAN",
  "MATE DE LUNA, CAPITAL, TUCUMAN",
  "MEDINAS, CHICLIGASTA, TUCUMAN",
  "MENDEZ, PEDRO G., CRUZ ALTA, TUCUMAN",
  "MILLAN, DOMINGO, GRANEROS, TUCUMAN",
  "MIRAFLORES, TRANCAS, TUCUMAN",
  "MIXTA, LEALES, TUCUMAN",
  "MONTE GRANDE, GRANEROS, TUCUMAN",
  "MONTEAGUDO, CHICLIGASTA, TUCUMAN",
  "MONTEROS, MONTEROS, TUCUMAN",
  "MUNDO NUEVO, FAMAILLA, TUCUMAN",
  "MUÑECAS, CAPITAL, TUCUMAN",
  "NARANJITO, CRUZ ALTA, TUCUMAN",
  "NUEVA ESPAÑA, LEALES, TUCUMAN",
  "NUEVA TRINIDAD, RIO CHICO, TUCUMAN",
  "ORAN, LEALES, TUCUMAN",
  "PACARA, CRUZ ALTA, TUCUMAN",
  "PADILLA, FAMAILLA, TUCUMAN",
  "PALA PALA, LEALES, TUCUMAN",
  "PAZ, BENJAMIN, TRANCAS, TUCUMAN",
  "PAZ, LEOCADIO, TRANCAS, TUCUMAN",
  "PAZ, MARCOS, TAFI, TUCUMAN",
  "PEDRAZA, MANUELA ,AP., MONTEROS, TUCUMAN",
  "POLITO ,DV., CRUZ ALTA, TUCUMAN",
  "POTRERO DE LAS TABLAS, FAMAILLA, TUCUMAN",
  "POZO DEL ALTO, CRUZ ALTA, TUCUMAN",
  "PUEBLO VIEJO, MONTEROS, TUCUMAN",
  "PUERTA DE SAN JAVIER, TAFI, TUCUMAN",
  "RACO, TAFI, TUCUMAN",
  "RANCHILLOS, CRUZ ALTA, TUCUMAN",
  "REQUELME, BURRUYACU, TUCUMAN",
  "RIO CHICO, RIO CHICO, TUCUMAN",
  "RIO COLORADO, FAMAILLA, TUCUMAN",
  "RIO DEL NIO, BURRUYACU, TUCUMAN",
  "RIO SECO, MONTEROS, TUCUMAN",
  "ROMERO POZO, LEALES, TUCUMAN",
  "ROUGES, LEON, MONTEROS, TUCUMAN",
  "RUMI PUNCO, GRANEROS, TUCUMAN",
  "SAN AGUSTIN, CRUZ ALTA, TUCUMAN",
  "SAN ANDRES, CRUZ ALTA, TUCUMAN",
  "SAN FELIPE, CAPITAL, TUCUMAN",
  "SAN IGNACIO, GRANEROS, TUCUMAN",
  "SAN JAVIER, TAFI, TUCUMAN",
  "SAN JOSE, GRANEROS, TUCUMAN",
  "SAN MIGUEL, CRUZ ALTA, TUCUMAN",
  "SAN MIGUEL DE TUCUMAN ,EST.TUCUMAN, CAPITAL, TUCUMAN",
  "SAN PABLO, FAMAILLA, TUCUMAN",
  "SAN PATRICIO, BURRUYACU, TUCUMAN",
  "SAN PEDRO DE COLALAO, TRANCAS, TUCUMAN",
  "SAN RAFAEL, FAMAILLA, TUCUMAN",
  "SAN VICENTE, CRUZ ALTA, TUCUMAN",
  "SANTA BARBARA, CAPITAL, TUCUMAN",
  "SANTA CRUZ, CHICLIGASTA, TUCUMAN",
  "SANTA ROSA DE LEALES, LEALES, TUCUMAN",
  "SIAMBON, TAFI, TUCUMAN",
  "SIMOCA, MONTEROS, TUCUMAN",
  "SINQUIEAL, BURRUYACU, TUCUMAN",
  "SOL DE MAYO, GRANEROS, TUCUMAN",
  "SUELDOS, LEALES, TUCUMAN",
  "TACANAS ,EST.SUPERINTENDENTE LEDESMA, LEALES, TUCUMAN",
  "TACO PALTA, BURRUYACU, TUCUMAN",
  "TACO RALO, GRANEROS, TUCUMAN",
  "TAFI DEL VALLE, TAFI, TUCUMAN",
  "TAFI VIEJO, TAFI, TUCUMAN",
  "TAFICILLO, TAFI, TUCUMAN",
  "TALA POZO, BURRUYACU, TUCUMAN",
  "TAPIA, TRANCAS, TUCUMAN",
  "TARUCA PAMPA, BURRUYACU, TUCUMAN",
  "TICUCHO, TRANCAS, TUCUMAN",
  "TIMBO NUEVO, BURRUYACU, TUCUMAN",
  "TIMBO VIEJO, BURRUYACU, TUCUMAN",
  "TINAJEROS, BURRUYACU, TUCUMAN",
  "TRANCAS, TRANCAS, TUCUMAN",
  "TREJOS, CHICLIGASTA, TUCUMAN",
  "TRES POZOS, LEALES, TUCUMAN",
  "TUNA SOLA, TRANCAS, TUCUMAN",
  "TUSQUITAS, LEALES, TUCUMAN",
  "URIZAR, BURRUYACU, TUCUMAN",
  "UTURUNCO, TRANCAS, TUCUMAN",
  "VILLA CARMELA, TAFI, TUCUMAN",
  "VILLA LUJAN, CAPITAL, TUCUMAN",
  "VILLA NUEVA, BURRUYACU, TUCUMAN",
  "VILLA PADRE MONTI, BURRUYACU, TUCUMAN",
  "VILLA QUINTEROS, MONTEROS, TUCUMAN",
  "VILLA RESCATE, CRUZ ALTA, TUCUMAN",
  "VIPOS, TRANCAS, TUCUMAN",
  "YACUCHINA, MONTEROS, TUCUMAN",
  "YANIMAS, GRANEROS, TUCUMAN",
  "YERBA BUENA, TAFI, TUCUMAN",
  "ABASTO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "AGRONOMIA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "ALMAGRO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "BALVANERA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "BARRACAS, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "BARRIO NORTE, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "BELGRANO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "BOEDO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "CABALLITO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "CHACARITA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "COGHLAN, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "COLEGIALES, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "CONSTITUCION, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "FLORES, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "FLORESTA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "LA BOCA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "LINIERS, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "MATADEROS, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "MICROCENTRO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "MONSERRAT, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "MONTECASTRO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "NUEVA POMPEYA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "NUÑEZ, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PALERMO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PALERMO VIEJO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PARQUE AVELLANEDA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PARQUE CHACABUCO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PARQUE PATRICIOS, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PATERNAL, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PUERTO MADERO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "RECOLETA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "RETIRO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "SAAVEDRA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "SAN CRISTOBAL, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "SAN NICOLAS, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "SAN TELMO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VELEZ SARSFIELD, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VERSALLES, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA CRESPO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA DEL PARQUE, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA DEVOTO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA GENERAL MITRE, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA LUGANO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA LURO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA ORTUZAR, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA PUEYRREDON, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA REAL, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA RIACHUELO, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA SANTA RITA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA SOLDATI, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "VILLA URQUIZA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "PARAJE PAVON, GENERAL LAVALLE, BUENOS AIRES",
  "AGUAS VERDES, PARTIDO DE LA COSTA, BUENOS AIRES",
  "COSTA AZUL, PARTIDO DE LA COSTA, BUENOS AIRES",
  "COSTA CHICA, PARTIDO DE LA COSTA, BUENOS AIRES",
  "COSTA DEL ESTE, PARTIDO DE LA COSTA, BUENOS AIRES",
  "LAS TONINAS, PARTIDO DE LA COSTA, BUENOS AIRES",
  "NUEVA ATLANTIS, PARTIDO DE LA COSTA, BUENOS AIRES",
  "PUNTA MEDANOS, PARTIDO DE LA COSTA, BUENOS AIRES",
  "COSTA ESMERALDA, PARTIDO DE LA COSTA, BUENOS AIRES",
  "PINAR DEL SOL, PARTIDO DE LA COSTA, BUENOS AIRES",
  "MAR CHIQUITA, GENERAL PUEYRREDON, BUENOS AIRES",
  "CABA, CAPITAL FEDERAL, CAPITAL FEDERAL",
  "EMBALSE, CALAMUCHITA, CORDOBA",
  "CAMPO DE MAYO, TIGRE, BUENOS AIRES",
  "BASE NAVAL DE PUERTO BELGRANO, JURISDICCIÃ“N FEDERAL, JURISDICCIÃ“N FEDERAL",
  "MAR DE OSTENDE, PINAMAR, BUENOS AIRES",
  "VALERIA DEL MAR, PINAMAR, BUENOS AIRES",
  "CARILO, PINAMAR, BUENOS AIRES",
  "MAR AZUL, VILLA GESELL, BUENOS AIRES",
  "MAR DE LAS PAMPAS, VILLA GESELL, BUENOS AIRES",
  "LAS GAVIOTAS, VILLA GESELL, BUENOS AIRES",
  "SIERRA DE LOS PADRES, GENERAL PUEYRREDON, BUENOS AIRES",
  "ALMIRANTE BROWN, ALMIRANTE BROWN, BUENOS AIRES",
  "RAFAEL CALZADA, ALMIRANTE BROWN, BUENOS AIRES",
  "VILLA SARMIENTO, MORON, BUENOS AIRES",
  "LOMAS DEL PALOMAR , TRES DE FEBRERO, BUENOS AIRES",
  "LOMAS DEL MIRADOR, LA MATANZA, BUENOS AIRES",
  "SAN MIGUEL, SAN MARTIN, BUENOS AIRES",
  "LEANDRO N. ALEM, LEANDRO N.ALEM, MISIONES",
  "SIN ASIGNAR, AZUL, BUENOS AIRES",
  "VILLA MARTELLI, VICENTE LOPEZ, BUENOS AIRES",
  "RINCON DE COBOS, PINAMAR, BUENOS AIRES"]
  ;
    return localidad;
  }

  crearCliente(cliente){
    let accion='crearCliente';
    let body=JSON.stringify({accion,cliente} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }

  updateCliente(cliente){
    let accion='updateCliente';
    let body=JSON.stringify({accion,cliente} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listarpaises(){
    let accion='traerPais';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarClientes(termino,deudores,tipo,tipoint,localidad,localidadstr){
    let accion='traerClientes';

    let body=JSON.stringify({accion ,termino,deudores,tipo,tipoint,localidad,localidadstr} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarClientesid(id){
    let accion='traerClientesid';

    let body=JSON.stringify({accion ,id} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  tipo_cliente(){
    let accion='traerTipo';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
}

import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Cliente} from '../interface/cliente.interface';
import {Movimiento} from '../interface/movimientos.interface';
import {Router} from "@angular/router";
interface myData{success: boolean, messagge: string,id? , movimientos?:Movimiento[],cliente?:Cliente[],concepto?,saldo?,caja?};
import * as jsPDF from 'jspdf';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {
  private nombrephp:string='https://cerrapp.com/movimientos.php';
  //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/movimientos.php';

  constructor(private http:HttpClient,private router:Router) {

  }
  listarformapago(){
    let accion='traer_formas_pago';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listar_cajaschicas(){
    let accion='traer_cajachica';
    let id=sessionStorage.getItem('id');
    let body=JSON.stringify({accion,id} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarcheque(valor,tipo_moneda){
    let accion='traer_cheques';
    let body=JSON.stringify({accion,valor,tipo_moneda} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarcuentadeposito(){
    let accion='traer_cuenta_deposito';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  lista_movimiento(fecha_desde='',fecha_hasta=''){
    let accion='movimientos';
    let body=JSON.stringify({accion,fecha_desde,fecha_hasta} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listarbanco(){
    let accion='traer_bancos';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }

  lista_movimientos_personal(id,tipo,fecha_desde='', fecha_hasta=''){
    let accion='movimientos_personal';
    let body=JSON.stringify({accion,id,tipo,fecha_desde,fecha_hasta} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
/*  lista_movimientopdf(id,tipo){
    let accion='movimientos_cliente';
    let body=JSON.stringify({accion,id,tipo} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>("http://cerramientosmartin.com/PdfMovimientos.php",body
    ,{headers:headers});
  }*/
  lista_movimiento_id(id_movimientos){
    let accion='movimientos_id';
    let body=JSON.stringify({accion,id_movimientos} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listarconcepto(){
    let accion='traer_conceptos_pago';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarchequeTodos(valor,buscado,tipo_moneda,banco,entregado,rechazado,tipo_valor,banco_valor,entregado_valor,estado_valor){
    let accion='traer_cheques_bancos';
    let body=JSON.stringify({accion,buscado,valor,tipo_moneda,banco,entregado,rechazado,tipo_valor,banco_valor,entregado_valor,estado_valor} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listartipomoneda(){
    let accion='traer_tipo_moneda';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  cambiarEstadoCheque(id,estado){
    let accion='cambiarEstadoCheque';
    let body=JSON.stringify({accion,id,estado} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  eliminarMovimiento(id){
    let accion='eliminarMovimiento';
    let body=JSON.stringify({accion,id} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  insertarMovimiento(movimiento,id,tipo='',fkcaja_chica=''){
    let accion='guardarMovimiento';
    let idusuario=sessionStorage.getItem('id');
    let body=JSON.stringify({accion,movimiento,id,tipo,idusuario,fkcaja_chica} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  donwloadMovimientos(id){
    const doc = new jsPDF();
    var img = new Image();
    var movimiento;
    img.src = 'assets/img/bannercerramientossinfondo.png';
    this.lista_movimiento_id(id).subscribe(data=>{
      movimiento=data.movimientos;
      //console.log(data)
      doc.rect(8, 8, 193,280); // empty square
      doc.addImage(img,'png',16,10,68,23);
      doc.setFontSize(10);
      doc.text('Adam, Matias Jorge-Caseros 550/(7109) Mar de ajó',10,38);
      doc.text('Whatsapp: +5492257664330/Tel:(02257)450408',10,42);
      doc.text('E.mail: cerramientos_martin@yahoo.com.ar',10,46);
      doc.text('Página: www.cecrramientosmartin.com',10,50);
      doc.setFontSize(60);
      doc.setTextColor(0);
      doc.rect(95, 16, 24,26); // empty square
      doc.text('X', 100, 36);
      doc.setFontSize(18);
      //doc.text('Recibo ', 142, 20);
      doc.text('Recibo N°:'+ movimiento[0].numero_recibo, 125, 23);
      doc.setFontSize(12);
      doc.text('(documento no valido como factura) ', 130, 30);
      doc.setFontSize(20);

      var fecha_entrega =movimiento[0].fecha.substring(8,10)+'/'+movimiento[0].fecha.substring(5,7)+'/'+movimiento[0].fecha.substring(0,4)
      doc.text('Fecha:'+ fecha_entrega, 140, 40);
      doc.rect(10,52,190,0); // empty square
      //doc.text('', 160, 38);

      doc.setFontSize(12);
      doc.text('NOMBRE Y APELLIDO: '+ movimiento[0].cliente[0].nombre +' ' + movimiento[0].cliente[0].apellido,15,60);
      doc.text('DOMICILIO: '+movimiento[0].cliente[0].calle+' '+ movimiento[0].cliente[0].altura+ ' '+((movimiento[0].cliente[0].piso=='0' || !(movimiento[0].cliente[0].piso) )?'':movimiento[0].cliente[0].piso)+' '+ movimiento[0].cliente[0].depto + '  '+ movimiento[0].cliente[0].localidad,15,65);
      doc.text('C.U.I.T: '+ ((movimiento[0].cliente[0].cuit==0 || !(movimiento[0].cliente[0].cuit))?'':movimiento[0].cliente[0].cuit) ,15,70);
      doc.rect(10,75,190,0); // empty square
      //doc.rect(10,80,20,10);
      //doc.rect(30,80,100,10);
      doc.rect(10,90,155,0);
      doc.rect(165,90,33,0);
      //doc.text('Cantidad',20,86,'center');
      doc.text('Detalle',20,86,'center');
      //doc.text('Precio',140,86,'center');
      doc.text('Total',175,86,'center');
      let y=96;
    /*  presupuesto[0].detalle.forEach(function(value){*/
      //  console.log(movimiento);
      //  doc.text(value.cantidad,20,y,'center');
        if(movimiento[0].fkforma_pago!=2)
        {
          var splitTitle = doc.splitTextToSize(movimiento[0].detalle, 180);
        }else if(movimiento[0].fkforma_pago==2)
        {
            var cheques='';

            movimiento[0].cheque.forEach(function(value){
             cheques = cheques + ' CHEQUE N° ' + value.numero + '- Banco '+value.banco + '- Importe '+value.total_cheque+' \n'
             //console.log('cheques;')
            })
              var splitTitle = doc.splitTextToSize(movimiento[0].detalle +' \n'+ cheques, 180);
        }

        doc.text(splitTitle,15,y,'left');
        /*doc.text(presupuesto[0].tipo_moneda +value.precio_detalle,135,y,'left')*/
        doc.text(movimiento[0].signo +movimiento[0].fktipo_moneda+movimiento[0].importe ,170,y,'left')
        splitTitle.forEach(function(){
          y=y+6;
        });
    /*  })*/
      doc.rect(10,y,187,0);
      doc.text('Importe Total:' +movimiento[0].signo + movimiento[0].fktipo_moneda+ movimiento[0].importe ,142,y+6,'left')
    //doc.text('Importe Confirmado:' + presupuesto[0].tipo_moneda+presupuesto[0].importe_confirmado ,130,y+12,'left')
    //  doc.rect(10,y+18,187,0); // empty square
      //var splitTitle2 = doc.splitTextToSize( 'Observacion:'+ presupuesto[0].observacion, 160);
    //  doc.text( splitTitle2 ,20 ,y+24,'left')
      var f=new Date();
      doc.setFontSize(6);
      doc.text('Impreso el dia ' + f  ,10,290,'left')
      var nombre='recibo_'+movimiento[0].id+'_'+fecha_entrega+'.pdf';
      doc.save(nombre);
    })
  }
  abrir(monto_inicial){
    let idusuario=sessionStorage.getItem('id');
    console.log( monto_inicial);
    let accion='Abrircaja';
    //let idusuario=sessionStorage.getItem('id');
    let body=JSON.stringify({accion,idusuario,monto_inicial} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  cerrar(monto_final,pkcaja){
    let idusuario=sessionStorage.getItem('id');
    let accion='cerrarcaja';
    //let idusuario=sessionStorage.getItem('id');
    let body=JSON.stringify({accion,idusuario,monto_final,pkcaja} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listacaja(){
    let idusuario=sessionStorage.getItem('id');
    let accion='listarcajar';
    let body=JSON.stringify({accion,idusuario} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
}

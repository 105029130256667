import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, Validators, FormArray} from '@angular/Forms';
import {AuthService} from '../../services/auth.service';
import {Router} from "@angular/router";
import {UsuariosService} from '../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contrasenia',
  templateUrl: './contrasenia.component.html',
  styleUrls: ['./contrasenia.component.css']
})
export class ContraseniaComponent implements OnInit {
    usuario;
    forma:FormGroup;
  constructor(private auth:AuthService,private spinner: NgxSpinnerService,private usuariosService: UsuariosService,private router:Router) {
    this.usuario= auth.preguntarUsuario();
    this.forma=new FormGroup({
      'password':new FormControl('',[Validators.required,Validators.minLength(4)]),
      'passwordN':new FormControl('',[Validators.required,Validators.minLength(4)]),
      'passwordN2':new FormControl('',[Validators.required,Validators.minLength(4)]),
    })
      this.forma.controls['passwordN2'].setValidators([Validators.required,this.noIgual.bind(this.forma)]);
      this.forma.controls['passwordN'].setValidators([Validators.required,this.noIgualoriginal.bind(this.forma)]);
   }

  ngOnInit() {
  }
  noIgual(control: FormControl):{[s:string]:boolean}{
    let forma:any=this;
    if(control.value !==forma.controls['passwordN'].value){
      return{ noiguales:true}
    }
    return null;

  }
  noIgualoriginal(control: FormControl):{[s:string]:boolean}{
    let forma:any=this;
    if(control.value ===forma.controls['password'].value){
      return{ igualorignal:true}
    }
    return null;

  }
  guardarCambios(){

      if(this.forma.valid)
      {
        this.spinner.show();
        this.usuariosService.modificarContrasenia(this.forma.value.password,this.forma.value.passwordN,this.usuario.id).subscribe(data=>{
          this.spinner.hide()
          //let succes;
          if (data.success)
          {
            Swal.fire( {type: 'success',
                        title: 'Realizado',
                        text:data.messagge});

          }
          else
          {
            Swal.fire( {type: 'error',
                        title: 'Oops...',
                        text:data.messagge});
          }

          if(!this.auth.isLoggedIn())
          {
              this.auth.logout();
              this.router.navigate(['login'])
          }
        })
      }
      else
      {
        Swal.fire( {type: 'error',
                    title: 'Oops...',
                    text:'Quedan campos sin completas o no son validos'});
      }
  }
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UsuariosService} from '../../services/usuarios.service';
import {AuthService} from "../../services/auth.service";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-buscar-usuario',
  templateUrl: './buscar-usuario.component.html',
  styleUrls: ['./buscar-usuario.component.css']
})
export class BuscarUsuarioComponent implements OnInit {
  usuarios:any[]=[];
  termino:string;
  private habilitadobloquear;
  private habilitadopermisopantalla;
  constructor( private Auth: AuthService, private activatedRoute: ActivatedRoute,
    public usuariosService: UsuariosService,private spinner: NgxSpinnerService) {
        this.Auth.seguimientoPantallas(1).subscribe(data=>{});
     }

  ngOnInit() {

    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
        this.termino=params['termino'];
        this.usuariosService.listaruserbuscado(params['termino']).subscribe(data=>{
          this.spinner.hide();
          this.usuariosService.setListadoUsuario(data.usuarios);
        })
    //this.heroes=this._heroesService.buscarHeroes(params['termino']);
    });
  //  console.log('ads');
    this.usuariosService.permisopantalla('4').subscribe(data=>{
      console.log('data')
        this.habilitadobloquear=data.success;

    });
    this.usuariosService.permisopantalla('3').subscribe(data=>{
        this.habilitadopermisopantalla=data.success;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {MovimientosService} from '../../services/movimientos.service';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';

import {AuthService} from "../../services/auth.service";
@Component({
  selector: 'app-transpaso-cajachica',
  templateUrl: './transpaso-cajachica.component.html',
  styleUrls: ['./transpaso-cajachica.component.css']
})
export class TranspasoCajachicaComponent implements OnInit {

  forma:FormGroup;
  tipo_moneda;
  movimientos;
  conceptos;
  cuentas_depositos;
  cheque;
  caja_chica;
  visible=false;
  visible2=false;
   profile = {
    fecha:"",
    forma_pago: "",
    cuenta_deposito: "1",
    tipo_moneda:"1",
    importe:"",
    detalle:"",
    cheque:this._forma.array([])
  };
  constructor(private Auth: AuthService,private clienteService:MovimientosService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder) {
    this.Auth.seguimientoPantallas(43).subscribe(data=>{});
    this.forma=this._forma.group({
                                      fecha:['',Validators.required],
                                      forma_pago: ['',Validators.required],
                                      caja_chica: ['',Validators.required],
                                      cuenta_deposito: ['1',Validators.required],
                                      tipo_moneda: ['1',Validators.required],
                                      importe: ['',[Validators.required,Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      detalle:['',Validators.required],
                                      cheque:this._forma.array([])
                                })
    this.clienteService.listartipomoneda().subscribe(data=>{
        this.tipo_moneda=data;
        this.spinner.hide();
        //console.log(this.pais)
    });
    this.clienteService.listarformapago().subscribe(data=>{
      this.movimientos=data;
      this.spinner.hide();
      //console.log(this.pais)
    });
    this.clienteService.listarconcepto().subscribe(data=>{
      this.conceptos=data;
      this.spinner.hide();
      //console.log(this.pais)
    });
    this.clienteService.listarcuentadeposito().subscribe(data=>{
      this.cuentas_depositos=data;
      this.spinner.hide();
      //console.log(this.pais)
    });
    this.clienteService.listar_cajaschicas().subscribe(data=>{
      this.caja_chica=data;
      this.spinner.hide();
      console.log(this.caja_chica)
    });
  }

  ngOnInit() {
  }
  cambiovisible(){
    console.log(this.forma)
    this.visible=false;
    this.visible2=false;
    for(let i=0;i<=this.chequeArray.length+1 ;i++)
    {
        this.eliminarCheque(i);
    }
    this.eliminarCheque(0);
    if(this.forma.value.forma_pago=='3' || this.forma.value.forma_pago=='4' || this.forma.value.forma_pago=='5')
    {

      this.visible=true;
    }else{
      if(this.forma.value.forma_pago=='2')
      {
        this.visible2=true;
        this.forma.controls['importe'].reset( '',[Validators.required,Validators.pattern("[0-9]*")]);
       
      }
    }
  }
  insertarChequeEgreso(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    this.chequeArray.push( this.ponerCheque(cheque));
    this.importe_cheque();
  }
  eliminarCheque(index){
    this.chequeArray.removeAt(index);
  }
  get chequeArray(){
    return <FormArray>this.forma.get('cheque');
  }
  importe_cheque(){
    let importe_cheque:number=0;
    for (let control of this.chequeArray.controls) {
      console.log(control)
      importe_cheque= importe_cheque+ parseFloat(control.value.importe_cheque);
    }
    this.forma.controls['importe'].reset( importe_cheque,[Validators.required]);
  }
  ponerCheque(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    return this._forma.group({
      id_cheque:[cheque.pkcheque],
      fecha_cobro:[cheque.fecha_cobro,Validators.required],
      numero_cheque:[cheque.numero_cheque,[Validators.required,Validators.pattern("[0-9]*")]],
      banco:[cheque.fkbanco,Validators.required],
      bancoNombre:[cheque.banco],
      importe_cheque:[cheque.importe,Validators.required]
    })
    //console.log(cheque)
  }
  buscarCheque(termino:string){
    this.spinner.show();
      this.clienteService.listarcheque(termino,this.forma.value.tipo_moneda).subscribe(data=>{
          this.spinner.hide();
          this.cheque= data;
        })
  }
  guardarCambios(){
    //console.log(this.forma.value.fecha+'--')
      if(this.forma.valid)
      {
        this.spinner.show();
        let movimiento = {detalle:this.forma.value.detalle +' (TRANSPASO DE CAJA)',fecha:this.forma.value.fecha,fkforma_pago:this.forma.value.forma_pago,fktipo_moneda:this.forma.value.tipo_moneda,visible:'1',fkconcepto:'36', fk_cuenta_deposito:this.forma.value.cuenta_deposito,cheque:this.forma.value.cheque,importe:this.forma.value.importe,signo:'-'};
           this.clienteService.insertarMovimiento(movimiento,'').subscribe(data=>{
            this.spinner.hide()
            if(data.success)
            {
              let movimiento2 = {detalle:this.forma.value.detalle+' (TRANSPASO DE CAJA)',fecha:this.forma.value.fecha,fkforma_pago:this.forma.value.forma_pago,fktipo_moneda:this.forma.value.tipo_moneda,visible:'1',fkconcepto:'36', fk_cuenta_deposito:this.forma.value.cuenta_deposito,cheque:this.forma.value.cheque,importe:this.forma.value.importe,signo:'+'};
              this.clienteService.insertarMovimiento(movimiento2,'','',this.forma.value.caja_chica).subscribe(data=>{
                if(data.success)
                {
                  Swal.fire( {type: 'success',
                      title: 'Realizado',
                      text:data.messagge});
                  this.forma.reset(this.profile)
                  this.visible=false;
                  this.visible2=false;
                }else{
                  Swal.fire( {type: 'error',
                      title: 'Oops...',
                      text:data.messagge});
                }
              })

            }else{
              Swal.fire( {type: 'error',
                  title: 'Oops...',
                  text:data.messagge});
            }
          })

      }
      else
      {
          Swal.fire( {type: 'error',
                      title: 'Oops...',
                      text: 'Quedan campos sin completas o no son validos'});
      }
    }
}

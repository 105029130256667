import { Component, OnInit } from '@angular/core';
import {  clientesModels} from '../../models/clientes.models';
import {ClientesService} from '../../services/clientes.service';
import {ProveedoresService} from '../../services/proveedores.service';
import { NgForm} from '@angular/forms';
import {NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AuthService} from "../../services/auth.service";
import {FormGroup,FormBuilder,FormControl, Validators,FormArray,ReactiveFormsModule} from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-editar-proveedor',
  templateUrl: './editar-proveedor.component.html',
  styleUrls: ['./editar-proveedor.component.css']
})
export class EditarProveedorComponent implements OnInit {
  cliente:clientesModels= new clientesModels();
  forma:FormGroup;
  tipo_cliente;
  pais;
  localidad:String[] ;
  myControl = new FormControl();
  filteredOptions: Observable<String[]>;
  constructor(private Auth: AuthService,private _forma:FormBuilder,private spinner: NgxSpinnerService,private activatedRoute: ActivatedRoute,private clienteService: ClientesService,private proveedoresService: ProveedoresService,private http: HttpClient) {
    this.Auth.seguimientoPantallas(44).subscribe(data=>{});
    this.forma=  this.forma=new FormGroup({
                              'nombrecompleto': new FormGroup({
                                'dni':new FormControl('',[Validators.minLength(6),Validators.pattern("[0-9]*")]),
                                'nombre': new FormControl('',[Validators.required,Validators.minLength(3)]),
                                'apellido': new FormControl('',[Validators.minLength(3)]),
                                'cuit': new FormControl('',[Validators.pattern("[0-9]{11}")]),
                                'pais':new FormControl('1', Validators.required),
                                'calle': new FormControl('',Validators.required),
                                'altura': new FormControl('',Validators.required),
                                'piso': new FormControl(''),
                                'depto': new FormControl(''),
                              }),
                              'tipo': new FormControl('1',[Validators.required]),
                              'telefono': new FormArray([ new FormControl('', Validators.required)]),
                              'mail': new FormArray([ new FormControl('',Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"))]),
                              'observaciones':new FormControl(''),
                          })

    this.clienteService.tipo_cliente().subscribe(data=>{
        this.tipo_cliente=data;
        //console.log(this.pais)
    });
    this.clienteService.listarpaises().subscribe(data=>{
        this.pais=data;
        //console.log(this.pais)
    });
    this.activatedRoute.params.subscribe( params=>{
      this.proveedoresService.listarProveedoresid(params['id']).subscribe(data=>{
        console.log(data);
        this.cliente=data.clientesModels;
        this.forma=this.agregarCliente();
        this.myControl=this.agregarLocalidad();
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
        );
          //  this.  myControl = new FormControl(this.cliente.localidad);
      })

    });
   }

  ngOnInit() {
  }
  agregarCliente(){
    let cuit;
    let tel:FormArray;
    let mail:FormArray;
    if(this.cliente.cuit!=0)
    {
      cuit=this.cliente.cuit
    }
    this.cliente.telefono.forEach(function(value){
      if(tel)
      {
        (<FormArray>tel).push(
            new FormControl(value, Validators.required)
        )
      }else{
        tel=new FormArray([ new FormControl(value, Validators.required) ]);
      }

    })
    this.cliente.mail.forEach(function(value){
      if(mail)
      {
        (<FormArray>mail).push(
            new FormControl(value, Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"))
        )
      }else{
        mail=new FormArray([ new FormControl(value, Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"))]);
      }
    })
    return this._forma.group({
      'nombrecompleto': new FormGroup({
        'dni':new FormControl(this.cliente.dni,[Validators.minLength(6),Validators.pattern("[0-9]*")]),
        'nombre': new FormControl(this.cliente.nombre,[Validators.required,Validators.minLength(3)]),
        'apellido': new FormControl(this.cliente.apellido,[Validators.minLength(3)]),
        'cuit': new FormControl(cuit,[Validators.pattern("[0-9]{11}")]),
        'pais':new FormControl(this.cliente.pais, Validators.required),
        'calle': new FormControl(this.cliente.calle,Validators.required),
        'altura': new FormControl(this.cliente.altura,Validators.required),
        'piso': new FormControl(this.cliente.piso),
        'depto': new FormControl(this.cliente.depto),
      }),
      'tipo': new FormControl(this.cliente.tipo,[Validators.required]),
      'telefono': tel,
      'mail': mail,
      'observaciones':new FormControl(this.cliente.observacion),
    })
  }
  agregarLocalidad(){
    return new FormControl(this.cliente.localidad);
  }
  guardarCambios(){
    this.spinner.show();
    let cliente = {id:this.cliente.id,tipo:this.forma.value.tipo ,dni:this.forma.value.nombrecompleto.dni,nombre:this.forma.value.nombrecompleto.nombre,apellido:this.forma.value.nombrecompleto.apellido, cuit:this.forma.value.nombrecompleto.cuit,pais:this.forma.value.nombrecompleto.pais,localidad:this.myControl.value ,calle:this.forma.value.nombrecompleto.calle,altura:this.forma.value.nombrecompleto.altura, piso:this.forma.value.nombrecompleto.piso,depto: this.forma.value.depto,telefono:this.forma.value.telefono,mail:this.forma.value.mail,observaciones:this.forma.value.observaciones};
    this.proveedoresService.updateCliente(cliente).subscribe(data=>{
      this.spinner.hide()
      if(data.success)
      {
        Swal.fire( {type: 'success',
            title: 'Realizado',
            text:data.messagge});

      }else{
        Swal.fire( {type: 'error',
            title: 'Oops...',
            text:data.messagge});
      }//alert('aca')
    })
  }
  agregarTelefono(){
    (<FormArray>this.forma.controls['telefono']).push(
        new FormControl('', Validators.required)
    )
  }
  eliminarTelefono(i){
    console.log(i>'0')
    //i=this.forma.controls['telefono'];
    if(i>1){
        (<FormArray>this.forma.controls['telefono']).removeAt(i-1);
      }
  }
  eliminarMail(i){
    console.log(i>'0')
    //i=this.forma.controls['telefono'];
    if(i>1){
        (<FormArray>this.forma.controls['mail']).removeAt(i-1);
      }
  }
  agregarMail(){
    (<FormArray>this.forma.controls['mail']).push(
        new FormControl('', Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"))
    )
  }
  private _filter(value: String): String[] {
    this.localidad=this.clienteService.listarLocalidades();
    if(value!='' && value.length>=4)
    {
      const filterValue = value.toLowerCase();
      return this.localidad.filter(option => option.toLowerCase().includes(filterValue));
    }
  }
  guardarCambiostelefono(i,valor,tipo)
  {
    if(tipo=='T')
    {
      this.cliente.telefono[i]=valor;
    }
    else
    {
      this.cliente.mail[i]=valor;
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from "@angular/router";
import {  clientesModels} from '../models/clientes.models';

interface myData{success: boolean, messagge: string,clientesModels:clientesModels};

@Injectable({
  providedIn: 'root'
})

export class ProveedoresService {
  private nombrephp:string='https://cerrapp.com/clientes.php';
  //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/proveedor.php';
  constructor(private http:HttpClient,private router:Router) { }
  crearProveedor(proveedor){
    //console.log('aca');
    let accion='crearProveedor';
    let body=JSON.stringify({accion,proveedor} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listarProveedores(termino,deudores,tipo,tipoint,localidad,localidadstr){
    let accion='traerProveedores';

    let body=JSON.stringify({accion ,termino,deudores,tipo,tipoint,localidad,localidadstr} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post(this.nombrephp,body
    ,{headers:headers});
  }
  listarProveedoresid(id){
    let accion='traerProveedoresid';

    let body=JSON.stringify({accion ,id} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  updateCliente(cliente){
    let accion='updateProveedores';
    let body=JSON.stringify({accion,cliente} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {PresupuestosService} from "../../services/presupuestos.service";
import {AuthService} from "../../services/auth.service";
import {UsuariosService} from "../../services/usuarios.service";
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-estados-eventos',
  templateUrl: './estados-eventos.component.html',
  styleUrls: ['./estados-eventos.component.css']
})
export class EstadosEventosComponent implements OnInit {
  forma2:FormGroup;
  variablepantalla='';
  variableestado='';
  parametroid;
  presupuesto_particular;
  habilitadocontinuarestado;
  habilitadoeditaragregarrequerimiento;
  habilitadoeliminarrequerimiento;
  habilitadoinsertareventospresupuesto;
  habilitadocerrareventospresupuesto;
  eventos;
  constructor(private _forma:FormBuilder,private presupuestoService:PresupuestosService,private Auth: AuthService,private activatedRoute: ActivatedRoute,private usuariosService:UsuariosService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.forma2=this._forma.group({
      detalles:this._forma.array( []),
      historial_estado:this._forma.array([]),
      fecha:[''],
      fecha_entrega:[''],
      area:[''],
      observacion:[''],
      tiene_desc:['0'],
      tipo_descuento:['fijo'],
      valor_descuento:[''],
      importe_confirmado:[''],
      importe: [''],
      importe_sin_descuento:[''],
      importe_confirmado_sin_descuento:[''],
      importe_descuento:[''],
      importe_descuento_confirmado:['']
    })
    this.buscarid();
  }
  async buscarid(){
    this.Auth.seguimientoPantallas(21).subscribe(data=>{});
    this.presupuestoService.listarevento().subscribe(data=>{
      //this.spinner.hide();
      this.eventos= data;
      //  console.log(data)
    })
    this.usuariosService.permisopantalla('33').subscribe(data=>{
      this.habilitadocontinuarestado=data.success;
    });
    this.usuariosService.permisopantalla('46').subscribe(data=>{
      this.habilitadoeditaragregarrequerimiento=data.success;
    });
    this.usuariosService.permisopantalla('47').subscribe(data=>{
      this.habilitadoeliminarrequerimiento=data.success;
    });
    this.usuariosService.permisopantalla('30').subscribe(data=>{
     
      this.habilitadoinsertareventospresupuesto=data.success;
    });
    this.usuariosService.permisopantalla('31').subscribe(data=>{
        this.habilitadocerrareventospresupuesto=data.success;
    });
   
    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
      //console.log(params['pantalla'])
      this.variablepantalla=params['pantalla'];
      this.variableestado=params['idestado'];
      this.parametroid=params['idclienteusuario'];
     // console.log(params['id'])
      this.presupuestoService.listar_presupuesto_id(params['id'],'').subscribe(data=>{
        this.spinner.hide();
        this.presupuesto_particular= data;
        //console.log(data);
        let tiene_desc:boolean;
        this.vaciardetalleArray();
        if(this.presupuesto_particular[0].tiene_descuento==0)
        {
          tiene_desc=false;
        }else{
          tiene_desc=true;
        }
       

        let fechaDate = new Date(this.presupuesto_particular[0].fecha_entrega + ' 0:00:00');
        for (let elemento of this.presupuesto_particular[0].detalle){
            this.detalleArray.push( this.agregardetalledatos(elemento.id_detalle,elemento.cantidad,elemento.id_articulo,elemento.detalle,elemento.precio_detalle,elemento.total,elemento.confirmado));
        }
        for (let elemento of this.presupuesto_particular[0].historialEstado){
          this.estado.push( this.agregarhistorialdatos(elemento.fkestado,elemento.estado,elemento.fecha_inicio,elemento.fecha_hasta));
        }
      //  
        this.forma2.controls['fecha'].reset( this.presupuesto_particular[0].fecha_presupuesto);
        this.forma2.controls['fecha_entrega'].reset( this.presupuesto_particular[0].fecha_entrega);
        this.forma2.controls['area'].reset(this.presupuesto_particular[0].area);
        this.forma2.controls['observacion'].reset(this.presupuesto_particular[0].observacion);
        this.forma2.controls['tiene_desc'].reset( tiene_desc, [Validators.required]);
        this.forma2.controls['tipo_descuento'].reset( this.presupuesto_particular[0].tipo_descuento, [Validators.required]);
        this.forma2.controls['valor_descuento'].reset(this.presupuesto_particular[0].valor_descuento,[Validators.required]);
        this.forma2.controls['importe_confirmado'].reset( this.presupuesto_particular[0].importe_confirmado, [Validators.required]);
        this.forma2.controls['importe'].reset( this.presupuesto_particular[0].importe, [Validators.required]);
        this.forma2.controls['importe_sin_descuento'].reset(this.presupuesto_particular[0].importe_sin_descuento,[Validators.required]);
        this.forma2.controls['importe_confirmado_sin_descuento'].reset(this.presupuesto_particular[0].importe_confirmado_sin_descuento,[Validators.required]);
        this.forma2.controls['importe_descuento'].reset(this.presupuesto_particular[0].descuento_total);
        this.forma2.controls['importe_descuento_confirmado'].reset(this.presupuesto_particular[0].descuento_total_confirmado);
      //  console.log(this.forma2);
      });
    });
  }
  get detalleArray(){
    return <FormArray>this.forma2.get('detalles');
  }
  get estado(){
    return <FormArray>this.forma2.get('historial_estado');
  }
  vaciardetalleArray(){
    while (this.detalleArray.length !== 0) {
      this.detalleArray.removeAt(0)
    }
  }
  agregardetalledatos(id_detalle,cantidad,id_articulo,articulo,importe_detalle,importe_total,confirmado){
    return this._forma.group({
      id_detalle:[id_detalle],
      cantidad:[cantidad,[Validators.required,Validators.pattern("[0-9]*")]],
      id_articulo:[id_articulo,''],
      articulo:[articulo,Validators.required],
      importe_detalle:[importe_detalle,Validators.required],
      confirmado_detalle:[confirmado,''],
      total_detalle:[importe_total,''],
    })
  }
  agregarhistorialdatos(fkestado,estado,fecha_inicio,fecha_hasta){
    return this._forma.group({
      fkestado:[fkestado],
      estado:[estado],
      fecha_inicio:[fecha_inicio],
      fecha_hasta:[fecha_hasta]
    })
  }
  editarestado(id_presupuesto,id_estado,importe_confirmado,id_pantalla){
    this.Auth.seguimientoPantallas(id_pantalla).subscribe(data=>{});
    if(id_estado=='')
    {
      Swal.fire(    'Oops',
                    'Debe seleccionar un estado',

                    'error'
      );
    }else
    {
        if(( id_estado!=6 && parseInt(importe_confirmado)>0)|| (id_estado==6 && parseInt(importe_confirmado)<=0)){
            Swal.fire({
              title: 'Estas seguro?',
              text: "¿Desea cambiar de estado? " ,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText:'Cancelar',
              confirmButtonText: 'Aceptar '
            }).then((result) => {
              if (result.value) {
                this.spinner.show()
                this.presupuestoService.updateEstado(id_presupuesto,id_estado).subscribe(data=>{

                   if(data.success)
                   {
                     this.presupuestoService.listar_presupuesto_id(id_presupuesto,'').subscribe(data=>{
                      // this.spinner.hide();
                       this.presupuesto_particular= data;
                    });
                    this.buscarid();
                    Swal.fire( 'Realizado',
                                  data.messagge,
                                'success');
                  }else{
                    this.spinner.hide()
                    Swal.fire(    'Oops',
                                  data.messagge,

                                  'error'
                    );
                  }
                })
              }
          })
        }else{
          Swal.fire(    'Oops',
                        'Si desea cambiar el estado de un presupuesto sin items confirmados primero deberá confirmar al menos un item, asi mismo si el presupuesto posee items confirmados el estado NO debe ser "SIN CONFIRMAR", Verifique el estado y los items confirmados',
                        'error');
        }
    }

  }
  insertarrequerimiento(fknumero_presupuesto,requerimiento){
    this.Auth.seguimientoPantallas(46).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea insertar este requerimiento?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
    }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestoService.guardarrequerimiento(fknumero_presupuesto,requerimiento).subscribe(data=>{
          this.spinner.hide()
          if(data.success)
          {
            this.presupuestoService.listar_presupuesto_id(fknumero_presupuesto,'').subscribe(data=>{
              this.spinner.hide();
              this.presupuesto_particular= data;

              });
             Swal.fire( 'Realizado',
                            data.messagge,
                          'success'
                      );
          }else{
              Swal.fire( 'Oops',
                          data.messagge,
                          'error'
                        );
          }
        })
      }
    })
  }
  eliminarRequerimientos(id_requerimiento,id){
    this.Auth.seguimientoPantallas(47).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea eliminar este requerimiento? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestoService.eliminarRequerimiento(id_requerimiento).subscribe(data=>{
             this.spinner.hide()
             if(data.success)
             {
               this.presupuestoService.listar_presupuesto_id(id,'CLIENTE').subscribe(data=>{
                 this.spinner.hide();
                 this.presupuesto_particular= data;

                 });

               Swal.fire( 'Realizado',
                            data.messagge,
                          'success');
            }else{
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })
  }
  insertarevento(fknumero_presupuesto,fkevento){
    this.Auth.seguimientoPantallas(30).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea insertar este evento?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
    }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestoService.guardarEvento(fknumero_presupuesto,fkevento,'').subscribe(data=>{
          this.spinner.hide()
          if(data.success)
          {
            this.presupuestoService.listar_presupuesto_id(fknumero_presupuesto,'').subscribe(data=>{
              this.spinner.hide();
              this.presupuesto_particular= data;

              });
             Swal.fire( 'Realizado',
                            data.messagge,
                          'success'
                      );
          }else{
              Swal.fire( 'Oops',
                          data.messagge,
                          'error'
                        );
          }
        })
      }
    })
  }
  cerrarevento(fknumero_presupuesto,fkhistorico_evento){
    this.Auth.seguimientoPantallas(31).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea cerrar este evento?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
    }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestoService.guardarEvento(fknumero_presupuesto,'',fkhistorico_evento).subscribe(data=>{
          this.spinner.hide()
          if(data.success)
          {
            this.presupuestoService.listar_presupuesto_id(fknumero_presupuesto,'').subscribe(data=>{
              this.spinner.hide();
              this.presupuesto_particular= data;

              });
             Swal.fire( 'Realizado',
                            data.messagge,
                          'success'
                      );
          }else{
              Swal.fire( 'Oops',
                          data.messagge,
                          'error'
                        );
          }
        })
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {UsuariosService} from "../../services/usuarios.service";
import Swal from 'sweetalert2';
import {AuthService} from "../../services/auth.service";
import {MovimientosService} from '../../services/movimientos.service';
@Component({
  selector: 'app-cuenta-corriente',
  templateUrl: './cuenta-corriente.component.html',
  styleUrls: ['./cuenta-corriente.component.css']
})
export class CuentaCorrienteComponent implements OnInit {
  forma:FormGroup;
  saldo;
  movimientostabla;
  movimientoParticular;
  tipo_moneda;
  conceptos;
  conceptos_importe
  habilitadoRechazar
  constructor(private usuariosService:UsuariosService,private Auth: AuthService,private movimiento:MovimientosService ,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder) {
    this.Auth.seguimientoPantallas(13).subscribe(data=>{});
    this.forma=this._forma.group({
                                      fecha_desde:['',Validators.required],
                                      fecha_hasta: ['',Validators.required]

                                })
    this.spinner.show();
    this.movimiento.lista_movimiento().subscribe(data=>{
        //console.log(data)
        this.movimientostabla=data.movimientos;
        this.conceptos_importe=data.concepto;
        this.saldo=data.saldo;
        this.spinner.hide();
    });
    this.usuariosService.permisopantalla('16').subscribe(data=>{
        this.habilitadoRechazar=data.success;
    });
    this.movimiento.listarconcepto().subscribe(data=>{
      this.conceptos=data;
    })
    this.movimiento.listartipomoneda().subscribe(data=>{
      this.tipo_moneda=data;
      //console.log(data)
    })
  }

  ngOnInit() {

  }

  buscar(){
    this.spinner.show();
    this.movimiento.lista_movimiento(this.forma.value.fecha_desde,this.forma.value.fecha_hasta).subscribe(data=>{
        this.movimientostabla=data.movimientos;
        this.conceptos_importe=data.concepto;
        this.saldo=data.saldo;
        this.spinner.hide();
       // console.log(data)
    });
  }
  buscarid(id){
    this.spinner.show();
    this.movimiento.lista_movimiento_id(id).subscribe(data=>{
        this.movimientoParticular=data.movimientos;
        this.spinner.hide();

    });
  }
  eliminarMovimiento(id){
    this.Auth.seguimientoPantallas(16).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea rechazar este movimeinto? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
            this.spinner.show();
            this.movimiento.eliminarMovimiento(id).subscribe(data=>{
            this.spinner.hide();
              if(data.success)
              {

                Swal.fire(

                            'Realizado',
                            data.messagge,
                           'success');
                this.movimiento.lista_movimiento().subscribe(data=>{
                    this.movimientostabla=data.movimientos;
                    this.conceptos_importe=data.concepto;
                      this.saldo=data.saldo;

                });
              }else{
                Swal.fire(    'Oops',
                              data.messagge,
                              'error'
                );
              }
            });
        }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, Validators} from '@angular/Forms';
import {AuthService} from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {UsuariosService} from '../../services/usuarios.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-datospersonales',
  templateUrl: './datospersonales.component.html',
  styleUrls: ['./datospersonales.component.css']
})
export class DatospersonalesComponent implements OnInit {
  usuario;
  forma:FormGroup;
  constructor(private auth:AuthService,private spinner: NgxSpinnerService,private usuariosService: UsuariosService) {
    this.usuario= auth.preguntarUsuario();
    this.forma=new FormGroup({
      'nombrecompleto': new FormGroup({
        'dni':new FormControl(this.usuario.dni,[Validators.required,Validators.minLength(6),Validators.pattern("[0-9]*")]),
        'nombre': new FormControl(this.usuario.nombre,[Validators.required,Validators.minLength(3)]),
        'apellido': new FormControl(this.usuario.apellido,[Validators.required,Validators.minLength(3)])

      }),
      'password':new FormControl('',[Validators.required,Validators.minLength(4)]),
    })
  }

  ngOnInit() {
  }
  guardarCambios(){

      if(this.forma.valid)
      {
        this.spinner.show();
        this.usuariosService.modificarUsuario(this.usuario.id,this.forma.value.nombrecompleto.dni,this.forma.value.nombrecompleto.nombre,this.forma.value.nombrecompleto.apellido,this.forma.value.password).subscribe(data=>{
          this.spinner.hide()
          //window.alert(data.messagge)
          if(data.success)
          {

            Swal.fire( {type: 'success',
                        title: 'Realizado',
                        text:data.messagge}).then((result) => {
                          let usuario=data.usuario
                          this.auth.buscarArea().subscribe(data=>{

                            this.auth.setUsuario(usuario,data.areasPantallas);
                            this.usuario= this.auth.preguntarUsuario();
                            this.forma.get('password').setValue('')
                            //window.location.reload();
                          })


                  });

          }else{
            Swal.fire( {type: 'error',
                        title: 'Oops...',
                        text:data.messagge});
          }
        })
      }
      else
      {
        Swal.fire( {type: 'error',
                    title: 'Oops...',
                    text:'Quedan campos sin completas o no son validos'});
      }
  }
}

import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {HomeComponent} from "./components/home/home.component";
import {NuevouserComponent} from "./components/nuevouser/nuevouser.component";
import {BuscarUsuarioComponent} from "./components/buscar-usuario/buscar-usuario.component";
import {ListadouserComponent} from './components/listadouser/listadouser.component';
import {UsuarioComponent}from './components/usuario/usuario.component';
import {ContraseniaComponent} from './components/contrasenia/contrasenia.component';
import {DatospersonalesComponent} from './components/datospersonales/datospersonales.component';
import {AuthGuardService} from "./services/auth-guard.service";
import {EditaruserComponent} from './components/editaruser/editaruser.component';
import {NuevoClienteComponent} from './components/nuevo-cliente/nuevo-cliente.component';
import {ListadoClientesComponent} from './components/listado-clientes/listado-clientes.component';
import {BuscarClienteComponent} from './components/buscar-cliente/buscar-cliente.component';
import {EditarClienteComponent} from './components/editar-cliente/editar-cliente.component';
import {HistorialpagosComponent} from './components/historialpagos/historialpagos.component';
import {NuevoMovimientoClienteComponent} from './components/nuevo-movimiento-cliente/nuevo-movimiento-cliente.component';
import {NuevoMovimientoComponent} from './components/nuevo-movimiento/nuevo-movimiento.component';
import {NuevoPresupuestoComponent} from './components/nuevo-presupuesto/nuevo-presupuesto.component';
import {CuentaCorrienteComponent} from './components/cuenta-corriente/cuenta-corriente.component';
import {ListadoChequeComponent} from './components/listado-cheque/listado-cheque.component';
import {MovimientosUsuarioComponent} from './components/movimientos-usuario/movimientos-usuario.component';
import {HistorialpagosusuariosComponent} from './components/historialpagosusuarios/historialpagosusuarios.component';
import {ListadoPresupuestosComponent} from './components/listado-presupuestos/listado-presupuestos.component';
import {ListadoPresupuestoClienteComponent} from './components/listado-presupuesto-cliente/listado-presupuesto-cliente.component';
import {NuevoPresupuestoUsuarioComponent} from './components/nuevo-presupuesto-usuario/nuevo-presupuesto-usuario.component';
import {ListadoPresupuestoUsuarioComponent} from './components/listado-presupuesto-usuario/listado-presupuesto-usuario.component';
import {NuevoProveedorComponent} from './components/nuevo-proveedor/nuevo-proveedor.component';
import { ListadoProveedoresComponent } from './components/listado-proveedores/listado-proveedores.component';
import { EditarProveedorComponent } from './components/editar-proveedor/editar-proveedor.component';
import {CajaChicaComponent } from './components/caja-chica/caja-chica.component';
import {TranspasoCajachicaComponent} from './components/transpaso-cajachica/transpaso-cajachica.component';
import {InfoPresupuestoComponent} from './components/info-presupuesto/info-presupuesto.component';
import {ArchivosPresupuestosComponent} from './components/archivos-presupuestos/archivos-presupuestos.component'
import {EstadosEventosComponent} from './components/estados-eventos/estados-eventos.component'
import {ComentariosComponent} from './components/comentarios/comentarios.component'

const app_routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent},
  { path: 'listadouser', component: ListadouserComponent, canActivate:[AuthGuardService]},
  { path: 'nuevouser', component: NuevouserComponent, canActivate:[AuthGuardService]},
  { path: 'buscar-usuario/:termino', component: BuscarUsuarioComponent, canActivate:[AuthGuardService]},
  { path: 'usuario/:id', component:UsuarioComponent, canActivate:[AuthGuardService]},
  { path: 'contrasenia', component:ContraseniaComponent},
  { path: 'datospersonales', component:DatospersonalesComponent},
  { path: 'editaruser', component:EditaruserComponent},
  { path: 'nuevoCliente',component:NuevoClienteComponent, canActivate:[AuthGuardService]},
  { path: 'listadoClientes',component:ListadoClientesComponent, canActivate:[AuthGuardService]},
  { path: 'buscar-cliente/:id', component: BuscarClienteComponent, canActivate:[AuthGuardService]},
  { path: 'editar-cliente/:id', component: EditarClienteComponent, canActivate:[AuthGuardService]},
  { path: 'historialpagos/:id', component: HistorialpagosComponent, canActivate:[AuthGuardService]},
  { path: 'NuevoMovimientoClienteComponent/:id', component:NuevoMovimientoClienteComponent, canActivate:[AuthGuardService]},
  { path: 'NuevoMovimientoComponent', component:NuevoMovimientoComponent, canActivate:[AuthGuardService]},
  { path: 'NuevoPresupuestoComponent/:id', component:NuevoPresupuestoComponent, canActivate:[AuthGuardService]},
  { path: 'CuentaCorrienteComponent', component:CuentaCorrienteComponent, canActivate:[AuthGuardService]},
  { path: 'ListadoChequeComponent', component:ListadoChequeComponent, canActivate:[AuthGuardService]},
  { path: 'movimientos-usuario/:id', component:MovimientosUsuarioComponent, canActivate:[AuthGuardService]},
  { path: 'historialpagosusuarios/:id', component:HistorialpagosusuariosComponent, canActivate:[AuthGuardService]},
  { path: 'listadoPresupuestosComponent', component:ListadoPresupuestosComponent, canActivate:[AuthGuardService]},
  { path: 'listadoPresupuestosComponent/:idestado', component:ListadoPresupuestosComponent, canActivate:[AuthGuardService]},
  { path: 'listadoPresupuestoClienteComponent/:id', component:ListadoPresupuestoClienteComponent, canActivate:[AuthGuardService]},
  { path: 'NuevoPresupuestoUsuarioComponent/:id', component:NuevoPresupuestoUsuarioComponent, canActivate:[AuthGuardService]},
  { path: 'listadoPresupuestoUsuarioComponent/:id', component:ListadoPresupuestoUsuarioComponent, canActivate:[AuthGuardService]},
  { path: 'NuevoProveedorComponent', component:NuevoProveedorComponent, canActivate:[AuthGuardService]},
  { path: 'ListadoProveedoresComponent', component:ListadoProveedoresComponent, canActivate:[AuthGuardService]},
  { path: 'editarProveedor/:id', component:EditarProveedorComponent, canActivate:[AuthGuardService]},
  { path: 'cajachica', component: CajaChicaComponent, canActivate:[AuthGuardService]},
  { path: 'tanspaso-cajachicha', component: TranspasoCajachicaComponent, canActivate:[AuthGuardService]},
  { path: 'info-presupuesto/:id/:pantalla/:idestado', component: InfoPresupuestoComponent, canActivate:[AuthGuardService]},
  { path: 'info-presupuesto/:id/:pantalla/:idclienteusuario/:idestado', component: InfoPresupuestoComponent, canActivate:[AuthGuardService]},
  { path: 'info_archivo/:id/:pantalla/:idestado', component: ArchivosPresupuestosComponent, canActivate:[AuthGuardService]},
  { path: 'info_archivo/:id/:pantalla/:idclienteusuario/:idestado', component: ArchivosPresupuestosComponent, canActivate:[AuthGuardService]},
  { path: 'estadoseventos/:id/:pantalla/:idestado', component: EstadosEventosComponent, canActivate:[AuthGuardService]},
  { path: 'estadoseventos/:id/:pantalla/:idclienteusuario/:idestado', component: EstadosEventosComponent, canActivate:[AuthGuardService]},
  { path: 'comentarios/:id/:pantalla/:idestado', component: ComentariosComponent, canActivate:[AuthGuardService]},
  { path: 'comentarios/:id/:pantalla/:idclienteusuario/:idestado', component: ComentariosComponent, canActivate:[AuthGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '/home' }
];

export const APP_ROUTING = RouterModule.forRoot(app_routes);

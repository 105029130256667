import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
//import { LoadingController } from 'ionic-angular';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {FormGroup,FormControl, Validators} from '@angular/Forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  forma:FormGroup;
  usuario:any={
    usuario:"",
    password:""
    //pasatiempos:["correr","dormir","comer"]
  }
  constructor(private Auth: AuthService,private router:Router,private spinner: NgxSpinnerService) {
    this.forma=new FormGroup({

        'usuario': new FormControl('',Validators.required),
        'password': new FormControl('',Validators.required)
      })
  }

  ngOnInit() {


  }


  loginUser(event){

    if(this.forma.valid)
    {
      this.spinner.show();


      event.preventDefault();
      const target= event.target;
      const username= target.querySelector('#username').value;
      const password= target.querySelector('#password').value;


      this.Auth.getUserDetails(username,password).subscribe(data=>{
         this.spinner.hide();
        if(data.success)
        {
          //console.log(data);
          if(data.usuario.fecha_contrasenia)
          {

            this.Auth.setUsuario(data.usuario,data.areasPantallas);

            this.router.navigate(['/home']);
            this.Auth.setAreas(data.areasPantallas);
            this.Auth.setLoggedIn(true);
          }
          else
          {
            Swal.fire( {type: 'error',
                title: 'Oops...',
                text:'Su password esta caducado, Para ingresar al sistema deberá cambiarlo'});
              this.Auth.setUsuario(data.usuario,data.areasPantallas);
                //this.Auth.setLoggedIn(false);
              this.router.navigate(['contrasenia']);
          }

        }
        else
        {
          Swal.fire( {type: 'error',
                      title: 'Oops...',
                      text:data.messagge})
        }
      })

    }else{
      Swal.fire( {type: 'error',
                  title: 'Oops...',
                  text:'Los campos son requeridos, debe ingresar el usuario y password'})
    }
  }
}

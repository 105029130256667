import { Component, OnInit } from '@angular/core';
import {UsuariosService} from "../../services/usuarios.service";
import {Router}from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {AuthService}from '../../services/auth.service';
@Component({
  selector: 'app-listadouser',
  templateUrl: './listadouser.component.html',
  styleUrls: ['./listadouser.component.css']
})
export class ListadouserComponent implements OnInit {
  private habilitadobloquear;
  private habilitadopermisopantalla;
  private habilitadomovimiento;
  private habilitadohistorial;
  private habilitadopresupuesto;
  private habilitadoverpresupuesto;
  constructor(public usuariosService:UsuariosService,private router:Router,private spinner: NgxSpinnerService,private auth:AuthService) {


  }

  ngOnInit() {
    this.spinner.show();
    //this.auth.seguimientoPantallas(1).subscribe(data=>{});
    this.usuariosService.listaruser().subscribe(data=>{
      this.spinner.hide();
      this.usuariosService.setListadoUsuario(data.usuarios);
    })
    this.usuariosService.permisopantalla('4').subscribe(data=>{
        this.habilitadobloquear=data.success;

    });
    this.usuariosService.permisopantalla('3').subscribe(data=>{
        this.habilitadopermisopantalla=data.success;
    });
    this.usuariosService.permisopantalla('17').subscribe(data=>{
        this.habilitadomovimiento=data.success;
    });
    this.usuariosService.permisopantalla('19').subscribe(data=>{
        this.habilitadohistorial=data.success;
    });
    this.usuariosService.permisopantalla('38').subscribe(data=>{
        this.habilitadopresupuesto=data.success;
    });
    this.usuariosService.permisopantalla('39').subscribe(data=>{
        this.habilitadoverpresupuesto=data.success;
    });
  }
  buscarUsuario(termino:string){

    this.router.navigate(['/buscar-usuario',termino]);

  }
}

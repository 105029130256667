import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ClientesService} from '../../services/clientes.service';
import {ActivatedRoute} from '@angular/router';
import {PresupuestosService} from '../../services/presupuestos.service'
import {  clientesModels} from '../../models/clientes.models';
import { NgxSpinnerService } from 'ngx-spinner';
import {UsuariosService} from "../../services/usuarios.service";
import {FileuploadService} from "../../services/fileupload.service";
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-listado-presupuesto-cliente',
  templateUrl: './listado-presupuesto-cliente.component.html',
  styleUrls: ['./listado-presupuesto-cliente.component.css']
})
export class ListadoPresupuestoClienteComponent implements OnInit {
  cliente:clientesModels= new clientesModels();
  idCliente
  presupuesto;
  presupuesto_particular
  habilitadoinfo;
  habilitadodescargar;
  habilitadoarchivos;
  habilitadoEliminarPresupuesto;
  habilitadovereventospresupuesto;
  habilitadopasarafabricacion;
  habilitadovercomentario;
  eventos;
  @ViewChild('inputArchivo')
  inputArchivoVariable: ElementRef;
  constructor(private _forma:FormBuilder,private uploadService:FileuploadService,private Auth: AuthService,private usuariosService:UsuariosService,private presupuestosService:PresupuestosService,private clienteService:ClientesService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
      this.idCliente=params['id']
      this.clienteService.listarClientesid(params['id']).subscribe(data=>{
        this.cliente=data.clientesModels;
      })
    });

    this.activatedRoute.params.subscribe( params=>{
      this.presupuestosService.listar_presupuesto_personas(params['id'],'CLIENTE').subscribe(data=>{
        this.spinner.hide();
        this.presupuesto=data;
        //console.log(data);
      })
    })
    this.usuariosService.permisopantalla('21').subscribe(data=>{
        this.habilitadoinfo=data.success;
    });
    this.usuariosService.permisopantalla('22').subscribe(data=>{
        this.habilitadodescargar=data.success;
    });
    this.usuariosService.permisopantalla('23').subscribe(data=>{
        this.habilitadoarchivos=data.success;
    });
   
    this.usuariosService.permisopantalla('28').subscribe(data=>{
        this.habilitadoEliminarPresupuesto=data.success;
    });
    this.usuariosService.permisopantalla('29').subscribe(data=>{
        this.habilitadovereventospresupuesto=data.success;
    });
    
    this.usuariosService.permisopantalla('32').subscribe(data=>{
        this.habilitadopasarafabricacion=data.success;
    });
    
    this.usuariosService.permisopantalla('35').subscribe(data=>{
        this.habilitadovercomentario=data.success;
    });
  }

  ngOnInit() {
  }


  imprimir(id_presupuesto){
    this.Auth.seguimientoPantallas(22).subscribe(data=>{});
    this.presupuestosService.donwloadPDF(id_presupuesto,'CLIENTE');
  }

 
  eliminarpresupuesto(id){
    this.Auth.seguimientoPantallas(25).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea eliminar este Presupuesto? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestosService.eliminar_presupuesto(id).subscribe(data=>{
             //this.spinner.hide()
             if(data.success)
             {
               this.activatedRoute.params.subscribe( params=>{
                 this.presupuestosService.listar_presupuesto_personas(params['id'],'CLIENTE').subscribe(data=>{
                   this.spinner.hide();
                   this.presupuesto=data;
                  // console.log(data);
                 })
               })
               Swal.fire( 'Realizado',
                            data.messagge,
                          'success');
            }else{
              this.spinner.hide()
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })
  }

  
  
  

}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {UsuariosService} from "../../services/usuarios.service";
import Swal from 'sweetalert2';
import {AuthService} from "../../services/auth.service";
import {MovimientosService} from '../../services/movimientos.service';

@Component({
  selector: 'app-listado-cheque',
  templateUrl: './listado-cheque.component.html',
  styleUrls: ['./listado-cheque.component.css']
})
export class ListadoChequeComponent implements OnInit {
  ListadoCheque;
  buscado:boolean=false;
  bancos;
  termino;
  tipo_moneda
  movimientoParticular;
  habilitadoRechazar;
  habilitadoCobrar;
  forma:FormGroup;
  constructor(private usuariosService:UsuariosService,private Auth: AuthService,private movimiento:MovimientosService ,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.forma=new FormGroup({
                                'tipochecked': new FormControl(''),
                                'tipo': new FormControl('1'),
                                'bancochecked': new FormControl(''),
                                'banco': new FormControl('1'),
                                'entregadoChecked': new FormControl(''),
                                'entregado': new FormControl('1'),
                                'estado': new FormControl(''),
                                'estadovalor': new FormControl('1')
                          })
    this.Auth.seguimientoPantallas(14).subscribe(data=>{});
    this.movimiento.listarchequeTodos('',false,'','','','','','','','').subscribe(data=>{
          this.ListadoCheque=data;
          this.spinner.hide();
    });
    this.usuariosService.permisopantalla('15').subscribe(data=>{
        this.habilitadoRechazar=data.success;

    });
    this.usuariosService.permisopantalla('18').subscribe(data=>{
        this.habilitadoCobrar=data.success;

    });
    this.movimiento.listarbanco().subscribe(data=>{
      this.bancos=data;
    });
    this.movimiento.listartipomoneda().subscribe(data=>{
        this.tipo_moneda=data;
        this.spinner.hide();
        //console.log(this.pais)
    });
  }
  ngOnInit() {

  }
  buscarCheque(termino:string){
    this.buscado=true;
    this.spinner.show();
    this.termino=termino;

    this.movimiento.listarchequeTodos(termino,this.buscado,this.forma.value.tipochecked,this.forma.value.bancochecked,this.forma.value.entregadoChecked,this.forma.value.estado,this.forma.value.tipo,this.forma.value.banco,this.forma.value.entregado,this.forma.value.estadovalor).subscribe(data=>{
          this.spinner.hide();
          this.ListadoCheque= data;
            console.log(data);
    })
    //this.heroes=this._heroesService.buscarHeroes(params['termino']);
  }
  buscarid(id){
      console.log(id)
    this.spinner.show();
    this.movimiento.lista_movimiento_id(id).subscribe(data=>{
        console.log(data)
        this.movimientoParticular=data.movimientos;
        this.spinner.hide();
    });
  }
  rechazarCheque(id,termino:string){
    this.Auth.seguimientoPantallas(15).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea rechazar este cheque? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.movimiento.cambiarEstadoCheque(id,1).subscribe(data=>{
             this.spinner.hide()
             if(data.success)
             {
               if(this.buscado)
               {
                 this.buscarCheque(termino)
               }
               else
               {
                 this.movimiento.listarchequeTodos('',false,'','','','','','','','').subscribe(data=>{
                       this.ListadoCheque=data;
                       this.spinner.hide();

                 });
               }
               Swal.fire( 'Realizado',
                            data.messagge,
                          'success');
            }else{
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })

  }
  CobrarCheque(id,termino:string){
    this.Auth.seguimientoPantallas(18).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea cobrar este cheque? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.movimiento.cambiarEstadoCheque(id,2).subscribe(data=>{
             this.spinner.hide()
             if(data.success)
             {
               if(this.buscado)
               {
                 this.buscarCheque(termino)
               }
               else
               {
                 this.movimiento.listarchequeTodos('',false,'','','','','','','','').subscribe(data=>{
                       this.ListadoCheque=data;
                       this.spinner.hide();

                 });
               }
               Swal.fire( 'Realizado',
                            data.messagge,
                          'success');
            }else{
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })

  }
}

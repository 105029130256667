import { Component, OnInit ,Input,Output, EventEmitter} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ClientesService} from '../../services/clientes.service';
import {AuthService}from '../../services/auth.service';
import {Router}from '@angular/router';
@Component({
  selector: 'app-clientetarjeta',
  templateUrl: './cliente.tarjeta.component.html',
  styleUrls: ['./cliente.tarjeta.component.css']
})
export class ClienteTarjetaComponent implements OnInit {
    @Input() cliente:any={};
      @Output() clienteSeleccionado:EventEmitter<number>;
      @Input() habilitadoeditar;
      @Input() habilitadover;
      @Input() habilitadohistoricoPago;
      @Input() habilitadoingresoPago;
      @Input() habilitadoPresupuesto;
      @Input() habilitadoListadoPresupuesto;
      visible:boolean=false;
  constructor(private router:Router,private clienteService:ClientesService,private spinner: NgxSpinnerService,private auth:AuthService) {
        this.clienteSeleccionado=new EventEmitter();
   }
  ngOnInit() {
  }


  cambiar(id){
    //console.log(this.visible);
    if(this.visible)
    {
        this.visible=false;
    }else{
        this.visible=true;
    }

  }

}

import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import {UsuariosService} from '../../services/usuarios.service';
import {MovimientosService} from '../../services/movimientos.service'
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {AuthService} from "../../services/auth.service";
import {PresupuestosService} from "../../services/presupuestos.service";
import {FileuploadService} from "../../services/fileupload.service";
import * as jsPDF from 'jspdf';
@Component({
  selector: 'app-nuevo-presupuesto-usuario',
  templateUrl: './nuevo-presupuesto-usuario.component.html',
  styleUrls: ['./nuevo-presupuesto-usuario.component.css']
})
export class NuevoPresupuestoUsuarioComponent implements OnInit {
  archivo={
    nombre:null,
    nombreArchivo:null,
    base64textString:null,
    tipo:null,
  }
  @ViewChild('inputArchivo')
  inputArchivoVariable: ElementRef;
  usuario:any={};
  articulo
  tipo_moneda;
  materiales;
  estados;
  conceptos;
  talonarios;
  forma:FormGroup;
  forma2:FormGroup;
  valorIndice:number;
  valido:boolean=false;
  visible:boolean=false;
  profile = {
              fecha: "",
              fecha_entrega:"",
              area:"",
              concepto:"",
              tipo_moneda:"1",
              confirmado:"0",
              estado_inicial:"7",
              numero_externo: "",
              talonario: "",
              tiene_desc:"",
              tipo_descuento:"fijo",
              valor_descuento:"",
              importe:"0.00",
              colocacion:"",
              importe_confirmado:"0.00",
              importe_sin_descuento:"0.00",
              importe_confirmado_sin_descuento:"0.00",
              importe_descuento:"0.00",
              importe_descuento_confirmado:"0.00",
              observacion:"",
              detalle:"",
              archivos:"",
            };
  constructor(private uploadService:FileuploadService ,private Auth: AuthService,private presupuestosService:PresupuestosService,private movimientosService:MovimientosService,private _usuarioService:UsuariosService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder) {
    this.Auth.seguimientoPantallas(12).subscribe(data=>{});
    this.forma=this._forma.group({
                                      fecha:['',Validators.required],
                                      fecha_entrega:[''],
                                      area:[''],
                                      concepto: ['',Validators.required],
                                      tipo_moneda: ['1',Validators.required],
                                      confirmado:['0',''],
                                      colocacion:['',''],
                                      estado_inicial: ['7',Validators.required],
                                      numero_externo: [''],
                                      talonario: [''],
                                      tiene_desc:[''],
                                      tipo_descuento:['fijo'],
                                      valor_descuento:[''],
                                      importe_sin_descuento:['0.00',Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")],
                                      importe_confirmado_sin_descuento:['0.00',Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")],
                                      importe_descuento:['0.00',Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")],
                                      importe_descuento_confirmado:['0.00',Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")],
                                      importe: ['0.00',[Validators.required,Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      importe_confirmado: ['0.00',[Validators.required,Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      observacion: [''],
                                      detalle: this._forma.array( [this.agregardetalle()]),
                                      archivos:this._forma.array([])
                                })
    this.forma2=this._forma.group({
                                    material:['',Validators.required],
                                    material_vidrio:['',Validators.required],
                                    nombre_material:['',Validators.required],
                                    nombre_material_vidrio:['',Validators.required],
                                    es_anodizado:['',Validators.required],
                                    tipo_material:['',Validators.required],
                                    tiene_vidrio:['',Validators.required],
                                    precio_individual:['',Validators.required],
                                    precio_individual_anodizado:['',Validators.required],
                                    tipo_material_vidrio:['',Validators.required],
                                    precio_individual_vidrio:['0.00',Validators.required],
                                    ancho:['',Validators.required],
                                    alto: ['',Validators.required],
                                    precio: ['0.00',Validators.required],

                              })
    this.spinner.show();

    this.activatedRoute.params.subscribe( params=>{
       this._usuarioService.listaruserespecifico(params['id']).subscribe(data=>{
         this.usuario=data.usuario;
       })
    });
    this.movimientosService.listartipomoneda().subscribe(data=>{
        this.tipo_moneda=data;
      //  this.spinner.hide();
        //console.log(this.pais)
    });

    this.movimientosService.listarconcepto().subscribe(data=>{
      this.conceptos=data;

    })
    this.presupuestosService.listararticulo().subscribe(data=>{
        this.spinner.hide();
        this.articulo= data;
      //  console.log(data)
    })
    this.presupuestosService.listarMateriales().subscribe(data=>{
        this.materiales=data;
        console.log('-'+data)
      //  this.spinner.hide();
        //console.log(this.pais)
    });
    this.presupuestosService.listartalonario().subscribe(data=>{
        this.spinner.hide();
        this.talonarios= data
      //  console.log(data)
    })
    this.presupuestosService.listarestado().subscribe(data=>{
        this.spinner.hide();
        this.estados= data;
      //  console.log(data)
    })
  }

  ngOnInit() {
  }

   //detalle de archivos y articulos
    agregardetalle(){
      return this._forma.group({
        cantidad:['',[Validators.required,Validators.pattern("[0-9]*")]],
        id_articulo:['',''],
        articulo:['',Validators.required],
        importe_detalle:['',Validators.required],
        confirmado_detalle:[0,''],
      })
    }
    agregarArchivos(archivo){
      let tipo
      switch (archivo['tipo']) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          // $extension='docx';
           tipo= 'application/docx';
        break;
        case 'application/msword':
          //$extension='docx';
          tipo= 'application/doc';
        break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
  //$extension='xlsx';
          tipo= 'application/xlsx';
        break;
        case 'application/vnd.ms-excel':
         // $extension='xls';
          tipo= 'application/xls';
        break;
        case 'text/plain':
  //$extension='txt';
          tipo= 'text/txt';
        break;
        case 'application/x-zip-compressed':
  //$extension='zip';
          tipo= 'application/zip';
        break;
        default:
  //$var=explode("/",$archivo->tipo);
         //$extension=$var[1];
         tipo= archivo['tipo'];
       break;
      }
      return this._forma.group({
        nombreArchivo:[archivo['nombreArchivo'],Validators.required],
        base64textString:[archivo['base64textString'],Validators.required],
        tipo_archivo:[tipo,Validators.required]
      })
  
    }
    buscar_articulo(termino){
      this.presupuestosService.listararticulotermino(termino).subscribe(data=>{
        this.articulo=data;
      })
    }
    insertarArticulo(){
      this.detalleArray.push( this.agregardetalle());
    }
    insertarArchivo(){
      this.archivoArray.push( this.agregarArchivos(this.archivo));
      this.inputArchivoVariable.nativeElement.value = "";
    }
    eliminarArticulo(){
      //console.log(this.detalleArray.length)
      if(this.detalleArray.length>1)
      {
          this.detalleArray.removeAt((this.detalleArray.length-1));
      }
    }
    eliminarArchivo(){
      //console.log(this.detalleArray.length)

          this.archivoArray.removeAt((this.archivoArray.length-1));

    }
    insertarArticuloNuevo(articulo:{'pkarticulo':number,'articulo':string}){
        this.detalleArray.at(this.valorIndice).get('id_articulo').setValue(articulo['id_articulo'])
        this.detalleArray.at(this.valorIndice).get('articulo').setValue(articulo['articulo'])

    }
    indice(i,tipo)
    {
      this.valorIndice=i;
      if(tipo==2)
      {
        this.forma.controls['tipo_moneda'].reset(2,Validators.required);
      }
      //  console.log(this.valorIndice);
    }


    get detalleArray(){
      return <FormArray>this.forma.get('detalle');
    }
    get archivoArray(){
      return <FormArray>this.forma.get('archivos');
    }

    // confirmar importe

    importeConfirmado(i){
      let importe_detalle:number=0;
      let importe_total:number=0;
      let importe_descuento:number=0;
      let importe_descuento_confirmado:number=0;
      let descuento_detalle:number=0;
      let descuento_total:number=0;
      let confirmado='0';
      if((this.detalleArray.at(i).value.importe_detalle>0 && this.detalleArray.at(i).value.confirmado_detalle) || !this.detalleArray.at(i).value.confirmado_detalle )
      {
        for (let control of this.detalleArray.controls) {
          //console.log(control.value.confirmado)
          if(control.value.confirmado_detalle==1)
          {
            confirmado='1';
            importe_detalle=importe_detalle+ (parseFloat(control.value.importe_detalle)*parseFloat(control.value.cantidad))
          }
          importe_total=importe_total+ (parseFloat(control.value.importe_detalle)*parseFloat(control.value.cantidad));
        }
        if(this.forma.value.tiene_desc==1)
        {
          if(this.forma.value.tipo_descuento!='fijo')
          {
            if(importe_detalle>0)
            {
              descuento_detalle=(((importe_detalle*parseFloat(this.forma.value.valor_descuento))/100));
            }
            descuento_total=(((importe_total*parseFloat(this.forma.value.valor_descuento))/100));

          }
          else
          {
            if(importe_detalle>0)
            {
              descuento_detalle=(parseFloat(this.forma.value.valor_descuento));
            }
            descuento_total=(parseFloat(this.forma.value.valor_descuento));
          }

        }
        importe_descuento_confirmado=importe_detalle-descuento_detalle;
        importe_descuento=importe_total-descuento_total;
        this.forma.controls['importe_confirmado'].reset( importe_descuento_confirmado.toFixed(2), [Validators.required]);
        this.forma.controls['importe'].reset( importe_descuento.toFixed(2), [Validators.required]);
        this.forma.controls['importe_sin_descuento'].reset(importe_total.toFixed(2),[Validators.required]);
        this.forma.controls['importe_confirmado_sin_descuento'].reset(importe_detalle.toFixed(2),[Validators.required]);
        this.forma.controls['importe_descuento'].reset(descuento_total.toFixed(2));
        this.forma.controls['importe_descuento_confirmado'].reset(descuento_detalle.toFixed(2));
        this.forma.controls['confirmado'].reset(confirmado,'');
      }else{
        Swal.fire( {type: 'error',
        title: 'Oops...',
        text: 'Debe ingresar un importe'});
        this.detalleArray.at(i).get('confirmado_detalle').setValue(false)
      }
    }
    cambiovisible(){
      this.visible=false;

      if(this.forma.value.tiene_desc=='1')
      {
        this.visible=true;
        this.forma.controls.valor_descuento.reset('',Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$"));
      }else{
        this.visible=false;
        this.forma.controls.valor_descuento.reset('');
      }
    }
    guardarCambios(){

      if(this.forma.valid )
        {
          this.spinner.show();
          if( (this.forma.value.importe_confirmado>0 && this.forma.value.estado_inicial !=6)  || (this.forma.value.importe_confirmado<=0 && this.forma.value.estado_inicial ==6)   )
          {
            let presupuesto = {fecha:this.forma.value.fecha,
                                fecha_entrega:this.forma.value.fecha_entrega,
                                area:this.forma.value.area,
                                fktipo_moneda:this.forma.value.tipo_moneda,
                                fkestado:this.forma.value.estado_inicial,
                                numero_externo:this.forma.value.numero_externo,
                                talonario:this.forma.value.talonario,
                                fkconcepto:this.forma.value.concepto,
                                colocacion:this.forma.value.colocacion ,
                                confirmado:this.forma.value.confirmado ,
                                detalle:this.forma.value.detalle,
                                archivos:this.forma.value.archivos,
                                tiene_descuento:this.forma.value.tiene_desc,
                                tipo_descuento:this.forma.value.tipo_descuento,
                                valor_descuento:this.forma.value.valor_descuento,
                                importe_total:this.forma.value.importe_sin_descuento,
                                importe_descuento:this.forma.value.importe_descuento,
                                importe:this.forma.value.importe,
                                importe_confirmado_sin_descuento:this.forma.value.importe_confirmado_sin_descuento,
                                importe_descuento_confirmado:this.forma.value.importe_descuento_confirmado,
                                importe_confirmado:this.forma.value.importe_confirmado,
                                observacion:this.forma.value.observacion};

            this.activatedRoute.params.subscribe( params=>{
               this.presupuestosService.guardarPresupuesto(presupuesto,params['id'],'USUARIO').subscribe(data=>{
                this.spinner.hide()
                if(data.success)
                {
                  Swal.fire( {type: 'success',
                      title: 'Realizado',
                      text:data.messagge});
                  this.forma.reset(this.profile)
                  this.presupuestosService.donwloadPDF(data.id,'USUARIO');
                    //window.location.href ="www.cerramientosmartin.com/PDFpresupuesto.php?id="+data.id,'_blank';
                }else{
                  Swal.fire( {type: 'error',
                      title: 'Oops...',
                      text:data.messagge});
                }
              })
            })
          }else{
              this.spinner.hide()
            Swal.fire( {type: 'error',
            title: 'Oops...',
            text: 'Si desea agregar un presupuesto sin items confirmados el estado inicial del mismo debe ser "SIN CONFIRMAR" asi mismo si el presupuesto posee items confirmados el estado inicial NO debe ser "SIN CONFIRMAR", Verifique el estado inicial y los items confirmados'});
          }
        }
        else
        {
            Swal.fire( {type: 'error',
            title: 'Oops...',
            text: 'Quedan campos sin completas o no son validos'});
        }
      }

      //seleccion de archivo
      seleccionarAchivos(event){
        var files=event.target.files;
        var file=files[0];
        this.valido=false;
        //this.archivoArray.at(j).get('nombreArchivo').setValue(file.name)
        this.archivo.nombreArchivo=file.name;
        this.archivo.tipo= file.type;
  
        if(file.type=='application/x-zip-compressed' || file.type=='image/jpeg' || file.type=='image/png'|| file.type=='application/pdf' || file.type=='.pto' || file.type=='.doc' || file.type=='application/msword' || file.type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'|| file.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type=='application/vnd.ms-excel' || file.type=='text/plain' )
        {     
          this.valido=true;
          if(files && file)
          {
            var render = new FileReader();
            render.onload=this._handleReaderLoaded.bind(this);
            render.readAsBinaryString(file);
          }
        }
        else
        {
          this.valido=false;
          Swal.fire( {type: 'error',
          title: 'Oops...',
          text: 'El tipo de archivo no es Valido, solo se aceptan extensiones .ZIP, .JGP, .PNG ,.PDF, .XLS, .XLSX, .DOC, .DOCS, .TXT'});
          this.inputArchivoVariable.nativeElement.value = "";
        }
      }

      _handleReaderLoaded(readerEvent){
        //console.log(readerEvent+'{{}}');
        var binaryString=readerEvent.target.result;
        this.archivo.base64textString=btoa(binaryString);
        //this.archivoArray.at(j).get('base64textString').setValue(this.archivo.base64textString)

      }
      salirdemodalarchivo(){
        this.inputArchivoVariable.nativeElement.value = "";
      }
      cargardatos(){
        //console.log(this.forma2.value.material)
        this.presupuestosService.listarMateriales_id(this.forma2.value.material).subscribe(data=>{
          //console.log(data);
          this.forma2.controls['tipo_material'].reset( data[0].tipo_material, [Validators.required]);
          this.forma2.controls['nombre_material'].reset( data[0].material )
          this.forma2.controls['tiene_vidrio'].reset( data[0].tiene_vidrio, [Validators.required]);
          this.forma2.controls['precio_individual'].reset(data[0].precio);
          this.forma2.controls['precio_individual_anodizado'].reset(data[0].precio_anodizado)
          this.calcular_precio()
        });
      }
      cargardatos_vidrio(){
        //console.log(this.forma2.value.material)
        if(this.forma2.value.material_vidrio!='')
        {
          this.presupuestosService.listarMateriales_id(this.forma2.value.material_vidrio).subscribe(data=>{
          //  console.log(data);
          this.forma2.controls['nombre_material_vidrio'].reset( data[0].material )
            this.forma2.controls['precio_individual_vidrio'].reset(data[0].precio);
            this.calcular_precio()
          });

        }

      }
      calcular_precio(){
        //console.log('hola')
      //  console.log(this.presupuestosService.calcular_presupuesto(this.forma2.value.material,this.forma2.value.ancho,this.forma2.value.alto,this.forma2.value.precio_individual,this.forma2.value.precio_individual_anodizado,this.forma2.value.tipo_material,this.forma2.value.es_anodizado,this.forma2.value.precio_individual_vidrio));
          this.forma2.controls['precio'].reset(this.presupuestosService.calcular_presupuesto(this.forma2.value.material,this.forma2.value.ancho,this.forma2.value.alto,this.forma2.value.precio_individual,this.forma2.value.precio_individual_anodizado,this.forma2.value.tipo_material,this.forma2.value.es_anodizado,this.forma2.value.precio_individual_vidrio))
      }
      pasarPrecio(){
          let articulo=(this.forma2.value.nombre_material +' de ' + this.forma2.value.ancho + '*' + this.forma2.value.alto)
          if(this.forma2.value.tiene_vidrio=='1')
          {
             articulo= articulo  + ' ' + this.forma2.value.nombre_material_vidrio;
          }
            //this.detalleArray.at(this.valorIndice).get('id_articulo').setValue(articulo['id_articulo'])
            this.detalleArray.at(this.valorIndice).get('articulo').setValue(articulo)
            this.detalleArray.at(this.valorIndice).get('cantidad').setValue('1')
            this.detalleArray.at(this.valorIndice).get('importe_detalle').setValue(this.forma2.value.precio)
            this.importeConfirmado(this.valorIndice);


      }
  }

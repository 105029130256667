import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {PresupuestosService} from "../../services/presupuestos.service";
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from "../../services/auth.service";
import {UsuariosService} from "../../services/usuarios.service";
import {FileuploadService} from "../../services/fileupload.service";
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-listado-presupuestos',
  templateUrl: './listado-presupuestos.component.html',
  styleUrls: ['./listado-presupuestos.component.css']
})
export class ListadoPresupuestosComponent implements OnInit {
  forma:FormGroup;
  //forma2:FormGroup;
  presupuestos;
  variableestado='';
  presupuesto_particular;
  habilitadoinfo;
  habilitadodescargar;
  habilitadoarchivos;
  habilitadoEliminarPresupuesto;
  habilitadovereventospresupuesto;
  habilitadopasarafabricacion;
  habilitadovercomentario;
  estados;
  buscado=false;

  

  constructor(private uploadService:FileuploadService,private activatedRoute: ActivatedRoute,private _forma:FormBuilder,private usuariosService:UsuariosService,private presupuestoService:PresupuestosService,private Auth: AuthService,private spinner: NgxSpinnerService,) {
    this.Auth.seguimientoPantallas(20).subscribe(data=>{});
    this.spinner.show();
    this.forma=this._forma.group({
                                      fecha_desde:[''],
                                      fecha_hasta: [''],
                                      estado:['0']

                                })
                             
    //this.listar_buscador_presupuesto();

    this.presupuestoService.listarestado().subscribe(data=>{
        //this.spinner.hide();
        this.estados= data;
      //  console.log(data)
    })
   
    this.usuariosService.permisopantalla('21').subscribe(data=>{
        this.habilitadoinfo=data.success;
    });
    this.usuariosService.permisopantalla('22').subscribe(data=>{
        this.habilitadodescargar=data.success;
    });
    this.usuariosService.permisopantalla('23').subscribe(data=>{
        this.habilitadoarchivos=data.success;
    });
    this.usuariosService.permisopantalla('28').subscribe(data=>{
        this.habilitadoEliminarPresupuesto=data.success;
    });
    this.usuariosService.permisopantalla('29').subscribe(data=>{
        this.habilitadovereventospresupuesto=data.success;
    });
   
    this.usuariosService.permisopantalla('32').subscribe(data=>{
        this.habilitadopasarafabricacion=data.success;
    });
    this.usuariosService.permisopantalla('35').subscribe(data=>{
        this.habilitadovercomentario=data.success;
    });
   
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params=>{
      if(params['idestado'])
      {
        this.variableestado=params['idestado']; 
      }else{
        this.variableestado='';
      }
      this.forma.controls['estado'].reset(this.variableestado)
      this.listar_buscador_presupuesto();
    }); 
  }

  listar_buscador_presupuesto(){
   
    
    if(!this.buscado){
      this.presupuestoService.listar_presupuesto('','',this.forma.value.estado).subscribe(data=>{
        this.presupuestos= data;
          this.spinner.hide();
          //console.log(data);
      });
    }else{
      this.presupuestoService.listar_presupuesto(this.forma.value.fecha_desde,this.forma.value.fecha_hasta,this.forma.value.estado).subscribe(data=>{
        this.presupuestos= data;
          this.spinner.hide();
        //console.log(data);
      });
    }

   
  }
 
 

  imprimir(id_presupuesto){
    this.Auth.seguimientoPantallas(22).subscribe(data=>{});
    this.presupuestoService.donwloadPDF(id_presupuesto,'CLIENTE');
  }
  buscador()
  {
    this.spinner.show();
    //console.log(fecha_desde+','+fecha_hasta+','+id_estado)
    this.buscado=true;
    this.listar_buscador_presupuesto();
  }
  eliminarpresupuesto(id){
    this.Auth.seguimientoPantallas(25).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea eliminar este Presupuesto? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          this.presupuestoService.eliminar_presupuesto(id).subscribe(data=>{
             //this.spinner.hide()
             if(data.success)
             {
               this.listar_buscador_presupuesto();
               Swal.fire( 'Realizado',
                            data.messagge,
                          'success');
            }else{
              this.spinner.hide()
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })
  } 
}

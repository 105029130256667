import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from "@angular/router";
import * as jsPDF from 'jspdf';
import { Base64 } from 'js-base64';
interface myData{success: boolean, messagge: string,id?:number,presupuesto:any[]};

@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {
    private nombrephp:string='https://cerrapp.com/presupuesto.php';
    //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/presupuesto.php';
    constructor(private http:HttpClient,private router:Router) {
    }
    listararticulo(){
      let accion='traer_articulo';
      let body=JSON.stringify({accion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listarMateriales(){
      let accion='traer_materiales';
      let body=JSON.stringify({accion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listarMateriales_solo_vidrio(){
      let accion='traer_materiales_vidrio';
      let body=JSON.stringify({accion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listararticulotermino(termino){
      let accion='traer_articulo';
      let body=JSON.stringify({accion,termino} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listar_presupuesto_id(id,tipo){
      
      let accion='listar_presupuesto_id';
      let body=JSON.stringify({accion,id,tipo} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body
      ,{headers:headers});
    }
    listar_presupuesto(fecha_desde,fecha_hasta,id_estado){
      let idusuario=sessionStorage.getItem('id');
      let accion='listar_presupuesto';
      let body=JSON.stringify({accion,fecha_desde,fecha_hasta,id_estado,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body
      ,{headers:headers});
    }
    listar_presupuesto_personas(id,tipo){
      let accion='listar_presupuesto_persona';
      let body=JSON.stringify({accion,id,tipo} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body
      ,{headers:headers});
    }
    eliminar_presupuesto(id){
      let accion='eliminar_presupuesto';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    listarestado(){
      let accion='traer_estados';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listartalonario(){
      let accion='traer_talonarios';
      let body=JSON.stringify({accion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    listarevento(){
      let accion='traer_eventos';
      let body=JSON.stringify({accion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    guardarEvento(numero_presupuesto,fkevento,id_historico_evento){
      let accion='guardar_evento';
      let id_usuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,numero_presupuesto,fkevento,id_usuario,id_historico_evento} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    guardarrequerimiento(numero_presupuesto,requerimiento){
      let accion='guardar_requerimientos';
      let id_usuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,numero_presupuesto,requerimiento,id_usuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    guardar_observacion(numero_presupuesto,observacion){
      let accion='guardar_observacion';
      let id_usuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,numero_presupuesto,observacion,id_usuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    guardarPresupuesto(presupuesto,id,tipo){
      let accion='guardar_presupuesto';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,presupuesto,id,idusuario,tipo} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    confirmadetalle(presupuesto, confirmacion){
    //  console.log(presupuesto);
      let accion='confirmarDetalles';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,presupuesto,idusuario,confirmacion} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    actualizarDescuento(presupuesto){
    //  console.log(presupuesto);
      let accion='cambiar_descuento';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,presupuesto,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    guardarArchivos(archivos,idpasar){
      let accion='guardar_archivos';
     
      let idusuario=sessionStorage.getItem('id');
     
      let body=JSON.stringify({accion,archivos,idpasar,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    eliminarArchivos(id){
      let accion='eliminarArchivo';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    eliminarRequerimiento(id){
      let accion='eliminarRequerimiento';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    eliminarObservacion(id){
      let accion='eliminarObservacion';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    updateEstado(id,id_estado){
      let accion='uploadEstado';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,id_estado,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    updateFecha(id,fecha_entrega){
      let accion='uploadFecha';
      let idusuario=sessionStorage.getItem('id');
      
      let body=JSON.stringify({accion,id,fecha_entrega,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    updateArea(id,area){
      let accion='uploadArea';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,area,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    updateObservacion(id,observacion){
      let accion='uploadObservacion';
      let idusuario=sessionStorage.getItem('id');
      let body=JSON.stringify({accion,id,observacion,idusuario} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post<myData>(this.nombrephp,body,{headers:headers});
    }
    donwloadPDF(id,tipo){
      const doc = new jsPDF();
      var img = new Image();
      var presupuesto;
      img.src = 'assets/img/bannercerramientossinfondo.png';
      console.log('aaa');
      this.listar_presupuesto_id(id,tipo).subscribe(data=>{
        presupuesto=data;
        console.log(data)
        doc.rect(8, 8, 193,280); // empty square
        doc.addImage(img,'png',16,10,68,23);
        doc.setFontSize(10);
        doc.text('Adam, Matias Jorge-Av.Libertador 1299 / Mar de ajó',10,38);
        doc.text('Whatsapp: +5492257664330',10,42);
        doc.text('E.mail: cerramientos_martin@yahoo.com.ar',10,46);
        doc.text('Página: www.cecrramientosmartin.com',10,50);
        doc.setFontSize(60);
        doc.setTextColor(0);
        doc.rect(95, 16, 24,26); // empty square
        doc.text('X', 100, 36);
        doc.setFontSize(20);
        doc.text('Presupuesto ', 142, 17);
        doc.setFontSize(12);
        doc.text('(documento no valido como factura) ', 130, 23);
        doc.setFontSize(20);
        doc.text('N°:'+ presupuesto[0].numero_presupuesto, 140, 41);
        var fecha_entrega =presupuesto[0].fecha_presupuesto.substring(8,10)+'/'+presupuesto[0].fecha_presupuesto.substring(5,7)+'/'+presupuesto[0].fecha_presupuesto.substring(0,4)
        doc.text('Fecha:'+ fecha_entrega, 140, 49);
        doc.rect(10,52,190,0); // empty square
        //doc.text('', 160, 38);

        doc.setFontSize(12);
        doc.text('NOMBRE Y APELLIDO: '+ presupuesto[0].cliente[0].nombre +' ' + presupuesto[0].cliente[0].apellido,15,60);
        doc.text('DOMICILIO: '+presupuesto[0].cliente[0].calle+' '+ presupuesto[0].cliente[0].altura + ' '+((presupuesto[0].cliente[0].piso=='0')?'':presupuesto[0].cliente[0].piso)+' '+ presupuesto[0].cliente[0].depto + '  '+ presupuesto[0].cliente[0].localidad,15,65);
        doc.text('C.U.I.T: '+ ((presupuesto[0].cliente[0].cuit==0)?'':presupuesto[0].cliente[0].cuit) ,15,70);
       if(presupuesto[0].area)
       {
        doc.text('AREA/SUBCLIENTE: '+ presupuesto[0].area,15,75);

       }
       if(presupuesto[0].area)
       {
        doc.rect(10,78,190,0); // empty square
       }else{
        doc.rect(10,75,190,0);
       }
        doc.rect(10,80,20,10);
        doc.rect(30,80,100,10);
        doc.rect(130,80,35,10);
        doc.rect(165,80,33,10);
        doc.text('Cantidad',20,86,'center');
        doc.text('Detalle',40,86,'center');
        doc.text('Precio',140,86,'center');
        doc.text('Total',175,86,'center');
        let y=96;
        presupuesto[0].detalle.forEach(function(value){
          //console.log(value);
          if(y<=240)
          {
            doc.text(value.cantidad,20,y,'center');
            if(value.confirmado=='1')
            {
              var splitTitle = doc.splitTextToSize(value.detalle, 95);
            }else
            {
                var splitTitle = doc.splitTextToSize(value.detalle + ' (SIN CONFIRMAR)', 95);
            }

            doc.text(splitTitle,35,y,'left');
            doc.text(presupuesto[0].tipo_moneda +value.precio_detalle,135,y,'left')
            doc.text(presupuesto[0].tipo_moneda+value.total ,170,y,'left')
            splitTitle.forEach(function(){
              y=y+6;
            });
          }else{
            doc.addPage();
            doc.rect(8, 8, 193,280); // empty square
            doc.addImage(img,'png',16,10,68,23);
            doc.setFontSize(10);
            doc.text('Adam, Matias Jorge-Av.Libertador 1299 / Mar de ajó',10,38);
            doc.text('Whatsapp: +5492257664330',10,42);
            doc.text('E.mail: cerramientos_martin@yahoo.com.ar',10,46);
            doc.text('Página: www.cecrramientosmartin.com',10,50);
            doc.setFontSize(60);
            doc.setTextColor(0);
            doc.rect(95, 16, 24,26); // empty square
            doc.text('X', 100, 36);
            doc.setFontSize(20);
            doc.text('Presupuesto ', 142, 17);
            doc.setFontSize(12);
            doc.text('(documento no valido como factura) ', 130, 23);
            doc.setFontSize(20);
            doc.text('N°:'+ presupuesto[0].numero_presupuesto, 140, 41);
            var fecha_entrega =presupuesto[0].fecha_presupuesto.substring(8,10)+'/'+presupuesto[0].fecha_presupuesto.substring(5,7)+'/'+presupuesto[0].fecha_presupuesto.substring(0,4)
            doc.text('Fecha:'+ fecha_entrega, 140, 49);
            doc.rect(10,52,190,0); // empty square
            //doc.text('', 160, 38);
    
            doc.setFontSize(12);
            doc.text('NOMBRE Y APELLIDO: '+ presupuesto[0].cliente[0].nombre +' ' + presupuesto[0].cliente[0].apellido,15,60);
            doc.text('DOMICILIO: '+presupuesto[0].cliente[0].calle+' '+ presupuesto[0].cliente[0].altura + ' '+((presupuesto[0].cliente[0].piso=='0')?'':presupuesto[0].cliente[0].piso)+' '+ presupuesto[0].cliente[0].depto + '  '+ presupuesto[0].cliente[0].localidad,15,65);
            doc.text('C.U.I.T: '+ ((presupuesto[0].cliente[0].cuit==0)?'':presupuesto[0].cliente[0].cuit) ,15,70);
            doc.rect(10,75,190,0); // empty square
            doc.rect(10,80,20,10);
            doc.rect(30,80,100,10);
            doc.rect(130,80,35,10);
            doc.rect(165,80,33,10);
            doc.text('Cantidad',20,86,'center');
            doc.text('Detalle',40,86,'center');
            doc.text('Precio',140,86,'center');
            doc.text('Total',175,86,'center');
            y=96;
            doc.text(value.cantidad,20,y,'center');
            if(value.confirmado=='1')
            {
              var splitTitle = doc.splitTextToSize(value.detalle, 95);
            }else
            {
                var splitTitle = doc.splitTextToSize(value.detalle + ' (SIN CONFIRMAR)', 95);
            }

            doc.text(splitTitle,35,y,'left');
            doc.text(presupuesto[0].tipo_moneda +value.precio_detalle,135,y,'left')
            doc.text(presupuesto[0].tipo_moneda+value.total ,170,y,'left')
            splitTitle.forEach(function(){
              y=y+6;
            });
          }      
        })
        doc.rect(10,y,187,0);
        doc.text('Importe:' + presupuesto[0].tipo_moneda+ presupuesto[0].importe_sin_descuento ,122,y+6,'left')
        doc.text('Descuento:' + presupuesto[0].tipo_moneda+ presupuesto[0].descuento_total*-1 ,122,y+12,'left')
        doc.setFontType('bold');
        doc.text('Importe Total:' + presupuesto[0].tipo_moneda+ presupuesto[0].importe ,122,y+18,'left')
        doc.setFontType('normal');
        doc.text('Importe Confirmado:' + presupuesto[0].tipo_moneda+ presupuesto[0].importe_confirmado_sin_descuento ,122,y+24,'left')
        doc.text('Descuento:' + presupuesto[0].tipo_moneda+ presupuesto[0].descuento_total_confirmado*-1 ,122,y+30,'left')
        doc.setFontType('bold');
        doc.text('Total Importe Confirmado:' + presupuesto[0].tipo_moneda+presupuesto[0].importe_confirmado ,122,y+36,'left')
        doc.setFontType('normal');
        doc.rect(10,y+42,187,0); // empty square
        var splitTitle2 = doc.splitTextToSize( 'Observacion:'+ presupuesto[0].observacion, 160);
        doc.text( splitTitle2 ,20 ,y+48,'left')
        var f=new Date();
        doc.setFontSize(6);
        doc.text('Impreso el dia ' + f  ,10,290,'left')
        var x=doc.getLineHeight 
      
        doc.save('presupuesto-N-'+id+'.pdf');
      })


    }
    listarMateriales_id(id_tipo){
      //console.log(id_tipo);
      let accion='traer_materiales_id';
      let body=JSON.stringify({accion,id_tipo} );
      let headers=new HttpHeaders({
        'Content-Type':'application/json'
      });
      return this.http.post(this.nombrephp,body,{headers:headers});
    }
    calcular_presupuesto(id_tipo,ancho,alto,precio_individual,precio_individual_anodizado,tipo_material,es_anodizado,precio_individual_vidrio){
      let presupuesto;
      //console.log(id_tipo);
      let aluminio=0;
      let vidrio=0;
      if(tipo_material=='V')
      {
        presupuesto=((ancho/1000)*(alto/1000)*precio_individual)
      }else{
        switch (id_tipo) {
          case '1':
            //console.log(es_anodizado);

            if(es_anodizado!=1)
            {
              //console.log('entro_aca')
              aluminio=((((ancho/1000)*2)+((alto/1000)*3))*precio_individual)
            }else{
              aluminio=((((ancho/1000)*2)+((alto/1000)*3))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);
            presupuesto=aluminio+vidrio;
          break;
          case '2':
            //console.log(es_anodizado);
            if(es_anodizado!=1)
            {
              //console.log('entro_aca')
              aluminio=((((ancho/1000)*2)+((alto/1000)*2))*precio_individual)
            }else{
              aluminio=((((ancho/1000)*2)+((alto/1000)*2))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);
            presupuesto=aluminio+vidrio;
          break;
          case '3':
            if(ancho<1750 && alto<1800 )
            {
              aluminio=precio_individual*1;
            }else{
              presupuesto=0.00*1;
            }
            presupuesto=aluminio;
         break;
          case '4':

            aluminio=((ancho/1000)*(alto/1000))*precio_individual
            presupuesto=aluminio;
          break;
          case '5':

            aluminio=((((ancho/1000)*2)+((alto/1000)*2))*precio_individual)
            presupuesto=aluminio+vidrio;
          break;
          case '7':

            console.log(precio_individual)
            if(es_anodizado!=1)
            {
              aluminio=((ancho/1000)*precio_individual)
            }
            else
            {
              aluminio=((ancho/1000)*precio_individual_anodizado)

            }
            presupuesto=aluminio;
          break;
          case '8':

            console.log(precio_individual)
            if(es_anodizado!=1)
            {
              aluminio=((ancho/1000)*precio_individual)
            }
            else
            {
              aluminio=((ancho/1000)*precio_individual_anodizado)

            }
            presupuesto=aluminio;
          break;
          case '9':

            console.log(precio_individual)
            if(es_anodizado!=1)
            {
              aluminio=((ancho/1000)*precio_individual)
            }
            else
            {
              aluminio=((ancho/1000)*precio_individual_anodizado)

            }
            presupuesto=aluminio;
          break;
          case '10':

            console.log(precio_individual)
            if(es_anodizado!=1)
            {
              aluminio=((ancho/1000)*precio_individual)
            }
            else
            {
              aluminio=((ancho/1000)*precio_individual_anodizado)

            }
            presupuesto=aluminio;
          break;
          case '11':

            if((ancho*2)+(alto*2)<=1.6)
            {

              aluminio=precio_individual
            }else{
              aluminio=0
            }
            presupuesto=aluminio;
          break;
          case '12':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;

          case '21':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;

          case '22':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;
          case  '23':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;
          case  '24':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;
          case '25':

            aluminio=precio_individual;
            presupuesto=aluminio;
          break;

          case '26':

            if(es_anodizado!=1)
            {
              aluminio=((((ancho/1000)*2)+((alto/1000)*4))*precio_individual)
            }else{
              aluminio=((((ancho/1000)*2)+((alto/1000)*4))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);
            presupuesto=aluminio+vidrio;
          break;
          case '27':

            if(es_anodizado!=1)
            {
              aluminio=((((ancho/1000)*2)+((alto/1000)*4))*precio_individual)
            }else{
              aluminio=((((ancho/1000)*2)+((alto/1000)*4))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio+vidrio;
          break;
          case '28':

            if(es_anodizado!=1)
            {
              aluminio=((((ancho/1000)*2)+((alto/1000)*5))*precio_individual)
            }else{
              aluminio=((((ancho/1000)*2)+((alto/1000)*5))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio+vidrio;
          break;
          case '29':

            if(es_anodizado!=1)
            {
              aluminio=(( ( ((ancho/1000)*2)+((alto/1000)*2))*0.756)*precio_individual);
            }else{
              aluminio=( (( ((ancho/1000)*2)+((alto/1000)*2))*0.756)*precio_individual_anodizado);
            }
            //vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio;
          break;
          case '30':

            if(es_anodizado!=1)
            {
              aluminio=((  ((ancho/1000)*2)+((alto/1000)*2))*precio_individual);
            }else{
              aluminio=(( ((ancho/1000)*2)+((alto/1000)*2))*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio+vidrio;
          break;
          case '31':

            if(es_anodizado!=1)
            {
              aluminio=((  ((ancho/1000)*2)+(alto/1000) )*precio_individual);
            }else{
              aluminio=(( ((ancho/1000)*2)+(alto/1000) )*precio_individual_anodizado);
            }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio+vidrio;
          break;
          case '32':

           if(es_anodizado!=1)
           {
             aluminio=((  ((ancho/1000)*2)+((alto/1000)*2))*precio_individual);
           }else{
             aluminio=(( ((ancho/1000)*2)+((alto/1000)*2))*precio_individual_anodizado);
           }
            vidrio=((ancho/1000)*(alto/1000)*precio_individual_vidrio);

            presupuesto=aluminio+vidrio;
          break;
          case  '34':

            aluminio=(ancho/1000)*(alto/1000)*precio_individual;
            presupuesto=aluminio;
          break;
          default:
              presupuesto=(ancho/1000*alto/1000*precio_individual)
            break;
        }

      }
      return presupuesto.toFixed(2);
    }
}

import { Component ,OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cerrapp';
  showSpinner=false
  ngOnInit(){

  }
  ngOnChanges(changes: SimpleChanges){

  }


}

import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ClientesService} from '../../services/clientes.service';
import {AuthService}from '../../services/auth.service';
import {Router}from '@angular/router';
@Component({
  selector: 'app-proveedortarjeta',
  templateUrl: './proveedor.tarjeta.component.html',
  styleUrls: ['./proveedor.tarjeta.component.css']
})
export class ProveedorTarjetaComponent implements OnInit {
  @Input() cliente:any={};
    @Output() clienteSeleccionado:EventEmitter<number>;
    @Input() habilitadoeditar;
    @Input() habilitadover;
    @Input() habilitadohistoricoPago;
    @Input() habilitadoingresoPago;
    @Input() habilitadoPresupuesto;
    @Input() habilitadoListadoPresupuesto;
  constructor(private router:Router,private clienteService:ClientesService,private spinner: NgxSpinnerService,private auth:AuthService) {
    this.clienteSeleccionado=new EventEmitter();
   }

  ngOnInit() {

  }

}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, Validators} from '@angular/Forms';
import {UsuariosService} from '../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {AuthService}from '../../services/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-nuevouser',
  templateUrl: './nuevouser.component.html',
  styleUrls: ['./nuevouser.component.css']
})
export class NuevouserComponent implements OnInit {
  forma:FormGroup;
  profile = {
              'nombrecompleto': new FormGroup({
                'dni':new FormControl('',[Validators.required,Validators.minLength(6),Validators.pattern("[0-9]*")]),
                'nombre': new FormControl('',[Validators.required,Validators.minLength(3)]),
                'apellido': new FormControl('',[Validators.required,Validators.minLength(3)])

              }),
                'tipo': new FormControl('',[Validators.required])
            };
  constructor( private usuariosService: UsuariosService,private spinner: NgxSpinnerService,private auth:AuthService) {
      this.auth.seguimientoPantallas(2).subscribe(data=>{});
    this.forma=new FormGroup({
      'nombrecompleto': new FormGroup({
        'dni':new FormControl('',[Validators.required,Validators.minLength(6),Validators.pattern("[0-9]*")]),
        'nombre': new FormControl('',[Validators.required,Validators.minLength(3)]),
        'apellido': new FormControl('',[Validators.required,Validators.minLength(3)])

      }),
          'tipo': new FormControl('',[Validators.required])
    })
   }

  ngOnInit() {
  }
  guardarCambios(){

    if(this.forma.valid)
    {
      this.spinner.show();
      console.log(this.forma.value.nombrecompleto.nombre);
      this.usuariosService.crearUsuario(this.forma.value.nombrecompleto.dni,this.forma.value.nombrecompleto.nombre,this.forma.value.nombrecompleto.apellido,this.forma.value.tipo).subscribe(data=>{
        this.spinner.hide()
        if(data.success)
        {
          Swal.fire( {type: 'success',
              title: 'Realizado',
              text:data.messagge});
          this.forma.reset(this.profile)

        }else{
          Swal.fire( {type: 'error',
              title: 'Oops...',
              text:data.messagge});
        }
      })
    }
    else
    {
      Swal.fire( {type: 'error',
          title: 'Oops...',
          text:'Quedan campos sin completas o no son validos'});
    }
  //  this.forma.reset(this.usuario);
  }
}

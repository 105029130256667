import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';

import {Router} from "@angular/router";
import {Usuario} from "../interface/usuario.interface";
import {NavItem} from "../interface/nav.items";
interface myData{success: boolean, messagge: string,usuario?:Usuario,areasPantallas?:NavItem[]};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInStatus= false;
  private usuario:Usuario={id:1,dni:'',nombre:'',apellido:''};
  private areasPantallas:NavItem[];
  private nombrephp:string='https://cerrapp.com/auth.php';
  //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/auth.php';
  constructor(private http:HttpClient,private router:Router) {
   }

  setLoggedIn(value:boolean){
  //  console.log('ada');
    sessionStorage.setItem('loggedInStatus',String(value));
    this.loggedInStatus= Boolean(value);
  }
  public isLoggedIn(){

    this.loggedInStatus=Boolean(sessionStorage.getItem('loggedInStatus'));
    return this.loggedInStatus;
  }
  setUsuario(usuar:Usuario, pantallas){

    sessionStorage.setItem('dni',usuar.dni);
    sessionStorage.setItem('id',String(usuar.id));
    sessionStorage.setItem('apellido',usuar.apellido);
    sessionStorage.setItem('nombre', usuar.nombre);
    sessionStorage.setItem('usuario', usuar.usuario);
    sessionStorage.setItem('pantalla',JSON.stringify(pantallas));
    //console.log(pantallas);
  }
  public preguntarUsuario(){
    
    this.usuario={id:Number(sessionStorage.getItem('id')),usuario:sessionStorage.getItem('usuario'),dni:sessionStorage.getItem('dni'),nombre:sessionStorage.getItem('nombre'),apellido:sessionStorage.getItem('apellido')};
    return this.usuario;
  }
  setAreas(area:NavItem[]){
    sessionStorage.setItem('pantalla',JSON.stringify(area));
    //this.areasPantallas=area;
  }

  buscarArea(){
    let accion='buscarpantallas';
    let id=sessionStorage.getItem('id');
    //console.log('entre'+ id)
    let body=JSON.stringify({id,accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }

  public preguntarArea():NavItem[]{
    let data=JSON.parse(sessionStorage.getItem('pantalla'));
   //console.log( data)
  //  data.each()
    return data;
  }
  public logout(): void {
    this.setLoggedIn(false)
    console.log('entro')
    // Remove isLoggedIn flag from localStorage
  //localStorage.removeItem('isLoggedIn');
    this.areasPantallas=[];
   sessionStorage.clear();
    // Go back to the home route
    this.router.navigate(['/login']);
  }
  getUserDetails(username,password){
    let accion='logear';
    let tipo="web"
    let body=JSON.stringify({username,password,tipo,accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
    //post this details from user
  }
  seguimientoPantallas(idpantalla)
  {
    let accion='guardarSeguimiento';
    let idusuario=sessionStorage.getItem('id');

    let body=JSON.stringify({idpantalla,idusuario,accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
}

import { Component, OnInit } from '@angular/core';
import {ClientesService} from '../../services/clientes.service';
import {PresupuestosService } from '../../services/presupuestos.service'
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {Movimiento} from '../../interface/movimientos.interface';
import {MovimientosService} from '../../services/movimientos.service'
import {clientesModels} from '../../models/clientes.models';
import {AuthService} from "../../services/auth.service";


@Component({
  selector: 'app-nuevo-movimiento-cliente',
  templateUrl: './nuevo-movimiento-cliente.component.html',
  styleUrls: ['./nuevo-movimiento-cliente.component.css']
})
export class NuevoMovimientoClienteComponent implements OnInit {
  cliente:clientesModels= new clientesModels();
  movimientos;
  conceptos;
  cuentas_depositos;
  presupuestos;
  bancos;
  cheque;
  tipo_moneda
  visible=false;
  visible2=false;
  profile = {
              fecha:"",
              forma_pago: "",
              concepto:"",
              cuenta_deposito: "1",
              tipo_moneda:"1",
              importe:"",
              valor_cambio:"0",
              detalle:"",
              signo: "+",
              visible: '1',
              cheque:this._forma.array([])
            };
  forma:FormGroup;
  //formaCheque:FormGroup;
  constructor(private presupuestoservice:PresupuestosService,private Auth: AuthService,private movimientosService:MovimientosService,private clienteService:ClientesService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder)
  {
    this.Auth.seguimientoPantallas(10).subscribe(data=>{});
    this.forma=this._forma.group({
                                      fecha:['',Validators.required],
                                      forma_pago: ['',Validators.required],
                                      concepto: ['',Validators.required],
                                      cuenta_deposito: ['1',Validators.required],
                                      tipo_moneda: ['1',Validators.required],
                                      valor_cambio:['0.0',[Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      importe: ['',[Validators.required,Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      detalle:['',Validators.required],
                                      id_presupuestos:new FormArray([]),
                                      signo: ['+',Validators.required],
                                      visible: ['1',Validators.required],
                                      cheque:this._forma.array([])
                                })
      this.spinner.show();
       this.activatedRoute.params.subscribe( params=>{
         this.clienteService.listarClientesid(params['id']).subscribe(data=>{
           this.cliente=data.clientesModels;
         })  
         this.presupuestoservice.listar_presupuesto_personas(params['id'],'CLIENTE').subscribe(data=>{
           this.presupuestos=data;
           //console.log(data);
         });
       });
       this.movimientosService.listarformapago().subscribe(data=>{
           this.movimientos=data;
           this.spinner.hide();
           //console.log(this.pais)
       });
     
       this.movimientosService.listarconcepto().subscribe(data=>{
           this.conceptos=data;
           this.spinner.hide();
           //console.log(this.pais)
       });
       this.movimientosService.listartipomoneda().subscribe(data=>{
           this.tipo_moneda=data;
           this.spinner.hide();
           //console.log(this.pais)
       });
       this.movimientosService.listarcheque('',this.forma.value.tipo_moneda).subscribe(data=>{
           this.spinner.hide();
           this.cheque= data;
      })
       this.movimientosService.listarcuentadeposito().subscribe(data=>{
           this.cuentas_depositos=data;
           this.spinner.hide();
           //console.log(this.pais)
       });
       this.movimientosService.listarbanco().subscribe(data=>{
         this.bancos=data;
        // console.log(data);
       })
  }
  ngOnInit() {
  }
guardarCambios(){
//  console.log(this.forma)
    if(this.forma.valid)
    {
      this.spinner.show();
      let movimiento = {detalle:this.forma.value.detalle,fecha:this.forma.value.fecha,fkforma_pago:this.forma.value.forma_pago,fktipo_moneda:this.forma.value.tipo_moneda,valor_cambio:this.forma.value.valor_cambio,visible:this.forma.value.visible,fkconcepto:this.forma.value.concepto, fk_cuenta_deposito:this.forma.value.cuenta_deposito,cheque:this.forma.value.cheque,importe:this.forma.value.importe,signo:this.forma.value.signo,presupuestos:this.forma.value.id_presupuestos};
      this.activatedRoute.params.subscribe( params=>{
         this.movimientosService.insertarMovimiento(movimiento,params['id'],'cliente').subscribe(data=>{
          this.spinner.hide()
          if(data.success)
          {
            Swal.fire( {type: 'success',
                title: 'Realizado',
                text:data.messagge});
            this.forma.reset(this.profile)
            this.movimientosService.donwloadMovimientos(data.id);
            this.visible=false;
            this.visible2=false;

          }else{
            Swal.fire( {type: 'error',
                title: 'Oops...',
                text:data.messagge});
          }
        })
      })
    }
    else
    {
        Swal.fire( {type: 'error',
        title: 'Oops...',
        text: 'Quedan campos sin completas o no son validos'});
    }
  }
  agregarPresupuesto(){
    (<FormArray>this.forma.controls['id_presupuestos']).push(
        new FormControl('', Validators.required)
    )
  }
  eliminarPresupuesto(i){
      (<FormArray>this.forma.controls['id_presupuestos']).removeAt(i-1);
  }
  cambiovisible(){
    this.visible=false;
    this.visible2=false;
    for(let i=0;i<=this.chequeArray.length+1 ;i++)
    {
        this.eliminarCheque(i);
    }
    this.eliminarCheque(0);
    if(this.forma.value.forma_pago=='3'  || this.forma.value.forma_pago=='4' || this.forma.value.forma_pago=='5')
    {

      this.visible=true;
    }else{
      if(this.forma.value.forma_pago=='2')
      {
        this.visible2=true;
        this.forma.controls['importe'].reset( '',[Validators.required,Validators.pattern("[0-9]*")]);
        if(this.forma.value.signo=='+')
        {
          this.insertarCheque();
        }
      }
    }
  }
  agregarCheque(){
    return this._forma.group({
      id_cheque:[''],
      fecha_cobro:['',Validators.required],
      numero_cheque:['',[Validators.required,Validators.pattern("[0-9]*")]],
      banco:['',Validators.required],
      bancoNombre:[''],
      importe_cheque:['',Validators.required]
    })
  }
  ponerCheque(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    return this._forma.group({
      id_cheque:[cheque.pkcheque],
      fecha_cobro:[cheque.fecha_cobro,Validators.required],
      numero_cheque:[cheque.numero_cheque,[Validators.required,Validators.pattern("[0-9]*")]],
      banco:[cheque.fkbanco,Validators.required],
      bancoNombre:[cheque.banco],
      importe_cheque:[cheque.importe,Validators.required]
    })
    //console.log(cheque)
  }
  buscarCheque(termino:string){
    this.spinner.show();
      this.movimientosService.listarcheque(termino, this.forma.value.tipo_moneda).subscribe(data=>{
          this.spinner.hide();
          this.cheque= data;
        })
  }
  insertarCheque(){
    this.chequeArray.push( this.agregarCheque());
  }
  insertarChequeEgreso(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    this.chequeArray.push( this.ponerCheque(cheque));
    this.importe_cheque();
  }
  importe_cheque(){
    let importe_cheque:number=0;
    for (let control of this.chequeArray.controls) {
      //console.log(control)
      importe_cheque= importe_cheque+ parseFloat(control.value.importe_cheque);
    }
    this.forma.controls['importe'].reset( importe_cheque,[Validators.required]);
  }
  cambiar_cheque(){
    this.importe_cheque();
  }
  eliminarCheque(index){
    this.chequeArray.removeAt(index);
  }
  get chequeArray(){
    return <FormArray>this.forma.get('cheque');
  }
  cambiar_tipo_moneda(){
  //  console.log('aca entro')
    this.movimientosService.listarcheque('',this.forma.value.tipo_moneda).subscribe(data=>{
        this.spinner.hide();
        this.cheque= data;
      })
  }

}

export class clientesModels {
  id:number ;
  tipo:number;
  tiponombre?:string;
  dni:string;
  nombre:string;
  apellido:string;
  cuit:number;
  pais:string;
  localidad:string;
  calle:string;
  altura:number;
  piso:string;
  depto:string;
  observacion:string;
  telefono:number[];
  mail:string[];
  saldo:number;
  constructor(){

  }
}

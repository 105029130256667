import { Component, OnInit } from '@angular/core';
import {Router}from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {ClientesService} from '../../services/clientes.service';
//import {ClientesService} from '../../services/clientes.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormGroup,FormControl, Validators,FormArray,ReactiveFormsModule} from '@angular/Forms';
import {Cliente} from '../../interface/cliente.interface';
import {UsuariosService} from "../../services/usuarios.service";
import {AuthService} from "../../services/auth.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-listado-clientes',
  templateUrl: './listado-clientes.component.html',
  styleUrls: ['./listado-clientes.component.css']
})
export class ListadoClientesComponent implements OnInit {
  localidad ;
  buscado:boolean=false;
  tipo_cliente;
  termino:string='';
  clientes;
  private habilitadoeditar;
  private habilitadover;
  private habilitadohistoricoPago;
  private habilitadoingresoPago;
  private habilitadoPresupuesto;
  private habilitadoListadoPresupuesto;
  myControl = new FormControl();
  filteredOptions: Observable<String[]>;
  forma:FormGroup;
  constructor(private activatedRoute: ActivatedRoute,private http: HttpClient,private Auth: AuthService,private usuariosService:UsuariosService,private router:Router,private spinner: NgxSpinnerService,private clienteService:ClientesService) {
    this.Auth.seguimientoPantallas(6).subscribe(data=>{});
    this.spinner.show();

    this.forma=new FormGroup({
                            'deudores': new FormControl('',[Validators.required]),
                            'localidad': new FormControl('',[Validators.required]),
                            'localidadtexto': new FormControl('',[Validators.required]),
                            'tipotext': new FormControl('1',[Validators.required]),
                            'tipo':  new FormControl('', Validators.required),
                        })


      this.myControl.setValidators(Validators.required);
      this.clienteService.tipo_cliente().subscribe(data=>{
        this.tipo_cliente=data;
        this.spinner.hide();
        //console.log(this.pais)
      });
   }
  ngOnInit() {
    this.activatedRoute.queryParams
     .subscribe(params => {
       //console.log(params); // {order: "popular"}
       if(params.termino)
       {
         this.termino = params.termino;
          this.buscarUsuario(this.termino)
       }


     });
    this.usuariosService.permisopantalla('7').subscribe(data=>{
        this.habilitadoeditar=data.success;
    });
    this.usuariosService.permisopantalla('8').subscribe(data=>{
        this.habilitadover=data.success;
    });
    this.usuariosService.permisopantalla('9').subscribe(data=>{
        this.habilitadohistoricoPago=data.success;
    });
    this.usuariosService.permisopantalla('10').subscribe(data=>{
        this.habilitadoingresoPago=data.success;
    });
    this.usuariosService.permisopantalla('12').subscribe(data=>{
        this.habilitadoPresupuesto=data.success;
    });
    this.usuariosService.permisopantalla('37').subscribe(data=>{
        this.habilitadoListadoPresupuesto=data.success;
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  buscarUsuario(termino:string){
    //this.router.navigate(['/buscar-cliente',termino,deudores,tipo,localidad,tipoint,localidadstr]);
    //console.log('ddd')
    this.spinner.show();
    this.termino=termino;
    this.buscado=true;
    this.clienteService.listarClientes(termino,this.forma.value.deudores,this.forma.value.tipo,this.forma.value.tipotext,this.forma.value.localidad,this.myControl.value).subscribe(data=>{
          this.spinner.hide();
          //console.log(data);
          this.clientes= data;

        })
    //this.heroes=this._heroesService.buscarHeroes(params['termino']);
  }
  private _filter(value: String): String[] {
    this.localidad=this.clienteService.listarLocalidades();
    if(value!='' && value.length>=4)
    {
      const filterValue = value.toLowerCase();
      return this.localidad.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UsuariosService} from '../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {Usuario} from '../../interface/usuario.interface';
import {NavItem} from '../../interface/nav.items';
import {FormGroup,FormControl, Validators, FormArray} from '@angular/Forms';
import {AuthService}from '../../services/auth.service';
import {PresupuestosService} from '../../services/presupuestos.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

    forma:FormGroup;
  usuario:any={};
  estados;
  pantallas:NavItem[];
//habilitado:boolean[]

constructor(private presupuestoservice: PresupuestosService,private activatedRoute: ActivatedRoute,  private _usuarioService: UsuariosService,private spinner: NgxSpinnerService,private auth:AuthService) {
    this.spinner.show();
    this.auth.seguimientoPantallas(3).subscribe(data=>{});
    this.actualizar();

}
  ngOnInit() {
    }
    actualizar(){
      this.activatedRoute.params.subscribe( params=>{
        this._usuarioService.listaruserespecifico( params['id']).subscribe(data=>{
          this.usuario=data.usuario;
        });
        this._usuarioService.buscarArea( params['id']).subscribe(data=>{
          
          this.pantallas=data.areasPantallas;
          this.estados=data.estados;
          this.spinner.hide();

        });
        
    })
    }
  guardarEstado(id_estado,a){
    this.estados[a]['habilitado'] = !this.estados[a]['habilitado'];
   
    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
      this._usuarioService.guardaestadopermitido( params['id'],id_estado,this.estados[a]['habilitado']).subscribe(data=>
      {
        this.spinner.hide();
        this.actualizar();
      });
    });
  }
  guardarCambios(id_pantalla,id_usuario,i,h){
    this.pantallas[i].children[h].habilitado = !this.pantallas[i].children[h].habilitado;
    let estado=this.pantallas[i].children[h].habilitado;

    this.spinner.show();
    this._usuarioService.habilitarpantalla(id_usuario,id_pantalla,estado).subscribe(data=>
    {
       //console.log(data)
       this.actualizar();
        this.spinner.hide();
    })

  }


}

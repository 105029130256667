import { Component, OnInit } from '@angular/core';
import {ClientesService} from '../../services/clientes.service';
import {ProveedoresService} from '../../services/proveedores.service';
import {FormGroup,FormControl, Validators,FormArray,ReactiveFormsModule} from '@angular/Forms';
import {NgxSpinnerService } from 'ngx-spinner';
import {AuthService}from '../../services/auth.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-nuevo-proveedor',
  templateUrl: './nuevo-proveedor.component.html',
  styleUrls: ['./nuevo-proveedor.component.css']
})
export class NuevoProveedorComponent implements OnInit {
  localidad:String[] ;
  pais;
  tipo_cliente;
  myControl = new FormControl();
  filteredOptions: Observable<String[]>;
  forma:FormGroup;
  profile = {
              'nombrecompleto': {
                'dni':'',
                'nombre': '',
                'apellido': '',
                'cuit': '',
                'pais':'1',
                'calle': '',
                'altura': '',
                'piso':'',
                'depto': '',
              },
              'tipo':'1',
              'telefono': '',
              'mail': '',
              'observaciones':'',
            };
  constructor(private provedoresService: ProveedoresService,private clienteService: ClientesService,private spinner: NgxSpinnerService,private auth:AuthService,private http: HttpClient) {
    this.spinner.show();
    this.auth.seguimientoPantallas(42).subscribe(data=>{});
    this.forma=new FormGroup({
                            'nombrecompleto': new FormGroup({
                              'dni':new FormControl('',[Validators.minLength(6),Validators.pattern("[0-9]*")]),
                              'nombre': new FormControl('',[Validators.required,Validators.minLength(3)]),
                              'apellido': new FormControl('',[Validators.minLength(3)]),
                              'cuit': new FormControl('',[Validators.pattern("[0-9]{11}")]),
                              'pais':new FormControl('1', Validators.required),
                              'calle': new FormControl('',Validators.required),
                              'altura': new FormControl('',[Validators.required,Validators.pattern("[0-9]*")]),
                              'piso': new FormControl(''),
                              'depto': new FormControl(''),
                            }),
                            'tipo': new FormControl('1',[Validators.required]),
                            'telefono': new FormArray([ new FormControl('')]),
                            'mail': new FormArray([ new FormControl('',Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,10}$"))]),
                            'observaciones':new FormControl(''),
                        })

      this.localidad=this.clienteService.listarLocalidades();
      this.clienteService.listarpaises().subscribe(data=>{
          this.pais=data;
          //console.log(this.pais)
      });
      this.clienteService.tipo_cliente().subscribe(data=>{
          this.tipo_cliente=data;
          //console.log(this.pais)
          this.spinner.hide();
      });
      this.myControl.setValidators(Validators.required);
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  private _filter(value: String): String[] {
    this.localidad=this.clienteService.listarLocalidades();
    if(value!='' && value.length>=4)
    {
      const filterValue = value.toLowerCase();
      return this.localidad.filter(option => option.toLowerCase().includes(filterValue));
    }
  }
  agregarTelefono(){
    (<FormArray>this.forma.controls['telefono']).push(
        new FormControl('', Validators.required)
    )
  }
  eliminarTelefono(i){
    //console.log(i>'0')
    //i=this.forma.controls['telefono'];
    if(i>1){
        (<FormArray>this.forma.controls['telefono']).removeAt(i-1);
      }
  }
  eliminarMail(i){
    console.log(i>'0')
    //i=this.forma.controls['telefono'];
    if(i>1){
        (<FormArray>this.forma.controls['mail']).removeAt(i-1);
      }
  }
  agregarMail(){
    (<FormArray>this.forma.controls['mail']).push(
        new FormControl('', Validators.pattern("^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$"))
    )
  }


  guardarCambios(){

    if(this.forma.valid)
    {
      this.spinner.show();
      let proveedor = {tipo:this.forma.value.tipo ,dni:this.forma.value.nombrecompleto.dni,nombre:this.forma.value.nombrecompleto.nombre,apellido:this.forma.value.nombrecompleto.apellido, cuit:this.forma.value.nombrecompleto.cuit,pais:this.forma.value.nombrecompleto.pais,localidad:this.myControl.value ,calle:this.forma.value.nombrecompleto.calle,altura:this.forma.value.nombrecompleto.altura, piso:this.forma.value.nombrecompleto.piso,depto: this.forma.value.depto,telefono:this.forma.value.telefono,mail:this.forma.value.mail,observaciones:this.forma.value.observacion};
      this.provedoresService.crearProveedor(proveedor).subscribe(data=>{
        //alert('aca')
        this.spinner.hide()
        //window.alert(data.messagge)
        if(data.success)
        {
          Swal.fire( {type: 'success',
              title: 'Realizado',
              text:data.messagge});
          //this.forma.reset(this.profile)
          //  this.myControl.reset()
         //  window.location.href ="/listadoClientes?termino="+this.forma.value.nombrecompleto.nombre+" "+this.forma.value.nombrecompleto.apellido,"_blank";

        }else{
          Swal.fire( {type: 'error',
              title: 'Oops...',
              text:data.messagge});
        }

      })
    }
    else
    {
        Swal.fire( {type: 'error',
          title: 'Oops...',
          text:'Quedan campos sin completas o no son validos'});
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl, Validators, FormArray} from '@angular/Forms';
import Swal from 'sweetalert2';
import {AuthService} from '../../services/auth.service';
import {Router} from "@angular/router";
import {UsuariosService} from '../../services/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editaruser',
  templateUrl: './editaruser.component.html',
  styleUrls: ['./editaruser.component.css']
})
export class EditaruserComponent implements OnInit {
      usuario;
      forma:FormGroup;
  constructor(private auth:AuthService,private spinner: NgxSpinnerService,private usuariosService: UsuariosService,private router:Router) {
    this.usuario= auth.preguntarUsuario();
    this.forma=new FormGroup({
      'usuario':new FormControl(this.usuario.usuario,[Validators.required,Validators.minLength(4)]),
      'password':new FormControl('',[Validators.required,Validators.minLength(4)])
    })
   }

  ngOnInit() {
  }
  guardarCambios(){

      if(this.forma.valid)
      {
        this.spinner.show();
        this.usuariosService.modificarUsuarioName(this.usuario.id,this.forma.value.usuario,this.forma.value.password).subscribe(data=>{
          this.spinner.hide()
        //window.alert(data.messagge)
          if(data.success)
          {
            Swal.fire( {type: 'success',
                        title: 'Realizado',
                        text:data.messagge}).then((result) => {
                          let usuario=data.usuario
                          this.auth.buscarArea().subscribe(data=>{

                            this.auth.setUsuario(usuario,data.areasPantallas);
                            this.usuario= this.auth.preguntarUsuario();
                            this.forma.get('password').setValue('')
                            //window.location.reload();
                          })


                  });
          }else{
            Swal.fire( {type: 'error',
                title: 'Oops...',
                text:data.messagge});
          }
        })
      }
      else
      {
        Swal.fire( {type: 'error',
                    title: 'Oops...',
                    text:'Quedan campos sin completas o no son validos'});
      }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdfpresupuesto',
  templateUrl: './pdfpresupuesto.component.html',
  styleUrls: ['./pdfpresupuesto.component.css']
})
export class PdfpresupuestoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import {Router}from '@angular/router';
import {AuthService} from '../../../services/auth.service';
//import {HomeComponent} from '../../home/home.component'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor( private router:Router,public auth:AuthService) {
    if(this.auth.isLoggedIn())
    {

        this.auth.buscarArea().subscribe(data=>{
          this.auth.setAreas(data.areasPantallas);
      })
    }
  }

  ngOnInit() {

  }

  login(){
    this.router.navigate(['login']);
  }
  salir(){
      this.auth.logout();
      this.router.navigate(['login']);
  }

}

import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {ClientesService} from '../../services/clientes.service';
import {ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {UsuariosService} from "../../services/usuarios.service";
import {Movimiento} from '../../interface/movimientos.interface';
import {MovimientosService} from '../../services/movimientos.service'
import {  clientesModels} from '../../models/clientes.models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-historialpagos',
  templateUrl: './historialpagos.component.html',
  styleUrls: ['./historialpagos.component.css']
})
export class HistorialpagosComponent implements OnInit {
  movimientos;
  forma:FormGroup;
  tipo_moneda;
  habilitadoRechazar
  @ViewChild('content') content:ElementRef;
  cliente:clientesModels= new clientesModels();
  constructor(private usuariosService:UsuariosService,private Auth: AuthService,private movimientosService:MovimientosService,private clienteService:ClientesService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder) {
    this.Auth.seguimientoPantallas(9).subscribe(data=>{});
    this.spinner.show();
    this.forma=this._forma.group({
                                      fecha_desde:['',Validators.required],
                                      fecha_hasta: ['',Validators.required]

                                })
    this.activatedRoute.params.subscribe( params=>{
      this.clienteService.listarClientesid(params['id']).subscribe(data=>{
        //console.log(data.clientesModels);
        this.cliente=data.clientesModels;
      })
    });
    this.usuariosService.permisopantalla('16').subscribe(data=>{

        this.habilitadoRechazar=data.success;
    });
    this.movimientosService.listartipomoneda().subscribe(data=>{
      this.tipo_moneda=data;
      //console.log(data)
    })
    this.activatedRoute.params.subscribe( params=>{
      this.movimientosService.lista_movimientos_personal(params['id'],'CLIENTE').subscribe(data=>{
        this.spinner.hide();
        console.log(data);
        this.movimientos=data.movimientos;

      })
    //this.heroes=this._heroesService.buscarHeroes(params['termino']);
    });
  }
  ngOnInit() {

  }
  downloadPDF() {
    //  $log.log(url);*/
      if(this.forma.valid)
      {
        this.activatedRoute.params.subscribe( params=>{
              var url = "https://cerrapp.com/PdfMovimientos.php?id="+params['id']+"&tipo=CLIENTE&fecha_desde="+this.forma.value.fecha_desde+'&fecha_hasta='+this.forma.value.fecha_hasta;
          window.open(url,"_blanck")
        //this.heroes=this._heroesService.buscarHeroes(params['termino']);
        });
      }else{
        this.activatedRoute.params.subscribe( params=>{
              var url = "https://cerrapp.com/PdfMovimientos.php?id="+params['id']+"&tipo=CLIENTE";
          window.open(url,"_blanck")
      //this.heroes=this._heroesService.buscarHeroes(params['termino']);
      });
      }

  }
  imprimir(idM){
  //  this.Auth.seguimientoPantallas(22).subscribe(data=>{});
    this.movimientosService.donwloadMovimientos(idM);
  }
  buscarFecha(){
    this.activatedRoute.params.subscribe( params=>{
      this.movimientosService.lista_movimientos_personal(params['id'],'CLIENTE',this.forma.value.fecha_desde,this.forma.value.fecha_hasta).subscribe(data=>{
        this.spinner.hide();
        //console.log(data);
        this.movimientos=data.movimientos;
      })
    });
  }
  eliminarMovimiento(id){
    this.Auth.seguimientoPantallas(16).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea rechazar este movimiento? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.movimientosService.eliminarMovimiento(id).subscribe(data=>{
            this.spinner.hide();
            if(data.success)
            {

                Swal.fire(

                            'Realizado',
                            data.messagge,
                           'success');
               this.activatedRoute.params.subscribe( params=>{
                 this.movimientosService.lista_movimientos_personal(params['id'],'CLIENTE').subscribe(data=>{
                   this.spinner.hide();
                   //console.log(data);
                   this.movimientos=data.movimientos;

                 })
              });
            }else{
              Swal.fire(    'Oops',
                              data.messagge,
                              'error'
                );
            }
          });
        }
    });
  }
/*  public downloadPDF(){
    return xepOnline

  }*/
}

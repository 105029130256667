import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../../services/usuarios.service';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {MovimientosService} from '../../services/movimientos.service'
import {Movimiento} from '../../interface/movimientos.interface';
import {clientesModels} from '../../models/clientes.models';
import {AuthService} from "../../services/auth.service";
@Component({
  selector: 'app-movimientos-usuario',
  templateUrl: './movimientos-usuario.component.html',
  styleUrls: ['./movimientos-usuario.component.css']
})
export class MovimientosUsuarioComponent implements OnInit {
  usuario:any={};
  movimientos;
  conceptos;
  cuentas_depositos;
  bancos;
  cheque;
  tipo_moneda
  visible=false;
  visible2=false;
  forma:FormGroup;
  profile = {
              fecha:"",
              forma_pago: "",
              concepto:"",
              cuenta_deposito: "1",
              tipo_moneda:"1",
              importe:"",
              detalle:"",
              signo: "+",
              visible: '1',
              cheque:this._forma.array([])
            };
  constructor(private Auth: AuthService,private movimientosService:MovimientosService,private _usuarioService:UsuariosService,private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private _forma:FormBuilder) {
    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
        this._usuarioService.listaruserespecifico( params['id']).subscribe(data=>{
          this.usuario=data.usuario;
        });
        this.spinner.hide();
    })
    this.forma=this._forma.group({
                                      fecha:['',Validators.required],
                                      forma_pago: ['',Validators.required],
                                      concepto: ['',Validators.required],
                                      cuenta_deposito: ['1',Validators.required],
                                      tipo_moneda: ['1',Validators.required],
                                      importe: ['',[Validators.required,Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]],
                                      detalle:['',Validators.required],
                                      signo: ['+',Validators.required],
                                      visible: ['1',Validators.required],
                                      cheque:this._forma.array([])
                                })
     this.movimientosService.listarformapago().subscribe(data=>{
          this.movimientos=data;
          this.spinner.hide();
          //console.log(this.pais)
      });
      this.movimientosService.listarconcepto().subscribe(data=>{
          this.conceptos=data;
          this.spinner.hide();
          //console.log(this.pais)
      });
      this.movimientosService.listartipomoneda().subscribe(data=>{
          this.tipo_moneda=data;
          this.spinner.hide();
          //console.log(this.pais)
      });
      this.movimientosService.listarcheque('',this.forma.value.tipo_moneda).subscribe(data=>{
          this.spinner.hide();
          this.cheque= data;
     })
      this.movimientosService.listarcuentadeposito().subscribe(data=>{
          this.cuentas_depositos=data;
          this.spinner.hide();
          //console.log(this.pais)
      });
      this.movimientosService.listarbanco().subscribe(data=>{
        this.bancos=data;

      })
  }

  ngOnInit() {
  }
  guardarCambios(){
    //console.log(this.forma.value.fecha+'--')
      if(this.forma.valid)
      {
        this.spinner.show();
        let movimiento = {detalle:this.forma.value.detalle,fecha:this.forma.value.fecha,fkforma_pago:this.forma.value.forma_pago,fktipo_moneda:this.forma.value.tipo_moneda,visible:this.forma.value.visible,fkconcepto:this.forma.value.concepto, fk_cuenta_deposito:this.forma.value.cuenta_deposito,cheque:this.forma.value.cheque,importe:this.forma.value.importe,signo:this.forma.value.signo};
        this.activatedRoute.params.subscribe( params=>{
           this.movimientosService.insertarMovimiento(movimiento,params['id'],'usuario').subscribe(data=>{
            this.spinner.hide()
            if(data.success)
            {
              Swal.fire( {type: 'success',
                  title: 'Realizado',
                  text:data.messagge});
              this.forma.reset(this.profile)
              this.movimientosService.donwloadMovimientos(data.id);
              this.visible=false;
              this.visible2=false;
            }else{
              Swal.fire( {type: 'error',
                  title: 'Oops...',
                  text:data.messagge});
            }
          })
        })
      }
      else
      {
          Swal.fire( {type: 'error',
          title: 'Oops...',
          text: 'Quedan campos sin completas o no son validos'});
      }
    }
  cambiovisible(){
    this.visible=false;
    this.visible2=false;
    for(let i=0;i<=this.chequeArray.length+1 ;i++)
    {
        this.eliminarCheque(i);
    }
    this.eliminarCheque(0);
    if(this.forma.value.forma_pago=='3'  || this.forma.value.forma_pago=='4' || this.forma.value.forma_pago=='5')
    {

      this.visible=true;
    }else{
      if(this.forma.value.forma_pago=='2')
      {
        this.visible2=true;
        this.forma.controls['importe'].reset( '',[Validators.required,Validators.pattern("[0-9]*")]);
        if(this.forma.value.signo=='+')
        {
          this.insertarCheque();
        }
      }
    }
  }
  agregarCheque(){
    return this._forma.group({
      id_cheque:[''],
      fecha_cobro:['',Validators.required],
      numero_cheque:['',[Validators.required,Validators.pattern("[0-9]*")]],
      banco:['',Validators.required],
      bancoNombre:[''],
      importe_cheque:['',Validators.required]
    })
  }
  ponerCheque(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    return this._forma.group({
      id_cheque:[cheque.pkcheque],
      fecha_cobro:[cheque.fecha_cobro,Validators.required],
      numero_cheque:[cheque.numero_cheque,[Validators.required,Validators.pattern("[0-9]*")]],
      banco:[cheque.fkbanco,Validators.required],
      bancoNombre:[cheque.banco],
      importe_cheque:[cheque.importe,Validators.required]
    })
    //console.log(cheque)
  }
  buscarCheque(termino:string){
    this.spinner.show();
      this.movimientosService.listarcheque(termino, this.forma.value.tipo_moneda).subscribe(data=>{
          this.spinner.hide();
          this.cheque= data;
        })
  }
  insertarCheque(){
    this.chequeArray.push( this.agregarCheque());
  }
  insertarChequeEgreso(cheque:{'pkcheque':number,'fkbanco':number;'banco':string;'numero_cheque':string;'fecha_cobro':string;'importe':number}){
    this.chequeArray.push( this.ponerCheque(cheque));
    this.importe_cheque();
  }
  importe_cheque(){
    let importe_cheque:number=0;
    for (let control of this.chequeArray.controls) {
      //console.log(control)
      importe_cheque= importe_cheque+ parseFloat(control.value.importe_cheque);
    }
    this.forma.controls['importe'].reset( importe_cheque,[Validators.required]);
  }
  cambiar_cheque(){
    this.importe_cheque();
  }
  eliminarCheque(index){
    this.chequeArray.removeAt(index);
  }
  get chequeArray(){
    return <FormArray>this.forma.get('cheque');
  }
  cambiar_tipo_moneda(){
  //  console.log('aca entro')
    this.movimientosService.listarcheque('',this.forma.value.tipo_moneda).subscribe(data=>{
        this.spinner.hide();
        this.cheque= data;
      })
  }

}

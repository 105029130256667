import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from '@angular/router';
import {ClientesService} from '../../services/clientes.service';
import {Cliente} from '../../interface/cliente.interface'
import { NgxSpinnerService } from 'ngx-spinner';
import {  clientesModels} from '../../models/clientes.models';
import {FormGroup,FormControl, Validators,FormArray,ReactiveFormsModule} from '@angular/Forms';
@Component({
  selector: 'app-buscar-cliente',
  templateUrl: './buscar-cliente.component.html',
  styleUrls: ['./buscar-cliente.component.css']
})
export class BuscarClienteComponent implements OnInit {
  cliente:clientesModels= new clientesModels();
  constructor(private Auth: AuthService,private activatedRoute: ActivatedRoute,
    private clienteService: ClientesService,private spinner: NgxSpinnerService) {
      this.Auth.seguimientoPantallas(8).subscribe(data=>{});
    }

  ngOnInit() {
    this.spinner.show();

    this.activatedRoute.params.subscribe( params=>{
      this.clienteService.listarClientesid(params['id']).subscribe(data=>{
        this.spinner.hide();
        this.cliente= data.clientesModels;
      })
    //this.heroes=this._heroesService.buscarHeroes(params['termino']);
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MaterialModule} from './material';

import { MatNativeDateModule, DateAdapter } from '@angular/material';
import {FileuploadService} from './services/fileupload.service';
import { DateFormat } from './date-format';
//Formularios

import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
//rutas
import {APP_ROUTING } from "./app.routes";
//componentes
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';

//servicios
import { AuthService} from "./services/auth.service";
import {AuthGuardService } from "./services/auth-guard.service";
import { NavBarComponent } from './components/shared/nav-bar/nav-bar.component';
import { MatProgressSpinnerModule } from '@angular/material';

//

import { NuevouserComponent } from './components/nuevouser/nuevouser.component';
import { ListadouserComponent } from './components/listadouser/listadouser.component';
import { UsuarioTarjetaComponent } from './components/usuario.tarjeta/usuario.tarjeta.component';
import { BuscarUsuarioComponent } from './components/buscar-usuario/buscar-usuario.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { ContraseniaComponent } from './components/contrasenia/contrasenia.component';
import { DatospersonalesComponent } from './components/datospersonales/datospersonales.component';
import { EditaruserComponent } from './components/editaruser/editaruser.component';
import { NuevoClienteComponent } from './components/nuevo-cliente/nuevo-cliente.component';
import { ListadoClientesComponent } from './components/listado-clientes/listado-clientes.component';
import { ClienteTarjetaComponent } from './components/cliente.tarjeta/cliente.tarjeta.component';
import { BuscarClienteComponent } from './components/buscar-cliente/buscar-cliente.component';
import { EditarClienteComponent } from './components/editar-cliente/editar-cliente.component';
import { HistorialpagosComponent } from './components/historialpagos/historialpagos.component';
import { NuevoMovimientoClienteComponent } from './components/nuevo-movimiento-cliente/nuevo-movimiento-cliente.component';
import { NuevoMovimientoComponent } from './components/nuevo-movimiento/nuevo-movimiento.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NuevoPresupuestoComponent } from './components/nuevo-presupuesto/nuevo-presupuesto.component';
import { CuentaCorrienteComponent } from './components/cuenta-corriente/cuenta-corriente.component';
import { ListadoChequeComponent } from './components/listado-cheque/listado-cheque.component';
import { MovimientosUsuarioComponent } from './components/movimientos-usuario/movimientos-usuario.component';
import { HistorialpagosusuariosComponent } from './components/historialpagosusuarios/historialpagosusuarios.component';
import { ListadoPresupuestosComponent } from './components/listado-presupuestos/listado-presupuestos.component';
import { PdfpresupuestoComponent } from './components/pdfpresupuesto/pdfpresupuesto.component';
import { ListadoPresupuestoClienteComponent } from './components/listado-presupuesto-cliente/listado-presupuesto-cliente.component';
import { NuevoPresupuestoUsuarioComponent } from './components/nuevo-presupuesto-usuario/nuevo-presupuesto-usuario.component';
import { ListadoPresupuestoUsuarioComponent } from './components/listado-presupuesto-usuario/listado-presupuesto-usuario.component';
import { ClientesService } from './services/clientes.service';
import { NuevoProveedorComponent } from './components/nuevo-proveedor/nuevo-proveedor.component';
import { ListadoProveedoresComponent } from './components/listado-proveedores/listado-proveedores.component';
import { EditarProveedorComponent } from './components/editar-proveedor/editar-proveedor.component';
import { ProveedorTarjetaComponent } from './components/proveedor.tarjeta/proveedor.tarjeta.component';
import { CajaChicaComponent } from './components/caja-chica/caja-chica.component';
import { TranspasoCajachicaComponent } from './components/transpaso-cajachica/transpaso-cajachica.component';
import { InfoPresupuestoComponent } from './components/info-presupuesto/info-presupuesto.component';
import { ArchivosPresupuestosComponent } from './components/archivos-presupuestos/archivos-presupuestos.component';
import { EstadosEventosComponent } from './components/estados-eventos/estados-eventos.component';
import { ComentariosComponent } from './components/comentarios/comentarios.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavBarComponent,
    NuevouserComponent,
    ListadouserComponent,
    UsuarioTarjetaComponent,
    BuscarUsuarioComponent,
    UsuarioComponent,
    ContraseniaComponent,
    DatospersonalesComponent,
    EditaruserComponent,
    NuevoClienteComponent,
    ListadoClientesComponent,
    ClienteTarjetaComponent,
    BuscarClienteComponent,
    EditarClienteComponent,
    HistorialpagosComponent,

    NuevoMovimientoClienteComponent,

    NuevoMovimientoComponent,

    NuevoPresupuestoComponent,

    CuentaCorrienteComponent,

    ListadoChequeComponent,

    MovimientosUsuarioComponent,

    HistorialpagosusuariosComponent,

    ListadoPresupuestosComponent,

    PdfpresupuestoComponent,

    ListadoPresupuestoClienteComponent,

    NuevoPresupuestoUsuarioComponent,

    ListadoPresupuestoUsuarioComponent,

    NuevoProveedorComponent,

    ListadoProveedoresComponent,

    EditarProveedorComponent,

    ProveedorTarjetaComponent,

    CajaChicaComponent,

    TranspasoCajachicaComponent,

    InfoPresupuestoComponent,

    ArchivosPresupuestosComponent,

    EstadosEventosComponent,

    ComentariosComponent


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    APP_ROUTING,
    MaterialModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
  // CDK
  ],

  providers: [AuthService,
              AuthGuardService,
              FileuploadService,
            	{ provide: DateAdapter, useClass: DateFormat }

            ],


  bootstrap: [AppComponent]
})
export class AppModule { constructor(private dateAdapter:DateAdapter<Date>,auth:AuthService) {
		dateAdapter.setLocale('en-in'); // DD/MM/YYYY

	} }

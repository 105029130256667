import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,FormControl, Validators,FormArray} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {UsuariosService} from "../../services/usuarios.service";
import Swal from 'sweetalert2';
import {AuthService} from "../../services/auth.service";
import {MovimientosService} from '../../services/movimientos.service';
import { containsElement } from '@angular/animations/browser/src/render/shared';
@Component({
  selector: 'app-caja-chica',
  templateUrl: './caja-chica.component.html',
  styleUrls: ['./caja-chica.component.css']
})
export class CajaChicaComponent implements OnInit {
  forma:FormGroup;
  movimientostabla;
  tipo_moneda;
  conceptos;
  conceptos_importe
  saldo;
  movimientoParticular;
  habilitadoRechazar
  
  constructor(private usuariosService:UsuariosService,private spinner: NgxSpinnerService,private _forma:FormBuilder,private Auth: AuthService,private movimiento:MovimientosService ) { 
    this.Auth.seguimientoPantallas(43).subscribe(data=>{});
    this.forma=new FormGroup({
      montos:new FormArray([]),
      montos_finales:new FormArray([]),
      fecha_inicio: new FormControl('', []),
      pkcaja:new FormControl('',[])
    })
    
    this.usuariosService.permisopantalla('16').subscribe(data=>{
      this.habilitadoRechazar=data.success;
  });
    this.movimiento.listarconcepto().subscribe(data=>{
      this.conceptos=data;
    })
    this.movimiento.listartipomoneda().subscribe(data=>{
      this.tipo_moneda=data;
    
     this.listarCaja();
    })
    
  }
  agregartipo(fktipo,simbolo,monto='0.00'){
   
      
      (this.forma.get('montos') as FormArray).push(new FormGroup({
        monto_inicial:new FormControl(monto,[Validators.required,Validators.pattern("[0-9]*")]),
        fktipo_moneda:new FormControl(fktipo,[]),
        simbolo:new FormControl(simbolo,[]),
      }))
     
   
  }
  agregartipofinales(fktipo,simbolo,monto='0.00'){
   
      
    (this.forma.get('montos_finales') as FormArray).push(new FormGroup({
      monto_final:new FormControl(monto,[Validators.required,Validators.pattern("[0-9]*")]),
      fktipo_moneda:new FormControl(fktipo,[]),
      simbolo:new FormControl(simbolo,[]),
    }))
 
  } 
  ngOnInit() {
    
  }
  async listarCaja(){
    console.log('j')
    this.movimiento.listacaja().subscribe(data=>{
     // console.log(data)
      let caja= data.caja;
      
     
      if(data.success)
      {
      //  console.log(data)
        this.movimientostabla=data.movimientos;
        this.saldo=data.saldo;
        this.conceptos_importe=data.concepto;
        this.forma=new FormGroup({
          montos:new FormArray([]),
          montos_finales:new FormArray([]),
          fecha_inicio: new FormControl(caja.fecha_inicio, []),
          pkcaja:new FormControl(caja.pkcaja,[])
        })
        
        caja.montos_iniciales.forEach(element=>{
          this.agregartipo(element['fktipo_moneda'],element['simbolo'],element['monto'])
        })
        caja.montos_finales.forEach(element=>{
          this.agregartipofinales(element['fktipo_moneda'],element['simbolo'],element['monto'])
        })
     
      }else{
        this.movimientostabla;
        
        this.forma=new FormGroup({
          monto_inicial:new FormControl('0.00',[Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
          montos:new FormArray([]),
          montos_finales:new FormArray([]),
          fecha_inicio: new FormControl('', []),
          pkcaja:new FormControl('',[])
        })
        this.tipo_moneda.forEach(element=>{
          
          this.agregartipo(element['pktipo_moneda'],element['simbolo'])
        })
        this.tipo_moneda.forEach(element=>{
          this.agregartipofinales(element['pktipo_moneda'],element['simbolo'])
        })
      }
      
    });
  }
  abrir(){
    this.spinner.show();
    this.movimiento.abrir(this.forma.value.montos).subscribe(data=>{
      this.spinner.hide();
      this.listarCaja();
    });
  }
  cerrar(){
    this.spinner.show();
    this.movimiento.cerrar(this.forma.value.montos_finales, this.forma.value.pkcaja).subscribe(data=>{
      this.spinner.hide();
      this.listarCaja();
    });
  }
  buscarid(id){
    this.spinner.show();
    this.movimiento.lista_movimiento_id(id).subscribe(data=>{
        this.movimientoParticular=data.movimientos;
        this.spinner.hide();

    });
  }
  eliminarMovimiento(id){
    this.Auth.seguimientoPantallas(16).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea rechazar este movimeinto? No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
            this.spinner.show();
            this.movimiento.eliminarMovimiento(id).subscribe(data=>{
            this.spinner.hide();
              if(data.success)
              {

                Swal.fire(

                            'Realizado',
                            data.messagge,
                           'success');
                this.movimiento.lista_movimiento().subscribe(data=>{
                    this.movimientostabla=data.movimientos;
                    this.conceptos_importe=data.concepto;
                      this.saldo=data.saldo;

                });
              }else{
                Swal.fire(    'Oops',
                              data.messagge,
                              'error'
                );
              }
            });
        }
    });
  }
}

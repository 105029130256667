import { Component, OnInit ,Input,Output, EventEmitter} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {UsuariosService} from '../../services/usuarios.service';
import {AuthService}from '../../services/auth.service';
@Component({
  selector: 'app-usuariotarjeta',
  templateUrl: './usuario.tarjeta.component.html',
  styleUrls: ['./usuario.tarjeta.component.css']
})
export class UsuarioTarjetaComponent implements OnInit {
  @Input() usuario:any={};
  @Input() index: number;
  @Input() pantalla: string;
  @Input() termino: string;
  @Input() habilitadobloquear;
  @Input() habilitadopermisopantalla;
  @Input() habilitadomovimiento;
  @Input() habilitadohistorial;
  @Input() habilitadopresupuesto;
  @Input() habilitadolistadopresupuesto;
  @Output() usuarioSeleccionado:EventEmitter<number>;

  constructor(private usuariosService:UsuariosService,private spinner: NgxSpinnerService,private auth:AuthService) {
      this.usuarioSeleccionado=new EventEmitter();
      //console.log(this.usuarioSeleccionado)

  }

  ngOnInit() {

  }
  desactivar(idx:number,estadox:string,pantallax:string,terminox?:string){
    this.auth.seguimientoPantallas(4).subscribe(data=>{});
    let estado=estadox;
    let pantalla=pantallax
    let termino=terminox
    this.spinner.show();
    this.usuariosService.habilitarUsuario(idx, estado).subscribe(data=>{
      //console.log(data);
      if(pantalla=="todos")
      {
        this.usuariosService.listaruser().subscribe(data=>{
          this.usuariosService.setListadoUsuario(data.usuarios);
          this.spinner.hide();
        })
      }
      else
      {
        this.usuariosService.listaruserbuscado(termino).subscribe(data=>{
          this.usuariosService.setListadoUsuario(data.usuarios);
          this.spinner.hide();
        })
      }
      //this.usuariosService.setListadoUsuario(data.usuarios);
    })
  }

}

import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders}from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from "@angular/router";
import {Usuario} from "../interface/usuario.interface";
import {NavItem} from "../interface/nav.items";
import {AuthService} from "../services/auth.service";
interface myData{success: boolean, messagge: string,usuario?:Usuario,usuarios?:Usuario[],areasPantallas?:NavItem[],estados?:any[]};

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private listadoUsuarios:Usuario[];
  private areasPantallas:NavItem[];
  private nombrephp:string='https://cerrapp.com/auth.php';
  //private nombrephp:string='http://localhost/proyecto_angular/cerrapp/src/app/test/api/auth.php';
  constructor(private http:HttpClient,private router:Router,private _authService:AuthService) { }

  //listar usuarios con varios criterios
  listaruser(){
    let accion='listaruser';
    let body=JSON.stringify({accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listaruserbuscado(termino){
    let accion='listaruserbuscador';
    let terminopasar=termino
    //console.log(terminopasar)
    let body=JSON.stringify({accion,terminopasar} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  listaruserespecifico(idx){
    let accion='listaruserespecifico';
    let id=idx;
    //console.log(terminopasar)
    let body=JSON.stringify({accion,id} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }

//todas las pantallas del usuario para ver permisos

  buscarArea(idx){
    let accion='buscarpantallastodas';
    let id=idx;
    let body=JSON.stringify({id,accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }



  //acciones sobre los usuarios
  habilitarUsuario(id,estado){
    let accion='habilitarusuario';
    let idU=id
    let estadoU=estado
    //console.log(terminopasar)
    let body=JSON.stringify({accion,idU,estadoU} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  habilitarpantalla(idusuario,idpantalla,estado){
    let accion='habilitarpantallas';
    let idU=idusuario;
    let idP=idpantalla;
    let estadoU=estado
    //console.log(terminopasar)
    let body=JSON.stringify({accion,idU,idP,estadoU} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }

  crearUsuario(dni,nombre,apellido,tipo){
    let accion='crearusuario';
    let dniu=dni;
    let nombreu=nombre;
    let apellidou=apellido;
    let tipou=tipo;
    //console.log(terminopasar)
    let body=JSON.stringify({accion,dniu,nombreu,apellidou,tipou} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  modificarContrasenia(password,passwordn,idusuario){
    let accion='modificarContrasenia';
    let idu=idusuario;
    let passwordu=password;
    let passwordnuevau=passwordn;

    let body=JSON.stringify({accion,idu,passwordu,passwordnuevau} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  modificarUsuario(idusuario,dni,nombre,apellido,password){
    let accion='modificarUsuario';
    let idu=idusuario;
    let dniu=dni;
    let nombreu=nombre;
    let apellidou=apellido;
    let passwordu=password;

    let body=JSON.stringify({accion,idu,nombreu,apellidou,passwordu,dniu} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  modificarUsuarioName(idusuario,username,password){
    let accion='modificarUsuarioName';
    let idu=idusuario;
    let usernameu=username;
    let passwordu=password;

    let body=JSON.stringify({accion,idu,usernameu,passwordu} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  // setear y devolver variables (listados de usuarios, pantallas etc)
  setListadoUsuario(usuarios:Usuario[]){
    this.listadoUsuarios=usuarios;
  }

  public preguntarListadoUsuario():Usuario[]{

    return this.listadoUsuarios;
  }

  setAreas(area:NavItem[]){
    this.areasPantallas=area;
  }
  public preguntarArea():NavItem[]{

    return this.areasPantallas;
  }

  permisopantalla(id_pantalla){
    let accion='buscarpantallahabilitadaespecifica';
    let idu=this._authService.preguntarUsuario().id;
    let idp=id_pantalla;
    //console.log(terminopasar)
    let body=JSON.stringify({accion,idu,idp} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  estadosHabilitados(id_usuario){
    let accion='buscarpantallastodas';
    let body=JSON.stringify({id_usuario,accion} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
  guardaestadopermitido(id_usuario,id_estado,habilitado){
    let accion='guardar_estadopermitido';
    let idusuariosession=sessionStorage.getItem('id');
    let body=JSON.stringify({accion,id_usuario,id_estado,habilitado,idusuariosession} );
    let headers=new HttpHeaders({
      'Content-Type':'application/json'
    });
    return this.http.post<myData>(this.nombrephp,body
    ,{headers:headers});
  }
}

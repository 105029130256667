import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,FormBuilder,FormArray,Validators,ReactiveFormsModule} from '@angular/forms';
import {PresupuestosService} from "../../services/presupuestos.service";
import {AuthService} from "../../services/auth.service";
import {UsuariosService} from "../../services/usuarios.service";
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-info-presupuesto',
  templateUrl: './info-presupuesto.component.html',
  styleUrls: ['./info-presupuesto.component.css']
})
export class InfoPresupuestoComponent implements OnInit {
  forma2:FormGroup;
  variablepantalla='';
  variableestado='';
  parametroid=''
  habilitadoinfo;
  habilitadoverprecio;
  habilitadoconfirmarItem;
  habilitadoconfirmarEstado;
  habilitadoeditardescuento;
  habilitadoeditarfecha_entrega;
  habilitadoeditararea;
  habilitadoeditarobservacion;
  cambiar_descuento:boolean=false;
  estados;
  presupuesto_particular;
  constructor(private _forma:FormBuilder,private presupuestoService:PresupuestosService,private activatedRoute: ActivatedRoute,private Auth: AuthService,private usuariosService:UsuariosService,private spinner: NgxSpinnerService) {
    this.presupuestoService.listarestado().subscribe(data=>{
      //this.spinner.hide();
      this.estados= data;
      //console.log(data)
    })
    this.forma2=this._forma.group({
      detalles:this._forma.array( []),
      historial_estado:this._forma.array([]),
      fecha:[''],
      fecha_entrega:[''],
      area:[''],
      observacion:[''],
      tiene_desc:['0'],
      tipo_descuento:['fijo'],
      valor_descuento:[''],
      importe_confirmado:[''],
      importe: [''],
      importe_sin_descuento:[''],
      importe_confirmado_sin_descuento:[''],
      importe_descuento:[''],
      importe_descuento_confirmado:['']
    })

    this.buscarid()
  }

  ngOnInit() {
  }

  async buscarid(){
    this.Auth.seguimientoPantallas(21).subscribe(data=>{});
    this.usuariosService.permisopantalla('41').subscribe(data=>{
      this.habilitadoeditarfecha_entrega=data.success;
    });
    this.usuariosService.permisopantalla('45').subscribe(data=>{
      this.habilitadoeditararea=data.success;
    });
    this.usuariosService.permisopantalla('27').subscribe(data=>{
      this.habilitadoconfirmarEstado=data.success;
    });
    this.usuariosService.permisopantalla('44').subscribe(data=>{
      this.habilitadoverprecio=data.success;
    });
    this.usuariosService.permisopantalla('40').subscribe(data=>{
      this.habilitadoeditardescuento=data.success;
    });
    this.usuariosService.permisopantalla('48').subscribe(data=>{
      this.habilitadoeditarobservacion=data.success;
    });
    this.spinner.show();
    this.activatedRoute.params.subscribe( params=>{
      this.variablepantalla=params['pantalla'];
      this.variableestado=params['idestado'];
      console.log(this.variableestado)
      this.parametroid=params['idclienteusuario'];
      this.presupuestoService.listar_presupuesto_id(params['id'],'').subscribe(data=>{
        this.spinner.hide();
        this.presupuesto_particular= data;
        let tiene_desc:boolean;
        this.vaciardetalleArray();
        if(this.presupuesto_particular[0].tiene_descuento==0)
        {
          tiene_desc=false;
        }else{
          tiene_desc=true;
        }
        if(this.presupuesto_particular[0].importe_confirmado_sin_descuento==0)
        {
          //console.log(this.presupuesto_particular[0].importe_confirmado_sin_descuento);
          this.usuariosService.permisopantalla('26').subscribe(data=>{
              this.habilitadoconfirmarItem=data.success;
          });
        }else{
          this.habilitadoconfirmarItem=false;
        }

        let fechaDate = new Date(this.presupuesto_particular[0].fecha_entrega + ' 0:00:00');
        for (let elemento of this.presupuesto_particular[0].detalle){
            this.detalleArray.push( this.agregardetalledatos(elemento.id_detalle,elemento.cantidad,elemento.id_articulo,elemento.detalle,elemento.precio_detalle,elemento.total,elemento.confirmado));
        }
        for (let elemento of this.presupuesto_particular[0].historialEstado){
          this.estado.push( this.agregarhistorialdatos(elemento.fkestado,elemento.estado,elemento.fecha_inicio,elemento.fecha_hasta));
        }
      //  
        this.forma2.controls['fecha'].reset( this.presupuesto_particular[0].fecha_presupuesto);
        this.forma2.controls['fecha_entrega'].reset( this.presupuesto_particular[0].fecha_entrega);
        this.forma2.controls['area'].reset(this.presupuesto_particular[0].area);
        this.forma2.controls['observacion'].reset(this.presupuesto_particular[0].observacion);
        this.forma2.controls['tiene_desc'].reset( tiene_desc, [Validators.required]);
        this.forma2.controls['tipo_descuento'].reset( this.presupuesto_particular[0].tipo_descuento, [Validators.required]);
        this.forma2.controls['valor_descuento'].reset(this.presupuesto_particular[0].valor_descuento,[Validators.required]);
        this.forma2.controls['importe_confirmado'].reset( this.presupuesto_particular[0].importe_confirmado, [Validators.required]);
        this.forma2.controls['importe'].reset( this.presupuesto_particular[0].importe, [Validators.required]);
        this.forma2.controls['importe_sin_descuento'].reset(this.presupuesto_particular[0].importe_sin_descuento,[Validators.required]);
        this.forma2.controls['importe_confirmado_sin_descuento'].reset(this.presupuesto_particular[0].importe_confirmado_sin_descuento,[Validators.required]);
        this.forma2.controls['importe_descuento'].reset(this.presupuesto_particular[0].descuento_total);
        this.forma2.controls['importe_descuento_confirmado'].reset(this.presupuesto_particular[0].descuento_total_confirmado);
      //  console.log(this.forma2);
      });
    });
  }
  get detalleArray(){
    return <FormArray>this.forma2.get('detalles');
  }
  get estado(){
    return <FormArray>this.forma2.get('historial_estado');
  }
  vaciardetalleArray(){
    while (this.detalleArray.length !== 0) {
      this.detalleArray.removeAt(0)
    }
  }
  
  agregardetalledatos(id_detalle,cantidad,id_articulo,articulo,importe_detalle,importe_total,confirmado){
    return this._forma.group({
      id_detalle:[id_detalle],
      cantidad:[cantidad,[Validators.required,Validators.pattern("[0-9]*")]],
      id_articulo:[id_articulo,''],
      articulo:[articulo,Validators.required],
      importe_detalle:[importe_detalle,Validators.required],
      confirmado_detalle:[confirmado,''],
      total_detalle:[importe_total,''],
    })
  }
  agregarhistorialdatos(fkestado,estado,fecha_inicio,fecha_hasta){
    return this._forma.group({
      fkestado:[fkestado],
      estado:[estado],
      fecha_inicio:[fecha_inicio],
      fecha_hasta:[fecha_hasta]
    })
  }
  editarFecha(id_presupuesto,id_pantalla){
    this.Auth.seguimientoPantallas(id_pantalla).subscribe(data=>{});
    Swal.fire({
      title: 'Estas seguro?',
      text: "¿Desea cambiar la fecha de entrega?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Aceptar '
    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        
        this.presupuestoService.updateFecha(id_presupuesto,this.forma2.value.fecha_entrega).subscribe(data=>{

            if(data.success)
            {
              this.presupuestoService.listar_presupuesto_id(id_presupuesto,'').subscribe(data=>{
              // this.spinner.hide();
                this.presupuesto_particular= data;
            });
            this.buscarid();
            Swal.fire( 'Realizado',
                          data.messagge,
                        'success');
          }else{
            this.spinner.hide()
            Swal.fire(    'Oops',
                          data.messagge,

                          'error'
            );
          }
        })
      }
    })
  }
  editararea(id_presupuesto,id_pantalla)
  {
    this.Auth.seguimientoPantallas(id_pantalla).subscribe(data=>{});
    Swal.fire({
      title: 'Estas seguro?',
      text: "¿Desea cambiar el Area/Subcliente?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Aceptar '
    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.presupuestoService.updateArea(id_presupuesto,this.forma2.value.area).subscribe(data=>{
          if(data.success)
                   {
                     this.presupuestoService.listar_presupuesto_id(id_presupuesto,'').subscribe(data=>{
                      // this.spinner.hide();
                       this.presupuesto_particular= data;
                    });
                   this.buscarid();
                    Swal.fire( 'Realizado',
                                  data.messagge,
                                'success');
                  }else{
                    this.spinner.hide()
                    Swal.fire(    'Oops',
                                  data.messagge,

                                  'error'
                    );
                  }
        });
      }
    });
  }
  editarObservacion(id_presupuesto,id_pantalla)
  {
    this.Auth.seguimientoPantallas(id_pantalla).subscribe(data=>{});
    Swal.fire({
      title: 'Estas seguro?',
      text: "¿Desea cambiar la observación?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Aceptar '
    }).then((result) => {
      if (result.value) {
        this.spinner.show()
        this.presupuestoService.updateObservacion(id_presupuesto,this.forma2.value.observacion).subscribe(data=>{
          if(data.success)
                   {
                     this.presupuestoService.listar_presupuesto_id(id_presupuesto,'').subscribe(data=>{
                      // this.spinner.hide();
                       this.presupuesto_particular= data;
                    });
                   this.buscarid();
                    Swal.fire( 'Realizado',
                                  data.messagge,
                                'success');
                  }else{
                    this.spinner.hide()
                    Swal.fire(    'Oops',
                                  data.messagge,

                                  'error'
                    );
                  }
        });
      }
    });
  }
  editarestado(id_presupuesto,id_estado,importe_confirmado,id_pantalla){
    this.Auth.seguimientoPantallas(id_pantalla).subscribe(data=>{});
    if(id_estado=='')
    {
      Swal.fire(    'Oops',
                    'Debe seleccionar un estado',

                    'error'
      );
    }else
    {
        if(( id_estado!=6 && parseInt(importe_confirmado)>0)|| (id_estado==6 && parseInt(importe_confirmado)<=0)){
            Swal.fire({
              title: 'Estas seguro?',
              text: "¿Desea cambiar de estado? " ,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText:'Cancelar',
              confirmButtonText: 'Aceptar '
            }).then((result) => {
              if (result.value) {
                this.spinner.show()
                this.presupuestoService.updateEstado(id_presupuesto,id_estado).subscribe(data=>{

                   if(data.success)
                   {
                     this.presupuestoService.listar_presupuesto_id(id_presupuesto,'').subscribe(data=>{
                      // this.spinner.hide();
                       this.presupuesto_particular= data;
                    });
                    this.buscarid();
                    Swal.fire( 'Realizado',
                                  data.messagge,
                                'success');
                  }else{
                    this.spinner.hide()
                    Swal.fire(    'Oops',
                                  data.messagge,

                                  'error'
                    );
                  }
                })
              }
          })
        }else{
          Swal.fire(    'Oops',
                        'Si desea cambiar el estado de un presupuesto sin items confirmados primero deberá confirmar al menos un item, asi mismo si el presupuesto posee items confirmados el estado NO debe ser "SIN CONFIRMAR", Verifique el estado y los items confirmados',
                        'error');
        }
    }

  }
  habilitar_modificar_descuento(){
    if(!this.cambiar_descuento)
    {
      this.cambiar_descuento=true;
    }else
    {
      this.cambiar_descuento=false;
    }
  }
  importeConfirmado(i){
    let importe_detalle:number=0;
    let importe_total:number=0;
    let importe_descuento:number=0;
    let importe_descuento_confirmado:number=0;
    let descuento_detalle:number=0;
    let descuento_total:number=0;
    let confirmado='0';
    //console.log(this.detalleArray);
    this.detalleArray.at(i).value.total_detalle= this.detalleArray.at(i).value.importe_detalle*this.detalleArray.at(i).value.cantidad
    // this.forma2.controls['detalles'][i].reset(this.forma2.value.detalles.at(i).importe_detalle*this.forma2.value.detalles.at(i).cantidad);
    
    if((this.detalleArray.at(i).value.precio_detalle>0 && this.detalleArray.at(i).value.confirmado) || !this.detalleArray.at(i).value.confirmado )
    {

      for (let control of this.detalleArray.value) {
        for (let control2 of this.presupuesto_particular[0].detalle )
         {
           
           if(control2.id_detalle== control.id_articulo)
           {
             control2.confirmado=control.confirmado_detalle
           }
         }
        if(control.confirmado_detalle==1)
        {
          //console.log(control.precio_detalle);
          confirmado='1';
          importe_detalle=importe_detalle+ (parseFloat(control.importe_detalle)*parseFloat(control.cantidad))
        }
        importe_total=importe_total+ (parseFloat(control.importe_detalle)*parseFloat(control.cantidad));
      }
      if(this.forma2.value.tiene_desc==1)
      {
        if(this.forma2.value.tipo_descuento!='fijo')
        {
          if(importe_detalle>0)
          {
            descuento_detalle=(((importe_detalle*parseFloat(this.forma2.value.valor_descuento))/100));
          }
          descuento_total=(((importe_total*parseFloat(this.forma2.value.valor_descuento))/100));
        }
        else
        {
          if(importe_detalle>0)
          {
            descuento_detalle=(parseFloat(this.forma2.value.valor_descuento));
          }
          descuento_total=(parseFloat(this.forma2.value.valor_descuento));
        }

      }
      importe_descuento_confirmado=importe_detalle-descuento_detalle;
      importe_descuento=importe_total-descuento_total;
      this.presupuesto_particular[0].detalle=this.forma2.value.detalles
      console.log(this.forma2.value.detalles)
      console.log(this.presupuesto_particular)
      this.presupuesto_particular[0].importe_confirmado_sin_descuento=importe_detalle
      this.presupuesto_particular[0].importe_confirmado=importe_descuento_confirmado
      this.forma2.controls['importe_confirmado'].reset( importe_descuento_confirmado.toFixed(2), [Validators.required]);
      this.forma2.controls['importe'].reset( importe_descuento.toFixed(2), [Validators.required]);
      this.forma2.controls['importe_sin_descuento'].reset(importe_total.toFixed(2),[Validators.required]);
      this.forma2.controls['importe_confirmado_sin_descuento'].reset(importe_detalle.toFixed(2),[Validators.required]);
      this.forma2.controls['importe_descuento'].reset(descuento_total.toFixed(2));
      this.forma2.controls['importe_descuento_confirmado'].reset(descuento_detalle.toFixed(2));
    }else{
      Swal.fire( {type: 'error',
      title: 'Oops...',
      text: 'Debe ingresar un importe'});
      this.presupuesto_particular[0].detalle[i].get('confirmado_detalle').setValue(false)
    }
  }
  modificarDescuento(){
    this.Auth.seguimientoPantallas(40).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea modificar los descuentos? esto afectará a la cuenta corriente",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          var variableerror=false;
          if(this.forma2.value.tiene_desc=='1'){
            switch(this.forma2.value.tipo_descuento) { 
              case 'fijo':
                this.forma2.value.importe_descuento=this.forma2.value.valor_descuento;
                this.forma2.value.importe_descuento_confirmado=this.forma2.value.valor_descuento;
                this.forma2.value.importe_confirmado=this.forma2.value.importe_confirmado_sin_descuento-this.forma2.value.importe_descuento_confirmado;
                this.forma2.value.importe=this.forma2.value.importe_confirmado_sin_descuento-this.forma2.value.importe_descuento;
                console.log(this.forma2);
              break;
              case 'variable':
                this.forma2.value.importe_descuento=(parseFloat(this.forma2.value.importe_sin_descuento)*parseFloat(this.forma2.value.valor_descuento))/100;
                this.forma2.value.importe_descuento_confirmado=(parseFloat(this.forma2.value.importe_confirmado_sin_descuento)*parseFloat(this.forma2.value.valor_descuento))/100;
                this.forma2.value.importe_confirmado=this.forma2.value.importe_confirmado_sin_descuento-this.forma2.value.importe_descuento_confirmado;
                this.forma2.value.importe=this.forma2.value.importe_sin_descuento-this.forma2.value.importe_descuento;
               // console.log('variable');
                //console.log(this.forma2);
              break;
              case '':
                variableerror=true
                console.log('vacio')
              break
              
            }

          }else{
            this.forma2.value.importe=this.forma2.value.importe_sin_descuento;
            this.forma2.value.importe_confirmado=this.forma2.value.importe_confirmado_sin_descuento;
            this.forma2.value.importe_descuento=0;
            this.forma2.value.importe_descuento_confirmado=0;
          }
          if(!variableerror)
          {
            this.presupuesto_particular[0].detalle=this.forma2.value.detalles;
            this.presupuesto_particular[0].tiene_descuento=this.forma2.value.tiene_desc;
            this.presupuesto_particular[0].tipo_descuento=this.forma2.value.tipo_descuento;
            this.presupuesto_particular[0].valor_descuento=this.forma2.value.valor_descuento;
  
            this.presupuesto_particular[0].importe_confirmado=this.forma2.value.importe_confirmado;
            this.presupuesto_particular[0].importe=this.forma2.value.importe;
  
            this.presupuesto_particular[0].importe_sin_descuento=this.forma2.value.importe_sin_descuento;
            this.presupuesto_particular[0].importe_confirmado_sin_descuento=this.forma2.value.importe_confirmado_sin_descuento;
  
            this.presupuesto_particular[0].descuento_total=this.forma2.value.importe_descuento;
            this.presupuesto_particular[0].descuento_total_confirmado=this.forma2.value.importe_descuento_confirmado;
            this.presupuestoService.actualizarDescuento(this.presupuesto_particular).subscribe(data=>{
              this.spinner.hide()
              if(data.success)
              {
                this.presupuestoService.listar_presupuesto_id(this.presupuesto_particular[0]['id'],'').subscribe(data=>{
                  // this.spinner.hide();
                   this.presupuesto_particular= data;
                });
                this.buscarid();
                this.habilitar_modificar_descuento()
                Swal.fire( 'Realizado',
                              data.messagge,
                            'success');
              }else{
                Swal.fire(    'Oops', data.messagge,'error');
              }

            });
          }else{
            this.spinner.hide()
            Swal.fire(    'Oops','El presupuesto posee descuento deberá proporcionar un tipo de descuento',
                          'error'
              );

          }
        }
    })
  }
  confirmardetalle(){
    this.Auth.seguimientoPantallas(26).subscribe(data=>{});
    Swal.fire({
        title: 'Estas seguro?',
        text: "¿Desea confirmar estos detalles? El estado del presupuesto se cambiará a 'Toma de requerimiento y diagrama' y No podrás deshacer esta accion",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar '
        }).then((result) => {
        if (result.value) {
          this.spinner.show()
          let confirmacion=false;
          for (let control of this.forma2.value.detalles) {
            //console.log(control)
            if(control.confirmado_detalle)
            { 
              confirmacion=true;  
            }
          }

         /*console.log(this.forma2.value);
          console.log(this.presupuesto_particular);*/
          this.presupuesto_particular[0]['detalle']=this.forma2.value.detalles;
          this.presupuesto_particular[0].tiene_descuento=this.forma2.value.tiene_desc;
          this.presupuesto_particular[0].tipo_descuento=this.forma2.value.tipo_descuento;
          this.presupuesto_particular[0].valor_descuento=this.forma2.value.valor_descuento;
          this.presupuesto_particular[0].importe_confirmado=this.forma2.value.importe_confirmado;
          this.presupuesto_particular[0].importe=this.forma2.value.importe;
          this.presupuesto_particular[0].importe_sin_descuento=this.forma2.value.importe_sin_descuento;
          this.presupuesto_particular[0].importe_confirmado_sin_descuento=this.forma2.value.importe_confirmado_sin_descuento;
          this.presupuesto_particular[0].descuento_total=this.forma2.value.importe_descuento;
          this.presupuesto_particular[0].descuento_total_confirmado=this.forma2.value.importe_descuento_confirmado;
         
          this.presupuestoService.confirmadetalle(this.presupuesto_particular,confirmacion).subscribe(data=>{
          this.spinner.hide()
            if(data.success)
             {
                console.log(confirmacion)
                if(confirmacion)
                {
                  console.log('entro')
                  this.presupuestoService.updateEstado( this.presupuesto_particular[0]['id'],7).subscribe(data=>{
                    if(data.success)
                    {
                      this.presupuestoService.listar_presupuesto_id(this.presupuesto_particular[0]['id'],'CLIENTE').subscribe(data=>{
                        this.presupuesto_particular= data;
                      });
                      this.buscarid();
                      Swal.fire( 'Realizado',
                                    data.messagge,
                                  'success');
                    }else{
                      this.spinner.hide()
                      Swal.fire(    'Oops',
                                    data.messagge,
   
                                    'error'
                      );
                    }
                  })
                    
                } 
                  
            }else{
              Swal.fire(    'Oops',
                            data.messagge,

                            'error'
              );
            }
          })
        }
    })
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FileuploadService {
URL="https://cerrapp.com/subirArchivo.php";
//URL="http://localhost/proyecto_angular/cerrapp/src/app/test/api/subirArchivo.php";

  constructor(private http: HttpClient) { }
  uploadFile(archivo){
    return this.http.post(this.URL,JSON.stringify(archivo))
  }
}
